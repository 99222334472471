import { all, call, delay, fork, put } from "redux-saga/effects";
import {
  AuthService,
  AttachmentService,
  AccountService,
  AccountDeviceService,
  AccountKeyService,
  MessageService,
  ContactService,
  ConversationService,
} from "services";
import { handlingLogin, toCamel } from "utils";
import {
  LocalAccountService,
  LocalApiCallService,
  LocalAttachmentService,
  LocalContactService,
  LocalDeviceService,
  LocalEmojiService,
  LocalGroupService,
  LocalKeyActionService,
} from "services/local.service";
import { saveKeysOfDevice } from "./account-key.saga";
import { getBranch, getBranchAccount } from "./branch.saga";
import { SYNCH_NORMAL_MSEC, SYNCH_NOW_MSEC, formatPagingParams } from "./saga.helper";
import { getGroupMember } from "./conversation-info.saga";
import { deviceFetching } from "./account-device.saga";
import { sendMessage2Server } from "./conversation-message.saga";
import { ApiConstant, KeyConstant, SystemConstant } from "const";
import { ConversationActions } from "redux-store";
import { StorageUtil } from "utils";

export function* handleQueueTasks() {
  try {
    const lastCall = yield LocalApiCallService.getLast();

    if (lastCall && lastCall.id) {
      const data = JSON.parse(lastCall.content);
      data.data["call_id"] = lastCall.id;
      if (navigator.onLine) {
        yield sendMessage2Server(data);
      } else {
        yield delay(5000);
      }
    } else if (window.delaySyncMsec === SYNCH_NOW_MSEC) {
      window.delaySyncMsec = SYNCH_NORMAL_MSEC;
    }
  } catch (error) {
    console.log(error);
  }
}

export function* syncDeviceKey() {
  const deviceList = LocalDeviceService.getAllWithoutKeyRealTime(100000);
  const deActiveDeviceList = deviceList.filter(device => device.state !== SystemConstant.STATE.active);
  const activeDeviceList = deviceList.filter(device => device.state === SystemConstant.STATE.active);
  const chunkSize = 2;

  LocalDeviceService.save(
    deActiveDeviceList.map(device => ({ ...device, key_f: SystemConstant.DEVICE_KEY_STATE.correct })),
  );

  for (let index = 0; index < activeDeviceList.length; index += chunkSize) {
    const chunk = activeDeviceList.slice(index, index + chunkSize);
    yield delay(100);
    yield all(
      chunk.map(item =>
        call(saveKeysOfDevice, {
          accountId: item.account_id,
          deviceId: item.id,
        }),
      ),
    );
  }
}

// Synch data when user open app
export function* synchronizeData() {
  yield put(
    ConversationActions.conversationSet({
      isSynchronizing: true,
    }),
  );
  yield getBranchAccount();

  try {
    const getGroupResponse = yield call(ConversationService.getConversationList, {
      ...formatPagingParams(),
    });

    if (getGroupResponse.status === ApiConstant.STT_OK) {
      const groupResponseData = getGroupResponse.data.data;
      yield LocalGroupService.save(groupResponseData);
      const groupArray = yield LocalGroupService.getAll();

      if (Array.isArray(groupArray)) {
        const idArr = groupArray.map(item => item.id);
        if (idArr.length > 0) {
          yield getGroupMember(idArr);
        }
      }

      const groupListConvert = toCamel(groupResponseData);
      groupListConvert.forEach(item => {
        if (item.groupType === SystemConstant.GROUP_CHAT_TYPE.group && item.avatarId !== null) {
          const fileName = item.avatarId + ".png";
          if (!LocalAttachmentService.exitsLocalFile(item.avatarId, SystemConstant.GROUP_CHAT_TYPE.group, fileName)) {
            const responseData = AttachmentService.getGlobalAttachment({
              attachment_id: item.avatarId,
            });
            try {
              responseData.then(res => {
                const unit8String = new Uint8Array(res.data);
                if (res.status === ApiConstant.STT_OK) {
                  LocalAttachmentService.saveFileUnEncryptedGroup(unit8String, item.avatarId, fileName);
                }
              });
            } catch (error) {
              console.log({ error });
            }
          }
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  try {
    const accountResponse = yield call(AccountService.getAccountByIds, {
      account_ids: [accountId],
    });

    if (accountResponse.status === ApiConstant.STT_OK && Array.isArray(accountResponse.data)) {
      LocalAccountService.save(accountResponse.data);
    }
  } catch (error) {
    console.log(error);
  }

  try {
    const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
    const deviceName = StorageUtil.getItem(KeyConstant.KEY_DEVICE_NAME);
    const version = window.env?.npm_package_version?.replace(".", "");
    const device = LocalDeviceService.get(deviceId);
    if (device) {
      let updateData = { device_name: deviceName };

      if (version && device.version !== version) {
        updateData = { ...updateData, version: parseInt(version) };
      }
      if (Object.keys(updateData).length > 0) {
        yield call(AccountDeviceService.updateDevice, {
          ...updateData,
        });
      }
    }
  } catch (error) {
    console.log(error);
  }
  try {
    const getContactResponse = yield call(ContactService.getContactList, {
      ...formatPagingParams(),
    });
    if (getContactResponse.status === ApiConstant.STT_OK) {
      const responseData = getContactResponse.data.data;

      LocalContactService.getAll().then(contactList => {
        const contactListMapping = contactList.reduce((mappingArr, currentContact) => {
          mappingArr[currentContact.id] = currentContact;
          return mappingArr;
        }, []);

        const syncContactList = responseData.filter(item => {
          if (contactListMapping[item.id]) {
            return item.modified > contactListMapping[item.id].modified;
          }

          return true;
        });

        LocalContactService.save(syncContactList);
      });
    }
  } catch (error) {
    console.log(error);
  }

  yield fork(syncDeviceKey);
  //TODO send multiple group
  // yield LocalGroupService.getAll().then(async  groups=>{
  //   var newMessages = {}
  //   var marks = [];
  //   var count = 0;
  //    for (const group of groups) {
  //     const accountGroups = await LocalAccountGroupService
  //       .find({ group_id: group.id, state: 1 });
  //     const memberIdArr = accountGroups
  //       .map(member => member.account_id);
  //     const members =  await LocalDeviceService.findByList("account_id", memberIdArr)
  //     members = members.filter(m=> m.state === 1)
  //     const values = await Promise.all(
  //       members?.map(async device => {
  //         const checkShared = await LocalCipherService.checkMarkSenderKeyShared(device.account_id, device.id, group.id);
  //         if (checkShared) return null;
  //         if (device.id === deviceId) {
  //           return null;
  //         }
  //         return device;
  //       }),
  //     );
  //     const mess = []
  //     values = values.filter(s => {
  //       return s !== null;
  //     })
  //     if (values.length > 0){
  //       const distributionKey = await LocalCipherService.generateDistributionKey(accountId, deviceId, group.id);
  //       mess = await Promise.all( values.map(async device=>{
  //         const text = await LocalCipherService.encryptE2EMessage(
  //           device.account_id,
  //           device.id,
  //           group.id,
  //           distributionKey,
  //         );
  //         if (text === null) return null;
  //         const mesId = uuid();
  //         const mes = {
  //           messageId: mesId,
  //           sendToDeviceId: device.id,
  //           sendToAccountId: device.account_id,
  //           content: text,
  //           options: "",
  //           status: SystemConstant.MESSAGE_STATUS.send,
  //           sendType: SystemConstant.SEND_TYPE.senderKey,
  //           mentions: "",
  //         };
  //         return mes;
  //       }))
  //     }
  //     const messageDistribution = mess.filter(s => {
  //       return s !== null;
  //     });
  //     if (messageDistribution.length > 0) {
  //
  //       const sourceId = uuid();
  //       messageDistribution.forEach(function (mesItem) {
  //         marks.push({
  //           group_id: group.id,
  //           account_id: mesItem.sendToAccountId,
  //           device_id: mesItem.sendToDeviceId,
  //         });
  //       });
  //
  //       const newMessage = {
  //         isSendingKey: true,
  //         groupId: group.id,
  //         sourceId: sourceId,
  //         groupType: group.group_type,
  //         sendType: SystemConstant.SEND_TYPE.senderKey,
  //         created: Date.now(),
  //         messages: messageDistribution,
  //         branchId: group.branch_id,
  //       };
  //       if (newMessages[group.branch_id]){
  //         newMessages[group.branch_id].push(newMessage);
  //       }else {
  //         newMessages[group.branch_id] = [newMessage];
  //       }
  //
  //       // await LocalApiCallService.save([
  //       //   {
  //       //     id: uuid(),
  //       //     task: `${AppConstant.TASK_MESSAGE_SEND}`,
  //       //     original_uid: uuid(),
  //       //     query: "",
  //       //     content: JSON.stringify({ data: newMessage }),
  //       //     original_content: JSON.stringify({ data: newMessage }),
  //       //     created: new Date().getTime(),
  //       //     retry: 0,
  //       //     branch_id:  group.branch_id,
  //       //   },
  //       // ]);
  //     }
  //      count++;
  //      if (count === groups.length){
  //        if (Object.keys(newMessages).length > 0){
  //          const chunkSize = 20;
  //          for (const b of Object.keys(newMessages)) {
  //            for (const i = 0; i < newMessages[b].length; i += chunkSize) {
  //              const chunk = newMessages[b].slice(i, i + chunkSize);
  //              console.log(chunk);
  //              await LocalApiCallService.save([
  //                {
  //                  id: uuid(),
  //                  task: `${AppConstant.TASK_MESSAGE_SEND}`,
  //                  original_uid: uuid(),
  //                  query: "",
  //                  content: JSON.stringify({ data: {data_list: JSON.stringify(chunk)} }),
  //                  original_content: JSON.stringify({ data: {data_list: JSON.stringify(chunk)} }),
  //                  created: new Date().getTime(),
  //                  retry: 0,
  //                  branch_id:  b,
  //                },
  //              ]);
  //            }
  //
  //
  //          }
  //          if (marks && marks.length > 0) {
  //            await LocalSenderKeySharedService.save(marks);
  //          }
  //        }
  //      }
  //   }
  //
  //   }
  //
  // )

  try {
    const limit = SystemConstant.EMOIJ_LIMIT_RECORD;
    const emoji = yield LocalEmojiService.getLastRecord();
    const sinceTime = emoji ? emoji.created + 1 : 0;
    let page = 1;

    let response = yield getEmojiServer({
      sinceTime: sinceTime,
      offset: page * limit - limit,
    });
    while (response.data.limit === response.data.numberOfElements) {
      page++;
      response = yield getEmojiServer({
        sinceTime: sinceTime,
        offset: page * limit - limit,
      });
    }
  } catch (error) {
    console.log(error);
  }

  yield put(
    ConversationActions.conversationSet({
      isSynchronizing: false,
    }),
  );
}

async function getEmojiServer(conditionJson = {}) {
  const params = formatPagingParams({
    // Default params
    orderBy: SystemConstant.EMOIJ_ORDER_BY_COLUMN,
    limit: SystemConstant.EMOIJ_LIMIT_RECORD,
    sinceTime: 0,
    offset: 0,

    // Overwrite
    ...conditionJson,
  });

  const response = await MessageService.getEmoji(params);
  if (response.status === ApiConstant.STT_OK && response.data?.data) {
    await LocalEmojiService.save(response.data.data);
  }

  return toCamel(response);
}

// Synchronize data after AppConstant.SYNCHRONIZE_PERIOD_TIME
export function* periodicSynchronize() {
  yield getBranch();
  yield deviceFetching();

  // Synch keys
  try {
    while (false === Boolean(StorageUtil.getItem(KeyConstant.KEY_IS_VALID_KEYS))) {
      yield LocalKeyActionService.generateAllKeys();
    }

    if (StorageUtil.getItem(KeyConstant.KEY_MEET_TOKEN) === "" || !StorageUtil.getItem(KeyConstant.KEY_MEET_TOKEN)) {
      const accessToken = StorageUtil.getItem(KeyConstant.KEY_TOKEN);
      const refreshToken = StorageUtil.getItem(KeyConstant.KEY_REFRESH_TOKEN);
      const refreshResponse = yield call(AuthService.refresh, {
        access_token: accessToken,
        refresh_token: refreshToken,
      });
      if (refreshResponse.status === ApiConstant.STT_OK) {
        handlingLogin(toCamel(refreshResponse.data));
      }
    }

    const keys = LocalKeyActionService.getAllKeys();
    while (StorageUtil.getItem(KeyConstant.KEY_FIRST_UPLOAD_F) !== true) {
      const uploadResponse = yield call(AccountKeyService.uploadKeys, keys);
      if (uploadResponse.status === ApiConstant.STT_OK) {
        StorageUtil.setItem(KeyConstant.KEY_FIRST_UPLOAD_F, true);
      }
    }
  } catch (error) {
    console.log(error);
  }

  // Synch account
  try {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    const accountResponse = yield call(AccountService.getAccountByIds, {
      account_ids: [accountId],
    });

    if (accountResponse.status === ApiConstant.STT_OK && Array.isArray(accountResponse.data)) {
      LocalAccountService.save(accountResponse.data);
    }
  } catch (error) {
    console.log(error);
  }

  // Synch contact
  try {
    const getContactResponse = yield call(ContactService.getContactList, {
      ...formatPagingParams(),
    });

    if (getContactResponse.status === ApiConstant.STT_OK) {
      const responseData = getContactResponse.data.data;
      LocalContactService.save(responseData);
    }
    if (
      StorageUtil.getItem(SystemConstant.KEY_ERROR_TIME_WARNING) &&
      Number(StorageUtil.getItem(SystemConstant.KEY_ERROR_TIME_WARNING)) < new Date().getTime() - 60000 * 60 * 24
    ) {
      StorageUtil.setItem(SystemConstant.KEY_ERROR_TIME_WARNING, new Date().getTime());
    }
  } catch (error) {
    console.log(error);
  }
}
