import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { LangConstant } from "const";
import { getLabel } from "language";
import { getCommonKey } from "utils/lang.utils";
import PropTypes from "prop-types";

const ConfirmDeleteMessagePopup = ({ open, onCloseConfirmDialog, onConfirmDelete }) => {
  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={onCloseConfirmDialog}
      >
        <DialogTitle>{getLabel(getCommonKey(LangConstant.CONFIRM_DELETE_MESSAGE))}</DialogTitle>
        <DialogContent>{getLabel(getCommonKey(LangConstant.CONFIRM_DELETE_MESSAGE_CONTENT))}</DialogContent>
        <DialogActions>
          <Button onClick={onCloseConfirmDialog}>{getLabel(getCommonKey(LangConstant.TXT_CANCEL))}</Button>
          <Button onClick={onConfirmDelete} color="error">
            {getLabel(getCommonKey(LangConstant.TXT_DELETE))}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ConfirmDeleteMessagePopup.propTypes = {
  open: PropTypes.bool,
  onCloseConfirmDialog: PropTypes.func,
  onConfirmDelete: PropTypes.func,
};

export default ConfirmDeleteMessagePopup;
