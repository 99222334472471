import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { ArrowDropDown } from "@mui/icons-material";
import { Box, Button, CardMedia, Checkbox, FormControlLabel, OutlinedInput, Typography } from "@mui/material";
import clsx from "clsx";
import PhoneInput from "react-phone-input-2";
import { validatePhone, uuid, returnOsType } from "utils";
import { getCommonKey } from "utils/lang.utils";
import { getTimestamp } from "utils/date.utils";
import { AppConstant, ImageConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import parsePhoneNumber from "libphonenumber-js";
import { LocalKeyActionService } from "services/local.service";
import { AuthActions } from "redux-store";
import { StorageUtil } from "utils";

const LoginForm = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { t: getLabel } = useTranslation(LangConstant.NS_LOGIN);
  const isNotFoundPhone = useSelector(state => state.authRedux.isNotFoundPhone);
  const isLimitedResend = useSelector(state => state.authRedux.isLimitedResend);
  const [isEnableContinueButton, setIsEnableContinueButton] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState(AppConstant.LOCAL_PHONE_CODE);

  // TODO: Here we go again... Replace this will real data type.
  const [loginForm, setLoginForm] = useState({});
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [errorContent, setErrorContent] = useState(null);

  const onChangePhone = event => {
    let newPhone = event.target.value;
    const numberInputLimit = newPhone.length < 9 || newPhone.length > 10;
    const value = newPhone.replace(/\D/g, "");

    if (value !== phoneNumber) {
      setErrorContent(null);
    }

    setPhoneNumber(value);
    dispatch(
      AuthActions.authSet({
        isNotFoundPhone: false,
        isLimitedResend: false,
      }),
    );
    setIsEnableContinueButton(Boolean(validatePhone(value) && !numberInputLimit));
  };

  const onClickPhoneInput = () => {
    let phoneInput = document.getElementById(PHONE_CODE_BUTTON_ID);
    phoneInput.children[0].children[2].children[0].id = FLAG_BUTTON_ID;
    document.getElementById(FLAG_BUTTON_ID).click();
  };

  const onMovingOn = event => {
    event.preventDefault();
    if (loginForm.accountUuid === null || loginForm.accountUuid === "") return;
    if (validatePhone(phoneNumber)) {
      const phoneWithCode = parsePhoneNumber(phoneNumber, "VN");

      const loginBlockInfo = StorageUtil.getItem(KeyConstant.KEY_LOGIN_BLOCK_INFO);
      if (loginBlockInfo) {
        const currentTimestamp = new Date().getTime();
        const phoneKey = phoneWithCode.number;

        if (currentTimestamp - Number(loginBlockInfo[phoneKey]) < 5 * 60 * 1000) {
          setErrorContent(getLabel(LangConstant.TXT_OTP_LOCKED_FULL_MESSAGE));
          return;
        }
      }

      let submitForm = {
        ...loginForm,
        phone: phoneWithCode.number,
        regionCode: countryCode,
      };

      if (isRememberMe) {
        StorageUtil.setItem(KeyConstant.KEY_PHONE, phoneWithCode.number);
        StorageUtil.setItem(KeyConstant.KEY_REMEMBER_PHONE, true);
        StorageUtil.setItem(KeyConstant.KEY_COUNTRY_CODE, countryCode);
      } else {
        StorageUtil.removeItem(KeyConstant.KEY_PHONE);
        StorageUtil.removeItem(KeyConstant.KEY_REMEMBER_PHONE);
        StorageUtil.removeItem(KeyConstant.KEY_COUNTRY_CODE);
      }
      dispatch(AuthActions.requestLogin(submitForm));
      dispatch(
        AuthActions.authSet({
          phone: getLabel(LangConstant.FM_PHONE_CODE, {
            code: phoneWithCode.number,
          }),
          isWrongOtp: false,
          fullLoginForm: submitForm,
        }),
      );
    }
  };

  const onCheckedChange = event => {
    setIsRememberMe(event.target.checked);
  };

  useEffect(() => {
    if (isLimitedResend) {
      setIsEnableContinueButton(false);
      setErrorContent(getLabel(LangConstant.TXT_TRY_AGAIN_LATTER));
    }
  }, [isLimitedResend]);

  useEffect(() => {
    if (isNotFoundPhone) {
      setErrorContent(getLabel(LangConstant.TXT_UNREGISTERED_MESSAGE));
    }
  }, [isNotFoundPhone]);

  useEffect(() => {
    let savedPhone = StorageUtil.getItem(KeyConstant.KEY_PHONE);
    let savedCountryCode = StorageUtil.getItem(KeyConstant.KEY_COUNTRY_CODE);
    if (Boolean(savedPhone)) {
      setPhoneNumber(savedPhone);
    }
    if (Boolean(savedCountryCode)) {
      setCountryCode(savedCountryCode);
    }
    setIsEnableContinueButton(Boolean(savedPhone));
    setIsRememberMe(Boolean(StorageUtil.getItem(KeyConstant.KEY_REMEMBER_PHONE)));
  }, []);

  useEffect(async () => {
    LocalKeyActionService.generateMaster();
    await LocalKeyActionService.generateAllKeys();

    const deviceType = StorageUtil.getItem(KeyConstant.KEY_DEVICE_TYPE);
    const mac = StorageUtil.getItem(KeyConstant.KEY_MAC) || StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);

    setLoginForm({
      token: uuid(),
      accountUuid: StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID),
      state: 1,
      name: null,
      regionCode: "",
      pin: null,
      challenge: null,
      code: null,
      identityKey: uuid(),
      deviceUuid: mac,
      phone: "",
      type: deviceType,
      version: 1,
      signedKeyId: Math.floor(Math.random() * AppConstant.RANDOM_ID_LIMIT) + 1,
      signedPublicKey: uuid(),
      signedSignature: uuid(),
      created: getTimestamp(new Date()),
      modified: 0,
      mode: 0,
      check_master_f: true,
    });
  }, []);

  return (
    <Box className={classes.loginFormContainer}>
      <form onSubmit={onMovingOn}>
        <CardMedia component="img" src={ImageConstant.LogoImage} className={classes.logoImg} />
        <Typography className={clsx(classes.loginLabel, "semiBold-xl-txt")}>
          {getLabel(LangConstant.TXT_LOGIN)}
        </Typography>
        <Typography className={clsx(classes.inputLabel, "regular-lg-txt")}>
          {getLabel(getCommonKey(LangConstant.TXT_PHONE_NUMBER))}
        </Typography>
        <Box className={classes.phoneCodeAndInput}>
          <Box
            className={clsx(classes.phoneCodeButton, "no-select-text")}
            onClick={onClickPhoneInput}
            id={PHONE_CODE_BUTTON_ID}
          >
            <PhoneInput
              country={AppConstant.LOCAL_COUNTRY_CODE}
              onlyCountries={["vn"]}
              onChange={setCountryCode}
              inputClass={classes.countryInput}
              buttonClass={classes.dropdownCountryButton}
              containerClass={classes.phoneInputContainer}
              preferredCountries={[SystemConstant.COUNTRY.vietnam]}
            />
            {getLabel(LangConstant.FM_PHONE_CODE, { code: countryCode })} <ArrowDropDown />
          </Box>
          <OutlinedInput
            classes={{
              root: classes.phoneInputRoot,
              notchedOutline: classes.notchedOutline,
              focused: classes.notchedOutline,
              input: clsx(classes.phoneInput, "regular-md-txt"),
            }}
            value={phoneNumber}
            onChange={onChangePhone}
          />
        </Box>
        <FormControlLabel
          control={<Checkbox />}
          label={getLabel(LangConstant.TXT_REMEMBER_ME)}
          classes={{
            root: classes.saveLoginInfoBox,
            label: clsx(classes.saveLoginInfoLabel, "no-select-text", "regular-md-txt"),
          }}
          onChange={onCheckedChange}
          checked={isRememberMe}
        />
        <Button
          type="submit"
          variant="contained"
          className={clsx("semiBold-lg-txt", classes.continueButton)}
          disabled={!isEnableContinueButton || isNotFoundPhone}
          classes={{ disabled: classes.disabledButton }}
        >
          {getLabel(getCommonKey(LangConstant.TXT_CONTINUE))}
        </Button>
        {errorContent && (
          <Typography className={clsx(classes.unregisteredMessage, "regular-sm-txt")}>{errorContent}</Typography>
        )}
      </form>
    </Box>
  );
};

const FLAG_BUTTON_ID = "flag-button";
const PHONE_CODE_BUTTON_ID = "phone-code-button";

LoginForm.propTypes = {};

export default LoginForm;

const useStyles = makeStyles(theme => ({
  loginFormContainer: {
    backgroundColor: theme.palette.white,
    width: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0 80px",
    [theme.breakpoints.down(1350)]: {
      padding: "0 50px",
    },
  },

  logoImg: {
    maxWidth: 75,
  },

  countryInput: {
    display: "none",
  },

  dropdownCountryButton: {
    border: "none !important",
    position: "relative !important",
    height: "100%",
    background: "#F7F7F8",
    "&>div": {
      padding: 0 + " !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#F7F7F8 !important",
    },
    "&>div>div": {
      position: "unset",
      margin: 0,
    },
    "&>div>div>div": {
      display: "none",
    },
  },

  phoneInputContainer: {
    height: 30,
    width: "fit-content",
    display: "flex",
    alignItems: "center",
  },

  phoneCodeButton: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    borderRight: "1px solid #E4E4E4",
  },

  phoneInputRoot: {},

  phoneCodeAndInput: {
    display: "flex",
    padding: "10px 0",
    backgroundColor: "#F7F7F8",
    height: 50,
    borderRadius: 10,
    width: "100%",
  },

  notchedOutline: {
    borderWidth: 0 + " !important",
  },

  phoneInput: {
    color: "#030229",
    height: 30,
    padding: 10,
    MozAppearance: "textfield",

    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },

    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },

  saveLoginInfoBox: {
    width: "100%",
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 0,
  },

  saveLoginInfoLabel: {
    color: "#030229",
  },

  continueButton: {
    width: "100%",
    padding: "14px 0",
  },

  loginLabel: {
    marginTop: 16,
    marginBottom: 25,
    lineHeight: "28px",
  },

  inputLabel: {
    marginBottom: 8,
    width: "100% ",
  },

  unregisteredMessage: {
    marginTop: 12,
    color: theme.palette.secondary.main,
    lineHeight: "16px",
    textAlign: "center",
  },

  disabledButton: {
    backgroundColor: "#72B9E6 !important",
    color: theme.palette.white + "!important",
  },
}));
