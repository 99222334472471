import React, { useState, useEffect, useCallback, Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Button, Divider, List, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getImageSrcAvatar, objectToJSON } from "utils";
import { LangConstant, SystemConstant } from "const";
import { AvatarInfo, SearchBar } from "components";
import Checkbox from "@mui/material/Checkbox";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ACSelectContact from "./ACSelectContact";
import { isFunction } from "lodash";

const SelectingListContact = ({ data, onComplete, maximumLimit, minimumLimit }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const chatObjContent = getLabel(LangConstant.OBJ_ADDING_CHAT, { returnObjects: true });

  const [viewingList, setViewingList] = useState([]);
  const [selectedContact, setSelectedContact] = useState([]);
  const [defaultContactList, setDefaultContactList] = useState([]); // Only update by data into props

  const onCreateSelected = useCallback(
    item => () => {
      setSelectedContact(preSelectedContact => {
        const newContactIndex = preSelectedContact.findIndex(contact => objectToJSON(contact) === objectToJSON(item));
        return newContactIndex >= 0
          ? preSelectedContact.filter(contact => objectToJSON(contact) !== objectToJSON(item))
          : [...preSelectedContact, item];
      });
    },
    [],
  );

  const onDetectItem = item => selectedContact.findIndex(contact => objectToJSON(contact) === objectToJSON(item)) >= 0;

  const onCompleteSelectingContact = () => isFunction(onComplete) && onComplete(selectedContact);

  const onChangeSearchInput = content => {
    if (content && content !== "") {
      const filteredList = defaultContactList.filter(
        item =>
          item.name?.toLowerCase().includes(content.toLowerCase()) ||
          item.contactName?.toLowerCase().includes(content.toLowerCase()),
      );
      setViewingList(filteredList);
    } else {
      setViewingList(defaultContactList);
    }
  };

  const onDelete = item => {
    const newSelectedContact = selectedContact.filter(contact => objectToJSON(contact) !== objectToJSON(item));
    setSelectedContact(newSelectedContact);
  };

  const onGetContactAvatar = async contact => {
    try {
      const avatarUrl = await getImageSrcAvatar(contact.avatarId, SystemConstant.GROUP_CHAT_TYPE.personal);
      return { ...contact, avatarUrl };
    } catch (error) {
      console.log(error);
      return contact;
    }
  };

  useEffect(() => {
    if (data && Array.isArray(data)) {
      let dataDetail = [];
      data.forEach(contactItem => {
        dataDetail.push(onGetContactAvatar(contactItem));
      });

      Promise.all(dataDetail).then(values => {
        setDefaultContactList(values);
        setViewingList(values);
      });
    }
  }, [data]);

  const isShowMaximumError = selectedContact.length >= maximumLimit;
  const isDisabledButton = selectedContact.length < minimumLimit || isShowMaximumError;

  return (
    <>
      <Box className={classes.rootSpace}>
        <SearchBar onChange={onChangeSearchInput} />
      </Box>
      {isShowMaximumError && (
        <Typography className={clsx(classes.errorNoti)}>{getLabel(LangConstant.TXT_CONFERENCE_MEMBER_ERROR)}</Typography>
      )}

      <Typography variant="subtitle2" className={clsx(classes.subTitle, classes.rootSpace)}>
        {getLabel(LangConstant.FM_CHOOSE_MEMBER, { number: selectedContact.length })}
      </Typography>
      {selectedContact.length > 0 ? (
        <Box className={clsx(classes.selectedList)}>
          {selectedContact.map(item => (
            <ACSelectContact key={item.id} contact={item} onDelete={onDelete} />
          ))}
        </Box>
      ) : (
        <Box className={classes.noSelectedList}>
          <PeopleAltIcon className={classes.noChooseMemberIcon} color="disabled" fontSize="large" />
          <Typography>{getLabel(LangConstant.TXT_NO_MEMBER_CHOOSE)}</Typography>
        </Box>
      )}
      <Typography variant="subtitle2" className={clsx(classes.subTitle, classes.rootSpace)}>
        {getLabel(LangConstant.FM_MEMBER, { number: viewingList.length })}
      </Typography>
      <List>
        {viewingList.map((item, index) => (
          <Fragment key={item.id}>
            {index > 0 && <Divider variant="inset" component="li" />}
            <AvatarInfo
              avatar={{
                src: item.avatarId,
                name: item.contactName || item.name || item.phone,
              }}
              title={item.contactName || item.name || item.phone}
              onClick={onCreateSelected(item)}
              button={true}
              endComponent={<Checkbox color="primary" checked={onDetectItem(item)} />}
            />
          </Fragment>
        ))}
      </List>
      <Box className={classes.btnContainer}>
        <Button
          variant="contained"
          onClick={onCompleteSelectingContact}
          fullWidth
          className={classes.btnDone}
          size="large"
          classes={{ disabled: classes.disabledButton }}
          disabled={isDisabledButton}
        >
          {chatObjContent.btnDone}
        </Button>
      </Box>
    </>
  );
};

SelectingListContact.propTypes = {
  data: PropTypes.array,
  maximumLimit: PropTypes.number,
  minimumLimit: PropTypes.number,

  onComplete: PropTypes.func,
};

SelectingListContact.defaultProps = {
  data: [],
  maximumLimit: 1000,
  minimumLimit: 1,
};

export default SelectingListContact;

const useStyles = makeStyles(theme => ({
  rootSpace: {
    paddingLeft: 16,
    paddingRight: 16,
  },

  subTitle: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    textAlign: "left",
    marginTop: 10,
  },

  selectedList: {
    display: "flex",
    height: 80,
    marginLeft: 16,
    marginRight: 16,
    overflow: "auto",
  },

  btnContainer: {
    position: "sticky",
    padding: "10px 16px",
    bottom: 0,
    marginTop: "auto",
    backgroundColor: "white",
    paddingBottom: 15,
  },

  btnDone: {
    cursor: "pointer",
  },

  disabledButton: {
    backgroundColor: "#72B9E6 !important",
    color: theme.palette.white + "!important",
  },

  errorNoti: {
    color: "red",
    fontSize: 12,
    textAlign: "center",
  },

  noSelectedList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: 80,
    justifyContent: "center",
  },
}));
