import React, { useEffect, useState, Suspense, memo, useMemo } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Button, Collapse, Divider, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LangConstant, SystemConstant } from "const";
import { convertHex2rgba, isArrayNotEquals } from "utils";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddingChannelDrawer from "../../AddingChat/AddingChannel/AddingChannelDrawer";
import AddingPersonalDrawer from "../../AddingChat/AddingPersonalDrawer";
import AddingGroupDrawer from "../../AddingChat/AddingGroup/AddingGroupDrawer";

const ChatGroupItem = React.lazy(() => import("./ChatGroupItem"));

const ChatAllTab = ({ chatList, category, type }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);

  const [limit, setLimit] = useState(SystemConstant.NUMBER_MESSAGE_SHOW);
  const [sortedArr, setSortedArr] = useState(chatList);
  const [isExpand, setIsExpand] = useState(true);
  const [isShowDrawer, setIsShow] = useState(false);

  const [isAddingChannelDrawer, isAddingGroupDrawer, isAddingPersonalDrawer] = useMemo(
    () => [
      isShowDrawer && type === SystemConstant.CATEGORY_TYPE.channel,
      isShowDrawer && type === SystemConstant.CATEGORY_TYPE.group,
      isShowDrawer && type === SystemConstant.CATEGORY_TYPE.personal,
    ],
    [isShowDrawer],
  );

  const onViewMore = () => {
    setLimit(state => state + SystemConstant.NUMBER_MESSAGE_SHOW);
  };

  const onExpand = () => {
    if (isExpand) {
      setLimit(SystemConstant.NUMBER_MESSAGE_SHOW);
    }
    setIsExpand(!isExpand);
  };

  const onCreateNewConversation = () => setIsShow(true);

  const onCloseDrawer = () => setIsShow(false);

  useEffect(() => {
    if (isArrayNotEquals(sortedArr, chatList)) setSortedArr(chatList);
  }, [chatList]);

  return (
    <Box>
      <Box className={classes.category}>
        <Box className={classes.categoryName}>
          <Typography className={classes.title}>{category}</Typography>
          <IconButton onClick={onExpand}>{isExpand ? <ExpandLess /> : <ExpandMore />}</IconButton>
        </Box>
        <IconButton color="primary" onClick={onCreateNewConversation}>
          <AddOutlinedIcon />
        </IconButton>
      </Box>
      <Collapse in={isExpand}>
        <Suspense fallback={<Box></Box>}>
          {sortedArr.length > 0 &&
            limit &&
            sortedArr
              .slice(0, limit > sortedArr.length ? sortedArr.length : limit)
              .map(item => <ChatGroupItem key={item.id} data={item} />)}
        </Suspense>
        {chatList.length > limit && (
          <Divider className={classes.viewMore}>
            <Button variant="text" className={classes.viewMoreButton} onClick={onViewMore}>
              {getLabel(LangConstant.TXT_VIEW_MORE)}
            </Button>
          </Divider>
        )}
      </Collapse>

      {isAddingChannelDrawer && <AddingChannelDrawer open onComplete={onCloseDrawer} onClose={onCloseDrawer} />}

      {isAddingGroupDrawer && <AddingGroupDrawer open onComplete={onCloseDrawer} onClose={onCloseDrawer} />}

      {isAddingPersonalDrawer && <AddingPersonalDrawer open onClose={onCloseDrawer} />}
    </Box>
  );
};

ChatAllTab.propTypes = {
  chatList: PropTypes.array,
};

ChatAllTab.defaultProps = {
  chatList: [],
};

export default memo(ChatAllTab);

const useStyles = makeStyles(() => ({
  chatTabRoot: {
    padding: "20px 14px",
    height: "100%",
  },

  flexContainer: {
    borderBottom: "1px solid " + convertHex2rgba("#030229", 0.05),
  },

  chatList: {
    height: "100%",
    overflow: "auto",
    maxHeight: "calc(100vh - 336px)",
  },

  emptyChatList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
  },

  emptyImage: {
    padding: "0 26px",
  },

  emptyMessage: {
    fontSize: 15,
    fontWeight: 600,
    marginTop: 13,
    lineHeight: "20px",
  },

  viewMore: {
    margin: "10px 14px",
  },

  viewMoreButton: {
    textTransform: "none",
  },

  category: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  categoryName: {
    display: "flex",
    alignItems: "center",
  },

  title: {
    fontSize: 13,
    color: "gray",
  },
}));
