import React, { Fragment, Suspense } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import LineChat from "../../../LineChat";
import { convertMillisecondsToDate } from "utils/date.utils";
import { FormatConstant } from "const";

const ChatItem = React.lazy(() => import("../../../ChatItem"));

const ThreadMessage = ({ data, isShowTime, isAvatar, onSend }) => {
  return (
    <Fragment>
      <Suspense fallback={<Box></Box>}>
        <ChatItem
          data={data}
          onSendMessage={onSend}
          isAvatar={isAvatar}
          isThreading={true}
        />
      </Suspense>
      {isShowTime && data?.created && (
        <LineChat data={convertMillisecondsToDate(data?.created, FormatConstant.FM_HH_MM_DD_MM_YYYY)} />
      )}
    </Fragment>
  );
};

ThreadMessage.propTypes = {
  data: PropTypes.object,
  isShowTime: PropTypes.bool,
  isAvatar: PropTypes.bool,

  onSend: PropTypes.func,
};

ThreadMessage.defaultProps = {
  data: {},
  isShowTime: false,
  isAvatar: false,

  onSend: () => {},
};

export default ThreadMessage;
