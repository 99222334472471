import { createReducer, createActions } from "reduxsauce";
import {
  REDUX_STATE,
  requestReducerFunc,
  failureReducerFunc,
  successReducerFunc,
  setReducerFunc,
} from "./redux-structure";
import { getSavedServer } from "utils/common.utils";
import { SystemConstant } from "const";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getBranch: [],
  updateBranch: ["data"],

  branchSuccess: ["data"],
  branchFailure: ["error", "data"],
  branchSet: ["data"],
});

export const BranchTypes = Types;
export const BranchActions = Creators;
export default Creators;

/* ------------- Initial State ------------- */
const INITIAL_STATE = {
  ...REDUX_STATE,

  isUpdateBranchInviteStatus: false,
  isGetBranchSuccess: false,

  selectedBranch: getSavedServer(),
  defaultBranch: null,
  kickedBranchArray: [],
};

/* ------------- Selector ------------- */
export const BranchSelectors = {
  isBranchServer: state => state.branchRedux.selectedBranch?.type === SystemConstant.SERVER_TYPE.branch,
  isUpdateBranchInviteStatus: state => state.branchRedux.isUpdateBranchInviteStatus,
  isGetBranchSuccess: state => state.branchRedux.isGetBranchSuccess,

  getSelectedBranch: state => state.branchRedux.selectedBranch || {},
  getKickedBranchArray: state => state.branchRedux.kickedBranchArray || [],
  getDefaultBranch: state => state.branchRedux.defaultBranch,
};

/* ------------- Reducers ------------- */
const request = (state = INITIAL_STATE) =>
  requestReducerFunc({
    ...state,

    isUpdateBranchInviteStatus: false,
    isGetBranchSuccess: false,
  });

const set = (state = INITIAL_STATE, action) => setReducerFunc(state, action);

const success = (state = INITIAL_STATE, action) => successReducerFunc(state, action);

const failure = (state = INITIAL_STATE, action) => failureReducerFunc(state, action);

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_BRANCH]: request,
  [Types.UPDATE_BRANCH]: request,

  [Types.BRANCH_SET]: set,
  [Types.BRANCH_SUCCESS]: success,
  [Types.BRANCH_FAILURE]: failure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const BranchReducer = createReducer(INITIAL_STATE, HANDLERS);
