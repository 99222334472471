/**
 * Saga index: connects action type and saga
 */

import { all, takeLatest, call, fork, delay } from "redux-saga/effects";

/* ------------- Types ------------- */
import {
  AuthTypes,
  ConversationTypes,
  ContactTypes,
  CallingTypes,
  DeviceTypes,
  GroupInfoTypes,
  BranchTypes,
  RestoreTypes,
  ProfileTypes,
  AccountTypes,
  SystemTypes,
} from "redux-store";

/* ------------- Sagas ------------- */
import { requestLogin, requestVerify } from "./auth.saga";
import { logoutDevice } from "./account-device.saga";
import { getAccountList, blockUser, unblockUser } from "./account.saga";
import { getBranch, updateBranch } from "./branch.saga";
import {
  sendMessage2Server,
  uploadMessageFile,
  updateMessageStatus,
  fetchLastMessage,
  saveMsgInQueue,
  markReadMessageInGroup,
} from "./conversation-message.saga";
import {
  changeGroupPhoto,
  removeMemberGroup,
  muteGroupNotification,
  addAdmin,
  createNewGroup,
  updateGroup,
  deleteGroup,
  requestUploadImageCall,
  addMemberGroup,
  handleSelectConversation,
  checkBlockedContact,
} from "./conversation-info.saga";
import { findContact, updateContact, addNewContact, unfriendContact } from "./contact.saga";
import {
  checkReceiversStatus,
  checkCallingStatus,
  getCallHistory,
  updateCallHistory,
  getImageIncomingCall,
} from "./calling.saga";
import {
  changeProfileAvatar,
  updateProfileInfo,
  changeProfileBackground,
  deleteProfileImage,
  updateAccountStatus,
} from "./profile.saga";
import { synchronizeData, periodicSynchronize, handleQueueTasks } from "./synchronize.saga";
import { backup, changeDeviceRole, restore, verifyChangeDeviceRole, restoreToLocal } from "./restore.saga";
import { SYNCH_NORMAL_MSEC } from "./saga.helper";

window.delaySyncMsec = SYNCH_NORMAL_MSEC;
function* watchRequests() {
  while (true) {
    yield delay(window.delaySyncMsec); // Delay before continue to execute
    yield call(handleQueueTasks);
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default function* root() {
  yield fork(watchRequests);

  yield all([
    // Authentication
    takeLatest(AuthTypes.REQUEST_LOGIN, requestLogin),
    takeLatest(AuthTypes.REQUEST_VERIFY, requestVerify),

    // Account
    takeLatest(AccountTypes.GET_ACCOUNT_LIST, getAccountList),
    takeLatest(AccountTypes.BLOCK_USER, blockUser),
    takeLatest(AccountTypes.UNBLOCK_USER, unblockUser),

    // Branch
    takeLatest(BranchTypes.GET_BRANCH, getBranch),
    takeLatest(BranchTypes.UPDATE_BRANCH, updateBranch),

    // Conversation Message
    takeLatest(ConversationTypes.POST_MESSAGE, sendMessage2Server),
    takeLatest(ConversationTypes.UPLOAD_MESSAGE_FILE, uploadMessageFile),
    takeLatest(ConversationTypes.UPDATE_MESSAGE_STATUS, updateMessageStatus),
    takeLatest(ConversationTypes.FETCH_LAST_MESSAGE, fetchLastMessage),
    takeLatest(ConversationTypes.SEND_MESSAGE, saveMsgInQueue),
    takeLatest(ConversationTypes.MARK_READ_MESSAGE_IN_GROUP, markReadMessageInGroup),

    // Conversation Info: Group, Channel, Personal
    takeLatest(GroupInfoTypes.CHANGE_GROUP_PHOTO, changeGroupPhoto),
    takeLatest(GroupInfoTypes.REMOVE_MEMBER_GROUP, removeMemberGroup),
    takeLatest(GroupInfoTypes.ADD_MEMBER_GROUP, addMemberGroup),
    takeLatest(GroupInfoTypes.MUTE_GROUP_NOTIFICATION, muteGroupNotification),
    takeLatest(GroupInfoTypes.ADD_ADMIN, addAdmin),
    takeLatest(ConversationTypes.CREATE_NEW_GROUP, createNewGroup),
    takeLatest(ConversationTypes.UPDATE_GROUP, updateGroup),
    takeLatest(ConversationTypes.DELETE_GROUP, deleteGroup),
    takeLatest(ConversationTypes.REQUEST_UPLOAD_IMAGE_CALL, requestUploadImageCall),
    takeLatest(ConversationTypes.SET_SELECT_GROUP_ID, handleSelectConversation),
    takeLatest(ConversationTypes.CHECK_BLOCKED_CONTACT, checkBlockedContact),

    // Contact
    takeLatest(ContactTypes.FIND_CONTACT, findContact),
    takeLatest(ContactTypes.UPDATE_CONTACT, updateContact),
    takeLatest(ContactTypes.ADD_NEW_CONTACT, addNewContact),
    takeLatest(ContactTypes.UNFRIEND_CONTACT, unfriendContact),

    // Calling
    takeLatest(CallingTypes.ON_CALL_CHECK, checkCallingStatus),
    takeLatest(CallingTypes.ON_CHECK_RECEIVERS_STATUS, checkReceiversStatus),
    takeLatest(CallingTypes.GET_CALL_HISTORY, getCallHistory),
    takeLatest(CallingTypes.UPDATE_CALL_HISTORY, updateCallHistory),
    takeLatest(CallingTypes.GET_IMAGE_INCOMING_CALL, getImageIncomingCall),

    // Device Management
    takeLatest(DeviceTypes.DEVICE_LOGOUT, logoutDevice),

    // Profile
    takeLatest(ProfileTypes.CHANGE_PROFILE_AVATAR, changeProfileAvatar),
    takeLatest(ProfileTypes.CHANGE_PROFILE_BACKGROUND, changeProfileBackground),
    takeLatest(ProfileTypes.UPDATE_PROFILE_STATUS, updateAccountStatus),
    takeLatest(ProfileTypes.UPDATE_PROFILE_INFO, updateProfileInfo),
    takeLatest(ProfileTypes.DELETE_PROFILE_IMAGE, deleteProfileImage),

    // Synchronize
    takeLatest(SystemTypes.SYNCHRONIZE_DATA, synchronizeData),
    takeLatest(SystemTypes.PERIODIC_SYNCHRONIZE, periodicSynchronize),

    // Synchronize message

    // Backup/ Restore Data
    takeLatest(RestoreTypes.CHANGE_DEVICE_ROLE, changeDeviceRole),
    takeLatest(RestoreTypes.VERIFY_CHANGE_DEVICE_ROLE, verifyChangeDeviceRole),
    takeLatest(RestoreTypes.RESTORE, restore),
    takeLatest(RestoreTypes.RESTORE_TO_LOCAL, restoreToLocal),
    takeLatest(RestoreTypes.BACKUP, backup),
  ]);
}
