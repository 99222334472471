import { AppConstant } from "const";
import { useEffect, useState } from "react";

const useNotice = () => {
  const [noticeInfo, setNoticeInfo] = useState({
    open: false,
    title: "",
    content: "",
    onClose: () => {},
  });

  const handleClose = () => {
    noticeInfo.onClose();
    setNoticeInfo(state => ({ ...state, open: false }));
  };

  const handleNoticeEvent = listener => {
    const detail = listener.detail;
    setNoticeInfo({
      open: true,
      title: detail.title || "",
      content: detail.content,
      onClose: detail.callback,
    });
  };

  useEffect(() => {
    window.addEventListener(AppConstant.NOTICE_EVENT_NAME, handleNoticeEvent);

    return () => {
      window.removeEventListener(AppConstant.NOTICE_EVENT_NAME, handleNoticeEvent);
    };
  }, []);

  return { noticeInfo, handleClose };
};

export default useNotice;
