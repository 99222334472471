import { ApiConstant, KeyConstant, SystemConstant } from "const";
import qs from "qs";
import { createApiWithBranch } from "./config/api.config";
import { ContactService } from "services";
import { LocalContactService } from "./local.service";
import { StorageUtil } from "utils";

export const getContactList = data => createApiWithBranch().get(ApiConstant.GET_CONTACT_LIST, data);

export const updateContact = data =>
  createApiWithBranch().post(ApiConstant.POST_UPDATE_CONTACT, qs.stringify(data, { skipNulls: true }));

export const addContact = data =>
  createApiWithBranch().post(ApiConstant.POST_ADD_CONTACT, qs.stringify(data, { skipNulls: true }));

export const getContact = (account_id, contact_id) =>
  createApiWithBranch().get(ApiConstant.GET_CONTACT, { account_id, contact_id });

export const deleteContact = contact_id =>
  createApiWithBranch().post(ApiConstant.POST_DELETE_CONTACT, qs.stringify({ contact_id }));

// Return data if checkingAccount block login user (current account)
export const getBlockedContactOnServer = async checkingAccountId => {
  const loginAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const response = await ContactService.getContact(checkingAccountId, loginAccountId);
  const contactOfReceiverServer = response.data;

  if (response.status === ApiConstant.STT_OK && contactOfReceiverServer?.id) {
    const contactOfReceiverLocal = LocalContactService.getContact(checkingAccountId, loginAccountId);

    if (contactOfReceiverLocal?.id && contactOfReceiverLocal.status !== contactOfReceiverServer.status) {
      await LocalContactService.updateBeingBlockContact(contactOfReceiverServer.id, contactOfReceiverServer.status);
    }

    if (contactOfReceiverServer.status === SystemConstant.CONTACT_STATUS.block) {
      // If receiver block login user, save data to DB
      await LocalContactService.save([contactOfReceiverServer]);

      return contactOfReceiverServer;
    }
  }

  return null;
};
