import { useEffect, useState } from "react";
import { AppConstant, LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { downloadFile, getImageSrc, getImageSrcAvatar } from "utils";
import { Avatar, Box, Button, CardMedia, Grid, IconButton, Popover, Typography } from "@mui/material";
import { Close, Delete, Download, Upgrade, Visibility } from "@mui/icons-material";
import { ConfirmDialog, HandleUploadingInput } from "components";
import { makeStyles } from "@mui/styles";
import { ConversationActions, ProfileActions } from "redux-store";

const PopupUpdateImage = ({ open, onClose, anchorEl, isAvatar, group = false, data, initCall }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);

  const profileObjContent = getLabel(LangConstant.OBJ_ACCOUNT_PROFILE, { returnObjects: true });
  const [isConfirmRemove, setIsConfirmRemove] = useState(false);
  const [userAvatar, setUserAvatar] = useState("");
  const [userBackground, setUserBackground] = useState("");

  const onFileChange = event => {
    let file = event.target.files[0];
    if (group) {
      dispatch(ConversationActions.requestUploadImageCall({ upload: file, initCall: initCall }));
    } else {
      if (isAvatar) {
        dispatch(ProfileActions.changeProfileAvatar({ upload: file }));
        dispatch(
          ProfileActions.profileSet({
            isDeletingAvatar: false,
          }),
        );
      } else {
        dispatch(ProfileActions.changeProfileBackground({ upload: file }));
      }
    }
    onClose();
  };

  const handlePreviewImage = () => {
    document.getElementById("overPlay").style.width = "100%";
  };

  const handleClose = () => {
    document.getElementById("overPlay").style.width = "0%";
    onClose();
  };

  const saveImage = async () => {
    const id = isAvatar ? data.avatarId : data.backgroundId;
    const fileName = `${id}.png`;

    const imageSrc = await getImageSrcAvatar(id, SystemConstant.GROUP_CHAT_TYPE.personal);
    downloadFile(fileName, imageSrc);
    handleClose();
  };

  const deleteProfileImage = () => {
    handleClose();
    setIsConfirmRemove(false);
    dispatch(
      ProfileActions.deleteProfileImage({
        isAvatar: isAvatar,
      }),
    );

    dispatch(
      ProfileActions.profileSet({
        isDeletingAvatar: true,
      }),
    );
  };

  const clickBin = () => {
    setIsConfirmRemove(true);
  };

  useEffect(() => {
    if (group && data?.avatarId) {
      getImageSrcAvatar(data?.avatarId, SystemConstant.GROUP_CHAT_TYPE.group).then(src => {
        setUserAvatar(src);
      });
    }
    if (data?.avatarId) {
      getImageSrcAvatar(data?.avatarId, SystemConstant.GROUP_CHAT_TYPE.personal).then(src => {
        setUserAvatar(src);
      });
    }
    if (data?.backgroundId) {
      getImageSrc(data?.backgroundId, SystemConstant.GROUP_CHAT_TYPE.personal).then(src => {
        setUserBackground(src);
      });
    }
  }, [data]);

  return (
    <>
      <Popover
        anchorReference="anchorEl"
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Grid container spacing={1} className={classes.container}>
          {/* <Grid item>
            <input
              accept={AppConstant.ACCEPTABLE_IMAGE_TYPES}
              className="hidden"
              id="take_photo_id"
              type="file"
              onChange={onFileChange}
            />
            <label htmlFor="take_photo_id" className={classes.styleButton}>
              <CameraAlt />
              <Typography className={classes.range}>{profileObjContent.takePhoto}</Typography>
            </label>
          </Grid> */}
          <Grid item>
            <HandleUploadingInput
              accept={AppConstant.ACCEPTABLE_IMAGE_TYPES}
              className="hidden"
              id="upload_photo_id"
              type="file"
              onChange={onFileChange}
            />
            <label htmlFor="upload_photo_id" className={classes.styleButton}>
              <Upgrade />
              <Typography className={classes.range}>{profileObjContent.uploadPhoto}</Typography>
            </label>
          </Grid>
          <Grid item>
            <Button className={classes.styleButton} onClick={handlePreviewImage}>
              <Visibility className={classes.iconBlack} />
              <Typography className={classes.range}>{profileObjContent.viewPhoto}</Typography>
            </Button>
            <Box className={classes.boxOverPlay} id={"overPlay"}>
              <Box className={classes.containerBoxOverPlay}>
                <Box className={classes.containerOverPlay}>
                  <Box className={classes.center}>
                    <Avatar src={userAvatar} />
                    <Typography className={classes.textOverPlay}>{data.name}</Typography>
                    {isAvatar ? (
                      <Typography className={classes.textOverPlay}>(Avatar)</Typography>
                    ) : (
                      <Typography className={classes.textOverPlay}>(Cover)</Typography>
                    )}
                  </Box>
                  <Box className={classes.center}>
                    {isAvatar ? (
                      <Box>
                        {data?.avatarId !== "" && (
                          <Box>
                            <IconButton onClick={clickBin}>
                              <Delete className={classes.iconWhite} />
                            </IconButton>
                            <IconButton onClick={saveImage}>
                              <Download className={classes.iconWhite} />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Box>
                        {data?.backgroundId !== "" && (
                          <Box>
                            <IconButton onClick={clickBin}>
                              <Delete className={classes.iconWhite} />
                            </IconButton>
                            <IconButton onClick={saveImage}>
                              <Download className={classes.iconWhite} />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    )}
                    <ConfirmDialog
                      open={isConfirmRemove}
                      onClose={() => setIsConfirmRemove(false)}
                      title={getLabel(LangConstant.TXT_CONFIRM_REMOVE_PHOTO)}
                      content={getLabel(LangConstant.TXT_SURE_REMOVE)}
                      submitProps={{
                        submitText: getLabel(LangConstant.TXT_DELETE),
                        onClick: deleteProfileImage,
                      }}
                      cancelProps={{ onClick: () => setIsConfirmRemove(false) }}
                    />
                    <IconButton onClick={handleClose}>
                      <Close className={classes.iconWhite} />
                    </IconButton>
                  </Box>
                </Box>
                {isAvatar ? (
                  <Box className={classes.imageOverPlay}>
                    <CardMedia component="img" alt="" className={classes.coverImage} src={userAvatar} />
                  </Box>
                ) : (
                  <Box className={classes.imageOverPlayBackground}>
                    <CardMedia component="img" alt="" className={classes.coverBackground} src={userBackground} />
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Button className={classes.styleButton} onClick={clickBin}>
              <Delete className={classes.iconBlack} />
              <Typography className={classes.range}>{profileObjContent.removePhoto}</Typography>
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default PopupUpdateImage;

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": { textAlign: "center" },
  },

  coverBox: {
    position: "relative",
    marginBottom: 12,
  },

  cover: {
    width: "100%",
    height: 180,
    background: "darkgray",
    marginBottom: 36,
  },

  avatarBox: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },

  editIcon: {
    fontSize: 16,
    color: theme.palette.white,
    position: "absolute",
    bottom: "0%",
    left: "50%",
    cursor: "pointer",
    transform: "translate(-50%, 0)",
  },

  addPhotoIcon: {
    fontSize: 19,
    color: theme.palette.white,
    position: "absolute",
    bottom: 50,
    right: 15,
    zIndex: 1,
  },

  popover: {
    marginTop: 8,
    borderRadius: 20,
    overflow: "hidden",
    backgroundColor: theme.palette.white,
  },

  paper: {
    marginTop: 15,
    borderRadius: 20,
    overflowX: "unset",
    overflowY: "unset",
    "&::before": {
      content: '""',
      position: "absolute",
      top: -15,
      left: "calc(50% - 0px)",
      width: 15,
      height: 15,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
      transform: "translate(-50%, 50%) rotate(315deg)",
      clipPath: "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
    },
  },

  range: {
    marginLeft: 15,
    textTransform: "capitalize",
  },

  container: {
    padding: 20,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
  },

  styleButton: {
    padding: 0,
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
  },

  boxPopupUpdateImage: {
    borderRadius: 20,
  },

  colorIcon: {
    color: theme.palette.black,
  },

  list: {
    zIndex: 1,
    backgroundColor: "black",
    width: 20,
    height: 20,
    "&:before": {
      top: -22,
      right: -27,
      position: "absolute",
      border: "10px solid transparent",
    },
  },

  inputFile: {
    opacity: 0,
    position: "absolute",
    zIndex: 1,
    cursor: "pointer",
  },

  mediaInputRoot: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },

  mediaInput: {
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0,
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },

  coverBackground: {
    width: 797,
    height: 461,
    borderRadius: 10,
  },

  boxOverPlay: {
    position: "fixed",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0, 0.9)",
    width: 0,
    height: "100%",
    overflowX: "hidden",
    top: 0,
    left: 0,
  },

  iconBlack: {
    color: theme.palette.black,
  },

  iconWhite: {
    color: theme.palette.white,
  },

  containerBoxOverPlay: {
    margin: "32px 15px",
  },

  textOverPlay: {
    fontSize: 15,
    fontWeight: 600,
    color: "white",
    paddingLeft: 16,
  },

  center: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  imageOverPlay: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "5%",
  },

  imageOverPlayBackground: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "8%",
  },

  containerOverPlay: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  coverImage: {
    maxWidth: 692,
    maxHeight: 692,
    borderRadius: 10,
  },

  avatar: {
    width: 120,
    height: 120,
    margin: "auto",
    border: "2px solid white",
    fontSize: 48,
  },

  name: {
    fontWeight: 700,
    fontSize: 15,
  },
}));
