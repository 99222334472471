import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConversationActions } from "redux-store";

const useHandleUnreadMessage = () => {
  const dispatch = useDispatch();
  const selectedGroupId = useSelector(state => state.conversationRedux.selectedGroupId);
  const isFetchMessageSuccess = useSelector(state => state.conversationRedux.isFetchMessageSuccess);
  const isUpdateViewMode = useSelector(state => state.conversationRedux.isUpdateViewMode);

  useEffect(() => {
    if (selectedGroupId && (isUpdateViewMode || isFetchMessageSuccess)) {
      dispatch(ConversationActions.markReadMessageInGroup(selectedGroupId));
    }
  }, [isFetchMessageSuccess, isUpdateViewMode, selectedGroupId]);
};

export default useHandleUnreadMessage;
