import React, { memo, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const InfiniteScroller = ({
  childrenLength,
  children,
  isScrollingUp,
  onLoadOlder,
  onLoadNewer,
  loader,
  autoScrollDuringNew,
  className,
  isEnd,
  groupId,
  ...otherProps
}) => {
  const classes = useStyles();

  const baseRef = useRef();
  const oldObserver = useRef();
  const newObserver = useRef();

  var isLoadingOlder = false;
  const [isLoadingNewer, setIsLoadingNewer] = useState(false);

  const oldLoaderRef = useCallback(node => {
    if (oldObserver.current) oldObserver.current.disconnect();
    oldObserver.current = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && !isEnd) {
          isLoadingOlder = true;
          onLoadOlder();
        } else {
          isLoadingOlder = false;
        }
      },
      {
        root: baseRef.current,
        rootMargin: "20px 0px 0px 0px",
      },
    );

    if (node) {
      oldObserver.current.observe(node);
    }
  });

  const newLoaderRef = useCallback(node => {
    if (newObserver.current) newObserver.current.disconnect();

    newObserver.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setIsLoadingNewer(true);
        onLoadNewer();
      } else {
        setIsLoadingNewer(false);
      }
    });

    if (node) newObserver.current.observe(node);
  });

  return (
    <Box
      ref={baseRef}
      className={clsx(className, isScrollingUp ? classes.scrollUp : classes.scrollDown)}
      {...otherProps}
    >
      <Box ref={newLoaderRef}>{loader}</Box>
      {children}
      <Box ref={oldLoaderRef}>{loader}</Box>
    </Box>
  );
};

InfiniteScroller.propTypes = {
  loader: PropTypes.node,
  onLoadOlder: PropTypes.func,
  onLoadNewer: PropTypes.func,
  className: PropTypes.string,
  childrenLength: PropTypes.number,
  isScrollingUp: PropTypes.bool,
  autoScrollDuringNew: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.node),
};

InfiniteScroller.defaultProps = {
  loader: <></>,
  onLoadOlder: () => {},
  onLoadNewer: () => {},
  isScrollingUp: true,
  autoScrollDuringNew: true,
};

export default memo(InfiniteScroller);

const useStyles = makeStyles(theme => ({
  scrollUp: {
    display: "flex",
    flexDirection: "column-reverse",
    overflow: "auto",
  },
  scrollDown: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
}));
