import React, { useEffect, useReducer, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Box, Button, List, Tooltip, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  convertHex2rgba,
  formatStringWithKeyword,
  highlightString,
  isArrayNotEquals,
  replaceId2Name,
  toCamel,
} from "utils";
import { LangConstant, SystemConstant } from "const";
import { SearchBar } from "components";
import ChatAllTab from "./ChatAllTab";
import SearchPopup from "./SearchPopup";
import FlowMessageActive from "icons/FlowMessageActive";
import MessageFlow from "./MessageFlow";
import { FlowMessage } from "icons";
import { useAccountList, useContactList, useConversationList, useServerMode, useThreadInfo } from "hooks";
import { LocalMessageService } from "services/local.service";
import { ConversationActions } from "redux-store";

const ChatTab = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isBranchServer, selectedBranch } = useServerMode();
  const {
    channelList,
    groupList,
    personalMsgList,
    getChannelConversation,
    getGroupConversation,
    getPersonalConversation,
  } = useConversationList();
  const groupAndChannelList = [...channelList, ...groupList];
  const { unreadThreads } = useThreadInfo();
  const { accountList } = useAccountList();
  const { contactList } = useContactList();

  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const isRefreshing = useSelector(state => state.conversationRedux.isRefreshing);
  const isResizeWindow = useMediaQuery(theme => theme.breakpoints.up("xl"));
  const deletedGroupId = useSelector(state => state.conversationRedux.deletedGroupId);
  const dataUpdateGroupSuccess = useSelector(state => state.conversationRedux.dataUpdateGroupSuccess);
  const changeGroupPhoto = useSelector(state => state.conversationRedux.changeGroupPhoto);
  const isFetchMessageSuccess = useSelector(state => state.conversationRedux.isFetchMessageSuccess);
  const isUpdateViewMode = useSelector(state => state.conversationRedux.isUpdateViewMode);
  const selectedGroupId = useSelector(state => state.conversationRedux.selectedGroupId);
  const getGroupMembers = useSelector(state => state.groupInfoRedux.groupMembers);

  const [allList, setAllList] = useState([]);
  const [displayPersonalList, setDisplayPersonalList] = useState([]);
  const [displayGroupList, setDisplayGroupList] = useState([]);
  const [displayChannelList, setDisplayChannelList] = useState([]);
  const [searchInputEl, setSearchInputEl] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [contactSearchResults, setContactSearchResults] = useState([]);
  const [messageSearchResults, setMessageSearchResults] = useState([]);
  const [groupSearchResults, setGroupSearchResults] = useState([]);
  const [channelSearchResults, setChannelSearchResults] = useState([]);
  const [isView, setIsView] = useState(false);
  const [, forceRerender] = useReducer(x => x + 1, 0);

  const onCloseSearchPopup = () => {
    setSearchInputEl(null);
  };

  const onFocusSearchInput = () => {
    let inputEl = document.getElementById(SEARCH_INPUT_ANCHOR_ID);
    setSearchInputEl(inputEl);
  };

  const onChangeSearchInput = value => {
    setSearchValue(value);
  };

  const onViewMessageFlow = () => {
    setIsView(true);
  };

  useEffect(() => {
    if (searchValue) {
      const filteredChannels = channelList.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()));
      setChannelSearchResults(filteredChannels);

      const filteredList = groupList.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()));
      setGroupSearchResults(filteredList);

      LocalMessageService.searchMessageByCondition(searchValue).then(result => {
        let camelData = toCamel(result);
        let handleData = camelData.map(data => {
          const group = groupAndChannelList.filter(item => item.id === data.groupId);
          if (group.length > 0) {
            data.content = replaceId2Name(data.content, group[0].members);
          }
          data.content = formatStringWithKeyword(searchValue, data.content);
          data.content = highlightString(searchValue, data.content);

          return data;
        });
        setMessageSearchResults(
          handleData
            .filter(item => item.content.includes(searchValue))
            .sort((a, b) => (a.created > b.created ? 1 : -1))
            .slice(0, handleData.length > 5 ? 5 : handleData.length),
        );
      });
    }
  }, [searchValue]);

  useEffect(() => {
    if (false === Boolean(searchValue)) {
      setContactSearchResults([]);
    } else {
      const filteredList = (isBranchServer ? accountList : contactList).filter(
        item => (item.name || "").toLowerCase().includes(searchValue.toLowerCase()) || item.phone.includes(searchValue),
      );
      setContactSearchResults(filteredList);
    }
  }, [accountList, contactList, searchValue]);

  useEffect(() => {
    onCloseSearchPopup();
  }, [isResizeWindow]);

  useEffect(() => {
    let arrTimeout = null;
    if (isBranchServer) {
      getChannelConversation();
    }
    getGroupConversation();
    getPersonalConversation();

    arrTimeout = setTimeout(() => {
      if (isFetchMessageSuccess || isUpdateViewMode) {
        dispatch(
          ConversationActions.conversationSet({
            isFetchMessageSuccess: false,
          }),
        );
      }
      forceRerender();
    }, 200);

    return () => {
      if (arrTimeout) clearTimeout(arrTimeout);
    };
  }, [
    isRefreshing,
    isFetchMessageSuccess,
    dataUpdateGroupSuccess,
    changeGroupPhoto,
    isUpdateViewMode,
    selectedGroupId,
    selectedBranch,
    getGroupMembers,
  ]);

  useEffect(() => {
    if (deletedGroupId) {
      let personArray = [];
      let groupArray = [];
      let channelArray = [];
      let tmpArr = allList.filter(item => item.id !== deletedGroupId);
      setAllList(tmpArr);
      tmpArr.forEach(group => {
        if (group.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
          personArray.push(group);
        } else if (group.groupType === SystemConstant.GROUP_CHAT_TYPE.group) {
          groupArray.push(group);
        } else channelArray.push(group);
      });

      setDisplayChannelList(channelArray);
      setDisplayGroupList(groupArray);
      setDisplayPersonalList(personArray);
    }
  }, [deletedGroupId]);

  useEffect(() => {
    const allConversation = [...channelList, ...groupList, ...personalMsgList];
    if (isArrayNotEquals(allConversation, allList)) setAllList(allConversation);

    if (isArrayNotEquals(channelList, displayChannelList)) setDisplayChannelList(channelList);

    if (isArrayNotEquals(groupList, displayGroupList)) setDisplayGroupList(groupList);

    if (isArrayNotEquals(personalMsgList, displayPersonalList)) setDisplayPersonalList(personalMsgList);
  }, [channelList, groupList, personalMsgList]);

  const chatTabArray = isBranchServer
    ? [
        {
          label: getLabel(LangConstant.TXT_ALL),
        },
        {
          label: getLabel(LangConstant.TXT_CHANNEL),
        },
        {
          label: getLabel(LangConstant.TXT_PERSONAL),
        },
        {
          label: getLabel(LangConstant.TXT_GROUP),
        },
      ]
    : [
        {
          label: getLabel(LangConstant.TXT_ALL),
        },

        {
          label: getLabel(LangConstant.TXT_PERSONAL),
        },
        {
          label: getLabel(LangConstant.TXT_GROUP),
        },
      ];

  let tabs = isBranchServer ? CHAT_TAB_INDEX_WITH_CHANNEL : CHAT_TAB_INDEX;

  return (
    <Box className={classes.chatTabRoot}>
      <SearchBar
        placeholder={getLabel(LangConstant.TXT_CHAT_SEARCH_PLACE_HOLDER)}
        onFocus={onFocusSearchInput}
        onChange={onChangeSearchInput}
        value={searchValue}
        classes={{
          root: clsx(classes.searchBarRoot, {
            [classes.searchBarRootActive]: Boolean(searchInputEl),
          }),
        }}
        componentsProps={{
          root: { id: SEARCH_INPUT_ANCHOR_ID },
        }}
      />
      <SearchPopup
        anchorEL={searchInputEl}
        onClose={onCloseSearchPopup}
        keyword={searchValue}
        onKeywordClick={setSearchValue}
        data={{
          contacts: contactSearchResults,
          messages: messageSearchResults,
          groups: groupSearchResults,
          channels: channelSearchResults,
        }}
      />
      <Box className={classes.viewFullChat}>
        <Tooltip title={getLabel(LangConstant.TXT_VIEW_MESSAGE_THREAD)}>
          <Button
            className={classes.flowMessage}
            classes={{ root: unreadThreads.length === 0 && classes.buttonRoot }}
            variant="text"
            startIcon={
              unreadThreads.length > 0 ? (
                <FlowMessageActive className={classes.flowMessageBtn} />
              ) : (
                <FlowMessage className={classes.flowMessageBtn} />
              )
            }
            onClick={onViewMessageFlow}
          >
            {unreadThreads.length > 0
              ? getLabel(LangConstant.FM_NUMBER_FLOW_MESSAGE, { number: unreadThreads.length })
              : getLabel(LangConstant.TXT_FLOW_MESSAGE)}
          </Button>
        </Tooltip>
        <List>
          {isBranchServer && (
            <ChatAllTab
              chatList={displayChannelList}
              category={getLabel(LangConstant.FM_CHANNEL_TAG, { number: displayChannelList.length })}
              type={SystemConstant.CATEGORY_TYPE.channel}
            />
          )}
          <ChatAllTab
            chatList={displayGroupList}
            category={getLabel(LangConstant.FM_GROUP_TAG, { number: displayGroupList.length })}
            type={SystemConstant.CATEGORY_TYPE.group}
          />
          <ChatAllTab
            chatList={displayPersonalList}
            category={getLabel(LangConstant.FM_PERSONAL_TAG, { number: displayPersonalList.length })}
            type={SystemConstant.CATEGORY_TYPE.personal}
          />
        </List>
      </Box>

      {isView && <MessageFlow isOpen onClose={() => setIsView(false)} />}
    </Box>
  );
};

export default ChatTab;

ChatTab.propTypes = {};

const CHAT_TAB_INDEX_WITH_CHANNEL = {
  all: 0,
  channel: 1,
  personal: 2,
  group: 3,
};

const CHAT_TAB_INDEX = {
  all: 0,
  personal: 1,
  group: 2,
};

const SEARCH_INPUT_ANCHOR_ID = "search-input-anchor-id";

const useStyles = makeStyles(theme => ({
  chatTabRoot: {
    height: "100%",
    paddingTop: 12,
  },

  flexContainer: {
    borderBottom: "1px solid " + convertHex2rgba("#030229", 0.05),
    padding: "0 20px",
  },

  chatList: {
    height: "100%",
  },

  emptyChatList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },

  emptyImage: {
    padding: "0 14px",
  },

  emptyMessage: {
    fontSize: 15,
    fontWeight: 600,
    marginTop: 13,
    lineHeight: "20px",
  },

  tabPanel: {
    height: "100%",
  },

  tabRoot: {
    marginBottom: 10,
  },

  tabRootNoMargin: {
    margin: 0,
  },

  searchBarRoot: {
    margin: "0 14px",
    width: "calc(100% - 28px)",
  },

  searchBarRootActive: {
    zIndex: theme.zIndex.snackbar,
  },

  viewFullChat: {
    height: "95%",
    overflowY: "auto",
    margin: "0 14px",
    width: "calc(100% - 28px)",
  },

  flowMessage: {
    marginTop: 10,
    textTransform: "none",
  },

  flowMessageBtn: {},

  buttonRoot: {
    color: "#8E8E8E",
  },
}));
