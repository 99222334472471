import React, { memo, useCallback, useEffect, useState } from "react";
import { CardMedia, Fab } from "@mui/material";
import { LangConstant, ImageConstant } from "const";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import AddingContactResultDrawer from "./AddingContactResult";
import AddingGroupDrawer from "../AddingChat/AddingGroup/AddingGroupDrawer";
import { NoticeDialog } from "components";
import AddingContactDrawer from "./AddingContactDrawer";
import { BranchSelectors, ContactActions } from "redux-store";

const AddingContact = ({ isGroupTab }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isBranchServer = useSelector(BranchSelectors.isBranchServer);
  const isNotMatchingContact = useSelector(state => state.contactRedux.noSearchResults);
  const hasContact = useSelector(
    state => Boolean(state.contactRedux.existsInContactTable || state.contactRedux.notInContactTable),
    shallowEqual,
  );

  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const contactObjContent = getLabel(LangConstant.OBJ_ADDING_CONTACT, { returnObjects: true });

  const [isShow, setIsShow] = useState(false);
  const [isNotFound, setShowNotFoundDialog] = useState(false);
  const [isStartFindingContact, setStartFindingContact] = useState(false);
  const [fullPhoneNumber, setFullPhoneNumber] = useState("");

  const onCreateControlFuc = useCallback(
    open => () => {
      setIsShow(open);
      if (false === open) {
        setFullPhoneNumber("");
      }
    },
    [],
  );

  const onCloseAddingResultDrawer = () => {
    setStartFindingContact(false);
    setShowNotFoundDialog(false);
  };

  const onStartFindingContactByPhone = phoneNumber => {
    setStartFindingContact(true);
    setFullPhoneNumber(phoneNumber);
    dispatch(
      ContactActions.findContact({
        filter: JSON.stringify({ phone: phoneNumber }),
      }),
    );
  };

  useEffect(() => {
    if (isNotMatchingContact) {
      setShowNotFoundDialog(true);
      dispatch(
        ContactActions.contactSet({
          noSearchResults: false,
        }),
      );
    }
  }, [isNotMatchingContact]);

  return (
    <>
      {!isBranchServer && (
        <Fab color="primary" aria-label="add" className={classes.btnAdding} onClick={onCreateControlFuc(true)}>
          <CardMedia component="img" src={ImageConstant.AddContact} className={classes.logoImg} />
        </Fab>
      )}

      {isGroupTab ? (
        <AddingGroupDrawer open={isShow} onComplete={onCreateControlFuc(false)} onClose={onCreateControlFuc(false)} />
      ) : (
        <>
          {isShow && (
            <AddingContactDrawer open onClose={onCreateControlFuc(false)} onSubmit={onStartFindingContactByPhone} />
          )}

          {isStartFindingContact && (
            <>
              <NoticeDialog
                open={isNotFound}
                submitProps={{
                  className: classes.popupNoticeDialog,
                  onClick: onCloseAddingResultDrawer,
                }}
                title={contactObjContent.notification}
                content={contactObjContent.notRegisterPhone}
              />

              <AddingContactResultDrawer
                open={hasContact}
                onClose={onCloseAddingResultDrawer}
                onBackToMain={onCreateControlFuc(false)}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

AddingContact.propTypes = {};

export default memo(AddingContact);

const useStyles = makeStyles(theme => ({
  btnAdding: {
    boxShadow: "none",
    position: "absolute",
    bottom: 72,
    right: 16,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: "none",
    },
  },

  root: {
    paddingTop: 16,
    paddingBottom: 16,
  },

  logoImg: {
    width: 24,
    height: 24,
  },

  title: {
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
  },

  popupNoticeDialog: {
    "&:hover": {
      boxShadow: "none",
    },
  },
}));
