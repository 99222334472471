import { createReducer, createActions } from "reduxsauce";
import {
  REDUX_STATE,
  requestReducerFunc,
  failureReducerFunc,
  successReducerFunc,
  setReducerFunc,
} from "./redux-structure";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  changeProfileAvatar: ["data"],
  changeProfileBackground: ["data"],
  updateProfileInfo: ["data"],
  updateProfileStatus: ["data"],
  deleteProfileImage: ["data"],

  profileSuccess: ["data"],
  profileFailure: ["error", "data"],
  profileSet: ["data"],
});

export const ProfileTypes = Types;
export const ProfileActions = Creators;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  ...REDUX_STATE,
  changingAvatarId: "",
  changingBackgroundId: "",

  hasInternet: true,
  isDeletingAvatar: false,
  isUpdateUserInfoSuccess: false,
  isUpdateUserInfoFailed: false,
};

/* ------------- Selector ------------- */
export const ProfileSelectors = {};

/* ------------- Reducers ------------- */
const request = (state = INITIAL_STATE) =>
  requestReducerFunc({
    ...state,
    isDeletingAvatar: false,
    isUpdateUserInfoSuccess: false,
    isUpdateUserInfoFailed: false,
  });

const set = (state = INITIAL_STATE, action) => setReducerFunc(state, action);

const success = (state = INITIAL_STATE, action) => successReducerFunc(state, action);

const failure = (state = INITIAL_STATE, action) => failureReducerFunc(state, action);

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.CHANGE_PROFILE_AVATAR]: request,
  [Types.CHANGE_PROFILE_BACKGROUND]: request,
  [Types.UPDATE_PROFILE_INFO]: request,
  [Types.DELETE_PROFILE_IMAGE]: request,
  [Types.UPDATE_PROFILE_STATUS]: request,

  [Types.PROFILE_SET]: set,
  [Types.PROFILE_SUCCESS]: success,
  [Types.PROFILE_FAILURE]: failure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const ProfileReducer = createReducer(INITIAL_STATE, HANDLERS);
