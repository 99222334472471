import GlobalApi, { createApiWithBranch } from "./config/api.config";
import { ApiConstant } from "const";
import qs from "qs";

export const requestChangeDeviceRole = () =>
  GlobalApi.post(ApiConstant.POST_UPDATE_DEVICE, qs.stringify({ change_master_f: 1 }));

export const requestVerifyChangeDeviceRole = data =>
  GlobalApi.post(ApiConstant.POST_VERIFY_OTP_CHANGE_DEVICE_ROLE, qs.stringify(data, { skipNulls: true }));

export const requestBackupUpload = (data, branchId) =>
  createApiWithBranch(branchId).post(ApiConstant.POST_BACKUP_UPLOAD, data, {
    headers: ApiConstant.HEADER_DEFAULT_UPLOAD_FILE,
  });

export const requestBackup = (data, branchId) =>
  createApiWithBranch(branchId).post(ApiConstant.POST_BACKUP, qs.stringify(data, { skipNulls: true }));

export const requestGetBackup = data => createApiWithBranch().get(ApiConstant.GET_BACKUP_INFO, data);
