import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Box, Button } from "@mui/material";
import clsx from "clsx";
import { AppConstant, KeyConstant, LangConstant, PathConstant, SystemConstant } from "const";
import { getCommonKey } from "utils/lang.utils";
import OTPLocked from "./OTPLocked";
import OTPVerify from "components/OTPVerify";
import { AuthActions, AuthSelectors } from "redux-store";
import { StorageUtil } from "utils";

export const OTPAuth = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t: getLabel } = useTranslation(LangConstant.NS_LOGIN);
  const dispatch = useDispatch();

  const phoneNumber = useSelector(state => state.authRedux.phoneNumber);
  const isWrongOtp = useSelector(state => state.authRedux.isWrongOtp);
  const retries = useSelector(state => state.authRedux.retries);
  const isLimitedResend = useSelector(state => state.authRedux.isLimitedResend);
  const masterName = useSelector(state => state.authRedux.masterName);
  const fullLoginForm = useSelector(state => state.authRedux.fullLoginForm);
  const isLogin = useSelector(AuthSelectors.isLogin);
  const masterExisted = useSelector(state => state.authRedux.masterExisted);
  const isNew = useSelector(state => state.authRedux.isNew);
  const isLimitDevice = useSelector(state => state.authRedux.isLimitDevice);
  const isOtpExpired = useSelector(state => state.authRedux.isOtpExpired);

  const [isLockedOTP, setIsLockedOTP] = useState(false);
  const [otpType, setOtpType] = useState(
    masterExisted ? SystemConstant.OTP_TYPE.smartOtp : SystemConstant.OTP_TYPE.smsOtp,
  );
  const [otpValues, setOtpValues] = useState("");
  const [errorContent, setErrorContent] = useState(null);

  let deviceName = StorageUtil.getItem(KeyConstant.KEY_DEVICE_NAME);
  const isSmartOTP = otpType === SystemConstant.OTP_TYPE.smartOtp;
  const otpHeader = getOtpHeaderLang(
    { device: masterName || "", phoneNumber: phoneNumber || "" },
    isSmartOTP,
    getLabel,
  );

  const handleContinue = event => {
    event.preventDefault();
    dispatch(
      AuthActions.authSet({
        isWrongOtp: false,
        isLimitedResend: false,
        isLimitDevice: false,
        isOtpExpired: false,
      }),
    );
    dispatch(
      AuthActions.requestVerify({
        device_name: deviceName,
        phone: phoneNumber,
        code: otpValues,
      }),
    );
  };

  const onResendOtp = () => {
    setErrorContent(null);
    dispatch(
      AuthActions.requestLogin({
        ...fullLoginForm,
        mode: otpType,
        check_master_f: false,
      }),
    );
  };

  const onGoBack = () => {
    dispatch(
      AuthActions.authSet({
        phoneNumber: null,
        isLimitedResend: false,
      }),
    );

    // change phone number => reset input to null
    //
    // let asY = new AsYouType();
    // asY.input(phoneNumber);
    // console.log(asY.getNumber().number);
    // StorageUtil.setItem(KeyConstant.KEY_PHONE, asY.getNumber().number);
    // StorageUtil.setItem(KeyConstant.KEY_COUNTRY_CODE, asY.getNumber().countryCallingCode);
  };

  const onGotoHome = () => {
    history.replace(PathConstant.ROOT);
  };

  const onGotoCreateName = () => {
    history.replace(PathConstant.CREATE_NAME);
  };

  const onGotoRestoreData = () => {
    history.replace(PathConstant.RESTORE_DATA);
  };

  const changeTypeOfOTP = newOtpType => {
    setOtpType(newOtpType);

    dispatch(
      AuthActions.requestLogin({
        ...fullLoginForm,
        mode: newOtpType,
        check_master_f: false,
      }),
    );
  };

  useEffect(() => {
    if (isLogin) {
      StorageUtil.setItem(KeyConstant.KEY_LOGIN_TIME, new Date().getTime());
      isNew ? onGotoCreateName() : onGotoHome();
    }
  }, [isLogin]);

  useEffect(() => {
    if (retries === AppConstant.MAX_RETRY_TIMES) {
      setIsLockedOTP(true);
      let blockInfo = StorageUtil.getItem(KeyConstant.KEY_LOGIN_BLOCK_INFO)
        ? StorageUtil.getItem(KeyConstant.KEY_LOGIN_BLOCK_INFO)
        : {};
      blockInfo[phoneNumber] = new Date().getTime();
      StorageUtil.setItem(KeyConstant.KEY_LOGIN_BLOCK_INFO, blockInfo);
      dispatch(
        AuthActions.authSet({
          retries: 0,
        }),
      );
    }
  }, [retries]);

  useEffect(() => {
    if (isWrongOtp) {
      setErrorContent(
        getLabel(LangConstant.TXT_OTP_ERROR_MESSAGE, { tryTimes: AppConstant.MAX_RETRY_TIMES - retries }),
      );
    }
  }, [isWrongOtp]);

  useEffect(() => {
    if (isLimitedResend) {
      setErrorContent(getLabel(LangConstant.TXT_TRY_AGAIN_LATTER));
    }
  }, [isLimitedResend]);

  useEffect(() => {
    if (isLimitDevice) {
      setErrorContent(getLabel(LangConstant.TXT_LIMIT_DEVICE));
    }
  }, [isLimitDevice]);

  useEffect(() => {
    if (isOtpExpired) {
      setErrorContent(getLabel(LangConstant.TXT_OTP_EXPIRED));
    }
  }, [isOtpExpired]);

  return isLockedOTP ? (
    <OTPLocked />
  ) : (
    <Box className={classes.otpMainForm}>
      <form onSubmit={handleContinue}>
        <OTPVerify
          TitleProps={{
            title: otpHeader.title,
            content: otpHeader.content,
          }}
          InputProps={{
            errorContent: errorContent,
            onChange: setOtpValues,
          }}
          ActionProps={{
            otpType: otpType,

            onChangePhone: onGoBack,
            onResendOtp: onResendOtp,
            onChangeOtpType: changeTypeOfOTP,
          }}
        />

        <Button
          type="submit"
          variant="contained"
          className={clsx("semiBold-lg-txt", classes.continueButton)}
          disabled={otpValues.length < SystemConstant.DEFAULT_OTP_LENGTH}
          classes={{ disabled: classes.disabledButton }}
          onClick={handleContinue}
        >
          {getLabel(getCommonKey(LangConstant.TXT_CONTINUE))}
        </Button>
      </form>
    </Box>
  );
};

export default OTPAuth;

const getOtpHeaderLang = (data, isSmartOTP, getLabel) => {
  let tmpHeader = { title: "", content: "" };
  if (isSmartOTP) {
    tmpHeader = {
      title: getLabel(LangConstant.TXT_SENT_SMART_OTP_TITLE),
      content: getLabel(LangConstant.TXT_SENT_OTP_MESSAGE, { device: data.masterName || "" }),
    };
  } else {
    tmpHeader = {
      title: getLabel(LangConstant.TXT_SENT_SMS_OTP_TITLE),
      content: getLabel(LangConstant.TXT_SENT_SMS_OTP_MESSAGE, { phone: data.phoneNumber || "" }),
    };
  }
  return tmpHeader;
};

const useStyles = makeStyles(theme => ({
  otpMainForm: {
    backgroundColor: theme.palette.white,
    width: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0 80px",

    [theme.breakpoints.down("lg")]: {
      padding: "0 24px",
    },
  },

  disabledButton: {
    backgroundColor: "#72B9E6 !important",
    color: theme.palette.white + "!important",
  },

  continueButton: {
    marginTop: 15,
    width: "100%",
    padding: "14px 0",
  },
}));
