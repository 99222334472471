import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Backdrop, CircularProgress } from "@mui/material";

const Processing = ({ isShow }) => {
  const classes = useStyles();

  return (
    <Backdrop open={isShow} className={classes.backdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.tooltip + 1,
    color: theme.palette.white,
  },
}));

Processing.propTypes = { isShow: PropTypes.bool };
Processing.defaultProps = { isShow: false };

export default memo(Processing);
