import { call, put, select } from "redux-saga/effects";
import { AttachmentService, BranchService } from "services";
import { convertJSONObject, removeDuplicateInArray, removeDuplicatesWithKey, toCamel, toSnake } from "utils";
import { getSavedServer } from "../utils/common.utils";
import { LocalAttachmentService, LocalBranchAccountService, LocalBranchService } from "services/local.service";
import { ApiConstant, KeyConstant, SystemConstant } from "const";
import { saveAccountListByIds } from "./account.saga";
import { ConversationActions, BranchActions } from "redux-store";
import { StorageUtil } from "utils";

export function* synchBranchList() {
  try {
    const response = yield call(BranchService.getBranchList);
    if (response.status === ApiConstant.STT_OK) {
      const responseData = response.data;

      if (responseData.length > 0) {
        yield LocalBranchService.save(responseData);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getBranch() {
  try {
    const {
      branchRedux: { selectedBranch: selectedBranchRedux },
    } = yield select();
    const isSelectBranchRedux = Boolean(selectedBranchRedux && selectedBranchRedux.id);
    const response = yield call(BranchService.getBranchList);
    if (response.status === ApiConstant.STT_OK) {
      const responseData = response.data;

      if (Array.isArray(responseData) && responseData.length > 0) {
        yield LocalBranchService.save(responseData);
        saveFileFromBranchList(responseData);

        if (false === isSelectBranchRedux) {
          const storedBranch = getSavedServer();
          const filterFunc = storedBranch.id
            ? serverItem => serverItem.id === storedBranch.id
            : serverItem => serverItem.type === SystemConstant.SERVER_TYPE.server;

          let selectBranch = responseData.find(filterFunc);
          const isNotExist = false === Boolean(selectBranch && selectBranch.id);
          if (isNotExist) {
            selectBranch = responseData[0];
          }
          selectBranch = toCamel(selectBranch);

          // Save data to StorageUtil/ Redux
          StorageUtil.setItem(KeyConstant.KEY_SELECTED_SERVER, selectBranch);
          yield put(
            BranchActions.branchSet({
              selectedBranch: selectBranch,
            }),
          );
        }
      }

      yield getBranchAccount();

      yield put(
        BranchActions.branchSuccess({
          isGetBranchSuccess: true,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* updateBranch(action) {
  try {
    const { data } = action;

    const toApiData = toSnake(data);
    toApiData.branch_accounts = JSON.stringify(toApiData.branch_accounts);

    const response = yield call(BranchService.updateBranch, toApiData);
    if (response.status === ApiConstant.STT_OK) {
      const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
      const branchAccountData = response.data;
      LocalBranchAccountService.save(branchAccountData);

      const defaultBranchItem = branchAccountData.find(
        item =>
          item.state === SystemConstant.BRANCH_STATE.active &&
          item.account_id === accountId &&
          item.options &&
          Boolean(convertJSONObject(item.options)?.default_f),
      );

      if (defaultBranchItem) {
        const defaultBranch = LocalBranchService.getByBranchId(defaultBranchItem.branch_id);
        if (defaultBranch) {
          StorageUtil.setItem(KeyConstant.KEY_SELECTED_SERVER, toCamel(defaultBranch));
          StorageUtil.setItem(KeyConstant.KEY_IS_HAVING_DEFAULT_FLAG, defaultBranchItem.branch_id);

          yield put(
            BranchActions.branchSet({
              defaultBranch: defaultBranch,
            }),
          );
        }
      } else {
        StorageUtil.removeItem(KeyConstant.KEY_IS_HAVING_DEFAULT_FLAG);
      }

      yield getBranch();

      yield put(
        ConversationActions.updateMessageStatus({
          notifyIds: [data.branchAccounts[0].messageId],
          status: SystemConstant.MESSAGE_STATUS.read,
        }),
      );
      yield put(
        BranchActions.branchSuccess({
          isUpdateBranchInviteStatus: true,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

const saveFileFromBranchList = branchArr => {
  const tmpArr = toCamel(branchArr);

  if (Array.isArray(tmpArr)) {
    tmpArr.forEach(branch => {
      if (branch.attachmentId) {
        const fileName = branch.attachmentId + ".png";
        if (
          !LocalAttachmentService.exitsLocalFile(branch.attachmentId, SystemConstant.ATTACHMENT_TYPE.account, fileName)
        ) {
          const response = AttachmentService.getGlobalAttachment({ attachment_id: branch.attachmentId });
          try {
            response.then(responseData => {
              const unit8String = new Uint8Array(responseData.data);
              if (responseData.status === ApiConstant.STT_OK) {
                LocalAttachmentService.saveFileUnEncrypted(unit8String, branch.attachmentId, fileName);
              }
            });
          } catch (error) {
            console.log({ error });
          }
        }
      }
    });
  }
};

export function* getBranchAccount() {
  try {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);

    const branchAccount = LocalBranchService.getBranchServer();
    const currentBranches = LocalBranchService.getAllInstantly();
    const idsArr = branchAccount.map(item => ({
      branchId: item.id,
      lastTime: item.created + 1,
    }));

    const getBranchAccountResponse = yield call(BranchService.getBranchAccount, JSON.stringify(toSnake(idsArr)));

    if (getBranchAccountResponse.status === ApiConstant.STT_OK) {
      const branchAccountArray = getBranchAccountResponse.data;
      const camelAccountArr = toCamel(branchAccountArray);
      const accountIdArr = camelAccountArr.map(item => item.accountId);

      const tmpNewBranchArr = [...branchAccountArray]
        .filter(item => !Boolean(currentBranches.find(item2 => item2.id === item.branch_id)))
        .map(item => item.branch_id);

      const defaultBranchItem = branchAccountArray.find(
        item =>
          item.state === SystemConstant.BRANCH_STATE.active &&
          item.account_id === accountId &&
          item.options &&
          Boolean(convertJSONObject(item.options)?.default_f),
      );

      const savedServer = getSavedServer();

      if (defaultBranchItem) {
        const defaultBranch = LocalBranchService.getByBranchId(defaultBranchItem.branch_id);
        if (defaultBranch) {
          if (!savedServer || savedServer.id !== defaultBranchItem.branch_id) {
            StorageUtil.setItem(KeyConstant.KEY_SELECTED_SERVER, toCamel(defaultBranch));

            yield put(
              BranchActions.branchSet({
                selectedBranch: toCamel(defaultBranch),
              }),
            );
          }
          StorageUtil.setItem(KeyConstant.KEY_IS_HAVING_DEFAULT_FLAG, defaultBranchItem.branch_id);
        }
      } else {
        StorageUtil.setItem(KeyConstant.KEY_IS_HAVING_DEFAULT_FLAG, null);

        if (!savedServer) {
          const firstActiveBranch = branchAccountArray.find(
            item2 => item2.state === SystemConstant.BRANCH_STATE.active && item2.account_id === accountId,
          );

          const autoChooseBranch = [...branchAccount, ...currentBranches].find(
            item => item.id === firstActiveBranch?.branch_id,
          );

          if (autoChooseBranch) {
            StorageUtil.setItem(KeyConstant.KEY_SELECTED_SERVER, toCamel(autoChooseBranch));
            yield put(
              BranchActions.branchSet({
                selectedBranch: toCamel(autoChooseBranch),
              }),
            );
          } else {
            yield getBranch();
          }
        } else {
          yield put(
            BranchActions.branchSet({
              selectedBranch: savedServer,
            }),
          );
        }
      }

      if (tmpNewBranchArr.length > 0) {
        yield getBranch();
      }

      // Check if current account status in any branch !== 1 (active) -> remove that branch from selected server list
      const kickedBranchArray = [...branchAccountArray].filter(
        item =>
          item.state !== SystemConstant.BRANCH_STATE.active &&
          item.account_id === accountId &&
          LocalBranchAccountService.findByAccountId(accountId, item.branch_id)?.state !== item.state,
      );

      if (kickedBranchArray.length > 0) {
        const kickedBranchId = removeDuplicatesWithKey(
          kickedBranchArray.map(item => ({
            branchId: item.branch_id,
          })),
          "branchId",
        );

        const defaultBranchId = StorageUtil.getItem(KeyConstant.KEY_IS_HAVING_DEFAULT_FLAG);
        const isKickedDefaultBranch = kickedBranchArray.find(item => item.branchId === defaultBranchId);
        if (isKickedDefaultBranch) {
          StorageUtil.setItem(KeyConstant.KEY_IS_HAVING_DEFAULT_FLAG, null);
        }

        yield put(
          BranchActions.branchSet({
            kickedBranchArray: kickedBranchId,
          }),
        );
      }

      yield saveAccountListByIds({
        account_ids: removeDuplicateInArray(accountIdArr),
      });

      LocalBranchAccountService.save(branchAccountArray);
    }
  } catch (error) {
    console.log(error);
  }
}
