import { StorageUtil } from "utils";
import { AppConstant, KeyConstant, SystemConstant } from "../const";
import { convertString2JSON, deepCloneJsonObject, removeDuplicateInArray, toCamel } from "./index";
import {
  LocalAccountGroupService,
  LocalAccountService,
  LocalDeviceService,
  LocalGroupService,
  LocalMessageService,
} from "services/local.service";

export const showConfirmLeaveGroup = (data, setIsConfirmLeave, setIsChooseAdmin) => {
  let memberArray = data.members;
  LocalAccountGroupService.findByGroupId(data.id).then(result => {
    let camelResult = toCamel(result);
    let tmpAdminArray = [];
    camelResult.forEach(item => {
      if (item.type === SystemConstant.ROLE_OBJ.admin) {
        tmpAdminArray.push(memberArray.find(member => member.id === item.accountId));
      }
    });
    tmpAdminArray = tmpAdminArray.filter(item => item !== undefined);
    if (tmpAdminArray.length > 1) {
      setIsConfirmLeave(true);
    } else {
      if (tmpAdminArray.find(element => element.id === StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID))) {
        setIsChooseAdmin(true);
      } else {
        setIsConfirmLeave(true);
      }
    }
  });
};

export const getSavedServer = () => convertString2JSON(StorageUtil.getItem(KeyConstant.KEY_SELECTED_SERVER)) || {};

export const groupByByKey = (key, array) => {
  let list = array.filter(obj => {
    return obj[key];
  });
  let map = {};
  list.forEach(obj => {
    if (!map[obj[key]]) {
      map[obj[key]] = [];
    }
    map[obj[key]].push(obj);
  });
  return map;
};

export const notificationClear = result => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const mapByGroup = {};
  let clearList = [];
  let allNotSeen = {};
  result.forEach(obj => {
    if (!mapByGroup[obj.group_id]) {
      mapByGroup[obj.group_id] = [];
    }
    mapByGroup[obj.group_id].push(obj);
    if (!allNotSeen[obj.id]) {
      allNotSeen[obj.id] = obj;
    }
  });
  let groupDeleted =
    Object.keys(mapByGroup).length === 0 ? [] : LocalGroupService.getByGroupIds(Object.keys(mapByGroup));
  let groupIds = groupDeleted.map(gd => gd.id);
  let groupDeletedIds = groupDeleted
    .filter(
      gd => gd.state === SystemConstant.STATE.inactive || gd.account_group_state === SystemConstant.STATE.inactive,
    )
    .map(gd => gd.id);
  let diff = Object.keys(mapByGroup);
  diff = diff.filter(d => !groupIds.includes(d));
  diff = diff.concat(groupDeletedIds);
  diff.forEach(it => {
    mapByGroup[it].forEach(mes => {
      if (mes) {
        mes.status = SystemConstant.MESSAGE_STATUS.read;
        delete allNotSeen[mes.id];
        clearList.push(mes);
      }
    });
  });

  let threadMap = groupByByKey("thread_id", Object.values(allNotSeen));
  let threadParentMessage = LocalMessageService.getMessageWithSourceIds(Object.keys(threadMap)).map(it => it.source_id);
  threadParentMessage.forEach(tp => {
    delete threadMap[tp];
  });
  Object.keys(threadMap).forEach(tm => {
    if (threadMap[tm]) {
      threadMap[tm].forEach(mes => {
        mes.status = SystemConstant.MESSAGE_STATUS.read;
        delete allNotSeen[mes.id];
        clearList.push(mes);
      });
    }
  });
  let parentMap = groupByByKey("parent_id", Object.values(allNotSeen));
  let parentMessage = LocalMessageService.getMessageWithSourceIds(Object.keys(parentMap)).map(it => it.source_id);
  parentMessage.forEach(tp => {
    delete parentMap[tp];
  });
  Object.keys(parentMap).forEach(tm => {
    if (parentMap[tm]) {
      parentMap[tm].forEach(mes => {
        mes.status = SystemConstant.MESSAGE_STATUS.read;
        delete allNotSeen[mes.id];
        clearList.push(mes);
      });
    }
  });
  let clone = deepCloneJsonObject(Object.values(allNotSeen));
  clone.forEach(al => {
    if (
      al.send_type === SystemConstant.SEND_TYPE.deleteMessage ||
      al.send_type === SystemConstant.SEND_TYPE.deleteReaction ||
      al.send_type === SystemConstant.SEND_TYPE.leaveGroup ||
      (NORMAL_MESSAGE_SEND_TYPE.includes(al.send_type) && Boolean(al.parent_id)) || // edited message
      (SystemConstant.ARR_CALLING_TYPES.includes(al.send_type) &&
        al.call_status !== SystemConstant.MESSAGE_CALL_STATUS.missed) // notice call
    ) {
      delete allNotSeen[al.id];
      clearList.push(al);
    }
  });
  let cloneList = deepCloneJsonObject(Object.values(allNotSeen));
  cloneList.forEach(n => {
    try {
      let options = convertString2JSON(n.options);
      if (options.seen_members && options.seen_members.length > 0 && options.seen_members.includes(accountId)) {
        delete allNotSeen[n.id];
        clearList.push(n);
      }
    } catch (e) {
      console.log(e);
    }
  });
  clearList = clearList.filter(c => c.status !== SystemConstant.NOTIFICATION_STATUS.read);
  return { allNotSeen: allNotSeen, clearList: clearList };
};

// Return new mention arr in thread
export const validateMentions = (threadInfo, newMentions, parentMentions) => {
  const threadMentions = Boolean(threadInfo) ? JSON.parse(threadInfo.thread_mentions) : [];

  let newMentionArr = [];
  if (newMentions && Array.isArray(newMentions)) {
    newMentionArr = newMentions;
  } else if (newMentions) {
    newMentionArr = JSON.parse(newMentions);
  }
  const parentMentionArr = Boolean(parentMentions) ? JSON.parse(parentMentions) : [];
  return removeDuplicateInArray([...parentMentionArr, ...threadMentions, ...newMentionArr]);
};

export const validateInvolved = (message, mentionArr) => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const parentMessage = LocalMessageService.getMessageWithSourceId(message.thread_id);

  return parentMessage.sender_id === accountId || message.sender_id === accountId || mentionArr.includes(accountId);
};

export const checkOnlineStatus = accountId => {
  const account = LocalAccountService.getAccount(accountId);
  if (account && account.status === SystemConstant.ACCOUNT_STATUS.away) return AppConstant.ACCOUNT_STATUS.offline;
  const activeDevice = LocalDeviceService.getByAccountAndState(accountId, SystemConstant.DEVICE_STATE.active);
  const lastSeen = activeDevice.reduce((result, item) => {
    if (result < item.last_seen) {
      result = item.last_seen;
    }

    return result;
  }, 0);
  const nowTimestamp = new Date().getTime();

  if (lastSeen + AppConstant.ONE_MINUTE > nowTimestamp) {
    return AppConstant.ACCOUNT_STATUS.online;
  } else {
    return AppConstant.ACCOUNT_STATUS.offline;
  }
};

const NORMAL_MESSAGE_SEND_TYPE = [SystemConstant.SEND_TYPE.message, SystemConstant.SEND_TYPE.groupMessage];
