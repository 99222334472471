import React, { memo, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Avatar, Box, CardMedia, CircularProgress, Typography } from "@mui/material";
import { ApiConstant, AppConstant, SystemConstant } from "const";
import { convertJSONObject, convertString2JSON, isImage, isString, toCamel } from "utils";
import { AttachmentService } from "services";
import { LocalAttachmentService } from "services/local.service";

// TODO: Need create HOC to preview video, file, image
const ImagePreview = ({ data, isMine, onClick, isAvatar }) => {
  const classes = useStyles();

  const [mediaSrc, setMediaSrc] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [attachmentId, setAttachmentId] = useState(null);
  const [caption, setCaption] = useState(null);

  const onPreviewLocalMedia = async (attachmentId, metaData) => {
    const mediaPath = await LocalAttachmentService.getLocalImageSrc(
      attachmentId,
      AppConstant.GROUP_CHAT_TYPE.group,
      metaData.mimeType,
      metaData.fileName,
    );

    console.log("mediaPath", mediaPath);

    setMediaSrc(mediaPath);
    setMediaType(metaData.mimeType);
  };

  const onPreviewServerMedia = async (fileObj, fileMetaData) => {
    const responseData = await AttachmentService.getAttachment({ attachment_id: fileObj.attachmentId });
    if (responseData && responseData.status === ApiConstant.STT_OK && responseData.data) {
      const encryptedBytes = new Uint8Array(responseData.data);
      const decryptFilePath = await LocalAttachmentService.decryptAESGroupFile(
        encryptedBytes,
        fileObj.aesKeyInfo.key,
        fileObj.aesKeyInfo.iv,
        fileObj.aesKeyInfo.authtag,
        fileObj.attachmentId,
        fileMetaData.fileName,
      );

      if (isString(decryptFilePath)) onPreviewLocalMedia(fileObj.attachmentId, fileMetaData);
    } else {
      console.warn("Something wrong after calling", responseData);
    }
  };

  useEffect(() => {
    if (data) {
      const fileObj = toCamel(convertJSONObject(convertString2JSON(data.content)));
      const fileMetaData = fileObj.metaData || {};

      if (fileObj.attachmentId && fileObj.attachmentId !== attachmentId) {
        setAttachmentId(fileObj.attachmentId);
        setCaption(fileObj.contentMessage);

        const isExistFile = LocalAttachmentService.exitsLocalFile(
          fileObj.attachmentId,
          SystemConstant.GROUP_CHAT_TYPE.group,
          fileMetaData.fileName,
        );

        if (isExistFile) {
          onPreviewLocalMedia(fileObj.attachmentId, fileMetaData);
        } else {
          onPreviewServerMedia(fileObj, fileMetaData);
        }
      }
    }
  }, [data]);

  return isMine ? (
    <Box className={classes.wrapPreview}>
      <Box className={classes.contentContainer}>
        <MediaBox classes={classes} mediaSrc={mediaSrc} mediaType={mediaType} onClick={onClick} caption={caption} />
        {caption && <Typography className={classes.caption} dangerouslySetInnerHTML={{ __html: caption }} />}
      </Box>
    </Box>
  ) : (
    <Box className={classes.wrapPreviewFlex}>
      {isAvatar ? (
        <Avatar className={classes.imageTitle} alt="img-chat" src={data.avatar}>
          {data?.senderName?.charAt(0)}
        </Avatar>
      ) : (
        <Box className={classes.hiddenAvatar}></Box>
      )}
      <Box className={classes.avatarBox}>
        {isAvatar && (
          <Typography className={classes.senderName}>
            {data?.senderName}, {data.sentTime}
          </Typography>
        )}
        <Box className={classes.contentContainer}>
          <MediaBox mediaSrc={mediaSrc} mediaType={mediaType} onClick={onClick} />
          {caption && <Typography className={classes.caption} dangerouslySetInnerHTML={{ __html: caption }} />}
        </Box>
      </Box>
    </Box>
  );
};

const MediaBox = ({ mediaSrc, mediaType, onClick }) => {
  const classes = useStyles();
  if (!mediaSrc)
    return (
      <Box className={classes.loadingBox}>
        <CircularProgress />
      </Box>
    );

  const isImageMedia = isImage(mediaType);

  return isImageMedia ? (
    <CardMedia className={classes.cardImage} component="img" src={mediaSrc} onClick={onClick} />
  ) : (
    <video
      className={classes.cardVideo}
      controls
      onClick={event => {
        event.preventDefault();
        onClick();
      }}
      src={mediaSrc + "#t=1"}
    />
  );
};

export default memo(ImagePreview);

const useStyles = makeStyles({
  cardImage: {
    maxWidth: 300,
    borderRadius: 20,
  },

  wrapPreview: {
    display: "flex",
    justifyContent: "end",
    alignItems: "end",
    cursor: "pointer",
    width: "100%",
    height: "auto",
  },

  imagePreview: {
    color: "#666666",
    marginRight: 8,
  },

  wrapPreviewFlex: {
    display: "flex",
    cursor: "pointer",
    width: "100%",
    height: "auto",
  },

  imageTitle: {
    cursor: "pointer",
  },

  cardVideo: {
    maxWidth: "100%",
    maxHeight: 500,
    borderRadius: 20,
  },

  hiddenAvatar: {
    width: 40,
    height: 40,
  },

  contentContainer: {
    backgroundColor: "#F1F3F6",
    borderRadius: 20,
    padding: "10px 8px",
  },

  caption: {
    fontSize: 14,
    fontWeight: 600,
    borderTop: "1px dotted #a7a7a7",
    marginTop: 5,
    width: "100%",
    padding: "10px 0 5px",
  },

  avatarBox: {
    paddingLeft: 16,
  },

  senderName: {
    paddingLeft: 5,
    fontSize: 12,
    fontWeight: 550,
    marginBottom: 5,
  },
});
