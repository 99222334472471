import { useTranslation } from "react-i18next";
import { AppConstant, ImageConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { useEffect, useState } from "react";
import { DrawerLayout, NoticeDialog } from "components";
import { useDispatch, useSelector } from "react-redux";
import { RestoreActions } from "redux-store";
import { Box, Button, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BackupFileInfo from "./BackupFileInfo";
import OTPInput from "components/OTPVerify/OTPInput";
import { convertMillisecondsToHMS } from "utils/date.utils";
import RestoreProgress from "./RestoreProgress";
import SuccessDialog from "components/dialog/SuccessDialog";
import { StorageUtil } from "utils";

const RestoreDrawer = ({ open, onClose, ...otherProps }) => {
  const { t: getLabel } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const backupInfoObj = getLabel(LangConstant.OBJ_BACKUP_INFO, { returnObjects: true });

  const restoreStatus = useSelector(state => state.restoreRedux.restoreStatus);
  const backupInfo = useSelector(state => state.restoreRedux.backupInfo);

  const [hasBackup, setHasBackup] = useState(CHECKING_BACKUP_DATA_STATUS.checking);
  const [passCode, setPassCode] = useState("");
  const [errorContent, setErrorContent] = useState(null);
  const [isOpenRestoreProgress, setIsOpenRestoreProgress] = useState(false);
  const [retries, setRetries] = useState(1);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isOpenNoti, setIsOpenNoti] = useState(false);
  const [countDownTime, setCountDownTime] = useState(new Date().getTime());
  const [isFinish, setIsFinish] = useState(false);

  const handleRestoreToLocal = () => {
    dispatch(RestoreActions.restoreToLocal({ passCode }));
  };

  const handleCloseNoti = () => {
    setIsOpenNoti(false);
  };

  const handleFinish = () => {
    dispatch(
      RestoreActions.restoreSet({
        backupInfo: null,
        restoreStatus: SystemConstant.RESTORE_STATUS.notStart,
      }),
    );
    setHasBackup(CHECKING_BACKUP_DATA_STATUS.checking);
    setIsFinish(false);
    setErrorContent(null);
    setIsOpenRestoreProgress(false);
    dispatch(
      RestoreActions.restoreSet({
        backupInfo: null,
      }),
    );
    onClose();
  };

  const handleRetry = () => {
    dispatch(RestoreActions.restoreToLocal({ passCode }));
    setErrorContent(null);
  };

  useEffect(() => {
    switch (restoreStatus) {
      case SystemConstant.RESTORE_STATUS.noBackupFile:
        setErrorContent(null);
        setHasBackup(CHECKING_BACKUP_DATA_STATUS.noData);
        break;
      case SystemConstant.RESTORE_STATUS.getFileInfo:
        setErrorContent(null);
        if (backupInfo && Object.keys(backupInfo).length > 0) setHasBackup(CHECKING_BACKUP_DATA_STATUS.hasData);
        break;
      case SystemConstant.RESTORE_STATUS.inProgress:
        setErrorContent(null);
        setIsOpenRestoreProgress(true);
        break;
      case SystemConstant.RESTORE_STATUS.wrongPasscode:
        setIsOpenRestoreProgress(false);
        if (retries < AppConstant.MAX_RETRY_TIMES) {
          setErrorContent(getLabel(LangConstant.FM_PASSCODE_WRONG, { retries: AppConstant.MAX_RETRY_TIMES - retries }));
          setRetries(state => state + 1);
        } else {
          const blockTimestamp = new Date().getTime();
          StorageUtil.setItem(KeyConstant.KEY_DISABLE_TIMESTAMP, blockTimestamp);
          setCountDownTime(blockTimestamp);
          setIsBlocked(true);
          setIsOpenNoti(true);
        }
        break;
      case SystemConstant.RESTORE_STATUS.error:
        setErrorContent(getLabel(LangConstant.TXT_SERVER_ERROR));
        break;
      default:
        break;
    }
  }, [restoreStatus]);

  useEffect(() => {
    let countDownInterval = null;
    const blockTimestamp = StorageUtil.getItem(KeyConstant.KEY_DISABLE_TIMESTAMP);
    const unBlockTimestamp = Number(blockTimestamp) + 300000;
    let time = unBlockTimestamp - countDownTime;

    if (time > 0) {
      setIsBlocked(true);
      countDownInterval = setInterval(() => {
        if (time > 0) {
          const countDownObj = convertMillisecondsToHMS(time - 1000, true);
          setErrorContent(
            getLabel(LangConstant.FM_PASSCODE_BLOCKED, {
              countDown: countDownObj.minutes + ":" + countDownObj.seconds,
            }),
          );
          time = time - 1000;
        } else {
          setIsBlocked(false);
          setErrorContent("");
          clearInterval(countDownInterval);
        }
      }, 1000);
    } else {
      setIsBlocked(false);
      setErrorContent(null);
    }

    return () => {
      if (countDownInterval) clearInterval(countDownInterval);
    };
  }, [countDownTime]);

  useEffect(() => {
    if (isFinish) {
      setTimeout(() => {
        handleFinish();
      }, 3000);
    }
  }, [isFinish]);

  return (
    <>
      <DrawerLayout open={open} onClose={handleFinish} title={getLabel(LangConstant.TXT_RESTORE)} {...otherProps}>
        {isOpenRestoreProgress ? (
          <Box className={classes.restoreProgressBox}>
            <RestoreProgress
              hasError={Boolean(errorContent)}
              size={backupInfo?.size || 0}
              onFinish={setIsFinish}
              onRetry={handleRetry}
              onCancel={handleFinish}
            />
          </Box>
        ) : (
          <Box className={classes.root}>
            <Box className={classes.component}>
              <CardMedia
                component="img"
                alt="image key"
                src={ImageConstant.SynchronizeImage}
                className={classes.synchronizeImage}
              />
              {hasBackup === CHECKING_BACKUP_DATA_STATUS.hasData && backupInfo ? (
                <>
                  <Box className={classes.restoreComponent}>
                    <BackupFileInfo
                      backupDeviceId={backupInfo.deviceId || ""}
                      backupTime={parseInt(backupInfo.createdTime) || 0}
                      size={backupInfo.size || 0}
                    />
                    <Typography className={classes.restoreInstruction}>
                      {getLabel(LangConstant.TXT_RESTORE_INSTRUCTION)}
                    </Typography>
                    <OTPInput onChange={setPassCode} errorContent={errorContent} />
                  </Box>
                </>
              ) : hasBackup === CHECKING_BACKUP_DATA_STATUS.noData ? (
                <>
                  <Typography className={classes.backupInfoTitle}>{backupInfoObj.title}</Typography>
                  <Typography className={classes.noBackup}>{getLabel(LangConstant.TXT_NO_BACKUP)}</Typography>
                </>
              ) : (
                <>
                  <Typography className={classes.backupInfoTitle}>
                    {getLabel(LangConstant.TXT_CHECKING_RESTORE_DATA)}
                  </Typography>
                  {errorContent && <Typography className={classes.noBackup}>{errorContent}</Typography>}
                </>
              )}
            </Box>
            <Button
              variant="contained"
              fullWidth
              className={hasBackup === CHECKING_BACKUP_DATA_STATUS.hasData ? classes.actionBtn : "hidden"}
              disabled={passCode.length !== SystemConstant.DEFAULT_OTP_LENGTH || isBlocked}
              onClick={handleRestoreToLocal}
            >
              {getLabel(LangConstant.TXT_RESTORE_ACTION)}
            </Button>
          </Box>
        )}
      </DrawerLayout>
      {isOpenNoti && (
        <NoticeDialog
          open
          title={getLabel(LangConstant.TXT_BLOCKED_RESTORE)}
          content={getLabel(LangConstant.TXT_BLOCKED_RESTORE_CONTENT)}
          submitProps={{ submitText: getLabel(LangConstant.TXT_GOT_IT), onClick: handleCloseNoti }}
        />
      )}

      {isFinish && <SuccessDialog open onClose={handleFinish} title={getLabel(LangConstant.TXT_RESTORE_SUCCESS)} />}
    </>
  );
};

export default RestoreDrawer;

const CHECKING_BACKUP_DATA_STATUS = {
  checking: 0,
  hasData: 1,
  noData: 2,
};

const useStyles = makeStyles({
  root: {
    padding: 20,
  },

  component: {
    height: "75vh",
  },

  restoreComponent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  synchronizeImage: {
    height: 140,
    objectFit: "contain",
    marginTop: 30,
  },

  backupInfoTitle: {
    marginTop: 10,
    fontWeight: "bold",
    textAlign: "center",
  },

  noBackup: {
    textAlign: "center",
    color: "red",
    fontSize: 12,
    marginTop: 20,
  },

  actionBtn: {
    marginTop: 8,
    minHeight: 40,
    fontSize: 14,
  },

  restoreInstruction: {
    textAlign: "center",
    marginTop: 50,
    marginBottom: 10,
  },

  restoreProgressBox: {
    height: "90vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
});
