import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Clear, Done, InsertEmoticon } from "@mui/icons-material";
import { Avatar, Box, IconButton, Input, Typography } from "@mui/material";
import clsx from "clsx";
import { isExternalLink, replaceEditName2Id } from "utils";
import { SystemConstant } from "const";
import LinkPreview from "./LinkPreview";
import { ConversationContext } from ".";

const MessengerPeople = ({ data, isMine, isEditing, setIsEditing, isAvatar }) => {
  const classes = useStyles();
  const inputRef = useRef();
  const { onSendMessage } = useContext(ConversationContext);

  const [messageContent, setMessageContent] = useState(data.content);

  const onChangeMessageContent = event => {
    setMessageContent(event.target.value);
  };

  const onClickEmoji = () => {
    inputRef.current.focus();
  };

  const onCancelEdit = () => {
    setMessageContent(data.content);
    setIsEditing(false);
  };

  const onSubmit = () => {
    if (messageContent !== data.content) {
      onSendMessage(SystemConstant.SEND_TYPE.message, replaceEditName2Id(messageContent, data.members), data.sourceId);
    }
    setIsEditing(false);
  };

  useEffect(() => {
    if (isEditing) {
      setMessageContent(data.content);
    }
  }, [isEditing]);

  useEffect(() => {
    setMessageContent(data.content);
  }, [data.content]);

  return (
    <>
      {isMine ? (
        <Box className={classes.wrapPeopleFlex}>
          <Box
            className={clsx(classes.messageContentItem, classes.imageMessengerFlex, {
              [classes.editingMessage]: isEditing,
              [classes.previewLink]: isExternalLink(data?.content),
            })}
          >
            {isEditing ? (
              <Box>
                <Input
                  classes={{
                    root: classes.editMessageRoot,
                    input: clsx(isEditing && classes.editMessageInputEditing, classes.editMessageInput),
                  }}
                  inputRef={inputRef}
                  value={messageContent}
                  onChange={onChangeMessageContent}
                  disableUnderline
                  multiline
                  maxRows={6}
                />
                <Box className={classes.editActionBox}>
                  <IconButton onClick={onClickEmoji}>
                    <InsertEmoticon />
                  </IconButton>
                  <Box>
                    <IconButton onClick={onCancelEdit}>
                      <Clear />
                    </IconButton>
                    <IconButton color="primary" onClick={onSubmit}>
                      <Done />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            ) : (
              <>
                {isExternalLink(data?.content) ? (
                  <LinkPreview message={data} />
                ) : (
                  <Typography className={classes.messengerFlex} dangerouslySetInnerHTML={{ __html: messageContent }} />
                )}
              </>
            )}
          </Box>
        </Box>
      ) : (
        <Box className={classes.wrapPeople}>
          {isAvatar && (
            <Avatar className={classes.imageTitle} alt="img-chat" src={data.avatar}>
              {data?.senderName?.charAt(0)}
            </Avatar>
          )}
          <Box className={classes.avatarBox}>
            {isAvatar && data?.senderName && (
              <Typography className={classes.senderName}>
                {data?.senderName}, {data.sentTime}
              </Typography>
            )}
            {isAvatar ? (
              <Box className={classes.textMessenger}>
                {isExternalLink(data?.content) ? (
                  <LinkPreview message={data} />
                ) : (
                  <Typography
                    className={clsx(classes.messengerFlex, classes.receivedMessage)}
                    dangerouslySetInnerHTML={{ __html: data?.content }}
                  />
                )}
              </Box>
            ) : (
              <Box className={classes.flexAvatar}>
                <Avatar className={classes.isAvatar}></Avatar>
                <Box className={classes.textMessenger}>
                  {isExternalLink(messageContent) ? (
                    <LinkPreview
                      message={{
                        ...data,
                        content: messageContent,
                      }}
                    />
                  ) : (
                    <Typography
                      className={clsx(classes.messengerFlex, classes.receivedMessage)}
                      dangerouslySetInnerHTML={{ __html: messageContent }}
                    />
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default memo(MessengerPeople, (prev, next) => {
  if (prev.content !== next.content) {
    return prev.content === next.content;
  }
});

const useStyles = makeStyles(theme => ({
  wrapPeople: {
    display: "flex",
    height: "100%",
  },

  textMessenger: {
    backgroundColor: "#F1F3F6",
    borderRadius: 20,
    height: "fit-content",
    width: "fit-content",
  },

  messenger: {
    padding: "13px 16px",
  },

  imageTitle: {
    cursor: "pointer",
  },

  wrapPeopleFlex: {
    display: "flex",
    height: "100%",
    minHeight: 42,
  },

  messageContentItem: {
    borderRadius: 20,
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    height: "100%",
    overflow: "hidden",
  },

  imageMessengerFlex: {
    backgroundColor: "#008FE8",
  },

  editingMessage: {
    backgroundColor: "#F3F3F5",
  },

  messengerFlex: {
    padding: "10px 24px",
    color: theme.palette.white,
    lineBreak: "auto",
    whiteSpace: "break-spaces",
    wordBreak: "break-word",
    width: "fit-content",
  },

  receivedMessage: {
    color: theme.palette.black,
    wordBreak: "break-word",
    width: "fit-content",
  },

  editMessageInput: {
    WebkitLineClamp: 5,
    width: 640,
    padding: "16px 30px",
    marginLeft: 30,
  },

  editMessageInputEditing: {
    paddingBottom: 0,
    width: 520,
  },

  editActionBox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 16px",
    paddingTop: 0,
  },

  flexAvatar: {
    display: "flex",
  },

  isAvatar: {
    backgroundColor: "transparent",
  },

  editMessageRoot: {
    padding: 0,
  },

  previewLink: {
    backgroundColor: theme.palette.white,
    boxShadow: "0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05)",
  },

  avatarBox: {
    paddingLeft: 16,
  },

  senderName: {
    paddingLeft: 5,
    fontSize: 12,
    fontWeight: 550,
    marginBottom: 5,
  },
}));
