import { AppConstant, SystemConstant } from "const";
import { toSnake } from "utils";

export const ARR_CALLING_TYPES = SystemConstant.ARR_CALLING_TYPES;
export const SYNCH_NOW_MSEC = 100;
export const SYNCH_NORMAL_MSEC = 1000;

export const ARR_NOTICE_NORMAL = [
  AppConstant.MESSAGE_SEND_TYPE.message,
  AppConstant.MESSAGE_SEND_TYPE.groupMessage,
  AppConstant.MESSAGE_SEND_TYPE.link,
];

export const ARR_NOTICE_SPECIAL = [
  AppConstant.MESSAGE_SEND_TYPE.image,
  AppConstant.MESSAGE_SEND_TYPE.file,
  AppConstant.MESSAGE_SEND_TYPE.audio,
  AppConstant.MESSAGE_SEND_TYPE.video,
  AppConstant.MESSAGE_SEND_TYPE.reaction,
];

export const MSG_SEND_TYPE = [
  SystemConstant.SEND_TYPE.message,
  SystemConstant.SEND_TYPE.groupMessage,
  SystemConstant.SEND_TYPE.image,
  SystemConstant.SEND_TYPE.file,
  SystemConstant.SEND_TYPE.audio,
  SystemConstant.SEND_TYPE.video,
  SystemConstant.SEND_TYPE.link,
  SystemConstant.SEND_TYPE.reaction,
  SystemConstant.SEND_TYPE.deleteMessage,
  SystemConstant.SEND_TYPE.deleteReaction,
  SystemConstant.SEND_TYPE.leaveGroup,
];

export const isFileMessage = sendType =>
  [
    SystemConstant.SEND_TYPE.file,
    SystemConstant.SEND_TYPE.audio,
    SystemConstant.SEND_TYPE.image,
    SystemConstant.SEND_TYPE.video,
  ].includes(sendType);

export const formatPagingParams = newParamsObj => {
  let params = {
    paging: 1,
    orderBy: "",
    asc: 1,
    offset: 0,
    //TODO: handle paging for requests with over 100 result
    limit: 10000,
    filter: "",
    groupId: null,
    sinceTime: 0,
  };
  params = {
    ...params,
    ...newParamsObj,
  };

  Object.entries(params).forEach(result => {
    if (result[1] === "" || result[1] === null || result[1] === undefined) {
      delete params[result[0]];
    }
  });

  return toSnake(params);
};

export const formatArray2Key = initialObject => {
  let result = {};
  if (!initialObject) return result;

  Object.entries(initialObject).forEach(pair => {
    if (Array.isArray(pair[1])) {
      pair[1].forEach((item, index) => {
        result[`${pair[0]}[${index}] `] = item;
      });
      delete result[pair[0]];
    }
  });

  return result;
};
