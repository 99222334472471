import React, { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import Account from "../Account";
import SelectServer from "./SelectServer";
import ViewModeTabs from "./ViewModeTabs";
import { LocalInitDataService } from "services/local.service";
import { ConversationActions } from "redux-store";
const Sidebar = () => {
  const dispatch = useDispatch();
  const isRefreshing = useSelector(state => state.conversationRedux.isRefreshing);
  const isFetchMessageSuccess = useSelector(state => state.conversationRedux.isFetchMessageSuccess);
  const isUpdateNotificationSuccess = useSelector(state => state.conversationRedux.isUpdateNotificationSuccess);

  const [, forceRerender] = useReducer(x => x + 1, 0);

  LocalInitDataService.initData().then((r, e) => {});

  useEffect(() => {
    if (isRefreshing) {
      forceRerender();
    }
  }, [isRefreshing]);

  useEffect(() => {
    if (isUpdateNotificationSuccess) {
      forceRerender();
      dispatch(
        ConversationActions.conversationSet({
          isUpdateNotificationSuccess: false,
        }),
      );
    }
  }, [isUpdateNotificationSuccess]);

  useEffect(() => {
    if (isFetchMessageSuccess) {
      dispatch(
        ConversationActions.conversationSet({
          isFetchMessageSuccess: false,
        }),
      );
    }
  }, [isFetchMessageSuccess]);

  return (
    <>
      <Account />
      <SelectServer />
      <ViewModeTabs />
    </>
  );
};

export default Sidebar;
