import React, { useState, useCallback, memo } from "react";
import PropTypes from "prop-types";
import { LangConstant } from "const";
import AccountItemProfile from "./AccountItemProfile";
import { useTranslation } from "react-i18next";
import AccountCoverEdit from "./AccountCoverEdit";
import { Box, Button, Divider, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { formatPhoneNumber } from "utils";
import { makeStyles } from "@mui/styles";
import { DrawerLayout, EditProfile } from "components";

const YourProfile = ({ data }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const profileObjContent = getLabel(LangConstant.OBJ_ACCOUNT_PROFILE, { returnObjects: true });
  const accountObjContent = getLabel(LangConstant.OBJ_ACCOUNT_ME, { returnObjects: true });

  const [isShow, setIsShow] = useState(false);

  const onCreateControlFuc = useCallback(open => () => setIsShow(open), []);

  let genderObject = {
    0: profileObjContent.undefinedGender,
    1: profileObjContent.male,
    2: profileObjContent.female,
    3: profileObjContent.other,
  };

  return (
    <>
      <AccountItemProfile text={accountObjContent.profile} onClick={onCreateControlFuc(true)} />
      <DrawerLayout open={isShow} onClose={onCreateControlFuc(false)} title={profileObjContent.title}>
        {data && <AccountCoverEdit data={data} />}
        <ProfileItem label={profileObjContent.name} text={data.name} hasDivider={false} />
        <ProfileItem label={profileObjContent.phone} text={formatPhoneNumber(data.phone)} />
        <ProfileItem label={profileObjContent.email} text={data?.mail} />
        <ProfileItem label={profileObjContent.birthday} text={data?.birthday} />
        <ProfileItem label={profileObjContent.address} text={data?.address} />
        <ProfileItem label={profileObjContent.gender} text={data?.gender ? genderObject[data?.gender] : ""} />
        <ProfileItem label={""} text={""} />

        <ButtonEditProfile data={data} />
      </DrawerLayout>
    </>
  );
};

YourProfile.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    birthday: PropTypes.string,
  }),
};

YourProfile.defaultProps = {
  data: {
    name: "",
    phone: "",
    email: "",
    birthday: "",
  },
};

export default memo(YourProfile);

const ProfileItem = ({ label, text, hasDivider }) => {
  const classes = useStyles();

  return (
    <>
      {hasDivider && <Divider sx={{ color: "#F5F5F5" }} />}
      <Box p={2}>
        <Typography className={classes.label}>{label}</Typography>
        <Typography className={classes.text}>{text}</Typography>
      </Box>
    </>
  );
};
ProfileItem.defaultProps = {
  hasDivider: true,
};

const ButtonEditProfile = ({ data }) => {
  const classes = useStyles();
  const [isShowEditProfile, setIsShowEditProfile] = useState(false);
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const profileObjContent = getLabel(LangConstant.OBJ_ACCOUNT_PROFILE, { returnObjects: true });

  const clickEditProfile = () => {
    setIsShowEditProfile(!isShowEditProfile);
  };

  return (
    <>
      <Box className={classes.boxEditProfile}>
        <Button className={classes.buttonEditProfile} onClick={clickEditProfile}>
          {profileObjContent.editProFile}
        </Button>
      </Box>
      <DrawerLayout
        open={isShowEditProfile}
        onClose={() => setIsShowEditProfile(false)}
        icon={<CloseIcon />}
        title={profileObjContent.editYourProfile}
      >
        <EditProfile dataProfile={data} />
      </DrawerLayout>
    </>
  );
};

const useStyles = makeStyles({
  label: {
    fontSize: 15,
    fontWeight: 400,
    color: "#1E272E",
  },
  text: {
    fontSize: 14,
    fontWeight: 400,
    color: "#424242",
    marginTop: 8,
  },
  buttonEditProfile: {
    backgroundColor: "#008FE8",
    width: "100%",
    height: "48px",
    margin: "auto",
    color: "white",
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    "&:hover": {
      backgroundColor: "#008FE8",
    },
  },
  boxEditProfile: {
    padding: "0 14px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
});
