import React, { useState, useCallback, useEffect } from "react";
import { IconButton, Box, Divider, ListItemButton, Typography, ListItem, ListItemText, Badge } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { formatLimitNumber, handlingLogout, isObjectNotEqual, toCamel } from "utils";
import { AppConstant, KeyConstant, LangConstant, PathConstant, SystemConstant } from "const";
import { AvatarInfo, ConfirmDialog, DrawerLayout } from "components";
import AccountCoverView from "./AccountCoverView";
import YourProfile from "./YourProfile";
import YourDevice from "./DeviceManagement/YourDevice";
import AccountItemProfile from "./AccountItemProfile";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import NotificationTab from "../Sidebar/ViewModeTabs/NotificationTab";
import { LocalAccountService, LocalAppNotificationService, LocalNotificationService } from "services/local.service";
import { BranchSelectors, DeviceActions, ProfileActions, RestoreActions } from "redux-store";
import { getCommonKey } from "utils/lang.utils";
import { useDeviceList, useUnreadMessage } from "hooks";
import BackUpDrawer from "./BackupRestore/BackUpDrawer";
import RestoreDrawer from "./BackupRestore/RestoreDrawer";
import ChooseDeviceDrawer from "./DeviceManagement/ChooseDeviceDrawer";
import { StorageUtil } from "utils";

const Account = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMaster, slaveDeviceList } = useDeviceList();
  const { allUnreadNumber } = useUnreadMessage();

  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);

  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const accountObjContent = getLabel(LangConstant.OBJ_ACCOUNT_ME, { returnObjects: true });
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const changingAvatarId = useSelector(state => state.profileRedux.changingAvatarId);
  const changingBackgroundId = useSelector(state => state.profileRedux.changingBackgroundId);
  const isUpdateUserInfoSuccess = useSelector(state => state.profileRedux.isUpdateUserInfoSuccess);
  const isFetchMessageSuccess = useSelector(state => state.conversationRedux.isFetchMessageSuccess);
  const isUpdateViewMode = useSelector(state => state.conversationRedux.isUpdateViewMode);
  const newBranchInvitation = useSelector(state => state.conversationRedux.newBranchInvitation);
  const selectedBranch = useSelector(BranchSelectors.getSelectedBranch);
  const loggedOutDevice = useSelector(state => state.deviceRedux.loggedOutDevice);

  const [isShow, setIsShow] = useState(false);
  const [accountDetail, setAccountDetail] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [avatar, setAvatar] = useState("");
  const [isOpenNoti, setIsOpenNoti] = useState(false);
  const [messageCount, setMessageCount] = useState(0);
  const [isOpenLogout, setIsOpenLogout] = useState(false);
  const [isOpenChooseDevice, setIsOpenChooseDevice] = useState(false);
  const [isShowRestore, setIsShowRestore] = useState(false);

  const onLogout = useCallback(e => {
    setIsOpenLogout(true);
  }, []);

  const onSetAccountStatus = useCallback(() => {
    dispatch(
      ProfileActions.updateProfileStatus({
        status: isActive ? 0 : 1,
      }),
    );
    setIsActive(preIsActive => !preIsActive);
  });

  const accountStatusContent = isActive ? accountObjContent.setAway : accountObjContent.setActive;

  const onOpenNotiTab = () => {
    setIsOpenNoti(true);
  };

  const handleChooseDevice = () => {
    setIsOpenLogout(false);
    setIsOpenChooseDevice(true);
  };

  const handleCancelLogout = () => {
    setIsOpenLogout(false);
  };

  const handleLogoutSlave = () => {
    dispatch(
      DeviceActions.deviceLogout({
        slaveDeviceId: [deviceId],
      }),
    );
  };

  useEffect(() => {
    if (loggedOutDevice && Array.isArray(loggedOutDevice) && loggedOutDevice.includes(deviceId)) {
      handlingLogout();
    }
  }, [loggedOutDevice]);

  useEffect(() => {
    let accountInterval = null;

    accountInterval = setInterval(() => {
      let tmpAccDetail = { ...accountDetail };
      let res = LocalAccountService.getAccount(accountId);
      if (res && isObjectNotEqual(toCamel(res), tmpAccDetail)) {
        setAccountDetail(toCamel(res));
        setIsActive(Boolean(res.status));
      }
    }, 2000);

    return () => {
      clearInterval(accountInterval);
    };
  }, [accountDetail]);

  useEffect(() => {
    if (accountDetail) {
      if (accountDetail.name === "" || (accountDetail.name === null && !isUpdateUserInfoSuccess)) {
        onGotoCreateName();
      }
    }

    if (accountDetail && accountDetail.avatarId) {
      setAvatar(accountDetail?.avatarId);
    } else {
      setAvatar("");
    }
  }, [accountDetail]);

  useEffect(() => {
    LocalAccountService.updateImage(accountDetail?.id, changingAvatarId, true).then(() => {
      setAccountDetail(currentState => ({ ...currentState, avatarId: changingAvatarId }));
    });
  }, [changingAvatarId]);

  useEffect(() => {
    LocalAccountService.updateImage(accountDetail?.id, changingBackgroundId, true).then(() => {
      setAccountDetail(currentState => ({ ...currentState, backgroundId: changingBackgroundId }));
    });
  }, [changingBackgroundId]);

  useEffect(() => {
    setAccountDetail(currentState => ({ ...currentState, avatarId: changingAvatarId }));
  }, [changingAvatarId]);

  useEffect(() => {
    setAccountDetail(currentState => ({ ...currentState, backgroundId: changingBackgroundId }));
  }, [changingBackgroundId]);

  useEffect(() => {
    if (!selectedBranch) return; // Skip if server is not selected

    let tmpCount = 0;
    LocalNotificationService.getNormalNotification().then(notification => {
      tmpCount += notification.filter(
        notificationItem =>
          notificationItem.branch_id === selectedBranch.id &&
          (notificationItem.status === SystemConstant.NOTIFICATION_STATUS.unreceived ||
            notificationItem.status === SystemConstant.NOTIFICATION_STATUS.received),
      ).length;
      setMessageCount(tmpCount);
      LocalAppNotificationService.setBadgeCount(tmpCount + allUnreadNumber);
    });
  }, [selectedBranch, isFetchMessageSuccess, isUpdateViewMode, newBranchInvitation, allUnreadNumber]);

  const onGotoCreateName = () => {
    history.replace(PathConstant.CREATE_NAME);
  };

  const hasSlave = slaveDeviceList.length > 0;

  const handleRestore = () => {
    dispatch(RestoreActions.restore({}));
    setIsShowRestore(true);
  };

  return (
    <>
      <Box className={classes.avatarBox}>
        {accountDetail?.id && (
          <AvatarInfo
            avatar={{
              name: accountDetail.name,
              src: avatar,
            }}
            className={classes.accountButton}
            title={accountDetail.name}
            size={AppConstant.SIZE_OBJ.large}
            onClick={() => {
              setIsShow(true);
            }}
          />
        )}
        <Box className={classes.notiBox}>
          <Badge
            badgeContent={formatLimitNumber(messageCount)}
            color="primary"
            classes={{ root: classes.numNotify, badge: "badge" }}
          >
            <IconButton onClick={onOpenNotiTab}>
              <NotificationsOutlinedIcon />
            </IconButton>
          </Badge>
        </Box>
      </Box>
      <DrawerLayout open={isShow} onClose={() => setIsShow(false)} title={accountObjContent.title}>
        <AccountCoverView status={isActive} accountDetail={accountDetail} />
        <AccountItemProfile text={accountStatusContent} onClick={onSetAccountStatus} />
        <YourProfile data={accountDetail} />
        <YourDevice />
        <Box className={!isMaster && "hidden"}>
          <BackUpDrawer />
        </Box>
        <Divider className={classes.divider} />
        <ListItem disablePadding>
          <ListItemButton onClick={handleRestore}>
            <ListItemText
              primary={
                <Typography className={classes.logoutText}>
                  {getLabel(getCommonKey(LangConstant.TXT_RESTORE))}
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
        <Divider className={classes.divider} />
        <ListItem disablePadding>
          <ListItemButton onClick={onLogout}>
            <ListItemText
              primary={<Typography className={classes.logoutText}>{accountObjContent.logout}</Typography>}
            />
          </ListItemButton>
        </ListItem>
        <Divider className={classes.divider} />
      </DrawerLayout>

      {isShowRestore && <RestoreDrawer open onClose={() => setIsShowRestore(false)} />}

      {isOpenNoti && (
        <NotificationTab open={isOpenNoti} onClose={() => setIsOpenNoti(false)} onBackToMessage={() => {}} />
      )}
      {isOpenLogout && (
        <ConfirmDialog
          open
          onClose={handleCancelLogout}
          title={
            isMaster && hasSlave
              ? getLabel(getCommonKey(LangConstant.TXT_CONFIRM_LOGOUT_MASTER))
              : accountObjContent.logout
          }
          content={getLabel(
            getCommonKey(
              isMaster
                ? hasSlave
                  ? LangConstant.TXT_CONTENT_LOGOUT_MASTER
                  : LangConstant.TXT_LOGOUT_MASTER_WITHOUT_SLAVE
                : LangConstant.TXT_CONTENT_LOGOUT_SLAVE,
            ),
          )}
          submitProps={
            isMaster && hasSlave
              ? {
                  submitText: getLabel(getCommonKey(LangConstant.TXT_CHOOSE_DEVICE)),
                  onClick: handleChooseDevice,
                }
              : {
                  submitText: accountObjContent.logout,
                  onClick: handleLogoutSlave,
                }
          }
          cancelProps={{ onClick: handleCancelLogout }}
        />
      )}
      {isOpenChooseDevice && <ChooseDeviceDrawer open onClose={() => setIsOpenChooseDevice(false)} />}
    </>
  );
};

Account.propTypes = {};

export default Account;

const useStyles = makeStyles({
  accountButton: {
    cursor: "pointer",
  },

  avatarBox: {
    display: "flex",
    paddingRight: 16,
    alignItems: "center",
  },

  logoutButton: {
    width: 40,
    height: 40,
  },
  divider: {
    color: "#F5F5F5",
    border: "0.5px solid #F5F5F5",
  },
  notiBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoutText: {
    fontSize: 15,
    fontWeight: 400,
    color: "#1E272E",
  },
  numNotify: {
    "& .badge": {
      top: 4,
      right: -1,
      backgroundColor: "#EE4F49",
      border: "1px solid white",
    },
  },
});
