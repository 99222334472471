import React, { memo, useEffect, useState, useRef } from "react";
import LazyLoad from "react-lazyload";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const LazyLoader = props => {
  const { children, ...otherProps } = props;

  return (
    <LazyLoad
      offset={window.innerHeight}
      overflow={true}
      placeholder={<Box height={100} />}
      {...otherProps}
    >
      {children}
    </LazyLoad>
  )
};

LazyLoader.propTypes = {}

export default memo(LazyLoader);