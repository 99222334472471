import { call, put } from "redux-saga/effects";
import { ApiConstant, KeyConstant } from "const";
import { AccountService, ContactService } from "services";
import { LocalContactService } from "services/local.service";
import { toCamel, toSnake } from "utils";
import { ContactActions } from "redux-store";
import { StorageUtil } from "utils";

export function* findContact(action) {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  try {
    const { data } = action;
    const params = {
      paging: 0,
      asc: 1,
      offset: 0,
      limit: 1,
      sinceTime: 0,
      isCorrect: 1,
      ...data,
    };

    const response = yield call(AccountService.getAccountList, toSnake(params));
    if (response.status === ApiConstant.STT_OK) {
      const findingAccount = Array.isArray(response.data?.data) ? response.data.data[0] : null;

      if (findingAccount && findingAccount.id) {
        const dataContact = yield LocalContactService.getContact(accountId, findingAccount.id);
        if (dataContact) {
          yield put(
            ContactActions.contactSuccess({
              existsInContactTable: toCamel({
                ...dataContact,
                phone: findingAccount.phone,
                avatar_id: findingAccount.avatar_id,
              }),
              idContactPhoneBook: dataContact.id,
            }),
          );
        } else {
          yield put(
            ContactActions.contactSuccess({
              notInContactTable: toCamel(findingAccount),
            }),
          );
        }
      } else {
        yield put(
          ContactActions.contactSuccess({
            noSearchResults: true,
          }),
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* updateContact(action) {
  try {
    const { data } = action;
    const response = yield call(ContactService.updateContact, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield LocalContactService.updateNameContact(toCamel(response.data));
      yield put(
        ContactActions.contactSuccess({
          nameAfterChange: toCamel(response.data).contactName,
          idContactPhoneBook: response.data.id,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* addNewContact(action) {
  try {
    const { data } = action;
    const response = yield call(ContactService.addContact, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield LocalContactService.addNewContactToPhoneBook(toCamel(response.data));

      yield put(
        ContactActions.contactSuccess({
          addNewContactResponse: toCamel(response.data),
          idContactPhoneBook: response.data.id,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* unfriendContact(action) {
  try {
    const { contactId } = action.data;
    const response = yield call(ContactService.deleteContact, contactId);
    if (response.status === ApiConstant.STT_OK) {
      yield LocalContactService.unfriendContact(toCamel(response.data));
      yield put(
        ContactActions.contactSuccess({
          unfriendContactId: toCamel(response.data).contactId,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}
