import { SystemConstant } from "const";
import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  changeDeviceRole: ["data"],
  verifyChangeDeviceRole: ["data"],
  restore: ["data"],
  restoreToLocal: ["data"],
  backup: ["data"],

  restoreSet: ["data"],
});

export const RestoreTypes = Types;
export const RestoreActions = Creators;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  verifyStatus: SystemConstant.VERIFY_OTP_ERROR_TYPE.noError,
  restoreStatus: SystemConstant.RESTORE_STATUS.notStart,
  backupStatus: SystemConstant.RESTORE_STATUS.notStart,
  changeRoleStatus: SystemConstant.REDUX_LIFECYCLE.notStart,
  fileSize: 0,
  backupInfo: null, // information about backup file: attachmentId, ckx, createdTime
};

/* ------------- Selector ------------- */
export const RestoreSelectors = {};

/* ------------- Reducers ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  changeRoleStatus: SystemConstant.REDUX_LIFECYCLE.notStart,
  verifyStatus: SystemConstant.VERIFY_OTP_ERROR_TYPE.noError,
  backupStatus: SystemConstant.RESTORE_STATUS.notStart,
  restoreStatus: SystemConstant.RESTORE_STATUS.notStart,
});

export const set = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.data,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.CHANGE_DEVICE_ROLE]: request,
  [Types.VERIFY_CHANGE_DEVICE_ROLE]: request,
  [Types.RESTORE]: request,
  [Types.RESTORE_TO_LOCAL]: request,
  [Types.BACKUP]: request,
  [Types.RESTORE_SET]: set,
};

/* ------------- Hookup Reducers To Types ------------- */
export const RestoreReducer = createReducer(INITIAL_STATE, HANDLERS);
