import React, { memo } from "react";
import { makeStyles } from "@mui/styles";
import { Avatar, Box, CardMedia, Typography } from "@mui/material";
import clsx from "clsx";

const FileDownLoad = ({ data }) => {
  const classes = useStyles();
  //TODO refactor correct information
  return data.isMine ? (
    <Box className={classes.wrapTextFile}>
      <Typography className={clsx(classes.messengerTimer, "medium-sm-txt")}>{data.timer}</Typography>
      <Box className={classes.backgroundPreview}>
        <Box className={classes.flexPreview}>
          <Box className={classes.imageDownloadPre}>
            <CardMedia component="img" className={classes.imageDownloadSubPre} src={data.imgDownLoadSub} />
          </Box>
          <Box className={classes.wrapText}>
            <Typography variant="subtitle2" className={classes.wrapTextSub}>
              {data.text}
            </Typography>
            <Typography variant="caption" className={classes.wrapTextSubP}>
              {data.textSub}
            </Typography>
          </Box>
          <CardMedia className={classes.imageChatItem} component="img" src={data.imgDownload} />
        </Box>
      </Box>
    </Box>
  ) : (
    <Box className={classes.wrapTextFileFlex}>
      <Avatar className={classes.imageTitle} alt="img-chat" src={data.avatar}>
        {data?.senderName?.charAt(0)}
      </Avatar>
      <Box className={classes.avatarBox}>
        <Typography className={classes.senderName}>
          {data?.senderName}, {data.sentTime}
        </Typography>
        <Box className={classes.backgroundPreviewFlex}>
          <Box className={classes.flexPreview}>
            <Box className={classes.imageDownloadPreFlex}>
              <CardMedia component="img" className={classes.imageDownloadSubPre} src={data.imgRed} />
            </Box>
            <Box className={classes.wrapText}>
              <Typography variant="subtitle2">{data.text}</Typography>
              <Typography className={classes.wrapTextFlex} variant="caption">
                {data.textSub}
              </Typography>
            </Box>
            <CardMedia className={classes.imageChatItem} component="img" src={data.imgDownLoadSubs} />
          </Box>
        </Box>
      </Box>
      <Typography className={clsx(classes.messengerTimer, "medium-sm-txt")}>{data.timer}</Typography>
    </Box>
  );
};

export default memo(FileDownLoad);

const useStyles = makeStyles(theme => ({
  backgroundPreview: {
    backgroundColor: theme.palette.primary.main,
    height: 65,
    padding: "0 24px",
    borderRadius: 20,
    width: "19%",
  },
  wrapTextFile: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "end",
    padding: "16px 24px",
  },
  wrapTextFlexRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  wrapTextFlex: {
    marginLeft: 4,
  },
  flexPreview: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: "7px 0",
  },
  imageDownloadPre: {
    width: 48,
    height: 48,
    borderRadius: "50%",
    backgroundColor: "#F1F3F6",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imageDownloadPreFlex: {
    backgroundColor: theme.palette.white,
    width: 48,
    height: 48,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imageDownloadSubPre: {
    padding: 11,
  },
  messengerTimer: {
    color: "#666666",
    marginRight: 8,
  },
  wrapText: {
    flex: 1,
    marginLeft: 8,
    display: "flex",
    flexDirection: "column",
  },
  wrapTextSub: {
    color: theme.palette.white,
  },
  wrapTextSubP: {
    color: theme.palette.white,
    marginLeft: 4,
  },
  imageChatItem: {
    width: 14,
  },
  wrapTextFileFlex: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "end",
    padding: "16px 24px",
  },
  imageTitle: {
    cursor: "pointer",
  },
  backgroundPreviewFlex: {
    backgroundColor: "#F1F3F6",
    padding: "0 24px",
    borderRadius: 20,
    width: "19%",
    margin: "0 8px 0 14px",
  },
}));
