import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Avatar, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import clsx from "clsx";
import { getImageSrcAvatar } from "../utils";
import { SystemConstant } from "../const";

const AvatarBadge = ({ avatar, onClick }) => {
  const classes = useStyles();
  const avatarName = avatar?.contactName || "";
  const [src, setSrc] = useState("");
  useEffect(()=>{
    if (avatar.src){
      getImageSrcAvatar(avatar.src, SystemConstant.ATTACHMENT_TYPE.account).then(res=>{
        setSrc(res)
      })
    }
  },[avatar])


  return (
    <Box className={classes.root}>
      <Box className={classes.avatarRoot}>
        <Avatar alt={avatarName} src={src} className={classes.avatarRoot}>
          {avatarName.charAt(0)}
        </Avatar>
        <Box onClick={onClick} className={classes.btnClosePosition}>
          <IconButton className={classes.btnClose}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <Typography className={clsx("ellipsis", classes.avatarName)}>{avatarName}</Typography>
    </Box>
  );
};

AvatarBadge.propTypes = {
  avatar: PropTypes.shape({
    name: PropTypes.string,
    src: PropTypes.string,
  }),

  onClick: PropTypes.func,
};

export default memo(AvatarBadge);

const useStyles = makeStyles(theme => ({
  root: {
    width: 90,
    padding: 4,
  },
  avatarRoot: {
    position: "relative",
    width: 48,
    height: 48,
    margin: "auto",
  },
  btnClosePosition: {
    position: "absolute",
    top: -12,
    right: -8,
    padding: 8,
  },
  avatarName: {
    fontSize: 14,
    textAlign: "center",
  },
  btnClose: {
    width: 16,
    height: 16,
    background: "#424242",
    borderRadius: "50%",
    color: "white",

    "&:hover": {
      background: theme.palette.grey[600],
    },

    "& svg": {
      fontSize: 12,
    },
  },
}));
