import { SYNCH_NOW_MSEC } from "sagas/saga.helper";

export const LocalAppNotificationService = window.TriosNotification;

// Connect to DB
export const LocalAttachmentService = window.repository.attachment;
export const LocalAccountService = window.repository.account;
export const LocalAccountKeyService = window.repository.accountKey;
export const LocalGroupService = window.repository.group;
export const LocalGroupSettingService = window.repository.groupSetting;
export const LocalSettingService = window.repository.setting;
export const LocalBranchAccountService = window.repository.branchAccount;
export const LocalBranchService = window.repository.branch;
export const LocalContactService = window.repository.contact;
export const LocalMessageService = window.repository.message;
export const LocalDeviceService = window.repository.device;
export const LocalAccountGroupService = window.repository.accountGroup;
export const LocalInitDataService = window.repository.initData;
export const LocalKeyActionService = window.repository.keyAction;
export const LocalNotificationService = window.repository.notification;
export const LocalSenderKeySharedService = window.repository.senderKeyShared;
export const LocalEmojiService = window.repository.emoji;
export const LocalCallHistoryService = window.repository.callHistory;
export const LocalMsgErrorResendService = window.repository.messageErrorReSend;
export const LocalMsgErrorSendNullService = window.repository.messageErrorSendNull;
export const LocalCipherService = window.repository.cipher;
export const LocalThreadService = window.repository.thread;

export const LocalApiCallService = {
  ...window.repository.apiCall,

  // Overwrite function
  save: arrayData => {
    window.repository.apiCall.save(arrayData);
    window.delaySyncMsec = SYNCH_NOW_MSEC;
  },
};
