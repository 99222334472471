import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { Avatar, Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Call, CallEnd, Close, HorizontalRule } from "@mui/icons-material";
import ConversationAction from "redux-store/conversation.redux";
import CallingAction from "redux-store/calling.redux";
import { AppConstant, KeyConstant, LangConstant, SoundConstant, SystemConstant } from "const";
import { convertString2JSON, getImageSrcAvatar, isJSONString, toCamel } from "utils";
import { getNSKey } from "utils/lang.utils";
import { isGroupOrChannelType } from "pages/Call";
import { useCallInfo, useContactList } from "hooks";
import { StorageUtil } from "utils";

const repository = window.repository;

const CallingNotification = ({ isOpenSearchPopup }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_CALLING);
  let accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  let timeout = null;

  const { callInfo } = useCallInfo();
  const { contactList } = useContactList();

  const isCalleeInAnotherCall = useSelector(state => state.callingRedux.isCalleeInAnotherCall);
  const isPingToCallee = useSelector(state => state.callingRedux.isPingToCallee);
  const isCallEnded = useSelector(state => state.callingRedux.isCallEnded);
  const isCallMissed = useSelector(state => state.callingRedux.isCallMissed);
  const isOpenNotificationDialog = useSelector(state => state.callingRedux.isOpenNotificationDialog);
  const isHideNotificationDialog = useSelector(state => state.callingRedux.isHideNotificationDialog);
  const selectedId = useSelector(state => state.conversationRedux.selectedGroupId);
  const callingGroupDetail = useSelector(state => state.callingRedux.callingGroupDetail);
  const message = useSelector(state => state.callingRedux.message);
  const isVideoCall = useSelector(state => state.callingRedux.isVideoCall);
  const isConnectSuccess = useSelector(state => state.callingRedux.isConnectSuccess);
  const isCallAccepted = useSelector(state => state.callingRedux.isCallAccepted);
  const isNotifyEnded = useSelector(state => state.callingRedux.isNotifyEnded);
  //TODO Refactor remove groupCallOptions
  const groupCallOptions = useSelector(state => state.callingRedux.groupCallOptions);

  const [deviceList, setDeviceList] = useState([]);
  const [isDisableButton, setIsDisableButton] = useState(true);
  const [missedTimeout, setMissedTimeout] = useState(null);
  const [data, setData] = useState({
    senderName: "",
    senderAvatarId: "",
    groupName: "",
  });
  const [avatarSrc, setAvatarSrc] = useState("");
  const [isNotWelcome, setIsNotWelcome] = useState(false);

  const onDeny = e => {
    dispatch(CallingAction.callingReset());

    clearTimeout(timeout);

    e?.stopPropagation();

    if (callingGroupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
      // TODO: Handle content and send message with call status "reject"
      onSendMessage(message?.content, message?.sourceId, null, SystemConstant.MESSAGE_CALL_STATUS.reject);
    }

    console.log("Deny calling");
    clearTimeout(missedTimeout);
  };

  const onAccept = e => {
    clearTimeout(timeout);

    e.stopPropagation();
    dispatch(CallingAction.getCallHistory());

    clearTimeout(missedTimeout);

    dispatch(
      CallingAction.callingSet({
        isOpenNotificationDialog: false,
        isOpenCallingDialog: AppConstant.CALLING_STATUS.checking,
        isReceiver: true,
        isReceiving: true,
      }),
    );

    dispatch(CallingAction.onCheckReceiversStatus());
  };

  const onHideDialog = () => {
    dispatch(CallingAction.callingSet({ isHideNotificationDialog: true }));
  };

  const onShowDialog = () => {
    dispatch(CallingAction.callingSet({ isHideNotificationDialog: false }));
  };

  const onSendMessage = (content = "", parentId = null, threadId = null, callStatus = null) => {
    let savedServer = convertString2JSON(StorageUtil.getItem(KeyConstant.KEY_SELECTED_SERVER));

    let sendType = isGroupOrChannelType(callingGroupDetail.groupType)
      ? SystemConstant.SEND_TYPE.conference
      : isVideoCall
      ? SystemConstant.SEND_TYPE.personalVideoCall
      : SystemConstant.SEND_TYPE.personalCall;

    let jsonContent = toCamel(convertString2JSON(content));

    dispatch(
      ConversationAction.sendMessage({
        groupDetail: callingGroupDetail,
        sendType: sendType,
        roomId: jsonContent.roomId,
        content: content,
        parentId: parentId,
        deviceList: deviceList,
        branchId: savedServer?.id,
        mentionIdsArr: [],
        threadId: threadId,
        callStatus: callStatus,
      }),
    );
  };

  useEffect(() => {
    if (data.senderAvatarId)
      getImageSrcAvatar(data.senderAvatarId, SystemConstant.ATTACHMENT_TYPE.account).then(src => {
        if (src !== avatarSrc) setAvatarSrc(src);
      });
  }, [data.senderAvatarId]);

  useEffect(() => {
    let isMounted = true;

    // Get needed data from group detail
    if (callingGroupDetail) {
      setIsDisableButton(false);

      if (isGroupOrChannelType(callingGroupDetail?.groupType)) {
        if (
          !groupCallOptions?.list_member_choose ||
          Object.keys(groupCallOptions.list_member_choose).length === 0 ||
          (groupCallOptions &&
            groupCallOptions.list_member_choose &&
            Array.isArray(groupCallOptions.list_member_choose) &&
            groupCallOptions.list_member_choose.includes(accountId))
        ) {
          setIsNotWelcome(false);
        } else {
          setIsNotWelcome(true);
        }

        const contact = contactList.find(item => item.contactAccountId === message?.senderId);
        // TODO: Duplicate logic
        if (contact) {
          repository.account.get(message?.senderId).then(account => {
            account = toCamel(account);
            if (isMounted) {
              setData(state => ({
                ...state,
                senderName: contact.name,
                senderAvatarId: account.avatarId || "",
              }));
            }
          });
        } else {
          repository.account.get(message?.senderId).then(account => {
            account = toCamel(account);
            if (isMounted) {
              setData(state => ({
                ...state,
                senderName: account.name,
                senderAvatarId: account.avatarId || "",
              }));
            }
          });
        }
      } else {
        setIsNotWelcome(false);
        if (isMounted) {
          const contact = contactList.find(item => item.contactAccountId === message?.senderId);
          // TODO: Duplicate logic
          if (contact) {
            repository.account.get(message?.senderId).then(account => {
              account = toCamel(account);
              if (isMounted) {
                setData(state => ({
                  ...state,
                  senderName: contact.name,
                  senderAvatarId: account.avatarId || "",
                }));
              }
            });
          } else {
            repository.account.get(message?.senderId).then(account => {
              account = toCamel(account);
              if (isMounted) {
                setData(state => ({
                  ...state,
                  senderName: account.name,
                  senderAvatarId: account.avatarId || "",
                }));
              }
            });
          }
        }
      }
      if (
        isGroupOrChannelType(callingGroupDetail?.groupType) &&
        ((isJSONString(callingGroupDetail.name) && JSON.parse(callingGroupDetail.name).name) || callingGroupDetail.name)
      ) {
        setData(state => ({
          ...state,
          groupName: isJSONString(callingGroupDetail.name)
            ? JSON.parse(callingGroupDetail.name).name
            : callingGroupDetail?.name || "",
        }));
      } else if (callingGroupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
        let other = callingGroupDetail.members.find(member => member.id !== accountId);
        setData(state => ({
          ...state,
          groupName: other ? (other.name ? other.name : other.phone) : getLabel(LangConstant.TXT_NO_NAME),
        }));
      } else {
        let groupNameArr = callingGroupDetail.members.map(item => item.name);
        setData(state => ({ ...state, groupName: groupNameArr.join(", ") }));
      }

      if (callingGroupDetail.members && callingGroupDetail.members.length > 0) {
        let memberIdArray = callingGroupDetail.members.map(member => member.id) || [];

        if (callingGroupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
          memberIdArray.push(accountId);
        }

        setDeviceList(repository.device.findByList("account_id", memberIdArray));
      }
    }

    return () => {
      isMounted = false;
    };
  }, [callingGroupDetail, groupCallOptions, contactList]);

  useEffect(() => {
    if ((isCallEnded === callingGroupDetail?.id || isCallMissed) && isOpenNotificationDialog) {
      console.log("Call end in call notification");
      clearTimeout(missedTimeout);
      setIsDisableButton(true);
      dispatch(CallingAction.callingReset());
    }
  }, [isCallEnded, isCallMissed]);

  useEffect(() => {
    if (
      isNotifyEnded &&
      callingGroupDetail &&
      isNotifyEnded.groupId === callingGroupDetail.id &&
      callingGroupDetail.groupType !== SystemConstant.GROUP_CHAT_TYPE.personal &&
      callingGroupDetail.roomId === isNotifyEnded.roomId
    ) {
      dispatch(CallingAction.callingReset());
    }
  }, [isNotifyEnded, callingGroupDetail]);

  useEffect(() => {
    // Ringing for about one minute, then if user doesn't pick up
    // Send message with call status "missed" and close the dialog automatically
    if (isOpenNotificationDialog && deviceList.length > 0 && !isCallAccepted && !isConnectSuccess) {
      timeout = setTimeout(() => {
        console.log("Out of ringing time");
        setIsDisableButton(true);
        // await onSendMessage(message?.content, message?.sourceId, null, SystemConstant.MESSAGE_CALL_STATUS.missed);
        dispatch(CallingAction.callingReset());
      }, AppConstant.CALL_WAITING_TIME + 15000);
      setMissedTimeout(timeout);
    } else {
      clearTimeout(missedTimeout);
    }

    return () => {
      clearTimeout(missedTimeout);
      clearTimeout(timeout);
    };
  }, [isOpenNotificationDialog, deviceList, isCallAccepted, isConnectSuccess]);

  return (
    !isNotWelcome && (
      <>
        <Dialog
          open={isHideNotificationDialog}
          hideBackdrop={true}
          disableEnforceFocus={true}
          classes={{
            root: clsx(classes.smallWrapperRoot, {
              [classes.smallWrapperRootInChat]: Boolean(selectedId),
              [classes.smallWrapperRootInSearch]: isOpenSearchPopup,
            }),
            paper: classes.smallWrapper,
          }}
        >
          <Box onClick={onShowDialog} className={classes.callDetailWrapper}>
            <Box className={classes.callDetail}>
              <Call />
              <Typography className={clsx(classes.title, classes.hiddenTitle)}>
                {callingGroupDetail && (
                  <>
                    {isGroupOrChannelType(callingGroupDetail?.groupType)
                      ? getLabel(
                          isVideoCall
                            ? LangConstant.FM_INCOMING_GROUP_VIDEO_CALL_TITLE
                            : LangConstant.FM_INCOMING_GROUP_CALL_TITLE,
                          {
                            senderName: data.senderName,
                            groupName: callInfo?.callName || data.groupName,
                          },
                        )
                      : getLabel(
                          isVideoCall ? LangConstant.FM_INCOMING_VIDEO_CALL_TITLE : LangConstant.FM_INCOMING_CALL_TITLE,
                          {
                            senderName: data.senderName,
                          },
                        )}
                  </>
                )}
              </Typography>
            </Box>
            <Box className={classes.buttonWrapper}>
              <IconButton className={clsx(classes.smallButton, classes.smallAcceptButton)} onClick={onAccept}>
                <Call />
              </IconButton>
              <IconButton className={clsx(classes.smallButton, classes.smallDenyButton)} onClick={onDeny}>
                <CallEnd />
              </IconButton>
            </Box>
          </Box>
        </Dialog>
        <Dialog
          open={isOpenNotificationDialog}
          classes={{
            root: isHideNotificationDialog ? "hidden" : "",
            paper: classes.wrapper,
          }}
        >
          <Box className={classes.smallButtonWrapper}>
            <IconButton className={classes.minimizeButton} onClick={onHideDialog}>
              <HorizontalRule />
            </IconButton>
            <IconButton className={classes.closeButton} onClick={onDeny}>
              <Close />
            </IconButton>
          </Box>
          <Typography className={clsx(classes.modalTitle, "bold-xl-txt")}>
            {getLabel(LangConstant.TXT_INCOMING_CALL)}
          </Typography>
          <Box className={classes.modalDetailWrapper}>
            <Avatar className={classes.avatar} src={avatarSrc}>
              {data?.senderName?.charAt(0)}
            </Avatar>
            <Box className={classes.textWrapper}>
              <Typography className={classes.title}>
                {callingGroupDetail && (
                  <>
                    {isGroupOrChannelType(callingGroupDetail?.groupType)
                      ? getLabel(
                          isVideoCall
                            ? LangConstant.FM_INCOMING_GROUP_VIDEO_CALL_TITLE
                            : LangConstant.FM_INCOMING_GROUP_CALL_TITLE,
                          {
                            senderName: data.senderName,
                            groupName: callInfo?.callName || data.groupName,
                          },
                        )
                      : getLabel(
                          isVideoCall ? LangConstant.FM_INCOMING_VIDEO_CALL_TITLE : LangConstant.FM_INCOMING_CALL_TITLE,
                          {
                            senderName: data.senderName,
                          },
                        )}
                  </>
                )}
              </Typography>
              <Typography className={clsx(classes.description, "regular-sm-txt")}>
                {getLabel(LangConstant.TXT_INCOMING_CALL_DESC)}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.buttonWrapper}>
            <Button className={classes.denyButton} onClick={onDeny} disabled={isDisableButton}>
              {getLabel(getNSKey(LangConstant.NS_COMMON, LangConstant.TXT_DENIED))}
            </Button>
            <Button className={classes.acceptButton} variant="contained" onClick={onAccept} disabled={isDisableButton}>
              {getLabel(getNSKey(LangConstant.NS_COMMON, LangConstant.TXT_CALL_ACCEPT))}
            </Button>
          </Box>
          {!isDisableButton && <audio className="hidden" src={SoundConstant.CallingRingtoneSound} autoPlay loop />}
        </Dialog>
      </>
    )
  );
};

export default CallingNotification;

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: 532,
    padding: "32px 24px",
    borderRadius: 20,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },

  modalTitle: {
    textAlign: "center",
    lineHeight: "27px",
    color: theme.palette.black,
    marginBottom: 8,
  },

  modalDetailWrapper: {
    marginBottom: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  avatar: {
    width: 50,
    height: 50,
  },

  textWrapper: {
    marginLeft: 12,
  },

  title: {
    marginBottom: 2,
    fontSize: 15,
    fontWeight: 700,
    lineHeight: "20px",
    color: theme.palette.black,
  },

  description: {
    lineHeight: "20px",
    color: theme.palette.grey[500],
  },

  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },

  acceptButton: {
    width: 188,
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
  },

  denyButton: {
    width: 188,
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    marginRight: 4,
  },

  smallWrapperRoot: {
    top: 32,
    right: 12,
    left: "unset",
    bottom: "unset",
    width: "100%",
    transition: "all .1s ease-in-out",
    maxWidth: `calc(100% - ${AppConstant.SIDEBAR_WIDTH.medium})`,
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.up(1500)]: {
      maxWidth: `calc(100% - ${AppConstant.SIDEBAR_WIDTH.large}px)`,
    },
  },

  smallWrapper: {
    width: "100%",
    maxWidth: "unset",
    borderRadius: 10,
    backgroundColor: theme.palette.grey[800],
    cursor: "pointer",
    zIndex: theme.zIndex.drawer - 1,
    marginLeft: 58,
    marginTop: 0,
    marginBottom: 0,
    boxShadow: "none",
  },

  callDetailWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 20px",
  },

  callDetail: {
    display: "flex",
    color: theme.palette.white,
  },

  smallButtonWrapper: {
    position: "absolute",
    top: 12,
    right: 24,
  },

  minimizeButton: {
    marginRight: 4,
    color: theme.palette.black,
  },

  closeButton: {
    color: theme.palette.black,
  },

  hiddenTitle: {
    color: theme.palette.white,
    marginLeft: 15,
  },

  smallButton: {
    color: theme.palette.white,
  },

  smallAcceptButton: {
    backgroundColor: "#30CC45",
    marginRight: 12,
    "&:hover": {
      backgroundColor: "#5AB767",
    },
  },

  smallDenyButton: {
    backgroundColor: "#FF2C28",
    "&:hover": {
      backgroundColor: "#D6605C",
    },
  },

  smallWrapperRootInChat: {
    top: 122,
  },

  smallWrapperRootInSearch: {
    top: 192,
  },
}));
