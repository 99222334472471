import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  postMessage: ["data"],
  deleteGroup: ["data"],
  updateGroup: ["data"],
  addAdmin: ["data"],
  createNewGroup: ["data"],
  uploadMessageFile: ["data"],
  updateMessageStatus: ["data"],
  conversationSet: ["data"],
  conversationSetLinkData: ["data"],
  conversationReset: [],
  sendMessage: ["data"],
  apiCallTask: [],
  fetchLastMessage: ["data"],
  changeGroupPhoto: ["data"],
  removeMemberGroup: ["data"],
  dataBackup: ["data"],
  requestUploadImageCall: ["data"],
  setSelectGroupId: ["data"],
  checkBlockedContact: ["data"], // data: {accountId: <Receiver's id>}
  markReadMessageInGroup: ["selectedGroupId"],
});

export const ConversationTypes = Types;
export const ConversationActions = Creators;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  isSynchronizing: false,
  isRefreshing: 0,
  isCreateGroupSuccess: false,
  isUpdateNotificationSuccess: false,
  isFetchMessageSuccess: false,
  isUpdateGroupSuccess: false,
  isAddAdminSuccess: "",
  dataUpdateGroupSuccess: "",
  isDeleteGroupSuccess: false,
  isSearchMode: false,
  isUpdateViewMode: 0,
  isSendMessageSuccess: false,
  threadingId: null,
  eventGroupUpdate: 1,
  updatedGroupId: null,
  deletedGroupId: null,
  uploadMessageAttachmentData: null,
  isUploadFileFailed: false,
  selectedGroupId: null,
  conversationSearchContent: null,
  conversationSearchResults: [],
  conversationSearchIndex: null,
  searchRedirect: null,
  changeGroupPhoto: "",
  error: null,
  removeMemberGroup: "",
  addMemberGroup: "",
  isFinishUpload: false,
  sendingMsg: null,
  sendingTimeStamp: 0,
  isFetchNotificationSuccess: false,
  newBranchInvitation: 0,
  idGroupCallAvatar: null,
  idAvatarEdit: null,
  linkData: {},
  isAddConference: false,

  scrollToChildId: null,
  blockedAccount: null,
};

/* ------------- Selector ------------- */
export const ConversationSelectors = {};

/* ------------- Reducers ------------- */
const request = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    ...data,
    isFetching: true,
  };
};

const setLinkData = (state = INITIAL_STATE, action) => {
  return { ...state, linkData: { ...state.linkData, [action.data.key]: action.data.value } };
};

const set = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    ...data,
  };
};

const reset = () => INITIAL_STATE;

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.POST_MESSAGE]: request,
  [Types.DELETE_GROUP]: request,
  [Types.UPDATE_GROUP]: request,
  [Types.CREATE_NEW_GROUP]: request,
  [Types.UPLOAD_MESSAGE_FILE]: request,
  [Types.UPDATE_MESSAGE_STATUS]: request,
  [Types.CHANGE_GROUP_PHOTO]: request,

  [Types.SEND_MESSAGE]: request,
  [Types.API_CALL_TASK]: request,
  [Types.FETCH_LAST_MESSAGE]: request,
  [Types.CONVERSATION_SET]: set,
  [Types.CONVERSATION_SET_LINK_DATA]: setLinkData,

  [Types.CONVERSATION_RESET]: reset,
  [Types.REMOVE_MEMBER_GROUP]: request,
  [Types.REQUEST_UPLOAD_IMAGE_CALL]: request,
  [Types.SET_SELECT_GROUP_ID]: set,
  [Types.CHECK_BLOCKED_CONTACT]: request,
  [Types.MARK_READ_MESSAGE_IN_GROUP]: request,
};

/* ------------- Hookup Reducers To Types ------------- */
export const ConversationReducer = createReducer(INITIAL_STATE, HANDLERS);
