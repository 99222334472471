import React, { memo } from "react";
import { makeStyles } from "@mui/styles";
import { Box, MenuItem } from "@mui/material";
import { AvatarInfo } from "components";
import { useServerMode } from "hooks";
import clsx from "clsx";

const ListPeople = ({ isShow, data, onClick, offsetWidth, tagListStyle }) => {
  const classes = useStyles({
    rootWidth: offsetWidth,
  });
  const { isBranchServer } = useServerMode(); // TODO: Need to using useConversation

  return (
    <Box className={isShow ? clsx(classes.popover, tagListStyle) : "hidden"}>
      <Box className={classes.memberListBox}>
        {data.map(item => (
          <MenuItem className={classes.itemPeople} key={item.id} onClick={() => onClick(item)}>
            <AvatarInfo
              secondTitle={item.phone}
              title={item.name}
              avatar={{ src: item.avatarId, name: item.name }}
              button={true}
            />
          </MenuItem>
        ))}
      </Box>
    </Box>
  );
};

export default memo(ListPeople);

const useStyles = makeStyles(theme => ({
  popover: {
    width: "auto",
    overflow: "hidden",
    margin: "0px 24px",
    boxShadow: "0px -3px 9px rgba(0, 0, 0, 0.15)",
    borderRadius: 0,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 8,
  },

  memberListBox: {
    backgroundColor: theme.palette.white,
    borderRadius: 4,
    overflow: "auto",
    maxHeight: 350,
  },

  memberAvatar: {},

  memberName: {
    marginLeft: 12,
  },

  itemPeople: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    padding: 12,
    paddingBottom: 8,
    paddingTop: 8,
    borderRadius: 20,
  },
}));
