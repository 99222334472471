import React from "react";
import PropTypes from "prop-types";
import { Drawer, IconButton, ListItem, ListItemText, Typography, Box } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { AppConstant } from "const";
import clsx from "clsx";

const DrawerLayout = ({ title, children, icon, isPadding, onClose, classes, ...drawerProps }) => {
  const defaultClasses = useStyles();

  return (
    <Drawer
      anchor="left"
      classes={{
        root: clsx(defaultClasses.drawer, classes.root),
        paper: clsx(defaultClasses.paper, classes.paper),
      }}
      hideBackdrop={true}
      onClose={onClose}
      disableAutoFocus
      disableEnforceFocus
      {...drawerProps}
    >
      <ListItem>
        <IconButton onClick={onClose} edge="start" className={defaultClasses.btnBack}>
          {icon || <ArrowBack />}
        </IconButton>
        <ListItemText primary={<Typography className={defaultClasses.drawerTitle}>{title}</Typography>} />
      </ListItem>

      <Box overflow="auto" className={clsx(isPadding && defaultClasses.drawerPadding, classes.wrapper)}>
        {children}
      </Box>
    </Drawer>
  );
};

DrawerLayout.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  icon: PropTypes.node,
  anchor: PropTypes.string,
  isPadding: PropTypes.bool,

  classes: PropTypes.shape({
    root: PropTypes.string,
    paper: PropTypes.string,
    wrapper: PropTypes.string,
  }),

  onClose: PropTypes.func,
};

DrawerLayout.defaultProps = {
  classes: {
    root: "",
    paper: "",
  },
  isPadding: false,
};

export default DrawerLayout;

const useStyles = makeStyles(theme => ({
  drawer: {
    "&, & $paper": {
      width: AppConstant.SIDEBAR_WIDTH.medium,
      flexShrink: 0,
      boxShadow: "unset",

      [theme.breakpoints.up(1500)]: {
        width: AppConstant.SIDEBAR_WIDTH.large,
      },
    },
  },

  paper: {
    paddingTop: 8,
  },

  btnBack: {
    color: "#424242",
  },

  drawerTitle: {
    fontWeight: 700,
    fontSize: 15,
    color: theme.palette.black,
    textAlign: "center",
  },

  drawerPadding: {
    paddingLeft: 16,
    paddingRight: 16,
  },
}));
