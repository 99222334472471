import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Box, CardMedia, Container } from "@mui/material";
import { ImageConstant } from "const";
import { LoginForm, OTPAuth } from "./components";

const Login = () => {
  const classes = useStyles();
  const phoneNumberRedux = useSelector(state => state.authRedux.phoneNumber);

  const [isHavePhone, setIsHavePhone] = useState(false);

  useEffect(() => {
    setIsHavePhone(Boolean(phoneNumberRedux));
  }, [phoneNumberRedux]);

  return (
    <Container
      component="main"
      maxWidth="xl"
      classes={{
        root: classes.container,
        maxWidthXl: classes.container,
      }}
    >
      <Box className={classes.root}>
        {isHavePhone ? <OTPAuth /> : <LoginForm />}
        <Box className={classes.imageContainer}>
          <CardMedia component="img" src={ImageConstant.LoginWelcomeImage} className={classes.loginWelcomeImage} />
        </Box>
      </Box>
    </Container>
  );
};

export default Login;

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%",
    padding: 0,
  },

  root: {
    display: "flex",
    height: "100%",
  },

  imageContainer: {
    width: "70%",
    backgroundColor: "#FAFAFA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  loginWelcomeImage: {
    width: "80%",
  },
}));
