import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Box, Button, Dialog, Popover, Typography } from "@mui/material";
import clsx from "clsx";
import { getNSKey } from "utils/lang.utils";
import { formatEmoij, isArrayNotEquals, swapArrayElements, toCamel } from "utils";
import { AppConstant, KeyConstant, LangConstant } from "const";
import { SearchBar, InfiniteScroll } from "components";
import { LocalEmojiService } from "services/local.service";
import { StorageUtil } from "utils";

const FullEmojiMenu = props => {
  const { onSelectEmoji, onClose, open, anchorEl, ...otherProps } = props;
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const localEmoji = StorageUtil.getItem(KeyConstant.KEY_EMOJI_STATIC_LIST);
  const defaultEmoji = AppConstant.DEFAULT_EMOJI;

  const [staticEmoji, setStaticEmoji] = useState([]);
  const [emojiList, setEmojiList] = useState([]);
  const [emojiCategories, setEmojiCategories] = useState([]);
  const [form, setForm] = useState("");
  const [isOpenCustomizeEmojiDialog, setIsOpenCustomizeEmojiDialog] = useState(false);
  const [limit, setLimit] = useState(DEFAULT_LIMIT_RECORD);

  const onChangeInput = value => {
    setForm(value);
  };

  const onSelect = value => {
    onSelectEmoji(value);
  };

  const onClickCustomizeButton = () => {
    setIsOpenCustomizeEmojiDialog(true);
    onClosePopup();
  };

  const onCloseCustomizeEmojiDialog = () => {
    setIsOpenCustomizeEmojiDialog(false);
  };

  const onClosePopup = () => {
    setForm("");
    onClose();
  };

  const onScrollToBottom = () => {
    setLimit(limit => limit + DEFAULT_LIMIT_RECORD);
  };

  useEffect(() => {
    if (localEmoji) {
      setStaticEmoji(localEmoji);
    } else {
      setStaticEmoji(defaultEmoji);
      StorageUtil.setItem(KeyConstant.KEY_EMOJI_STATIC_LIST, defaultEmoji);
    }
  }, []);

  useEffect(() => {
    let onStorageChange = () => {
      let newEmojiList = StorageUtil.getItem(KeyConstant.KEY_EMOJI_STATIC_LIST);
      setStaticEmoji(newEmojiList);
    };

    window.addEventListener("storage", onStorageChange);

    return () => {
      window.removeEventListener("storage", onStorageChange);
    };
  });

  useEffect(() => {
    let isEnd = false;
    LocalEmojiService.findEmoij(form, limit).then(result => {
      let camelEmoijList = toCamel(result);
      if (!isEnd) setEmojiList(camelEmoijList);
    });
    return () => (isEnd = true);
  }, [form, limit]);

  useEffect(() => {
    let handleEmojiObj = emojiList.reduce((finalEmojiList, currentEmoji) => {
      let group = currentEmoji.emojiGroup;
      finalEmojiList[group] = (finalEmojiList[group] || []).concat(currentEmoji);

      return finalEmojiList;
    }, {});

    let handleEmojiArr = Object.entries(handleEmojiObj).map(([group, emojiList]) => {
      return { group: group, emojiList: emojiList };
    });

    setEmojiCategories(handleEmojiArr);
  }, [emojiList]);

  return (
    <>
      <Popover
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={onClosePopup}
        anchorEl={anchorEl}
        open={open}
        classes={{ paper: classes.paper }}
        {...otherProps}
      >
        <Box className={classes.root}>
          <SearchBar value={form} onChange={onChangeInput} classes={{ root: classes.searchBar }} />
          <Box className={classes.emojiWrapper}>
            <Box className={classes.header}>
              <Typography className={clsx(classes.heading, "regular-sm-txt")}>
                {getLabel(getNSKey(LangConstant.NS_HOME_CONVERSATION, LangConstant.TXT_STATIC_REACTION))}
              </Typography>
              <Typography className={clsx(classes.customButton, "semiBold-sm-txt")} onClick={onClickCustomizeButton}>
                {getLabel(LangConstant.TXT_CUSTOMIZE)}
              </Typography>
            </Box>
            <Box className={classes.body}>
              {staticEmoji.map((emoji, index) => (
                <Box
                  key={index}
                  className={classes.emojiItem}
                  dangerouslySetInnerHTML={{ __html: formatEmoij(emoji) }}
                  onClick={() => onSelect(emoji)}
                />
              ))}
            </Box>
          </Box>
          <InfiniteScroll className={classes.fullEmojiContainer} onScrollToBottom={onScrollToBottom}>
            {emojiCategories.map(emoji => (
              <Box key={emoji.group} className={classes.paper}>
                <Box className={classes.header}>
                  <Typography className={clsx(classes.heading, "regular-sm-txt")}>{emoji.group}</Typography>
                </Box>
                <Box className={classes.body}>
                  {emoji.emojiList.map((emoji, index) => (
                    <Box
                      key={index}
                      className={classes.emojiItem}
                      dangerouslySetInnerHTML={{ __html: formatEmoij(emoji.hexCode) }}
                      onClick={() => onSelect(emoji.hexCode)}
                    />
                  ))}
                </Box>
              </Box>
            ))}
          </InfiniteScroll>
        </Box>
      </Popover>
      <CustomizeStaticEmojiDialog open={isOpenCustomizeEmojiDialog} onClose={onCloseCustomizeEmojiDialog} />
    </>
  );
};

export default FullEmojiMenu;

FullEmojiMenu.propTypes = {
  onSelectEmoji: PropTypes.func,
  onClose: PropTypes.func,

  open: PropTypes.bool,
};

FullEmojiMenu.defaultProps = {
  onSelectEmoji: () => {},
  onClose: () => {},

  open: false,
};

const DEFAULT_LIMIT_RECORD = 30;

const CustomizeStaticEmojiDialog = props => {
  const { onClose, open } = props;
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const localEmoji = StorageUtil.getItem(KeyConstant.KEY_EMOJI_STATIC_LIST);
  const defaultEmoji = AppConstant.DEFAULT_EMOJI;

  const [staticEmoji, setStaticEmoji] = useState([]);
  const [emojiList, setEmojiList] = useState([]);
  const [form, setForm] = useState("");
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [limit, setLimit] = useState(DEFAULT_LIMIT_RECORD);
  const [emojiCategories, setEmojiCategories] = useState([]);

  const onChangeInput = value => {
    setForm(value);
  };

  const onClickStaticEmoji = emoji => {
    setSelectedEmoji(emoji);
  };

  const onClickNormalEmoji = emoji => {
    if (selectedEmoji) {
      let newStaticEmoji = [];
      if (staticEmoji.includes(emoji)) {
        let newEmojiIndex = staticEmoji.indexOf(emoji);
        let selectedEmojiIndex = staticEmoji.indexOf(selectedEmoji);
        newStaticEmoji = swapArrayElements(staticEmoji, selectedEmojiIndex, newEmojiIndex);
      } else {
        newStaticEmoji = staticEmoji.map(staticEmojiItem => {
          return staticEmojiItem === selectedEmoji ? emoji : staticEmojiItem;
        });
      }
      setStaticEmoji(newStaticEmoji);
      setSelectedEmoji(emoji);
    }
  };

  const onClickResetButton = () => {
    setStaticEmoji(defaultEmoji);
    setSelectedEmoji(null);
    setForm("");
  };

  const onClickSaveButton = () => {
    if (isArrayNotEquals(localEmoji, staticEmoji)) {
      setForm("");
      StorageUtil.setItem(KeyConstant.KEY_EMOJI_STATIC_LIST, staticEmoji);
      window.dispatchEvent(new Event("storage"));
      onCloseDialog();
    }
  };

  const onCloseDialog = () => {
    setSelectedEmoji(null);
    setForm("");
    onClose();
  };

  const onScrollToBottom = () => {
    setLimit(limit => limit + DEFAULT_LIMIT_RECORD);
  };

  useEffect(() => {
    if (localEmoji) {
      setStaticEmoji(localEmoji);
    } else {
      setStaticEmoji(defaultEmoji);
      StorageUtil.setItem(KeyConstant.KEY_EMOJI_STATIC_LIST, defaultEmoji);
    }
  }, [open]);

  useEffect(() => {
    let onStorageChange = () => {
      let newEmojiList = StorageUtil.getItem(KeyConstant.KEY_EMOJI_STATIC_LIST);
      setStaticEmoji(newEmojiList);
    };

    window.addEventListener("storage", onStorageChange);

    return () => {
      window.removeEventListener("storage", onStorageChange);
    };
  });

  useEffect(() => {
    LocalEmojiService.findEmoij(form, limit).then(result => {
      let camelEmoijList = toCamel(result);
      setEmojiList(camelEmoijList);
    });
  }, [form, limit]);

  useEffect(() => {
    let handleEmojiObj = emojiList.reduce((finalEmojiList, currentEmoji) => {
      let group = currentEmoji.emojiGroup;
      finalEmojiList[group] = (finalEmojiList[group] || []).concat(currentEmoji);

      return finalEmojiList;
    }, {});

    let handleEmojiArr = Object.entries(handleEmojiObj).map(([group, emojiList]) => {
      return { group: group, emojiList: emojiList };
    });

    setEmojiCategories(handleEmojiArr);
  }, [emojiList]);

  return (
    <Dialog open={open} onClose={onCloseDialog} classes={{ paper: classes.paper }}>
      <Box className={classes.root}>
        <Box className={classes.emojiWrapper}>
          <Box className={classes.body}>
            {staticEmoji.map((emoji, index) => (
              <Box
                key={index}
                className={clsx(classes.emojiItem, {
                  [classes.unSelectedEmoji]: emoji !== selectedEmoji,
                })}
                onClick={() => onClickStaticEmoji(emoji)}
                dangerouslySetInnerHTML={{ __html: formatEmoij(emoji) }}
              />
            ))}
          </Box>
        </Box>
        <Typography className={clsx(classes.customizeEmojiText, "regular-sm-txt")}>
          {getLabel(getNSKey(LangConstant.NS_HOME_CONVERSATION, LangConstant.TXT_CUSTOM_EMOJI))}
        </Typography>
        <SearchBar value={form} onChange={onChangeInput} classes={{ root: classes.searchBar }} />
        <InfiniteScroll className={classes.fullEmojiContainer} onScrollToBottom={onScrollToBottom}>
          {emojiCategories.map(emoji => (
            <Box key={emoji.group} className={classes.paper}>
              <Box className={classes.header}>
                <Typography className={clsx(classes.heading, "regular-sm-txt")}>{emoji.group}</Typography>
              </Box>
              <Box className={classes.body}>
                {emoji.emojiList.map((emoji, index) => (
                  <Box
                    key={index}
                    className={classes.emojiItem}
                    onClick={() => onClickNormalEmoji(emoji.hexCode)}
                    dangerouslySetInnerHTML={{ __html: formatEmoij(emoji.hexCode) }}
                  />
                ))}
              </Box>
            </Box>
          ))}
        </InfiniteScroll>
        <Box className={classes.footer}>
          <Button
            color="primary"
            variant="text"
            className={classes.textButton}
            onClick={onClickResetButton}
            disabled={!isArrayNotEquals(staticEmoji, defaultEmoji)}
          >
            {getLabel(LangConstant.TXT_RESET)}
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.containedButton}
            onClick={onClickSaveButton}
            disabled={!isArrayNotEquals(localEmoji, staticEmoji)}
          >
            {getLabel(LangConstant.TXT_SAVE)}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

CustomizeStaticEmojiDialog.propTypes = {
  onClose: PropTypes.func,

  open: PropTypes.bool,
};

CustomizeStaticEmojiDialog.defaultProps = {
  onClose: () => {},

  open: false,
};

const useStyles = makeStyles(theme => ({
  paper: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },

  root: {
    width: 360,
    padding: "20px 14px",
    marginBottom: 12,
    marginRight: 12,
    backgroundColor: theme.palette.white,
    borderRadius: 20,
    boxShadow: "4px 4px 7px rgba(0, 0, 0, 0.1)",
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    top: 0,
    position: "sticky",
    backgroundColor: theme.palette.white,
  },

  heading: {
    lineHeight: "16px",
    color: theme.palette.grey[500],
  },

  customButton: {
    lineHeight: "16px",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    cursor: "pointer",
  },

  body: {
    display: "flex",
    flexWrap: "wrap",
  },

  emojiItem: {
    height: 30,
    width: "calc(360px / 12)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 12,
    fontSize: 30,
    cursor: "pointer",
    userSelect: "none",
  },

  fullEmojiContainer: {
    height: 250,
    overflowY: "scroll",
  },

  customizeEmojiText: {
    lineHeight: "16px",
    color: theme.palette.black,
    textAlign: "center",
    marginBottom: 12,
  },

  searchBar: {
    marginBottom: 12,
  },

  footer: {
    marginTop: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  containedButton: {
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
    "&:disabled": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.white,
    },
  },

  textButton: {
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
    "&:disabled": {
      color: theme.palette.primary.light,
    },
  },

  unSelectedEmoji: {
    opacity: 0.5,
  },
}));
