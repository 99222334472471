import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  convertString2JSON,
  deepCloneJsonObject,
  isArrayNotEquals,
  sortArrayObject,
  sortArrayObjectReserved,
  toCamel,
} from "utils";
import { InfiniteScroller } from "components";
import { FormatConstant, LangConstant, SystemConstant } from "const";
import ThreadMessage from "./ThreadMessage";
import LineChat from "../../../LineChat";
import { useTranslation } from "react-i18next";
import { LocalMessageService, LocalThreadService } from "services/local.service";
import { ConversationActions } from "redux-store";
import StringFormat from "string-format";

const ThreadViewMode = ({ onSendMessage, members }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);

  const threadingId = useSelector(state => state.conversationRedux.threadingId);
  const isUpdateViewMode = useSelector(state => state.conversationRedux.isUpdateViewMode);
  const scrollToChildId = useSelector(state => state.conversationRedux.scrollToChildId);

  const [threadList, setThreadList] = useState([]);
  const [unreadThreadMsgs, setUnreadThreadMsgs] = useState([]);
  const [parentMessage, setParentMessage] = useState({});

  const onSend = (sendType, content, parentId) => {
    onSendMessage(sendType, content, parentId, threadingId);
  };

  useEffect(() => {
    let checkUnreadInterval = null;

    if (threadingId) {
      var staticMembers = members.map(gm => gm.id);

      let messages = sortArrayObjectReserved(toCamel(LocalMessageService.getThreadMessage(threadingId)), "created");
      messages = messages.filter(
        item =>
          !(
            Boolean(item.parentId) &&
            (item.sendType === SystemConstant.SEND_TYPE.message ||
              item.sendType === SystemConstant.SEND_TYPE.groupMessage)
          ) &&
          item.sendType !== SystemConstant.SEND_TYPE.reaction &&
          item.sendType !== SystemConstant.SEND_TYPE.deleteMessage,
      );

      let show = deepCloneJsonObject(messages).map((msg, index) => {
        let childMessages = LocalMessageService.getChildMessages(msg.sourceId, SystemConstant.SEND_TYPE.deleteMessage);

        if (childMessages.length > 0 || msg.sendType === SystemConstant.SEND_TYPE.deleteMessage) {
          return null;
        }

        let seenMembers = [];

        if (msg.options && msg.options !== "") {
          let options = toCamel(convertString2JSON(msg.options));
          if (options != null && options.seenMembers) {
            options.seenMembers.forEach(s => {
              if (staticMembers.includes(s)) {
                let member = members.find(g => g.id === s);
                if (member) {
                  seenMembers.push({
                    name: s,
                    avatarId: member.avatarId,
                  });
                  members = members.filter(st => st !== s);
                  staticMembers = staticMembers.filter(st => st !== s);
                }
              }
            });
          }
        }

        const maxTime =
          SystemConstant.TIME_FORMAT.minute * SystemConstant.TIME_FORMAT.second * SystemConstant.TIME_FORMAT.ticks;
        const msgCreatedTime = msg.created;
        const prevMsg = messages[index + 1] ? messages[index + 1] : null;

        let returnMsg = { ...msg };
        if (
          prevMsg &&
          (msgCreatedTime - prevMsg.created >= maxTime || prevMsg.senderId !== msg.senderId) &&
          msg &&
          msg.created &&
          !CALLING_TYPES.includes(msg.sendType)
        ) {
          returnMsg.isShowTime = true;
          returnMsg.isAvatar = true;
        }

        if (!prevMsg) {
          returnMsg.isAvatar = true;
        }

        returnMsg.seenMembers = seenMembers;

        return returnMsg;
      });

      show = show.filter(
        item => item !== null && item !== undefined && item.sendType !== SystemConstant.SEND_TYPE.reaction,
      );
      show = sortArrayObject(show).reverse();

      if (isArrayNotEquals(show, [...threadList])) {
        setThreadList(show);
        LocalThreadService.update({ total_reply: show.length }, threadingId);
      }

      let unread = LocalMessageService.getUnreadInThread(threadingId);

      onUpdateReplyStatus(unread);

      checkUnreadInterval = setInterval(() => {
        if (unread.length > 0) {
          setUnreadThreadMsgs(unread);
        }
      }, 2000);
    }
    return () => {
      clearInterval(checkUnreadInterval);
    };
  }, [threadingId, isUpdateViewMode]);

  const onUpdateReplyStatus = msgArr => {
    let msgIdArr = msgArr.map(item => item.id);

    if (msgIdArr.length > 0) {
      dispatch(
        ConversationActions.updateMessageStatus({
          messageIds: msgIdArr,
          status: SystemConstant.MESSAGE_STATUS.read,
        }),
      );

      LocalMessageService.updateMessageStatusByMsgIdArr(msgIdArr);
      LocalThreadService.update({ total_unread: 0 }, threadingId);
    }
  };

  useEffect(() => {
    if (unreadThreadMsgs.length > 0) {
      onUpdateReplyStatus(unreadThreadMsgs);
    }
  }, [unreadThreadMsgs]);

  useEffect(() => {
    let parentMsg = toCamel(LocalMessageService.getMessageWithSourceId(threadingId));
    setParentMessage(parentMsg);
  }, [threadingId]);

  useEffect(() => {
    if (threadingId && scrollToChildId) {
      const isExistMsg = threadList.find(item => item.id === scrollToChildId);
      if (!Boolean(isExistMsg)) return;
      const elementId = StringFormat(FormatConstant.FM_CHAT_ITEM_ID, scrollToChildId);

      let scrollToEl = null;
      let retries = 0;
      while (scrollToEl === null && retries < 5) {
        retries++;
        scrollToEl = document.getElementById(elementId);
        if (scrollToEl) {
          scrollToEl.scrollIntoView({ block: "center" });
          dispatch(
            ConversationActions.conversationSet({
              scrollToChildId: null,
            }),
          );
        } else {
          const mainScroll = document.getElementById(THREAD_WRAPPER_ID);
          const scrollHeight = 0 - parseInt(mainScroll.scrollHeight);
          if (mainScroll) {
            mainScroll.scrollTo({
              top: scrollHeight,
              behavior: "smooth",
            });
          }
        }
      }

      dispatch(
        ConversationActions.conversationSet({
          scrollToChildId: null,
        }),
      );
    }
  }, [scrollToChildId, threadingId, threadList]);

  return (
    <InfiniteScroller
      className={classes.wrapChatItem}
      childrenLength={threadList?.length}
      isScrollingUp={true}
      autoScrollDuringNew={threadList.length > 0 && true}
      id={THREAD_WRAPPER_ID}
    >
      {/* Reply message */}
      {threadList.map(item => (
        <ThreadMessage
          key={item.id}
          data={{ ...item, members }}
          isShowTime={item.isShowTime}
          isAvatar={item.isAvatar}
          onSend={onSend}
        />
      ))}

      {threadList.length > 0 && (
        <LineChat data={getLabel(LangConstant.FM_NUMBER_THREAD_REPLY, { number: threadList.length })} />
      )}

      {/* Parent message */}
      <ThreadMessage
        data={{ ...parentMessage, members }}
        isShowTime={true}
        isAvatar={true}
        onSend={onSend}
      />
    </InfiniteScroller>
  );
};

ThreadViewMode.propTypes = {
  messages: PropTypes.array,
  members: PropTypes.array,
  groupDetail: PropTypes.object,

  onSendMessage: PropTypes.func,
};

ThreadViewMode.defaultProps = {
  members: [],
};

export default memo(ThreadViewMode);

const useStyles = makeStyles(theme => ({
  wrapChatItem: {
    height: "100%",
    overflow: "auto",
    paddingBottom: 15,
    padding: "6px 22px",
  },
}));

const THREAD_WRAPPER_ID = "thread-wrapper-id";

const CALLING_TYPES = [
  SystemConstant.SEND_TYPE.personalCall,
  SystemConstant.SEND_TYPE.groupCall,
  SystemConstant.SEND_TYPE.reconnect,
  SystemConstant.SEND_TYPE.personalVideoCall,
  SystemConstant.SEND_TYPE.groupVideoCall,
  SystemConstant.SEND_TYPE.conference,
];
