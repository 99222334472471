import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Box, Button, Typography } from "@mui/material";
import clsx from "clsx";
import { LangConstant } from "const";
import { getCommonKey } from "utils/lang.utils";
import { useDispatch } from "react-redux";
import { AuthActions } from "redux-store";

const OTPLocked = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_LOGIN);

  const onGoBack2Login = () => {
    dispatch(
      AuthActions.authSet({
        phoneNumber: null,
      }),
    );
  };

  return (
    <Box className={classes.otpMainForm}>
      <Typography className={clsx(classes.otpLockedTitle, "bold-xl-txt")}>
        {getLabel(LangConstant.TXT_OTP_LOCKED)}
      </Typography>
      <Typography className={classes.otpLockedMessage}>{getLabel(LangConstant.TXT_OTP_LOCKED_FULL_MESSAGE)}</Typography>
      <Button variant="contained" className={clsx(classes.resendButton, "semiBold-lg-txt")} onClick={onGoBack2Login}>
        {getLabel(getCommonKey(LangConstant.TXT_OK))}
      </Button>
    </Box>
  );
};

OTPLocked.propTypes = {};

export default OTPLocked;

const useStyles = makeStyles(theme => ({
  otpMainForm: {
    backgroundColor: theme.palette.white,
    width: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0 80px",
    [theme.breakpoints.down(1350)]: {
      padding: "0 50px",
    },
  },

  otpLockedTitle: {
    textAlign: "center",
    lineHeight: "20px",
  },

  resendButton: {
    width: "100%",
    lineHeight: "20px",
    paddingTop: 14,
    paddingBottom: 14,
  },

  otpLockedMessage: {
    fontSize: 15,
    fontWeight: 400,
    lineHeight: "18px",
    textAlign: "center",
    marginTop: 8,
    marginBottom: 16,
  },
}));
