import { call, put } from "redux-saga/effects";
import { AttachmentService, AccountService } from "services";
import { toSnake } from "utils";
import { LocalAttachmentService } from "services/local.service";
import { ApiConstant, SystemConstant } from "const";
import { ProfileActions } from "redux-store";

export function* updateAccountStatus(action) {
  try {
    const { data } = action;
    let response = yield call(AccountService.updateAccount, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(ProfileActions.profileSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* changeProfileAvatar(action) {
  try {
    const { data } = action;
    const apiPayload = new FormData();
    apiPayload.append("file", data.upload, data.upload.path);

    const response = yield call(AccountService.uploadFileAccount, apiPayload);
    if (response.status === ApiConstant.STT_OK) {
      yield call(AccountService.updateAccount, toSnake({ avatarId: response.data.id }));
      const avatarID = response.data.id;
      const fileName = avatarID + ".png";

      if (!LocalAttachmentService.exitsLocalFile(avatarID, SystemConstant.ATTACHMENT_TYPE.account, fileName)) {
        let attachmentResponse = yield call(
          AttachmentService.getGlobalAttachment,
          toSnake({
            attachmentId: avatarID,
          }),
        );
        if (attachmentResponse.status === ApiConstant.STT_OK) {
          let unit8String = new Uint8Array(attachmentResponse.data);
          try {
            LocalAttachmentService.saveFileUnEncrypted(unit8String, avatarID, fileName);
          } catch (error) {
            console.log({ error });
          }
          yield put(
            ProfileActions.profileSuccess({
              changingAvatarId: avatarID,
            }),
          );
        }
      }
    }
  } catch (error) {
    yield put(
      ProfileActions.profileFailure(error, {
        changingAvatarId: false,
      }),
    );
  }
}

export function* changeProfileBackground(action) {
  try {
    const { data } = action;
    const apiPayload = new FormData();
    apiPayload.append("file", data.upload, data.upload.path);

    const response = yield call(AccountService.uploadFileAccount, apiPayload);
    if (response.status === ApiConstant.STT_OK) {
      yield call(AccountService.updateAccount, toSnake({ backgroundId: response.data.id }));

      const backgroundID = response.data.id;
      const fileName = `${backgroundID}.png`;

      if (!LocalAttachmentService.exitsLocalFile(backgroundID, SystemConstant.ATTACHMENT_TYPE.account, fileName)) {
        const attachmentResponse = yield call(
          AttachmentService.getGlobalAttachment,
          toSnake({ attachmentId: backgroundID }),
        );
        if (attachmentResponse.status === ApiConstant.STT_OK) {
          let unit8String = new Uint8Array(attachmentResponse.data);
          try {
            LocalAttachmentService.saveFileUnEncrypted(unit8String, backgroundID, fileName);
          } catch (error) {
            console.log({ error });
          }
          yield put(
            ProfileActions.profileSuccess({
              changingBackgroundId: backgroundID,
            }),
          );
        }
      }
    }
  } catch (error) {
    yield put(
      ProfileActions.profileFailure(error, {
        changingBackgroundId: false,
      }),
    );
  }
}

export function* updateProfileInfo(action) {
  try {
    const { data } = action;
    const response = yield call(AccountService.updateAccount, toSnake(data));

    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ProfileActions.profileSuccess({
          isUpdateUserInfoSuccess: true,
        }),
      );
    } else {
      yield put(
        ProfileActions.profileFailure(response, {
          isUpdateUserInfoFailed: true,
        }),
      );
    }
  } catch (error) {
    yield put(ProfileActions.profileFailure(error));
  }
}

export function* deleteProfileImage(action) {
  const { data } = action;
  try {
    const response = yield call(
      AccountService.updateAccount,
      data.isAvatar ? { avatar_id: "" } : { background_id: "" },
    );

    if (response.status === ApiConstant.STT_OK) {
      yield put(ProfileActions.profileSuccess(data.isAvatar ? { changingAvatarId: "" } : { changingBackgroundId: "" }));
    }
  } catch (error) {
    console.log(error);
  }
}
