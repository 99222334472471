import { KeyConstant, SystemConstant, FormatConstant, PathConstant, LangConstant, AppConstant } from "const";
import { convertMillisecondsToDate } from "./date.utils";
import i18n from "language";
import parsePhoneNumber from "libphonenumber-js";
import { isEqual } from "lodash";
import { filesize } from "filesize";
import { getSavedServer } from "./common.utils";
import {
  LocalAccountService,
  LocalAppNotificationService,
  LocalAttachmentService,
  LocalContactService,
  LocalInitDataService,
  LocalMessageService,
} from "services/local.service";

export const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
    .replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    })
    .replace(/-/g, "");
};

export const getError = response => {
  let errorContent = "";
  if (response) {
    const { data, error } = response;
    if (data) {
      errorContent = data.message + data.error || "Có lỗi xảy ra!!!";
    } else if (error) errorContent = error;
  }

  return errorContent;
};

export const hasLogin = () => Boolean(StorageUtil.getItem(KeyConstant.KEY_TOKEN));

export const handlingLogin = camelResponse => {
  StorageUtil.setItem(KeyConstant.KEY_TRIOS_ACCESS, camelResponse.triosAccess);
  StorageUtil.setItem(KeyConstant.KEY_MEET_TOKEN, camelResponse.meetToken);
  StorageUtil.setItem(KeyConstant.KEY_TOKEN, camelResponse.accessToken);
  StorageUtil.setItem(KeyConstant.KEY_ACCOUNT_ID, camelResponse.accountId);
  StorageUtil.setItem(KeyConstant.KEY_DEVICE_ID, camelResponse.deviceId);
  StorageUtil.setItem(KeyConstant.KEY_EXPIRED_TIME, camelResponse.expiredTime);
  StorageUtil.setItem(KeyConstant.KEY_REFRESH_EXPIRED_TIME, camelResponse.refreshExpiredTime);
  StorageUtil.setItem(KeyConstant.KEY_REFRESH_TOKEN, camelResponse.refreshToken);
  StorageUtil.setItem(KeyConstant.KEY_SCOPES, camelResponse.scopes);
};

export const handlingLogout = async () => {
  StorageUtil.setItem(KeyConstant.KEY_LOGOUT_F, "1");
  LocalAppNotificationService.setBadgeCount(0);
  await LocalInitDataService.deleteAllTable();
  LocalAttachmentService.removeFileAttachments();
  StorageUtil.clear();
  window.location.reload();
};

export const snakeToCamelCase = str =>
  str.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace("-", "").replace("_", ""));

export const camelToSnakeCase = str =>
  (str.charAt(0).toLowerCase() + str.slice(1) || str).replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const toCamel = obj => {
  var newObj, origKey, newKey, value;
  if (obj instanceof Array) {
    return obj.map(function (value) {
      if (typeof value === "object") {
        value = toCamel(value);
      }
      return value;
    });
  } else {
    newObj = {};
    for (origKey in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, origKey)) {
        newKey = snakeToCamelCase(origKey);
        value = obj[origKey];
        if (value instanceof Array || (value && value.constructor === Object)) {
          value = toCamel(value);
        }
        newObj[newKey] = value;
      }
    }
  }
  return newObj;
};

export const toSnake = obj => {
  var newObj, origKey, newKey, value;
  if (obj instanceof Array) {
    return obj.map(function (value) {
      if (typeof value === "object") {
        value = toSnake(value);
      }
      return value;
    });
  } else {
    newObj = {};
    for (origKey in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, origKey)) {
        newKey = camelToSnakeCase(origKey);
        value = obj[origKey];
        if (value instanceof Array || (value && value.constructor === Object)) {
          value = toSnake(value);
        }
        newObj[newKey] = value;
      }
    }
  }
  return newObj;
};

export const isArray = array => array && Array.isArray(array);

export const isString = str => typeof str === "string";

export const formatPagination = data => {
  return {
    paging: data?.paging || 1,
    page: data?.page || 1,
    size: data?.size || 10,
    asc: data?.asc || 1,
  };
};

export const createParams = data => {
  let defaultParam = formatPagination();
  return {
    ...defaultParam,
    ...data,
  };
};

export const validatePhone = content => {
  const phoneNumber = parsePhoneNumber(content, "VN");
  return phoneNumber && phoneNumber.isValid();
};

export const validateEmail = content => {
  /* eslint-disable no-useless-escape */
  const regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return content && regex.test(String(content).replace(/\s+/g, "").toLowerCase());
};

export const objectToJSON = data => JSON.stringify(data);

export const jsonToObject = json => JSON.parse(json);

export const convertHex2rgba = (hex, alpha = 1) => {
  try {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  } catch (error) {
    return "";
  }
};

export const formatLimitNumber = number => {
  if (!number) return 0;

  let result = "";
  if (0 <= number && number < 10) result = "0" + number;
  if (10 <= number && number < 100) result = `${number}`;
  if (number > 99) result = "99+";

  return result;
};

export const isVideo = type => type && type.includes("video");

export const isAudio = type => type && type.includes("audio");

export const isImage = type => type && type.includes("image");

export const removeDuplicateInArray = arr => {
  let tmpArray = new Set(arr);
  arr = [...tmpArray];
  return arr;
};

export const isArrayNotEquals = (firstArray, secondArray) =>
  !firstArray ||
  !secondArray ||
  (Array.isArray(firstArray) && JSON.stringify(firstArray) !== JSON.stringify(secondArray));

export const isObjectNotEqual = (firstObject, secondObject) => !isEqual(firstObject, secondObject);

export const isCompareDataArray = (firstArray, secondArray) => {
  let isValid = firstArray && secondArray && Array.isArray(firstArray) && Array.isArray(secondArray);
  if (!isValid) return firstArray === secondArray;
  let sortingFirstArray = JSON.stringify(firstArray.sort());
  let sortingSecondArray = JSON.stringify(secondArray.sort());
  return sortingFirstArray === sortingSecondArray;
};

export const returnOsType = () => {
  let type = SystemConstant.DEVICE_TYPE.other;
  if ((navigator.userAgent.indexOf("Win") || navigator.userAgent.indexOf("Windows")) !== -1)
    type = SystemConstant.DEVICE_TYPE.window;
  else if (navigator.userAgent.indexOf("Mac") !== -1) type = SystemConstant.DEVICE_TYPE.mac;
  else if (navigator.userAgent.indexOf("Linux") !== -1) type = SystemConstant.DEVICE_TYPE.linux;
  else if (navigator.userAgent.indexOf("Android") !== -1) type = SystemConstant.DEVICE_TYPE.android;
  else if (navigator.userAgent.indexOf("like Mac") !== -1) type = SystemConstant.DEVICE_TYPE.ios;
  else type = SystemConstant.DEVICE_TYPE.other;
  return type;
};

export const addUrlParam = (basePath, key, val) => {
  let newParam = key + "=" + val;
  let queryParams = "?" + newParam;

  // If the "search" string exists, then build params from it
  if (basePath && basePath.includes("?")) {
    // Try to replace an exist instance
    queryParams = queryParams.replaceAll("?", "&");
    // If nothing was replaced, then add the new param to the end
    if (queryParams === basePath) {
      queryParams += "&" + newParam;
    }
  }

  return basePath + queryParams;
};

export const deepCloneJsonObject = json => {
  if (!json) return null;
  return JSON.parse(JSON.stringify(json));
};

export const sortArrayObject = (array, field = "created") =>
  array.sort((first, second) => (first[field] > second[field] ? 1 : second[field] > first[field] ? -1 : 0));

export const sortArrayObjectReserved = (array, field = "created") =>
  array.sort((first, second) => (first[field] < second[field] ? 1 : second[field] < first[field] ? -1 : 0));

export const convertString2JSON = string => {
  try {
    JSON.parse(string);
  } catch (e) {
    return string;
  }
  return JSON.parse(string);
};

export const removeDuplicatesWithKey = (array, key) => {
  let lookup = new Set();
  return array.filter(obj => !Boolean(obj) || (!lookup.has(obj[key]) && lookup.add(obj[key])));
};

export const randomKeyGenerator = (length = 8) => {
  let characters = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ+=/";
  let result = "";
  for (let i = length; i > 0; --i) result += characters[Math.round(Math.random() * (characters.length - 1))];
  return result;
};

export const fileToBuffer = (file, format) => {
  return format ? Buffer.from(file, format) : Buffer.from(file);
};

export const isJSONString = string => {
  try {
    let result = JSON.parse(string);
    if (typeof result === "object") return true;
  } catch (error) {
    return false;
  }
};

export const convertJSONObject = object => {
  if (isJSONString(object)) object = convertString2JSON(object);

  if (typeof object === "object") {
    Object.entries(object).forEach(entries => {
      object[entries[0]] = convertString2JSON(entries[1]);
      let childValue = object[entries[0]];

      if (typeof childValue === "object") {
        Object.entries(childValue).forEach(childEntries => {
          childValue[childEntries[0]] = convertString2JSON(childEntries[1]);
        });
      }
      object[entries[0]] = childValue;
    });
  }

  return object;
};

export const getImageSrc = (id, type = 0, mimeType = "image/png", fileName = null) => {
  if (!id) return "";
  return LocalAttachmentService.getLocalImageSrc(id, type, mimeType, fileName);
};

export const getImageSrcAvatar = (id, type = 0, mimeType = "image/png", fileName = null) => {
  return LocalAttachmentService.getLocalImageSrcAvatar(id, type, mimeType, fileName);
};

export class getImageFolderPath {}

export const getCreateTimeObject = data => {
  let newDateTimeData = {};
  if (data) {
    let today = new Date().setUTCHours(0, 0, 0, 0);
    data = data.sort((a, b) => b.created - a.created);
    data.forEach(element => {
      let time =
        element.created > today
          ? i18n.t(LangConstant.TXT_TODAY)
          : convertMillisecondsToDate(element.created, FormatConstant.FM_GALLERY_TIME);
      if (!newDateTimeData[time]) {
        newDateTimeData[time] = [];
      }
      newDateTimeData[time].push(element);
    });
  }

  return newDateTimeData;
};

export const convertFileSizeUnit = (size = 0) => {
  let result = "";

  if (size >= 1000 * 1000 * 1000) {
    let gb = (size / (1000 * 1000 * 1000)).toFixed(3);
    result = `${gb} GB`;
  } else if (size >= 1000 * 1000) {
    let mb = (size / (1000 * 1000)).toFixed(3);
    result = `${mb} MB`;
  } else if (size >= 1000) {
    let kb = (size / 1000).toFixed(3);
    result = `${kb} KB`;
  } else if (size > 0) {
    result = `${size} byte`;
  } else {
    result = "0 byte";
  }

  return result;
};

export const getFileColor = type => {
  let result = "#799098";
  switch (type) {
    case "doc":
      result = "#2A65C0";
      break;

    case "docs":
      result = "#2A65C0";
      break;

    case "xlsx":
      result = "#229C5B";
      break;

    case "xls":
      result = "#229C5B";
      break;

    case "pptx":
      result = "#E85332";
      break;

    case "pptm":
      result = "#E85332";
      break;

    case "ppt":
      result = "#E85332";
      break;

    case "pdf":
      result = "#F40F02";
      break;

    default:
      result = "#799098";
      break;
  }

  return result;
};

export const appendBuffer = (buffer1, buffer2) => {
  var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
  tmp.set(new Uint8Array(buffer1), 0);
  tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
  return tmp;
};

export const getAllIndexOfKeyword = (keyword, string) => {
  let startingIndices = [];
  let indexOccurence = string.indexOf(keyword, 0);
  while (indexOccurence >= 0) {
    startingIndices.push(indexOccurence);
    indexOccurence = string.indexOf(keyword, indexOccurence + 1);
  }

  return startingIndices;
};

export const highlightString = (searchValue, string, color = "inherit") => {
  let tmpContent = string.toLowerCase();
  let stringIndexArr = getAllIndexOfKeyword(searchValue.toLowerCase(), tmpContent);
  for (let i = stringIndexArr.length - 1; i >= 0; i--) {
    let index = stringIndexArr[i];
    let currentText = string.substring(index, index + searchValue.length);
    string =
      string.substring(0, index) +
      `<strong style="color: ${color};">${currentText}</strong>` +
      string.substring(index + searchValue.length);
  }

  return string;
};

export const formatStringWithKeyword = (keyword, string) => {
  let result = "";
  let tmpContent = string.toLowerCase();
  let tmpKeyword = keyword.toLowerCase();
  let keywordIndexArr = getAllIndexOfKeyword(tmpKeyword, tmpContent);
  if (string.length > 100 && keywordIndexArr.length > 1) {
    result = `${string.substr(0, 32)} ... ${string.substr(-32)}`;
  } else if (string.length > 100 && keywordIndexArr.length === 1) {
    let index = tmpContent.indexOf(tmpKeyword) > 32 ? 32 : tmpContent.indexOf(tmpKeyword);
    let lastIndex =
      string.length - tmpContent.indexOf(tmpKeyword) - keyword.length > 32
        ? 32
        : string.length - tmpContent.indexOf(tmpKeyword) - keyword.length - 12;
    result = `${string.substr(0, index)} ... ${keyword} ... ${string.substr(0 - lastIndex)}`;
  } else {
    result = string;
  }

  return result;
};

export const formatPhoneNumber = phoneNumberString => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(84|)?(\d{9})$/);
  if (match) {
    return "(+" + match[1] + ")" + match[2];
  }
  return null;
};

export const formatEmoij = hex => {
  let hexArray = hex.split(/[ \-]/gm);

  return hexArray.join("");
};

export const convertHexToEmoji = hex => {
  let hexArray = hex.split(/[ \-]/gm);
  let rawHexArray = hexArray.map(h => h.replace(/(&#x)+/gm, "0x")).map(h => h.replace(/(;)+/gm, ""));
  let rawHex = rawHexArray.join("");

  return String.fromCodePoint(rawHex);
};

export const swapArrayElements = (array, firstElementIndex, secondElementIndex) => {
  let tmpArray = [...array];
  let temp = tmpArray[firstElementIndex];
  tmpArray[firstElementIndex] = tmpArray[secondElementIndex];
  tmpArray[secondElementIndex] = temp;

  return tmpArray;
};

let newReg = `(http|https){1}://([-a-zA-Z0-9@:%_\+.~#?&//=]*)`;

export const isExternalLink = data => {
  const regex = new RegExp(newReg);
  return regex.test(data);
};

export const getExternalLinkFromString = data => {
  if (data) {
    const regex = new RegExp(newReg);

    const urlArray = data.match(regex);

    return urlArray.length > 0 ? urlArray[0] : null;
  }

  return null;
};

export const removeVietnameseTones = str => {
  if (!str) return "";
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
  return str;
};

export const textNormalize = str => {
  return removeVietnameseTones(str).toLowerCase();
};

export const getCaretPosition = oField => {
  if (!oField) return 0;
  // Initialize
  var iCaretPos = 0;

  // IE Support
  if (document.selection) {
    // Set focus on the element
    oField.focus();

    // To get cursor position, get empty selection range
    var oSel = document.selection.createRange();

    // Move selection start to 0 position
    oSel.moveStart("character", -oField.value.length);

    // The caret position is selection length
    iCaretPos = oSel.text.length;
  }

  // Firefox support
  else if (oField.selectionStart || oField.selectionStart === "0")
    iCaretPos = oField.selectionDirection === "backward" ? oField.selectionStart : oField.selectionEnd;

  // Return results
  return iCaretPos;
};

export const getAllLetterIndex = (string, letter) => {
  if (string.length === 0 || !letter) return [];

  let result = [];
  for (let index = 0; index < string.length; index++) {
    if (string[index] === letter) result.push(index);
  }

  return result;
};

export const replaceId2Name = (string, memberArr) => {
  if (string && !(memberArr && memberArr.length > 0)) return string;
  if (!(string && memberArr && memberArr.length > 0)) return "";
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  let tmpMemberArr = JSON.parse(JSON.stringify(memberArr));
  let tags = string?.match(/@{\w+}/gm) || [];
  let myAccount = LocalAccountService.getAccount(accountId);
  if (myAccount) {
    tmpMemberArr.push(myAccount);
  }
  let messageContent = string;
  tags.forEach(tag => {
    let tagAccountId = tag.replace("@{", "").replace("}", "");
    let currentAccount = tmpMemberArr.find(item => item.id === tagAccountId);
    if (!currentAccount) {
      currentAccount = LocalAccountService.getAccount(tagAccountId);
    }
    let tagAccountName = currentAccount?.name || "No name";
    messageContent = messageContent.replace(tag, `@${tagAccountName}`);
  });

  return messageContent;
};

export const replaceId2NameDisplay = (string, memberArr, isDesktopNotification = false) => {
  if (string && !(memberArr && memberArr.length > 0)) return string;
  if (!(string && memberArr && memberArr.length > 0)) return "";
  let tmpMemberArr = JSON.parse(JSON.stringify(memberArr));
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  let tags = string?.match(/@{\w+}/gm) || [];
  let myAccount = LocalAccountService.getAccount(accountId);
  if (myAccount) {
    tmpMemberArr.push(myAccount);
  }

  let messageContent = string;
  tags.forEach(tag => {
    let tagAccountId = tag.replace("@{", "").replace("}", "");
    let currentAccount = tmpMemberArr.find(item => item.id === tagAccountId);
    if (!currentAccount) {
      currentAccount = LocalAccountService.getAccountByIds([tagAccountId])[0];
    }
    let tagAccountName = currentAccount?.name || "No name";

    messageContent = messageContent.replace(
      tag,
      isDesktopNotification ? `@${tagAccountName}` : `<b> @${tagAccountName} </b>`,
    );
  });

  return messageContent;
};

export const replaceName2Id = (string, memberArr) => {
  let result = string;

  memberArr.forEach(member => {
    let string2Replace = "@" + member.name;
    let string2Insert = "@{" + member.id + "}";
    let index2Replace = result.indexOf(string2Replace);
    if (index2Replace >= 0) {
      result =
        result.slice(0, index2Replace) +
        string2Insert +
        result.slice(index2Replace + string2Replace.length, result.length);
    }
  });

  return result;
};

export const replaceEditName2Id = (content, memberArr) => {
  if (false === Boolean(content) || memberArr.length <= 0) return content;
  let result = content;
  const contentArr = result.split("<b>");
  result = contentArr.map(item => item.trim()).join(" ");
  const convertContentArr = result.split("</b>");
  result = convertContentArr.map(item => item.trim()).join(" ");

  return replaceName2Id(result, memberArr);
};

export const downloadFile = (fileName, srcContent, mimeType = "") => {
  let downloadFileType = mimeType;
  let downloadFileName = fileName;
  let downloadFileLink = srcContent;

  const isNotSupportVideo = mimeType.includes("video") && !AppConstant.SUPPORT_VIDEO_TYPES.includes(mimeType);

  if (isNotSupportVideo) {
    downloadFileType = AppConstant.DEFAULT_VIDEO_TYPE;
    downloadFileName = window.electronUtils.file.renameExtensionFile(fileName);
  }

  if (downloadFileLink.includes("base64")) {
    downloadFileLink = getUrlFromBase64(srcContent, downloadFileType);
  }

  const element = document.createElement("a");
  element.setAttribute("href", downloadFileLink);
  element.setAttribute("download", downloadFileName);
  element.setAttribute("type", downloadFileType);
  element.click();
  element.remove();
};

const getUrlFromBase64 = (base64, mimeType) => {
  const base64Data = base64.split(";base64,")[1];
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });
  return URL.createObjectURL(blob);
};

export const removeUndefinedValueInArray = arr => {
  const filtered = arr.filter(el => el !== null && el !== undefined);
  return filtered;
};

export const copyImageToClipboard = async base64 => {
  base64 = base64.replace("data:image/jpeg;base64", "data:image/png;base64");
  let image = await fetch(base64);
  let blob = await image.blob();
  let data = [new window.ClipboardItem({ "image/png": blob })];

  await navigator.clipboard.write(data);
};

export const openChildWindow = (url, option) => {
  let childWindow;

  if (option) {
    childWindow = window.open(`/#${url}`, "_blank", option);
  } else {
    childWindow = window.open(`/#${url}`, "_blank");
  }

  return childWindow;
};

export const formatCallingNotiWindowPath = messageId => {
  if (messageId && typeof messageId === "string") {
    return `${PathConstant.CALLING_NOTIFICATION}?${KeyConstant.KEY_CALLING_NOTI_QUERY}=${messageId}`;
  }

  console.error("formatCallingNotiWindowPath: get error with messageId:", messageId);

  return "";
};

export const compareObject = (obj1, obj2) => {
  if (Object.keys(obj1).length === 0 || Object.keys(obj2).length === 0) return false;

  let isEqual = true;

  Object.keys(obj1).forEach(key => {
    if (obj2[key] === undefined || JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
      isEqual = false;
    }
  });

  return isEqual;
};

export const getMaxObjectWithKey = (array, key = "created", min = false) => {
  if (!array || !array.length) return {};

  let resultItem = array.reduce(function (a, b) {
    if (min) {
      return a[key] < b[key] ? a : b;
    } else {
      return a[key] > b[key] ? a : b;
    }
  });

  return resultItem || {};
};

export const getContactName = senderId => {
  let result = "";
  const savedServer = getSavedServer();
  const isGlobalServer = savedServer.type === SystemConstant.SERVER_TYPE.server;
  const sender = LocalAccountService.getAccount(senderId);
  const senderContactArr = LocalContactService.getByAccountId(senderId);
  const senderContact = senderContactArr[0];
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);

  if (isGlobalServer) {
    if (senderContactArr && senderContact && senderContact.state === SystemConstant.STATE.active) {
      result = senderContact.contact_name;
    } else if (senderId === accountId) {
      result = sender.name;
    } else {
      result = sender.phone;
    }
  } else if (sender?.name) {
    result = sender.name;
  } else {
    result = i18n.t(LangConstant.TXT_APP_NAME);
  }

  return result;
};

export const getMediaMessagesByGroupId = groupId => {
  let mediaList = LocalMessageService.getMediaMessages(groupId, [
    SystemConstant.SEND_TYPE.image,
    SystemConstant.SEND_TYPE.video,
  ]);
  mediaList = toCamel(mediaList);

  const viewMediaList = mediaList.map(media => {
    const content = isJSONString(media.content) ? toCamel(JSON.parse(media.content)) : {};
    const metaData = content.metaData && isJSONString(content.metaData) ? toCamel(JSON.parse(content.metaData)) : null;

    return { ...media, content: { ...content, metaData: metaData } };
  });
  return viewMediaList;
};

export const convertFileSize = size => {
  try {
    return filesize(size, { round: 2, standard: "jedec" });
  } catch (error) {
    console.log(error);
    return "0 B";
  }
};

export const StorageUtil = window.electronUtils.storage;
