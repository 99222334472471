import React, { memo, useCallback, Fragment } from "react";
import PropTypes from "prop-types";
import { AvatarInfo, SearchBar } from "components";
import EmptyContact from "./EmptyContact";
import { AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { Box, Divider, List, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import AddingGroupButton from "./AddingGroupButton";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { getSavedServer } from "utils/common.utils";
import { useContactList, useCreateConversation, useServerMode } from "hooks";
import { TabPanel } from "@mui/base";
import { LocalGroupService } from "services/local.service";
import { ConversationActions } from "redux-store";
import { StorageUtil } from "utils";

const AddingMessageTab = ({ onCloseDrawer }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isFetching, contactList, getAllContactByName, getAllAccountInBranch } = useContactList();
  const { createWithPersonal } = useCreateConversation();
  const { isBranchServer } = useServerMode();

  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const chatObjContent = getLabel(LangConstant.OBJ_ADDING_CHAT, { returnObjects: true });

  const onCreateSelect = useCallback(
    contact => () => {
      const savedServer = getSavedServer();
      let accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
      // When branch mode use account and when global mode use contact
      let otherMemberId = contact.id;
      if (savedServer?.type === SystemConstant.SERVER_TYPE.server) {
        otherMemberId = contact.contactId;
      }
      LocalGroupService.findInbox(
        [accountId, otherMemberId],
        SystemConstant.GROUP_CHAT_TYPE.personal,
        savedServer?.id,
      ).then(result => {
        if (result && result.id) {
          dispatch(
            ConversationActions.setSelectGroupId({
              selectedGroupId: result.id,
            }),
          );
        } else {
          // Create a conversation if the user never chat before
          createWithPersonal([contact], contact.name);
        }
        onCloseDrawer();
      });
    },
    [],
  );

  if (false === isFetching && contactList.length === 0) return <EmptyMessage />;

  return (
    <TabPanel value={AppConstant.CHATTING_TAB_MODE.message}>
      <Box className={classes.rootSpace}>
        <SearchBar onChange={getAllContactByName} />
      </Box>
      <AddingGroupButton onComplete={onCloseDrawer} />
      <Typography variant="subtitle2" className={clsx(classes.subTitle, classes.rootSpace)}>
        {chatObjContent.people}
      </Typography>
      <List>
        {contactList.map((item, index) => (
          <Fragment key={item.id}>
            {index > 0 && <Divider variant="inset" component="li" />}
            <AvatarInfo
              avatar={{
                src: item.avatarId,
                name: item.contactName || item.name,
              }}
              title={item.contactName || item.name || item.phone}
              onClick={onCreateSelect(item)}
              button={true}
            />
          </Fragment>
        ))}
      </List>
    </TabPanel>
  );
};

AddingMessageTab.propTypes = {
  onCloseDrawer: PropTypes.func,
};

export default memo(AddingMessageTab);

const EmptyMessage = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.rootSpace}>
        <SearchBar />
      </Box>
      <EmptyContact mode={AppConstant.CHATTING_TAB_MODE.message} />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  rootSpace: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  subTitle: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
}));
