import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Grid, IconButton, Popover, Typography } from "@mui/material";
import { Article, Collections } from "@mui/icons-material";
import clsx from "clsx";
import { AppConstant, LangConstant } from "const";
import { HandleUploadingInput } from "components";

const PopupFile = ({ anchorEl, onClose, onFileChange }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const acceptFileExtension = AppConstant.FILE_EXTENSIONS.join(",");
  const acceptMediaExtension = ["image/*", ",audio/*", ...AppConstant.SUPPORT_VIDEO_TYPES].join(",");

  return (
    <Popover
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      onClose={onClose}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      classes={{ paper: classes.wrapIconGird }}
    >
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <HandleUploadingInput
            accept={acceptFileExtension}
            className="hidden"
            id="icon-button-document"
            type="file"
            onChange={onFileChange}
          />
          <label htmlFor="icon-button-document">
            <IconButton className={clsx(classes.wrapIcon)} component="span">
              <Article className={classes.iconPopup} />
            </IconButton>
            <Typography className={classes.textPopup} variant="body2">
              {getLabel(LangConstant.TXT_POPUP_DOCUMENT)}
            </Typography>
          </label>
        </Grid>
        {/* <Grid item xs={4}>
          <input
            accept="image/*,video/*"
            className="hidden"
            id="icon-button-camera"
            type="file"
            onChange={onFileChange}
          />
          <label htmlFor="icon-button-camera">
            <IconButton className={classes.wrapIconCamera} component="span">
              <PhotoCamera className={classes.iconPopup} />
            </IconButton>
            <Typography className={classes.textPopup} variant="body2">
              {getLabel(LangConstant.TXT_POPUP_CAMERA)}
            </Typography>
          </label>
        </Grid> */}
        <Grid item xs={6}>
          <HandleUploadingInput
            accept={acceptMediaExtension}
            className="hidden"
            id="icon-button-gallery"
            type="file"
            onChange={onFileChange}
          />
          <label htmlFor="icon-button-gallery">
            <IconButton className={classes.wrapIconGallery} component="span">
              <Collections className={classes.iconPopup} />
            </IconButton>
            <Typography className={classes.textPopup} variant="body2">
              {getLabel(LangConstant.TXT_POPUP_GALLERY)}
            </Typography>
          </label>
        </Grid>
        {/* <Grid item xs={12} className={classes.audioIcon}>
          <input accept="image/*" className="hidden" id="icon-button-audio" type="file" onChange={onFileChange} />
          <label htmlFor="icon-button-audio">
            <IconButton className={classes.wrapIconAudio}>
              <Headphones className={classes.iconPopup} />
            </IconButton>
            <Typography className={classes.textPopup} variant="body2">
              {getLabel(LangConstant.TXT_POPUP_AUDIO)}
            </Typography>
          </label>
        </Grid> */}
      </Grid>
    </Popover>
  );
};

export default memo(PopupFile);

const useStyles = makeStyles(theme => ({
  iconPopup: {
    margin: 5,
    color: theme.palette.white,
  },

  textPopup: {
    color: "#1E272E",
  },

  wrapIcon: {
    backgroundColor: "#7F66FF",
  },

  wrapIconCamera: {
    backgroundColor: "#FE2D74",
  },

  wrapIconGallery: {
    backgroundColor: "#CA60F7",
  },

  wrapIconAudio: {
    backgroundColor: "#F96633",
    margin: "auto",
    // marginTop: 14,
    width: 48,
    height: 48,
    borderRadius: "50%",
    cursor: "pointer",
  },

  wrapIconGird: {
    width: "fit-content",
    height: "fit-content",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "#F1F3F6",
    padding: 25,
    borderRadius: 24,
  },

  input: {
    visibility: "hidden",
  },

  audioIcon: {
    marginTop: 14,
    display: "none",
  },
}));
