import { ApiConstant } from "const";
import qs from "qs";
import GlobalApi, { createApiWithBranch } from "./config/api.config";

export const getCallingStatus = data => createApiWithBranch().get(ApiConstant.GET_CALL_STATUS, data);

export const getCallHistory = data => createApiWithBranch().get(ApiConstant.GET_CALL_HISTORY, data);

export const updateCallHistory = data =>
  createApiWithBranch().post(ApiConstant.POST_UPDATE_CALL_HISTORY, qs.stringify(data, { skipNulls: true }));

export const postMeetToken = data =>
  GlobalApi.post(ApiConstant.POST_MEET_TOKEN, qs.stringify(data, { skipNulls: true }));
