import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Card, CardMedia, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { getImageSrcAvatar } from "utils";
import { getCommonKey } from "utils/lang.utils";
import { AddAdmin, RemoveCircle, GroupRemove } from "icons";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { ConfirmDialog, DrawerLayout } from "components";
import { getSavedServer } from "../../../../utils/common.utils";
import { useCreateConversation } from "hooks";
import { LocalContactService } from "services/local.service";
import { AccountActions, AccountSelectors, ConversationActions, GroupInfoActions } from "redux-store";
import { Call, Message, VideoCall } from "@mui/icons-material";
import { StorageUtil } from "utils";

// TODO: Should remove accountBlockArray and make clearly props
const MemberDetail = ({ data, groupDetail, onClose, onCloseParent, dataAdmin, isAdminGroup, accountBlockArray }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const { createWithPersonal } = useCreateConversation();

  const [isMe, setIsMe] = useState(false);
  const [src, setSrc] = useState("");
  const [backSrc, setBackSrc] = useState("");

  const blockAccountId = useSelector(AccountSelectors.getBlockAccountId);
  const isUnblockSuccess = useSelector(AccountSelectors.isUnblockSuccess);
  const isBlockSuccess = useSelector(AccountSelectors.isBlockSuccess);
  const hasInternet = useSelector(state => state.profileRedux.hasInternet);
  const isInCall = useSelector(state => state.callingRedux.isInCall);

  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const contactPhoneContent = getLabel(getCommonKey(LangConstant.TXT_CONFIRM));
  const confirmDetail = getLabel(LangConstant.FM_CONFIRM_DETAIL, {
    confirm: data?.name,
  });
  const confirmBlock = getLabel(LangConstant.FM_CONFIRM_BLOCK_DETAIL, {
    confirm: data?.name,
  });
  const confirmRemove = getLabel(
    groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.group
      ? LangConstant.FM_CONFIRM_REMOVE
      : LangConstant.FM_CONFIRM_REMOVE_CHANNEL_MEMBER,
    {
      memberName: data?.name,
    },
  );

  const [isConfirmAdd, setIsConfirmAdd] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isRemove, setIsRemove] = useState(false);
  const [isConfirmUnBlock, setIsConfirmUnBlock] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  //TODO check it
  const [isBlocked, setIsBlocked] = useState(false);
  const [isCheckBlock, setIsCheckBlock] = useState(false);

  const onClickAddNew = () => {
    dispatch(
      GroupInfoActions.addAdmin({
        groupId: groupDetail.id,
        memberId: data.id,
        memberInviteType: SystemConstant.ROLE_OBJ.admin,
      }),
    );
    setIsConfirmAdd(false);
    onClose();
  };

  const onClickRemove = () => {
    dispatch(
      GroupInfoActions.removeMemberGroup({
        groupId: groupDetail.id,
        memberId: isMe ? null : data?.id,
      }),
    );

    dispatch(
      ConversationActions.sendMessage({
        groupDetail: groupDetail,
        sendType: SystemConstant.SEND_TYPE.leaveGroup,
        content: data?.id,
        parentId: null,
        deviceList: [],
        branchId: getSavedServer()?.id,
        mentionIdsArr: [],
        threadId: null,
        removingId: data?.id,
      }),
    );
    onClose();
    setIsRemove(false);
  };

  const onBlockDetail = () => {
    dispatch(AccountActions.blockUser(data?.id));
    setIsConfirm(false);
    onClose();
  };

  const onUnblockDetail = () => {
    dispatch(AccountActions.unblockUser(data?.id));
    setIsConfirmUnBlock(false);
    onClose();
  };

  const onMessenger = () => {
    createWithPersonal([data], data.name);

    onClose();
    onCloseParent();
  };

  const onCallVoice = () => {
    createWithPersonal([data], data.name, { isDemandVoiceCall: true });

    onClose();
    onCloseParent();
  };

  const onCallVideo = () => {
    createWithPersonal([data], data.name, { isDemandVideoCall: true });

    onClose();
    onCloseParent();
  };

  useEffect(() => {
    setIsCheckBlock(accountBlockArray.filter(item => item.contactId === data?.id).length > 0);
  }, [data, accountBlockArray]);

  useEffect(() => {
    if (data !== null && dataAdmin !== undefined) {
      let filterUndefined = dataAdmin.filter(item => item !== undefined);
      let listAdmin = filterUndefined.filter(item => item.id === data.id);
      setIsAdmin(listAdmin.length > 0);
      setIsMe(data.id === accountId);

      const checkingContact = LocalContactService.getContact(accountId, data.id);
      setIsBlocked(checkingContact?.status === SystemConstant.CONTACT_STATUS.block);
    } else {
      setIsAdmin(true);
    }

    getImageSrcAvatar(data?.backgroundId, SystemConstant.GROUP_CHAT_TYPE.account).then(s => {
      if (s !== backSrc) setBackSrc(s);
    });

    getImageSrcAvatar(data?.avatarId, SystemConstant.GROUP_CHAT_TYPE.account).then(s => {
      if (s !== src) setSrc(s);
    });
  }, [data, dataAdmin]);

  useEffect(() => {
    if (blockAccountId) {
      if (isUnblockSuccess) {
        setIsBlocked(true);
      }
      if (isBlockSuccess) {
        setIsBlocked(false);
      }
      dispatch(
        AccountActions.accountSet({
          blockAccountId: null,
        }),
      );
    }
  }, [blockAccountId]);

  return (
    <DrawerLayout
      anchor="right"
      open={Boolean(data)}
      onClose={onClose}
      hideBackdrop={false}
      classes={{
        root: classes.drawerRoot,
      }}
    >
      <Card className={classes.coverBox} elevation={0}>
        {backSrc ? (
          <CardMedia component="img" className={classes.cover} src={backSrc} />
        ) : (
          <Box className={classes.coverEmpty} />
        )}
        <Box className={classes.avatarBox}>
          <Avatar className={classes.avatar} src={src}>
            {data?.name}
          </Avatar>
        </Box>
      </Card>
      <Typography variant="subtitle1" className={classes.textTitle}>
        {data?.name}
      </Typography>
      <Box className={classes.actionContainer}>
        {!isMe && (
          <Box className={classes.girdDetail}>
            <Grid justifyContent="center" alignItems="center" container>
              <Grid item xs={3} className={classes.personalActionButton}>
                <IconButton className={classes.iconBtn} onClick={onCallVoice} disabled={isInCall || !hasInternet}>
                  <Call />
                </IconButton>
                <Typography variant="body2">{getLabel(LangConstant.TXT_VOICE_CALL)}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.personalActionButton}>
                <IconButton className={classes.iconBtn} onClick={onCallVideo} disabled={isInCall || !hasInternet}>
                  <VideoCall />
                </IconButton>
                <Typography variant="body2">{getLabel(LangConstant.TXT_VIDEO_CALL)}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.personalActionButton} onClick={onMessenger}>
                <IconButton className={classes.iconBtn} onClick={onMessenger}>
                  <Message />
                </IconButton>
                <Typography variant="body2">{getLabel(LangConstant.TXT_MESSENGER_MEMBER)}</Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        {isAdminGroup && (
          <Button
            className={clsx(classes.personalActionItem, { [classes.hiddenComponent]: isAdmin })}
            onClick={() => setIsConfirmAdd(true)}
          >
            <Typography className={classes.detailAction}>{getLabel(LangConstant.TXT_ADD_ADMIN_MEMBER)}</Typography>
            <AddAdmin className={classes.icon} />
          </Button>
        )}
        {!isMe && getSavedServer()?.type === SystemConstant.SERVER_TYPE.server && (
          <Box>
            {isCheckBlock || isBlocked ? (
              <Button className={classes.personalActionItem} onClick={() => setIsConfirmUnBlock(true)}>
                <Typography className={classes.detailAction}>{getLabel(LangConstant.TXT_UN_BLOCK)}</Typography>
                <RemoveCircle className={classes.icon} />
              </Button>
            ) : (
              <Button className={classes.personalActionItem} onClick={() => setIsConfirm(true)}>
                <Typography className={classes.detailAction}>{getLabel(LangConstant.TXT_BLOK_MEMBER)}</Typography>
                <RemoveCircle className={classes.icon} />
              </Button>
            )}
          </Box>
        )}
        {isAdminGroup && !isMe && (
          <Button className={classes.personalActionItem} onClick={() => setIsRemove(true)}>
            <Typography className={classes.detailAction}>
              {groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.group
                ? getLabel(LangConstant.TXT_REMOVER_MEMBER)
                : getLabel(LangConstant.TXT_REMOVE_MEMBER_CHANNEL)}
            </Typography>
            <GroupRemove className={classes.icon} />
          </Button>
        )}
      </Box>

      <ConfirmDialog
        open={isConfirmAdd}
        onClose={() => setIsConfirmAdd(false)}
        title={getLabel(LangConstant.TXT_ADD_GROUP_ADMIN)}
        content={confirmDetail}
        submitProps={{
          submitText: contactPhoneContent,
          className: classes.colorButton,
          onClick: onClickAddNew,
        }}
        cancelProps={{
          onClick: () => setIsConfirmAdd(false),
          className: classes.cancel,
        }}
      />
      <ConfirmDialog
        open={isConfirm}
        onClose={() => setIsConfirm(false)}
        title={getLabel(LangConstant.TXT_BLOCK_AND_CALL)}
        content={confirmBlock}
        submitProps={{
          submitText: contactPhoneContent,
          onClick: onBlockDetail,
          className: classes.submitPropsConfirmDialog,
        }}
        cancelProps={{
          className: classes.cancelPropsConfirmDialog,
          onClick: () => setIsConfirm(false),
        }}
      />
      <ConfirmDialog
        open={isConfirmUnBlock}
        onClose={() => setIsConfirmUnBlock(false)}
        title={getLabel(LangConstant.TXT_UN_BLOCK)}
        content={confirmBlock}
        submitProps={{
          className: classes.colorButtonRemove,
          submitText: contactPhoneContent,
          onClick: onUnblockDetail,
        }}
        cancelProps={{
          onClick: () => setIsConfirmUnBlock(false),
          className: classes.cancel,
        }}
      />
      <ConfirmDialog
        open={isRemove}
        onClose={() => setIsRemove(false)}
        title={getLabel(LangConstant.TXT_CONFIRM_REMOVE)}
        content={confirmRemove}
        submitProps={{
          className: classes.colorButtonRemove,
          submitText: getLabel(getCommonKey(LangConstant.TXT_DELETE)),
          onClick: onClickRemove,
        }}
        cancelProps={{
          onClick: () => setIsRemove(false),
          className: classes.cancel,
        }}
      />
    </DrawerLayout>
  );
};

export default memo(MemberDetail);

const useStyles = makeStyles(theme => ({
  textTitle: {
    textAlign: "center",
  },

  girdDetail: {
    textAlign: "center",
    marginTop: 12,
    marginBottom: 15,
  },
  colorButtonRemove: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  colorButton: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.primary.dark,
    },
  },
  cancel: {
    width: "auto",
    color: theme.palette.grey[200],
    "&:hover": {
      backgroundColor: theme.palette.white,
      color: theme.palette.grey[500],
    },
  },

  coverBox: {
    position: "relative",
    marginBottom: 12,
  },

  cover: {
    width: "100%",
    height: 180,
    background: "darkgray",
    marginBottom: 36,
  },

  coverEmpty: {
    width: "100%",
    height: 180,
    marginBottom: 36,
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, darkgray 71.67%, darkgray 100%)",
  },

  avatarBox: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },

  avatar: {
    width: 120,
    height: 120,
    margin: "auto",
    border: "2px solid white",
    fontSize: 48,
  },

  name: {
    fontWeight: 700,
    fontSize: 15,
  },

  drawerRoot: {
    "&>.MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
  },

  detailAction: {
    fontWeight: 400,
  },

  personalActionButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "fit-content",
  },

  personalAction: {
    width: "fit-content",
  },

  personalActionItem: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0 16px",
    justifyContent: "space-between",
    textTransform: "none",
    color: theme.palette.black,
    height: 40,
  },

  icon: {
    width: "unset",
    height: "unset",
  },

  iconPointer: {
    width: "unset",
    height: "unset",
    cursor: "pointer",
    "&:hover circle": {
      fill: "#D7D7D7",
    },
  },

  hiddenComponent: {
    display: "none",
  },

  cancelPropsConfirmDialog: {
    color: theme.palette.grey[200],
    "&:hover": {
      color: theme.palette.grey[500],
    },
  },

  submitPropsConfirmDialog: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
      boxShadow: "none",
    },
  },

  iconBtn: {
    backgroundColor: theme.palette.grey[100],
    marginBottom: 4,
  },
}));
