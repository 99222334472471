import { saveKeysByAccountIds } from "./account-key.saga";
import { KeyConstant } from "const";
import { StorageUtil } from "utils";

export function* synchGroupMemberKey(memberList) {
  if (Array.isArray(memberList) && memberList.length > 0) {
    const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    const listMemberIds = [];
    memberList.forEach(item => {
      if (item.account_id !== currentAccountId) {
        listMemberIds.push(item.account_id);
      }
    });

    if (Array.isArray(listMemberIds) && listMemberIds.length > 0) {
      yield saveKeysByAccountIds(listMemberIds);
    }
  }

  return true;
}
