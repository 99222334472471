import { Box, Button, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import MessageThreadItem from "./MessageThreadItem";
import { useEffect, useState } from "react";
import { LangConstant, SystemConstant } from "const";
import PeopleIcon from "@mui/icons-material/People";
import { useTranslation } from "react-i18next";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch } from "react-redux";
import { useConversationList } from "hooks";
import { ConversationActions } from "redux-store";
import { checkOnlineStatus } from "utils/common.utils";

const MessageThread = ({
  threadId,
  parentMessage,
  newestMessage,
  secondMessage,
  totalReply,
  groupId,
  senderParentMsg,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { channelList, groupList, personalMsgList } = useConversationList();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);

  let checkOnlineInterval = null;

  const [group, setGroup] = useState({});
  const [isOnline, setIsOnline] = useState(false);

  const onReply = () => {
    // Set selected group
    dispatch(
      ConversationActions.setSelectGroupId({
        selectedGroupId: group.id,
      }),
    );

    // TODO: Need to refactor
    // Set selected thread
    setTimeout(() => {
      dispatch(
        ConversationActions.conversationSet({
          threadingId: threadId,
        }),
      );
    }, 1000);
  };

  useEffect(() => {
    const allGroup = [...channelList, ...groupList, ...personalMsgList];
    if (groupId && allGroup) {
      const group = allGroup.filter(item => item.id === groupId)[0] || {};

      setGroup(group);
    }
  }, [channelList, groupList, personalMsgList]);

  useEffect(() => {
    if (group.groupType === SystemConstant.GROUP_CHAT_TYPE.personal && senderParentMsg) {
      checkOnlineInterval = setInterval(() => {
        setIsOnline(Boolean(checkOnlineStatus(senderParentMsg.id)));
      }, 1000);
    }

    return () => {
      clearInterval(checkOnlineInterval);
    };
  }, [threadId, senderParentMsg, group]);

  return (
    <Box className={classes.rootSpace}>
      <Box className={clsx(classes.threadBox)} onClick={onReply}>
        <Box className={classes.threadTitle}>
          {group.groupType === SystemConstant.GROUP_CHAT_TYPE.personal ? (
            <>
              <Box className={isOnline ? classes.activeState : classes.inactiveStatus} />
              <Typography>{senderParentMsg.name}</Typography>
            </>
          ) : group.groupType === SystemConstant.GROUP_CHAT_TYPE.group ? (
            <>
              <PeopleIcon className={classes.icon} />
              <Typography>{group.name}</Typography>
            </>
          ) : (
            <Box className={classes.threadHeader}>
              {group.privateF ? <LockIcon className={classes.icon} /> : <Grid3x3Icon className={classes.icon} />}
              <Typography>{group.name}</Typography>
            </Box>
          )}
        </Box>

        <MessageThreadItem data={{ ...parentMessage, groupMembers: group.members }} />
        <Box className={clsx(classes.divider, classes.rootSpace)}>
          <Typography className={classes.dividerText}>
            {getLabel(LangConstant.FM_NUMBER_THREAD_REPLY, { number: totalReply })}
          </Typography>
          <Divider variant="inset" />
        </Box>
        {secondMessage && <MessageThreadItem data={{ ...secondMessage, groupMembers: group.members }} />}
        <MessageThreadItem data={{ ...newestMessage, groupMembers: group.members }} />

        <Box className={clsx(classes.replyBox, classes.rootSpace)}>
          <Button
            variant="contained"
            className={classes.replyButton}
            classes={{ root: classes.buttonRoot }}
            onClick={onReply}
            title={getLabel(LangConstant.TXT_REPLY_THREAD_MESSAGE)}
          >
            {getLabel(LangConstant.TXT_REPLY)}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MessageThread;

const useStyles = makeStyles(() => ({
  rootSpace: {
    paddingLeft: 16,
    paddingRight: 16,
  },

  threadBox: {
    border: "solid",
    borderColor: "#e7e7e7",
    height: "fit-content",
    borderWidth: "thin",
    borderRadius: 5,
    width: "100%",
    marginBottom: 20,
  },

  threadTitle: {
    display: "flex",
    alignItems: "center",
    padding: 10,
  },

  activeState: {
    width: 10,
    height: 10,
    backgroundColor: "#0bd50b",
    borderRadius: "50%",
    marginRight: 10,
  },

  divider: {
    marginBottom: 10,
  },

  dividerText: {
    color: "#7F7F80",
    fontSize: 14,
  },

  replyBox: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 10,
  },

  buttonRoot: {
    textTransform: "none",
  },

  threadHeader: {
    display: "flex",
    alignItems: "center",
  },

  icon: {
    marginRight: 10,
    color: "gray",
  },

  inactiveStatus: {
    width: 10,
    height: 10,
    backgroundColor: "gray",
    borderRadius: "50%",
    marginRight: 10,
  },
}));
