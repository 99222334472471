import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Avatar, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { SystemConstant } from "const";
import { getImageSrcAvatar } from "utils";

const AvatarConversation = ({ memberArray = [], classes }) => {
  const defaultClasses = useStyles();

  const isMultiple = memberArray?.length > 2;
  const isDouble = memberArray?.length === 2;

  return (
    <Box className={defaultClasses.avatarRoot}>
      {memberArray.length > 0 &&
        (isMultiple ? (
          <MultipleAvatar members={memberArray} classes={classes} />
        ) : isDouble ? (
          <DoubleAvatar members={memberArray} classes={classes} />
        ) : (
          <SingleAvatar member={memberArray[0]} />
        ))}
    </Box>
  );
};

AvatarConversation.propTypes = {
  memberArray: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.string,
    }),
  ),
};

const SingleAvatar = ({ member }) => {
  const classes = useStyles();
  const [avatarSrc, setAvatarSrc] = useState("");

  useEffect(() => {
    let isMounted = true;

    if (member?.avatarId) {
      getImageSrcAvatar(member.avatarId, SystemConstant.GROUP_CHAT_TYPE.personal).then(src => {
        if (src !== avatarSrc && isMounted) {
          setAvatarSrc(src);
        }
      });
    } else {
      setAvatarSrc("");
    }

    return () => {
      isMounted = false;
    };
  }, [member?.avatarId]);

  return (
    <Avatar alt={member?.name} src={avatarSrc} className={classes.avatar}>
      {member?.name?.charAt(0)}
    </Avatar>
  );
};

const DoubleAvatar = ({ members, classes }) => {
  const defaultClasses = useStyles();

  const firstMember = members[0];
  const secondMember = members[1];

  const [firstMemberAvatar, setFirstMemberAvatar] = useState("");
  const [secondMemberAvatar, setSecondMemberAvatar] = useState("");

  useEffect(() => {
    let isMounted = true;

    if (firstMember?.avatarId) {
      getImageSrcAvatar(firstMember.avatarId, SystemConstant.GROUP_CHAT_TYPE.personal).then(src => {
        if (src !== firstMemberAvatar && isMounted) setFirstMemberAvatar(src);
      });
    } else {
      setFirstMemberAvatar("");
    }

    return () => {
      isMounted = false;
    };
  }, [firstMember?.avatarId]);

  useEffect(() => {
    let isMounted = true;

    if (secondMember?.avatarId) {
      getImageSrcAvatar(secondMember.avatarId, SystemConstant.GROUP_CHAT_TYPE.personal).then(src => {
        if (src !== firstMemberAvatar && isMounted) setSecondMemberAvatar(src);
      });
    } else {
      setSecondMemberAvatar("");
    }

    return () => {
      isMounted = false;
    };
  }, [secondMember?.avatarId]);

  return (
    <Box className={defaultClasses.doubleAvtBox}>
      <Avatar
        alt={firstMember?.name}
        src={firstMemberAvatar}
        className={clsx(
          defaultClasses.firstAvatarDouble,
          defaultClasses.multipleAvt,
          classes?.doubleAvt,
          "regular-sm-txt",
        )}
      >
        {firstMember?.name?.charAt(0)}
      </Avatar>
      <Avatar
        alt={secondMember?.name}
        src={secondMemberAvatar}
        className={clsx(
          defaultClasses.secondAvatarDouble,
          defaultClasses.multipleAvt,
          classes?.doubleAvt,
          "regular-sm-txt",
        )}
      >
        {secondMember?.name?.charAt(0)}
      </Avatar>
    </Box>
  );
};

const MultipleAvatar = ({ members, classes }) => {
  const defaultClasses = useStyles();

  const firstMember = members[0];
  const secondMember = members[1];
  const lastMember = members[members.length - 1];

  const [firstMemberAvatar, setFirstMemberAvatar] = useState("");
  const [secondMemberAvatar, setSecondMemberAvatar] = useState("");
  const [lastMemberAvatar, setLastMemberAvatar] = useState("");

  useEffect(() => {
    let isMounted = true;

    if (firstMember?.avatarId) {
      getImageSrcAvatar(firstMember.avatarId, SystemConstant.GROUP_CHAT_TYPE.personal).then(src => {
        if (src !== firstMemberAvatar && isMounted) setFirstMemberAvatar(src);
      });
    } else {
      setFirstMemberAvatar("");
    }

    return () => {
      isMounted = false;
    };
  }, [firstMember?.avatarId]);

  useEffect(() => {
    let isMounted = true;

    if (secondMember?.avatarId) {
      getImageSrcAvatar(secondMember.avatarId, SystemConstant.GROUP_CHAT_TYPE.personal).then(src => {
        if (src !== secondMemberAvatar && isMounted) setSecondMemberAvatar(src);
      });
    } else {
      setSecondMemberAvatar("");
    }

    return () => {
      isMounted = false;
    };
  }, [secondMember?.avatarId]);

  useEffect(() => {
    let isMounted = true;

    if (lastMember?.avatarId) {
      getImageSrcAvatar(lastMember.avatarId, SystemConstant.GROUP_CHAT_TYPE.personal).then(src => {
        if (src !== lastMemberAvatar && isMounted) setLastMemberAvatar(src);
      });
    } else {
      setLastMemberAvatar("");
    }

    return () => {
      isMounted = false;
    };
  }, [lastMember?.avatarId]);

  let isOverThreeMembers = members.length > 3;

  return (
    <Box className={defaultClasses.groupAvatarBox}>
      <Avatar
        alt={firstMember?.name}
        src={firstMemberAvatar}
        className={clsx(
          defaultClasses.firstAvatarGroup,
          defaultClasses.multipleAvt,
          classes?.individualAvt,
          "regular-sm-txt",
        )}
      >
        {firstMember?.name?.charAt(0)}
      </Avatar>
      <Avatar
        alt={secondMember?.name}
        src={secondMemberAvatar}
        className={clsx(
          defaultClasses.secondAvatarGroup,
          defaultClasses.multipleAvt,
          classes?.individualAvt,
          "regular-sm-txt",
        )}
      >
        {secondMember?.name?.charAt(0)}
      </Avatar>
      <Avatar
        alt={lastMember?.name}
        src={isOverThreeMembers ? null : lastMemberAvatar}
        className={clsx(
          defaultClasses.lastAvatarGroup,
          defaultClasses.multipleAvt,
          classes?.individualAvt,
          "regular-sm-txt",
        )}
      >
        {isOverThreeMembers ? `+${members.length - 2}` : lastMember?.name?.charAt(0)}
      </Avatar>
    </Box>
  );
};

export default AvatarConversation;

const useStyles = makeStyles(() => ({
  avatarRoot: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },

  avatar: {
    width: "100%",
    height: "100%",
  },

  groupAvatarBox: {
    position: "relative",
    width: "100%",
    height: "100%",
  },

  firstAvatarGroup: {
    position: "absolute",
    top: 0,
    marginLeft: " auto",
    marginRight: "auto",
    left: 0,
    right: 0,
  },

  secondAvatarGroup: {
    position: "absolute",
    bottom: 0,
    left: 0,
  },

  lastAvatarGroup: {
    position: "absolute",
    bottom: 0,
    right: 0,
    lineHeight: "16px",
  },

  doubleAvtBox: {
    position: "relative",
    width: "100%",
    height: "100%",
  },

  firstAvatarDouble: {
    position: "absolute",
    marginBottom: "auto",
    marginTop: "auto",
    left: 0,
    top: 0,
    bottom: 0,
  },

  secondAvatarDouble: {
    position: "absolute",
    marginBottom: "auto",
    marginTop: "auto",
    right: 0,
    top: 0,
    bottom: 0,
  },

  multipleAvt: {
    width: 28,
    height: 28,
  },
}));
