import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Box, Button, CardMedia, OutlinedInput, Typography, Container } from "@mui/material";
import clsx from "clsx";
import { getCommonKey } from "utils/lang.utils";
import { LangConstant, PathConstant, ImageConstant } from "const";
import { ProfileActions } from "redux-store";

const CreateName = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_LOGIN);

  const isUpdateUserInfoSuccess = useSelector(state => state.authRedux.isUpdateUserInfoSuccess);

  const [name, setName] = useState("");
  const [isEnableContinueButton, setIsEnableContinueButton] = useState(false);

  const onChangeSetName = event => {
    let newName = event.target.value;
    setName(newName);
    setIsEnableContinueButton(Boolean(newName !== ""));
  };

  const handleSubmit = event => {
    event.preventDefault();
    dispatch(
      ProfileActions.updateProfileInfo({
        name: name,
      }),
    );

    onGotoHome();
  };

  const onGotoHome = () => {
    history.replace(PathConstant.ROOT);
  };

  useEffect(() => {
    if (isUpdateUserInfoSuccess) {
      onGotoHome();
    }
  }, [isUpdateUserInfoSuccess]);

  return (
    <Container
      maxWidth="xl"
      classes={{
        root: classes.container,
        maxWidthXl: classes.container,
      }}
    >
      <Box className={classes.root}>
        <Box className={classes.nameMainForm}>
          <form onSubmit={handleSubmit}>
            <CardMedia component="img" src={ImageConstant.LogoImage} className={classes.logoImg} />
            <Typography variant="subtitle1" className={classes.nameTitle}>
              {getLabel(LangConstant.TXT_NAME_TITLE)}
            </Typography>

            <Box className={classes.nameContainer}>
              <OutlinedInput
                classes={{
                  root: classes.nameInputRoot,
                  notchedOutline: classes.notchedOutline,
                  focused: classes.notchedOutline,
                  input: clsx(classes.nameInput, "regular-md-txt"),
                }}
                value={name}
                onChange={onChangeSetName}
              />
            </Box>

            <Button
              type="submit"
              variant="contained"
              className={clsx("semiBold-lg-txt", classes.continueButton)}
              disabled={!isEnableContinueButton}
              classes={{ disabled: classes.disabledButton }}
            >
              {getLabel(getCommonKey(LangConstant.TXT_CONTINUE))}
            </Button>
          </form>
        </Box>
        <Box className={classes.imageContainer}>
          <CardMedia component="img" src={ImageConstant.LoginWelcomeImage} className={classes.loginWelcomeImage} />
        </Box>
      </Box>
    </Container>
  );
};

export default CreateName;

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%",
    padding: 0,
  },

  root: {
    display: "flex",
    height: "100%",
  },

  imageContainer: {
    width: "70%",
    backgroundColor: "#FAFAFA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  loginWelcomeImage: {
    width: "80%",
  },

  nameMainForm: {
    backgroundColor: theme.palette.white,
    width: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0 80px",
    [theme.breakpoints.down(1350)]: {
      padding: "0 50px",
    },
  },

  logoImg: {
    marginBottom: 15,
    maxWidth: 75,
  },

  nameInputRoot: {},

  nameContainer: {
    display: "flex",
    padding: "10px 0",
    backgroundColor: "#F7F7F8",
    height: 50,
    borderRadius: 10,
    width: "100%",
  },

  notchedOutline: {
    borderWidth: 0 + " !important",
  },

  nameInput: {
    color: "#030229",
    height: 30,
    padding: 10,
    MozAppearance: "textfield",

    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },

    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },

  nameTitle: {
    fontWeight: 700,
    textAlign: "center",
    lineHeight: "20px",
    width: "100%",
    marginBottom: 15,
  },

  disabledButton: {
    backgroundColor: "#72B9E6 !important",
    color: theme.palette.white + "!important",
  },

  continueButton: {
    marginTop: 15,
    width: "100%",
    padding: "14px 0",
  },
}));
