import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "@mui/material";
import PublicChanelAva from "../public.png";
import PrivateChanelAva from "../private.png";

const AvatarChannel = ({ data, ...otherProps }) => {
  const isPrivate = Boolean(data?.privateF);
  const firstLetter = data?.name?.charAt(0) || "";

  return (
    <Avatar
      alt={data?.name}
      src={isPrivate ? PrivateChanelAva : PublicChanelAva}
      sx={{ width: 50, height: 50, bgcolor: "#BEBEBE" }}
      imgProps={{
        sx: {
          objectFit: "scale-down",
        },
      }}
      {...otherProps}
    >
      {firstLetter}
    </Avatar>
  );
};

AvatarChannel.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    privateF: PropTypes.number,
  }),
};

export default AvatarChannel;
