import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Popover } from "@mui/material";
import { formatEmoij } from "utils";
import { AppConstant, KeyConstant } from "const";
import { Add } from "@mui/icons-material";
import { StorageUtil } from "utils";

const EmojiMenu = props => {
  const { onOpenFullEmojiMenu, onSelectEmoji, onClose, anchorEl, open, ...otherProps } = props;
  const classes = useStyles();
  const localEmoji = StorageUtil.getItem(KeyConstant.KEY_EMOJI_STATIC_LIST);
  const defaultEmoji = AppConstant.DEFAULT_EMOJI;

  const [staticEmoji, setStaticEmoji] = useState([]);

  const onSelect = value => {
    onSelectEmoji(value);
  };

  useEffect(() => {
    if (localEmoji) {
      setStaticEmoji(localEmoji);
    } else {
      setStaticEmoji(defaultEmoji);
      StorageUtil.setItem(KeyConstant.KEY_EMOJI_STATIC_LIST, defaultEmoji);
    }
  }, []);

  useEffect(() => {
    let onStorageChange = () => {
      let newEmojiList = StorageUtil.getItem(KeyConstant.KEY_EMOJI_STATIC_LIST);
      setStaticEmoji(newEmojiList);
    };

    window.addEventListener("storage", onStorageChange);

    return () => {
      window.removeEventListener("storage", onStorageChange);
    };
  });

  return (
    <Popover
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      onClose={onClose}
      anchorEl={anchorEl}
      open={open}
      classes={{ paper: classes.paper }}
      {...otherProps}
    >
      <Box className={classes.root}>
        <Box className={classes.staticEmojiWrapper}>
          {staticEmoji.map((emoji, index) => (
            <Box
              key={index}
              className={classes.staticEmojiItem}
              dangerouslySetInnerHTML={{ __html: formatEmoij(emoji) }}
              onClick={() => onSelect(emoji)}
            />
          ))}
          <Box className={classes.anotherEmoji}>
            <Add className={classes.anotherEmojiButton} onClick={onOpenFullEmojiMenu} />
          </Box>
        </Box>
      </Box>
    </Popover>
  );
};

export default EmojiMenu;

EmojiMenu.propTypes = {
  onOpenFullEmojiMenu: PropTypes.func,
  onSelectEmoji: PropTypes.func,
  onClose: PropTypes.func,

  open: PropTypes.bool,
};

EmojiMenu.defaultProps = {
  onOpenFullEmojiMenu: () => {},
  onSelectEmoji: () => {},
  onClose: () => {},

  open: false,
};

const useStyles = makeStyles(theme => ({
  paper: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },

  root: {
    marginBottom: 12,
    marginRight: 12,
    backgroundColor: theme.palette.white,
    borderRadius: 50,
    boxShadow: "4px 4px 7px rgba(0, 0, 0, 0.1)",
  },

  staticEmojiWrapper: {
    display: "flex",
  },

  staticEmojiItem: {
    width: 30,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 12,
    fontSize: 30,
    cursor: "pointer",
    userSelect: "none",
  },

  anotherEmoji: {
    width: 25,
    height: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 12,
    cursor: "pointer",
    backgroundColor: theme.palette.grey[200],
    borderRadius: "50%",
  },

  anotherEmojiButton: {
    color: theme.palette.black,
  },
}));
