import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const OTPTitle = ({ title, content }) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="subtitle1" className={classes.otpTitle}>
        {title}
      </Typography>
      <Typography className={classes.otpContent}>{content}</Typography>
    </Box>
  );
};

OTPTitle.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};

OTPTitle.defaultProps = {};

export default OTPTitle;

const useStyles = makeStyles(() => ({
  otpTitle: {
    fontWeight: 700,
    textAlign: "center",
    lineHeight: "20px",
    width: "100%",
  },

  otpContent: {
    fontSize: "15px",
    fontWeight: 400,
    textAlign: "center",
    marginBottom: 26,
    marginTop: 8,
    width: "100%",
  },
}));
