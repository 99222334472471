import { useCallback, useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, IconButton, InputAdornment, Menu, MenuItem, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { getNSKey } from "utils/lang.utils";
import { KeyConstant, LangConstant } from "const";
import { ContentPaste, InsertEmoticon } from "@mui/icons-material";
import { SendInputIcon } from "icons";
import { Attachment, KeyboardVoice } from "@mui/icons-material";
import ListPeople from "./ListPeople";
import PopupFile from "./PopupFile";
import { FullEmojiMenu } from "components";
import {
  convertHexToEmoji,
  getAllLetterIndex,
  getCaretPosition,
  removeDuplicateInArray,
  replaceId2Name,
  replaceName2Id,
  textNormalize,
} from "utils";
import { debounce } from "lodash";
import { ConversationContext } from ".";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { StorageUtil } from "utils";

const MessengerChatInput = ({
  customStyle,
  tagListStyle,
  placeholder,
  onSendMessage,
  onSetMessageContent,
  isAllowAttach = true,
  onFileChange,
}) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const autoFocusRef = useRef();
  const tmpMentionArr = useRef([]);
  const { groupDetail } = useContext(ConversationContext);

  const selectId = useSelector(state => state.conversationRedux.selectedGroupId);

  const [isShowTagList, setIsShowTagList] = useState(false);
  const [filteredGroupMembers, setFilteredGroupMembers] = useState([]);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [attachmentAnchorEl, setAttachmentAnchorEl] = useState(null);
  const [emojiAnchorEl, setEmojiAnchorEl] = useState(null);

  const handleResetInput = () => {
    autoFocusRef.current.value = "";
    tmpMentionArr.current = [];
    setIsShowTagList(false);
    setFilteredGroupMembers([]);
    setMenuAnchorEl(null);
    setAttachmentAnchorEl(null);
    setEmojiAnchorEl(null);
  };

  const getLastWord = inputString => {
    const wordsArray = inputString.split(" ");
    return wordsArray[wordsArray.length - 1];
  };

  const handleChangeContent = event => {
    const caret = getCaretPosition(autoFocusRef.current);
    const value = event.target.value;
    if (getLastWord(value).includes("@")) {
      const mentionIndexes = getAllLetterIndex(value, "@");
      const currentIndexes = mentionIndexes.filter(item => item < caret);
      if (caret > 0 && value[caret - 1] !== " " && currentIndexes.length > 0) {
        const nearestIndex = Math.max(...currentIndexes);

        const value2Search = value.slice(nearestIndex + 1, caret);

        let filteredArray = getFilterArray(groupDetail?.groupMembers, value2Search);
        if (tmpMentionArr.current.length > 0) {
          filteredArray = filteredArray.filter(item => !tmpMentionArr.current.includes(item.id));
        }

        setFilteredGroupMembers(filteredArray);
        setIsShowTagList(true);
      }
    } else {
      setIsShowTagList(false);
    }

    const contentMention = replaceName2Id(value, groupDetail?.groupMembers);

    const mentionArr = groupDetail?.groupMembers.reduce((results, member) => {
      const mention = "@{" + member.id + "}";
      if (contentMention.includes(mention)) {
        results = [...results, member.id];
      }
      return results;
    }, []);

    tmpMentionArr.current = removeDuplicateInArray(mentionArr);

    onSetMessageContent(value);
  };

  const handleCloseListPeople = () => {
    setIsShowTagList(false);
  };

  const handleSelectTag = person => {
    tmpMentionArr.current = [...tmpMentionArr.current, person.id];
    let value = autoFocusRef?.current?.value.slice(0);

    let mentionIndexes = getAllLetterIndex(value, "@");
    let caret = getCaretPosition(autoFocusRef.current);
    let currentIndexes = mentionIndexes.filter(item => item < caret);
    let nearestIndex = Math.max(...currentIndexes);

    let value2Send =
      value.slice(0, nearestIndex) + "@" + person.name + value.slice(caret, value[value.length - 1]) + " ";

    value2Send = replaceId2Name(value2Send, groupDetail.groupMembers);
    if (autoFocusRef && autoFocusRef.current) {
      autoFocusRef.current.value = value2Send;
      autoFocusRef.current.focus();
    }
    onSetMessageContent(value2Send);
    handleCloseListPeople();
  };

  const handleKeyDown = async event => {
    if (event.keyCode === 32) {
      handleCloseListPeople();
    } else if (event.keyCode === 13) {
      if (event.shiftKey) {
        if (event.type === "keypress" && autoFocusRef && autoFocusRef.current) {
          autoFocusRef.current.value = autoFocusRef.current.value + "\n";
        }
      } else if (autoFocusRef?.current?.value) {
        event.preventDefault();
        onSendMessage();
        handleResetInput();
      }
    }

    autoFocusRef?.current?.focus();
  };

  const handleDelayContent = useCallback(
    debounce(params => handleChangeContent(params), 100),
    [groupDetail?.groupMembers],
  );

  const handleAttachmentAnchor = event => {
    setAttachmentAnchorEl(event.currentTarget);
  };

  const handleCloseAttachmentAnchor = () => {
    setAttachmentAnchorEl(null);
  };

  const handleFileChange = event => {
    let file = event.target.files[0];

    if (file) {
      onFileChange(file);
    }
    setAttachmentAnchorEl(null);
  };

  const handleEmojiAnchor = event => {
    setEmojiAnchorEl(event.currentTarget);
    autoFocusRef?.current?.focus();
  };

  const handleSelectEmoji = value => {
    setEmojiAnchorEl(null);
    if (autoFocusRef && autoFocusRef.current) {
      autoFocusRef.current.value = autoFocusRef.current.value + convertHexToEmoji(value);
      onSetMessageContent(autoFocusRef.current.value);
      autoFocusRef.current.focus();
    }
  };

  const handleCloseEmoji = () => {
    setEmojiAnchorEl(null);
  };

  const handleButtonSend = event => {
    event.preventDefault();
    onSendMessage();
    handleResetInput();
  };

  const onPaste = async () => {
    const currentVal = autoFocusRef?.current?.value;
    const clipboardVal = await navigator.clipboard.readText();
    const newVal = currentVal + clipboardVal;
    onSetMessageContent(newVal);
    if (autoFocusRef && autoFocusRef.current) {
      autoFocusRef.current.value = newVal;
      autoFocusRef.current.focus();
    }
    setMenuAnchorEl(null);
  };

  useEffect(() => {
    handleResetInput();
  }, [selectId]);

  return (
    <Box className={clsx(classes.inputType, customStyle)}>
      {filteredGroupMembers.length > 0 && (
        <Box className={classes.tagListContainer}>
          <ListPeople
            onClose={handleCloseListPeople}
            isShow={isShowTagList}
            data={filteredGroupMembers}
            offsetWidth={0}
            tagListStyle={tagListStyle}
            onClick={handleSelectTag}
          />
        </Box>
      )}
      <TextField
        autoFocus
        multiline
        minRows={1}
        maxRows={5}
        onKeyDown={handleKeyDown}
        className={classes.input}
        onChange={handleDelayContent}
        placeholder={placeholder}
        inputRef={autoFocusRef}
        InputProps={{
          onContextMenu: () => {
            setMenuAnchorEl(autoFocusRef.current);
          },
          classes: {
            notchedOutline: classes.noBorder,
          },
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={handleEmojiAnchor}>
                <InsertEmoticon color="action" />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {isAllowAttach ? (
                Boolean(autoFocusRef?.current?.value) ? (
                  <IconButton onClick={handleButtonSend}>
                    <SendInputIcon />
                  </IconButton>
                ) : (
                  <Box>
                    <IconButton className={classes.iconHook} onClick={handleAttachmentAnchor}>
                      <Attachment variant="contained" color="action" />
                    </IconButton>
                    <IconButton className={classes.hiddenVoice}>
                      <KeyboardVoice color="action" />
                    </IconButton>
                  </Box>
                )
              ) : (
                <IconButton onClick={handleButtonSend}>
                  <SendInputIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />

      <PopupFile anchorEl={attachmentAnchorEl} onClose={handleCloseAttachmentAnchor} onFileChange={handleFileChange} />
      <FullEmojiMenu
        open={Boolean(emojiAnchorEl)}
        onClose={handleCloseEmoji}
        anchorEl={emojiAnchorEl}
        onSelectEmoji={handleSelectEmoji}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      />

      <Menu
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        PaperProps={{
          elevation: 0,
        }}
        classes={{
          root: classes.chatMenuRoot,
          paper: classes.chatMenuPaper,
          list: classes.chatMenuList,
        }}
      >
        <MenuItem className={classes.menuItem} onClick={onPaste}>
          <ContentPaste /> {getLabel(getNSKey(LangConstant.NS_HOME_CONVERSATION, LangConstant.TXT_PASTE))}
        </MenuItem>
      </Menu>
    </Box>
  );
};

MessengerChatInput.propTypes = {
  placeholder: PropTypes.string,
  isAllowAttach: PropTypes.bool,

  onSendMessage: PropTypes.func,
  onSetMessageContent: PropTypes.func,
  onFileChange: PropTypes.func,
};

MessengerChatInput.defaultProps = {
  placeholder: "",
  isAllowAttach: true,

  onSendMessage: () => {},
  onSetMessageContent: () => {},
  onFileChange: () => {},
};

export default MessengerChatInput;

const getFilterArray = (memberArray, search) => {
  let filterItems = [];
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  if (memberArray && memberArray.length > 0) {
    filterItems = memberArray.filter(
      member => member.id !== accountId && textNormalize(member.name).includes(textNormalize(search)),
    );
  }
  return filterItems;
};

const useStyles = makeStyles(theme => ({
  noBorder: {
    border: "none",
  },

  inputType: {
    padding: "0 22px",
    paddingBottom: 22,
    width: "100%",
    position: "relative",
    backgroundColor: theme.palette.white,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },

  input: {
    width: "100%",
    backgroundColor: "#F3F3F5",
    borderRadius: 10,
    zIndex: 10,
  },

  iconHook: {
    cursor: "pointer",
    transform: "rotate(-45deg)",
  },

  hiddenVoice: {
    display: "none",
  },

  tagListContainer: {
    width: "100%",
    position: "absolute",
    bottom: "calc(100% - 4px)",
    left: "50%",
    transform: "translate(-50%)",
    backgroundColor: theme.palette.white,
    zIndex: 1,
  },

  chatMenuPaper: {
    borderRadius: 20,
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      bottom: -6,
      left: "50%",
      width: 20,
      height: 20,
      backgroundColor: theme.palette.white,
      transform: "translateX(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },

  chatMenuList: {
    padding: 0,
    borderRadius: 20,
  },

  menuItem: {
    borderRadius: 20,
    padding: "10px 14px",
    "&::hover": {
      backgroundColor: "#F2F2F2",
    },

    "&>svg": {
      marginRight: 10,
    },

    fontSize: 15,
    fontWeight: 400,
    lineHeight: "20px",
    color: "#1E272E",
  },
}));
