import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, CardMedia, Radio, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DrawerLayout, LazyLoader } from "components";
import { ImageConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { useDeviceList } from "hooks";
import { useTranslation } from "react-i18next";
import { getNSKey } from "utils/lang.utils";
import { DeviceActions, RestoreActions } from "redux-store";
import { handlingLogout } from "utils";
import DeviceItem from "./DeviceItem";
import OTPChangeRole from "../OTPChangeRole";
import PropTypes from "prop-types";
import { StorageUtil } from "utils";

const ChooseDeviceDrawer = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation();
  const { slaveDeviceList } = useDeviceList();

  const deviceObjContent = getLabel(getNSKey(LangConstant.NS_HOME, LangConstant.OBJ_ACCOUNT_DEVICE), {
    returnObjects: true,
  });
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);

  const changeRoleStatus = useSelector(state => state.restoreRedux.changeRoleStatus);
  const loggedOutDevice = useSelector(state => state.deviceRedux.loggedOutDevice);

  const [selectedDevice, setSelectedDevice] = useState(null);
  const [isOpenOtp, setIsOpenOtp] = useState(false);
  const [errorContent, setErrorContent] = useState(null);

  const handleChangeDeviceRole = () => {
    dispatch(RestoreActions.changeDeviceRole());
  };

  const handleLogout = () => {
    dispatch(
      DeviceActions.deviceLogout({
        slaveDeviceId: [deviceId],
      }),
    );
  };

  const handleCancel = () => {
    dispatch(
      RestoreActions.restoreSet({
        changeRoleStatus: SystemConstant.REDUX_LIFECYCLE.notStart,
      }),
    );
  };

  useEffect(() => {
    if (changeRoleStatus === SystemConstant.REDUX_LIFECYCLE.success) {
      setErrorContent(null);
      setIsOpenOtp(true);
    } else if (changeRoleStatus === SystemConstant.REDUX_LIFECYCLE.fail) {
      setErrorContent(getLabel(LangConstant.TXT_SERVER_ERROR));
    } else {
      setErrorContent(null);
    }
  }, [changeRoleStatus]);

  useEffect(() => {
    if (loggedOutDevice && Array.isArray(loggedOutDevice) && loggedOutDevice.includes(deviceId)) {
      handlingLogout();
    }
  }, [loggedOutDevice]);

  return (
    <>
      <DrawerLayout
        open={open}
        title={getLabel(LangConstant.TXT_CHOOSE_DEVICE)}
        onClose={onClose}
        classes={{ wrapper: classes.drawerWrapper }}
      >
        <CardMedia
          component="img"
          alt="your device"
          src={ImageConstant.YourDeviceImage}
          className={classes.yourDeviceImg}
        />
        <Typography variant="body2" className={classes.deviceMode}>
          {deviceObjContent.otherDevice}
        </Typography>
        <Box className={classes.deviceList}>
          {slaveDeviceList.map((device, index) => (
            <LazyLoader key={index}>
              <DeviceItem
                data={device}
                hasDivider={Boolean(index + 1 < slaveDeviceList.length)}
                onClick={setSelectedDevice}
                control={<Radio checked={selectedDevice?.id === device.id} />}
              />
            </LazyLoader>
          ))}
        </Box>
        <Box className={classes.btnBox}>
          <Box className={classes.errorBox}>
            <Typography className={Boolean(errorContent) ? classes.errorText : "hidden"}>{errorContent}</Typography>
          </Box>
          <Button
            fullWidth
            variant="contained"
            className={classes.btn}
            onClick={handleChangeDeviceRole}
            disabled={!Boolean(selectedDevice)}
          >
            {getLabel(LangConstant.TXT_CHOOSE_DEVICE_CONFIRM)}
          </Button>
        </Box>
      </DrawerLayout>

      {isOpenOtp && Boolean(selectedDevice) && (
        <OTPChangeRole
          open
          onClose={() => {
            setIsOpenOtp(false);
            handleCancel();
          }}
          slaveDeviceId={selectedDevice.id}
          onResendOtp={() => {
            dispatch(RestoreActions.changeDeviceRole());
          }}
          onLogout={handleLogout}
        />
      )}
    </>
  );
};

ChooseDeviceDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ChooseDeviceDrawer;

const useStyles = makeStyles(theme => ({
  yourDeviceImg: {
    height: 140,
    objectFit: "contain",
    marginTop: 30,
  },

  deviceMode: {
    fontWeight: 600,
    color: theme.palette.text.secondary,
    padding: theme.spacing(0, 2),
    height: 36,
    lineHeight: "36px",
  },

  deviceList: {
    height: "calc(100% - 170px - 36px - 96px)",
    overflowY: "auto",
  },

  btnBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "10px 16px",
    flexDirection: "column",
  },

  btn: {
    backgroundColor: "#FF2C28",
    minHeight: 48,
    "&:hover": {
      backgroundColor: "#C00400",
    },
  },

  errorBox: {
    height: 24,
  },

  errorText: {
    textAlign: "center",
    color: "red",
    paddingBottom: 4,
    lineHeight: "24px",
  },

  drawerWrapper: {
    height: "100%",
  },
}));
