import { ApiConstant, AppConstant, KeyConstant, SystemConstant } from "const";
import { call, delay, put, select } from "redux-saga/effects";
import { MessageService } from "services";
import { convertJSONObject, isJSONString, toCamel, toSnake, uuid } from "utils";
import { getSavedServer } from "../utils/common.utils";
import {
  LocalApiCallService,
  LocalCipherService,
  LocalDeviceService,
  LocalGroupService,
  LocalMessageService,
  LocalSenderKeySharedService,
} from "services/local.service";
import { getGroupMessage } from "./synch-message.saga";
import { ARR_CALLING_TYPES, MSG_SEND_TYPE } from "./saga.helper";
import { CallingActions, ConversationActions } from "redux-store";
import { saveKeysByAccountIds } from "./account-key.saga";
import { updateThread } from "./thread.saga";
import { StorageUtil } from "utils";

export function* fetchLastMessage(action) {
  let isSynchronizing = yield select(state => state.conversationRedux.isSynchronizing);

  const { data } = action;

  // Pending util isSynchronizing = false
  while (isSynchronizing) {
    yield delay(100);
    isSynchronizing = yield select(state => state.conversationRedux.isSynchronizing);
  }
  yield getGroupMessage({
    data: {
      groupId: null,
      sinceTime: 0,
      isFetchFromCalling: Boolean(data?.isFetchFromCalling),
      isFetchFromSynchronizing: false,
    },
  });

  try {
    yield put(CallingActions.getCallHistory());
  } catch (error) {
    console.error(error);
  }

  yield delay(2000); // TODO: hotfix performance render MessengerChat
  yield put(ConversationActions.fetchLastMessage({ ...data, isFetchFromSynchronizing: false }));
}

export function* saveMsgInQueue(action) {
  try {
    const { groupDetail, sendType, roomId } = action.data;
    // Change this to get key by members account ids
    // Query database by group id for members account ids
    let mediaType = [
      SystemConstant.SEND_TYPE.audio,
      SystemConstant.SEND_TYPE.file,
      SystemConstant.SEND_TYPE.image,
      SystemConstant.SEND_TYPE.link,
      SystemConstant.SEND_TYPE.video,
      SystemConstant.SEND_TYPE.reaction,
      SystemConstant.SEND_TYPE.deleteReaction,
      SystemConstant.SEND_TYPE.deleteMessage,
      SystemConstant.SEND_TYPE.restoreData,
      SystemConstant.SEND_TYPE.personalCall,
      SystemConstant.SEND_TYPE.groupCall,
      SystemConstant.SEND_TYPE.reconnect,
      SystemConstant.SEND_TYPE.personalVideoCall,
      SystemConstant.SEND_TYPE.groupVideoCall,
      SystemConstant.SEND_TYPE.leaveGroup,
      SystemConstant.SEND_TYPE.conference,
    ];
    let accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    let isPersonal = groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;
    const selectedGroup = LocalGroupService.get(groupDetail.id);
    let memberIdArray =
      (selectedGroup.members || groupDetail.members || groupDetail.groupMembers).map(member => member.id) || []; // TODO: hotfix

    yield saveKeysByAccountIds(memberIdArray);

    if (isPersonal) {
      memberIdArray.push(accountId);
    }
    let newSendType = isPersonal ? SystemConstant.SEND_TYPE.message : SystemConstant.SEND_TYPE.groupMessage;
    if (mediaType.includes(sendType)) {
      newSendType = sendType;
    }

    if (ARR_CALLING_TYPES.includes(sendType) && !Boolean(roomId)) {
      return;
    }

    if (!isPersonal) {
      yield sendE2EEMessage({
        data: {
          ...action.data,
          newSendType: newSendType,
          memberIdArray: memberIdArray,
        },
      });
    } else {
      if (sendType === SystemConstant.SEND_TYPE.restoreData) {
        yield sendBackupFile({
          data: {
            ...action.data,
            newSendType: newSendType,
          },
        });
      } else {
        yield sendE2EMessage({
          data: {
            ...action.data,
            newSendType: newSendType,
            memberIdArray: memberIdArray,
          },
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function* sendBackupFile(action) {
  try {
    let { groupDetail, content, newSendType } = action.data;
    let device = yield LocalDeviceService.get(groupDetail.deviceId);
    let encryption_type = SystemConstant.ENCRYPTION_TYPE.NORMAL_ENCRYPTION;
    if (device !== null && isJSONString(device.options)) {
      let options = JSON.parse(device.options);
      if (options.encryption_type) {
        encryption_type = options.ENCRYPTION_TYPE;
      }
    }
    let text = null;
    if (encryption_type === SystemConstant.ENCRYPTION_TYPE.NO_ENCRYPTION) {
      text = content;
    } else {
      text = yield LocalCipherService.encryptE2EMessage(groupDetail.accountId, groupDetail.id, groupDetail.id, content);
    }
    if (text === null) return null;
    let id = uuid();
    let values = [
      {
        messageId: id,
        sendToDeviceId: groupDetail.deviceId,
        sendToAccountId: groupDetail.accountId,
        content: text,
        options: "",
        status: SystemConstant.MESSAGE_STATUS.send,
        sendType: newSendType,
      },
    ];

    let notNullValues = values.filter(s => {
      return s !== null;
    });

    let newMessage = {
      group_id: uuid(),
      groupType: SystemConstant.GROUP_CHAT_TYPE.personal,
      source_id: uuid(),
      sendType: newSendType,
      messages: notNullValues,
      branch_id: AppConstant.GLOBAL_BRANCH_ID,
      messageId: id,
    };

    yield LocalApiCallService.save([
      {
        id: uuid(),
        task: `${AppConstant.TASK_MESSAGE_SEND}`,
        original_uid: newMessage.source_id,
        query: "",
        content: JSON.stringify({ data: newMessage }),
        original_content: JSON.stringify({ data: newMessage }),
        created: new Date().getTime(),
        retry: 0,
        branch_id: AppConstant.GLOBAL_BRANCH_ID,
      },
    ]);
  } catch (e) {
    console.log(e);
  }
}

function* sendE2EEMessage(action) {
  let accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  let deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
  let {
    groupDetail,
    deviceList,
    parentId,
    content,
    newSendType,
    branchId,
    threadId,
    mentionIdsArr,
    memberIdArray,
    callStatus,
    roomId,
    removingId,
    currentMessage,
    option,
  } = action.data;
  let devices = LocalDeviceService.findByList("account_id", memberIdArray);

  deviceList = devices.filter(s => s.state === SystemConstant.STATE.active);
  let values = yield Promise.all(
    deviceList.map(async device => {
      let checkShared = await LocalCipherService.checkMarkSenderKeyShared(device.account_id, device.id, groupDetail.id);
      if (checkShared) return null;
      if (device.id === deviceId) {
        return null;
      }
      return device;
    }),
  );
  let mess = [];
  values = values.filter(s => {
    return s !== null;
  });
  let localId = uuid();
  if (values.length > 0) {
    let distributionKey = yield LocalCipherService.generateDistributionKey(accountId, deviceId, groupDetail.id);
    mess = yield Promise.all(
      values.map(async device => {
        let text = await LocalCipherService.encryptE2EMessage(
          device.account_id,
          device.id,
          groupDetail.id,
          distributionKey,
        );
        if (text === null) return null;
        let mesId = uuid();
        if (deviceId === device.id) mesId = localId;
        let mes = {
          messageId: mesId,
          sendToDeviceId: device.id,
          sendToAccountId: device.account_id,
          content: text,
          options: option,
          status: SystemConstant.MESSAGE_STATUS.send,
          sendType: SystemConstant.SEND_TYPE.senderKey,
          mentions: "",
          callStatus: callStatus,
          roomId: roomId,
          threadId: threadId,
          removingId: removingId,
        };
        return mes;
      }),
    );
  }

  let messageDistribution = mess.filter(s => {
    return s !== null;
  });

  if (messageDistribution.length > 0) {
    let marks = [];
    let sourceId = uuid();
    messageDistribution.forEach(mesItem => {
      marks.push({
        group_id: groupDetail.id,
        account_id: mesItem.sendToAccountId,
        device_id: mesItem.sendToDeviceId,
      });
    });
    if (marks && marks.length > 0) {
      yield LocalSenderKeySharedService.save(marks);
    }
    let newMessage = {
      marks: marks,
      isSendingKey: true,
      groupId: groupDetail.id,
      sourceId: sourceId,
      groupType: groupDetail.groupType,
      sendType: SystemConstant.SEND_TYPE.senderKey,
      created: Date.now(),
      messages: messageDistribution,
      branchId: branchId,
      roomId: roomId,
      threadId: threadId,
      removingId: removingId,
      options: option,
      messageId: localId,
    };

    yield LocalApiCallService.save([
      {
        id: uuid(),
        task: `${AppConstant.TASK_MESSAGE_SEND}`,
        original_uid: sourceId,
        query: "",
        content: JSON.stringify({ data: newMessage }),
        original_content: JSON.stringify({ data: newMessage }),
        created: new Date().getTime(),
        retry: 0,
        branch_id: branchId,
      },
    ]);
  }

  let messageId = uuid();
  let sourceId = uuid();
  let saveMessage = {};
  let created = Date.now();

  if (currentMessage) {
    // saveMessage = currentMessage;

    messageId = currentMessage.id;
    sourceId = currentMessage.source_id;
    if (currentMessage.created) created = currentMessage.created;
  }

  saveMessage = {
    account_id: accountId,
    branch_id: branchId,
    content: content,
    created: created,
    device_id: deviceId,
    group_id: groupDetail.id,
    id: messageId,
    mentions: mentionIdsArr ? JSON.stringify(mentionIdsArr) : "",
    modified: 0,
    options: option,
    parent_id: parentId,
    send_type: newSendType,
    sender_device_id: deviceId,
    sender_id: accountId,
    source_id: sourceId,
    state: 1,
    status: SystemConstant.MESSAGE_STATUS.read,
    thread_id: threadId,
    call_status: callStatus,
    room_id: roomId,
  };

  console.log({ saveMessage });

  yield LocalMessageService.save([saveMessage]);
  yield put(
    ConversationActions.conversationSet({
      sendingTimeStamp: Date.now(),
    }),
  );

  if (Boolean(saveMessage.thread_id)) {
    yield updateThread(saveMessage, false, true);
  }

  let device = deviceList.find(s => s.id === deviceId);
  let createdMessage = yield select(state => state.callingRedux.createdMessage);

  if (ARR_CALLING_TYPES.includes(newSendType) && !parentId) {
    yield put(
      CallingActions.callingSet({
        message: toCamel(saveMessage),
        createdMessage: {
          ...createdMessage,
          [groupDetail.id]: toCamel(saveMessage),
        },
      }),
    );
  }

  let newCurrentMes = null;
  let notEncryptList = deviceList.filter(s => {
    let options = null;
    if (isJSONString(s.options)) {
      options = JSON.parse(s.options);
    }
    return options != null && options.encryption_type === SystemConstant.ENCRYPTION_TYPE.NO_ENCRYPTION;
  });

  let encryptionList = deviceList.filter(s => {
    let options = null;
    if (isJSONString(s.options)) {
      options = JSON.parse(s.options);
    }

    return (
      options == null ||
      [null, undefined].includes(options.encryption_type) ||
      options.encryption_type === SystemConstant.ENCRYPTION_TYPE.NORMAL_ENCRYPTION
    );
  });

  if (device) {
    if (notEncryptList.length > 0) {
      let mesId = uuid();
      if (device.id === deviceId) {
        mesId = messageId;
      }

      newCurrentMes = {
        messageId: mesId,
        sendToDeviceId: device.id,
        sendToAccountId: device.account_id,
        content: content,
        options: option,
        status: SystemConstant.MESSAGE_STATUS.send,
        sendType: newSendType,
        mentions: mentionIdsArr ? JSON.stringify(mentionIdsArr) : "",
        threadId: threadId,
        removingId: removingId,
      };

      let newMessage = {
        isSendingKey: false,
        groupId: groupDetail.id,
        sourceId: sourceId,
        groupType: groupDetail.groupType,
        sendType: newSendType,
        created: Date.now(),
        messages: [newCurrentMes],
        deviceList: notEncryptList.map(s => s.id),
        branchId: branchId,
        parentId: parentId,
        threadId: threadId,
        removingId: removingId,
        options: option,
        messageId: messageId,
      };
      yield LocalApiCallService.save([
        {
          id: uuid(),
          task: `${AppConstant.TASK_MESSAGE_SEND}`,
          original_uid: sourceId,
          query: "",
          content: JSON.stringify({ data: newMessage }),
          original_content: JSON.stringify({ data: newMessage }),
          created: new Date().getTime(),
          retry: 0,
          branch_id: branchId,
        },
      ]);
    }

    if (encryptionList.length > 0) {
      let text = yield LocalCipherService.encryptE2EEMessage(device.account_id, device.id, groupDetail.id, content);
      if (text) {
        let mesId = uuid();
        if (notEncryptList.length === 0 && device.id === deviceId) {
          mesId = messageId;
        }

        newCurrentMes = {
          messageId: mesId,
          sendToDeviceId: device.id,
          sendToAccountId: device.account_id,
          content: text,
          options: option,
          status: SystemConstant.MESSAGE_STATUS.send,
          sendType: newSendType,
          mentions: mentionIdsArr ? JSON.stringify(mentionIdsArr) : "",
          callStatus: callStatus,
          roomId: roomId,
          threadId: threadId,
          removingId: removingId,
        };

        let newMessage = {
          isSendingKey: false,
          groupId: groupDetail.id,
          sourceId: sourceId,
          groupType: groupDetail.groupType,
          sendType: newSendType,
          created: Date.now(),
          messages: [newCurrentMes],
          deviceList: encryptionList.map(s => s.id),
          branchId: branchId,
          parentId: parentId,
          threadId: threadId,
          roomId: roomId,
          removingId: removingId,
          options: option,
          messageId: mesId,
        };

        yield LocalApiCallService.save([
          {
            id: uuid(),
            task: `${AppConstant.TASK_MESSAGE_SEND}`,
            original_uid: sourceId,
            query: "",
            content: JSON.stringify({ data: newMessage }),
            original_content: JSON.stringify({ data: newMessage }),
            created: new Date().getTime(),
            retry: 0,
            branch_id: branchId,
          },
        ]);
      }
    }
  }
}

function* sendE2EMessage(action) {
  try {
    let savedServer = getSavedServer();
    let accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    let deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
    let {
      groupDetail,
      deviceList = [],
      parentId,
      content,
      newSendType,
      branchId,
      threadId,
      mentionIdsArr,
      memberIdArray,
      callStatus,
      isReceiver,
      option,
      currentMessage,
    } = action.data;
    let messageContent = convertJSONObject(content);
    let roomId = messageContent?.room_id || null;

    let messageId = uuid();
    let sourceId = uuid();
    let saveMessage = {};
    let created = Date.now();
    if (currentMessage) {
      // saveMessage = currentMessage;
      messageId = currentMessage.id;
      sourceId = currentMessage.source_id;
      if (currentMessage.created) created = currentMessage.created;
    }

    saveMessage = {
      account_id: accountId,
      branch_id: savedServer?.id,
      content: content,
      created: created,
      device_id: deviceId,
      group_id: groupDetail.id,
      id: messageId,
      mentions: mentionIdsArr ? JSON.stringify(mentionIdsArr) : "",
      modified: 0,
      options: option ? option : null,
      parent_id: parentId,
      send_type: newSendType,
      sender_device_id: deviceId,
      sender_id: accountId,
      source_id: sourceId,
      state: 1,
      status: 1,
      thread_id: threadId,
      call_status: callStatus,
    };
    yield LocalMessageService.save([saveMessage]);
    yield put(
      ConversationActions.conversationSet({
        // isUpdateViewMode: true,
      }),
    );

    if (Boolean(saveMessage.thread_id)) {
      yield updateThread(saveMessage, false, true);
    }

    const devices = yield LocalDeviceService.findRealTime("account_id", memberIdArray);
    deviceList = devices.filter(s => s.state === SystemConstant.STATE.active);

    const values = yield Promise.all(
      deviceList.map(async device => {
        let encryption_type = SystemConstant.ENCRYPTION_TYPE.NORMAL_ENCRYPTION;
        try {
          let options = JSON.parse(device.options);
          if (options != null && options.encryption_type != null) {
            encryption_type = options.encryption_type;
          }
        } catch (e) {
          console.error(e);
        }
        let text = null;
        if (encryption_type === SystemConstant.ENCRYPTION_TYPE.NO_ENCRYPTION) {
          text = content;
        } else {
          text = await LocalCipherService.encryptE2EMessage(device.account_id, device.id, groupDetail.id, content);
        }
        if (text === null) return null;
        let mesId = uuid();
        if (device.id === deviceId) {
          mesId = messageId;
        }

        let newCallStatus = callStatus;
        if (callStatus === SystemConstant.MESSAGE_CALL_STATUS.accept) {
          if (device.account_id === accountId && device.id !== deviceId) {
            newCallStatus = SystemConstant.MESSAGE_CALL_STATUS.inAnotherCall;
          } else if (
            (device.account_id === accountId && device.id === deviceId) ||
            isReceiver ||
            newSendType === SystemConstant.SEND_TYPE.reconnect
          ) {
            newCallStatus = callStatus;
          } else {
            newCallStatus = SystemConstant.MESSAGE_CALL_STATUS.waiting;
          }
        }

        return {
          messageId: mesId,
          sendToDeviceId: device.id,
          sendToAccountId: device.account_id,
          content: text,
          options: option ? option : null,
          status: SystemConstant.MESSAGE_STATUS.send,
          sendType: newSendType,
          mentions: mentionIdsArr ? JSON.stringify(mentionIdsArr) : "",
          callStatus: newCallStatus,
          roomId: roomId,
        };
      }),
    );
    const notNullValues = values.filter(s => {
      return s !== null;
    });

    const newMessage = {
      isSendingKey: false,
      groupId: groupDetail.id,
      sourceId: sourceId,
      groupType: groupDetail.groupType,
      sendType: newSendType,
      created: Date.now(),
      messages: notNullValues,
      branchId: branchId,
      parentId: parentId,
      threadId: threadId,
      roomId: roomId,
      messageId: messageId,
    };

    console.log({ newMessage });

    yield LocalApiCallService.save([
      {
        id: uuid(),
        task: `${AppConstant.TASK_MESSAGE_SEND}`,
        original_uid: sourceId,
        query: "",
        content: JSON.stringify({ data: newMessage }),
        original_content: JSON.stringify({ data: newMessage }),
        created: new Date().getTime(),
        retry: 0,
        branch_id: branchId,
      },
    ]);

    let createdMessage = yield select(state => state.callingRedux.createdMessage);

    if (ARR_CALLING_TYPES.includes(newSendType) && !parentId) {
      yield put(
        CallingActions.callingSet({
          message: toCamel(saveMessage),
          createdMessage: {
            ...createdMessage,
            [groupDetail.id]: toCamel(saveMessage),
          },
        }),
      );
    }
  } catch (e) {
    console.trace(e);
  }
}

export function* sendMessage2Server(action) {
  let accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  try {
    const { data } = action;
    if (data.marks) delete data.marks;

    let response = yield call(MessageService.sendMessage, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let local = LocalMessageService.getMessageWithSourceId(data.sourceId);
      if (local && Object.keys(local).length > 0 && local.created) {
        yield LocalMessageService.save([
          {
            ...local,
            modified: local.created,
          },
        ]);
      }

      yield delay(100);

      let selectedGroupId = yield select(state => state.conversationRedux.selectedGroupId);
      if (
        local &&
        local.group_id === selectedGroupId &&
        [...ARR_CALLING_TYPES, ...MSG_SEND_TYPE].includes(local.send_type)
      ) {
        yield put(
          ConversationActions.conversationSet({
            isSent: true,
            sendingMsg: toCamel({
              ...local,
              modified: local.created,
            }),
          }),
        );
      }

      if (data.sendType === SystemConstant.SEND_TYPE.leaveGroup && data.removingId === accountId) {
        let payload = {
          groupId: data.groupId,
        };

        if (data.adminId) {
          payload.adminId = data.adminId;
        }

        yield put(
          ConversationActions.deleteGroup({
            ...payload,
          }),
        );
      }

      yield LocalApiCallService.poll(data.call_id);
    } else {
      if (response.status === ApiConstant.STT_DUPLICATE_MESSAGE) {
        let local = LocalMessageService.getMessageWithSourceId(data.sourceId);
        if (local && Object.keys(local).length > 0 && local.created) {
          yield LocalMessageService.save([
            {
              ...local,
              modified: local.created,
            },
          ]);
        }

        yield LocalApiCallService.poll(data.call_id);
      } else if (
        response.status === ApiConstant.STT_UNAUTHORIZED ||
        response.status === ApiConstant.STT_FORBIDDEN ||
        response.status === ApiConstant.STT_MAINTAIN_1 ||
        response.status === ApiConstant.STT_MAINTAIN_2 ||
        response.status === ApiConstant.STT_MAINTAIN_3
      ) {
        // handler refresh token in generic
      } else {
        let apiCall = yield LocalApiCallService.get(data.call_id);
        if (apiCall.retry <= ApiConstant.MAX_RETRY) {
          if (
            response.status === ApiConstant.STT_NOT_FOUND ||
            response.status === ApiConstant.STT_BAD_REQUEST ||
            response.status === ApiConstant.STT_INTERNAL_SERVER
          ) {
            yield LocalApiCallService.save([{ ...apiCall, retry: apiCall.retry + 1 }]);
          }
        } else {
          let deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
          if (action.data && action.data.messages && action.data.messages.length > 0) {
            action.data.messages.map(async message => {
              if (message.deviceId === deviceId) {
                LocalMessageService.get(message.messageId).then(mes => {
                  if (mes) {
                    mes.state = SystemConstant.STATE.inactive;
                    LocalMessageService.save([mes]);
                  }
                });
              }
            });
          }
          yield LocalApiCallService.poll(data.call_id);
        }
      }
    }
    yield put(
      ConversationActions.conversationSet({
        isUpdateViewMode: Date.now(),
      }),
    );
  } catch (error) {
    console.log(error);
  }
}

export function* uploadMessageFile(action) {
  try {
    const { data } = action;
    const upload = data.upload;
    const sendType = data.sendType;
    let response = yield call(MessageService.sendMessageFile, upload);
    if (response && response.status === ApiConstant.STT_OK && response?.data?.id) {
      yield put(
        ConversationActions.conversationSet({
          uploadMessageAttachmentData: { attachment_id: response.data.id, sendType: sendType },
        }),
      );
    } else {
      yield put(
        ConversationActions.conversationSet({
          uploadMessageAttachmentData: null,
          isUploadFileFailed: true,
        }),
      );
    }

    yield put(
      ConversationActions.conversationSet({
        isFinishUpload: true,
      }),
    );
  } catch (error) {
    yield put(
      ConversationActions.conversationSet({
        uploadMessageAttachmentData: null,
        isUploadFileFailed: true,
      }),
    );
  }
}

export function* updateMessageStatus(action) {
  try {
    const { data } = action;

    let response = yield call(MessageService.updateMessageStatus, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationActions.conversationSet({
          isUpdateNotificationSuccess: true,
          isUpdateViewMode: Date.now(),
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* markReadMessageInGroup(action) {
  const { selectedGroupId } = action;
  try {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);

    if (selectedGroupId) {
      const unreadMsgInGroup = yield LocalMessageService.findUnreadOrderByCreatedDesc(selectedGroupId, accountId);
      const unreadMsgIds = unreadMsgInGroup.map(item => item.id);
      if (Array.isArray(unreadMsgIds) && unreadMsgIds.length > 0) {
        yield updateMessageStatus({
          data: {
            messageIds: unreadMsgIds,
            status: SystemConstant.MESSAGE_STATUS.read,
          },
        });

        yield LocalMessageService.updateMessageStatusByMsgIdArr(unreadMsgIds);
      }
    }
  } catch (e) {
    console.log(e);
  }
}
