import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, Button, Dialog, IconButton, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import { KeyConstant, LangConstant } from "const";
import { CallingContext } from "..";
import { StorageUtil } from "utils";

const SettingDialog = ({ open, onClose }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_CALLING);
  const { setIsSaveInputSettingSuccess, setIsSaveOutputSettingSuccess } = useContext(CallingContext);

  const [audioInputDevices, setAudioInputDevices] = useState([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState([]);
  const [currentAudioInputDevice, setCurrentAudioInputDevice] = useState("");
  const [currentAudioOutputDevice, setCurrentAudioOutputDevice] = useState("");

  const onChangeAudioInput = e => {
    setCurrentAudioInputDevice(e.target.value);
  };

  const onChangeAudioOutput = e => {
    setCurrentAudioOutputDevice(e.target.value);
  };

  const onSaveAudioSetting = async () => {
    let mediaCallingOutput = document.getElementById(KeyConstant.KEY_CALLING_MEDIA_ID);

    if (mediaCallingOutput) {
      await onChangeInputSetting(mediaCallingOutput);
      await onChangeOutputSetting(mediaCallingOutput);
    } else {
      // TODO: Show error alert
      console.error("Cannot find calling media element");
    }

    onClose();
  };

  const onDetectAudioDevices = async mediaDevices => {
    try {
      let savedCallingSetting = StorageUtil.getItem(KeyConstant.KEY_CALLING_MEDIA_SETTING);
      let devices = await mediaDevices.enumerateDevices();

      let inputDevices = devices.filter(devices => devices.kind === "audioinput");
      let outputDevices = devices.filter(devices => devices.kind === "audiooutput");

      let defaultInputAudio = inputDevices.find(devices => devices.deviceId === "default");
      let defaultOutputAudio = outputDevices.find(devices => devices.deviceId === "default");

      if (savedCallingSetting) {
        let { input, output } = savedCallingSetting;

        let isSavedInputDeviceExist = inputDevices.find(device => device.deviceId === input);
        let isSavedOutputDeviceExist = outputDevices.find(device => device.deviceId === output);

        if (isSavedInputDeviceExist) {
          setCurrentAudioInputDevice(input);
        } else {
          setCurrentAudioInputDevice(defaultInputAudio?.deviceId || "");
          StorageUtil.setItem(KeyConstant.KEY_CALLING_MEDIA_SETTING, {
            ...savedCallingSetting,
            input: defaultInputAudio?.deviceId || "",
          });
        }

        if (isSavedOutputDeviceExist) {
          setCurrentAudioOutputDevice(output);
        } else {
          setCurrentAudioOutputDevice(defaultOutputAudio?.deviceId || "");
          StorageUtil.setItem(KeyConstant.KEY_CALLING_MEDIA_SETTING, {
            ...savedCallingSetting,
            output: defaultOutputAudio?.deviceId || "",
          });
        }
      } else {
        let newCallingSetting = {
          input: defaultInputAudio?.deviceId || "",
          output: defaultOutputAudio?.deviceId || "",
        };

        setCurrentAudioInputDevice(defaultInputAudio?.deviceId || "");
        setCurrentAudioOutputDevice(defaultOutputAudio?.deviceId || "");
        StorageUtil.setItem(KeyConstant.KEY_CALLING_MEDIA_SETTING, newCallingSetting);
      }

      setAudioInputDevices(inputDevices);
      setAudioOutputDevices(outputDevices);
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeInputSetting = async mediaElement => {
    if (currentAudioInputDevice) {
      let localCallingSetting = StorageUtil.getItem(KeyConstant.KEY_CALLING_MEDIA_SETTING);
      StorageUtil.setItem(KeyConstant.KEY_CALLING_MEDIA_SETTING, {
        ...localCallingSetting,
        input: currentAudioInputDevice,
      });
      setIsSaveInputSettingSuccess(true);
    }
  };

  const onChangeOutputSetting = async mediaElement => {
    if (currentAudioOutputDevice && typeof mediaElement.sinkId !== "undefined") {
      try {
        let localCallingSetting = StorageUtil.getItem(KeyConstant.KEY_CALLING_MEDIA_SETTING);
        mediaElement.setSinkId(currentAudioOutputDevice);
        StorageUtil.setItem(KeyConstant.KEY_CALLING_MEDIA_SETTING, {
          ...localCallingSetting,
          output: currentAudioOutputDevice,
        });

        setIsSaveOutputSettingSuccess(true);
      } catch (error) {
        // TODO: Show error alert
        console.error("Got error while change output setting: ", error);
      }
    } else {
      // TODO: Show error alert
      console.error("Application does not support output device selection");
    }
  };

  useEffect(async () => {
    await onDetectAudioDevices(navigator.mediaDevices);
  }, [open]);

  useEffect(() => {
    const onDeviceChange = async e => {
      await onDetectAudioDevices(e.target);
    };

    navigator.mediaDevices.addEventListener("devicechange", onDeviceChange);

    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", onDeviceChange);
    };
  });

  const menuProps = {
    classes: { paper: classes.menuItemPaper, list: classes.menuList },
    anchorOrigin: { vertical: "bottom", horizontal: "left" },
    transformOrigin: { vertical: "top", horizontal: "left" },
  };

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <Box className={classes.closeButtonWrapper}>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <Close />
        </IconButton>
      </Box>
      <Box className={classes.wrapper}>
        <Typography className={clsx(classes.title, "bold-xl-txt")}>{getLabel(LangConstant.TXT_SETTING)}</Typography>
        <Box className={classes.wrapperItem}>
          <Box className={classes.selectWrapper}>
            <InputLabel className={clsx(classes.label, "semiBold-sm-txt")}>
              {getLabel(LangConstant.TXT_INPUT_DEVICE)}
            </InputLabel>
            <Select
              onChange={onChangeAudioInput}
              classes={{ select: classes.select, icon: classes.dropIcon }}
              value={currentAudioInputDevice}
              MenuProps={menuProps}
              displayEmpty
            >
              {audioInputDevices.length > 0 ? (
                audioInputDevices.map(device => (
                  <MenuItem
                    classes={{ root: classes.menuItemRoot, selected: classes.menuItemSelected }}
                    key={device.deviceId}
                    value={device.deviceId}
                    selected={device.deviceId === currentAudioInputDevice}
                  >
                    {device.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem
                  value={""}
                  classes={{ root: classes.menuItemRoot, selected: classes.menuItemSelected }}
                  selected={true}
                >
                  {getLabel(LangConstant.TXT_DEVICE_NOT_FOUND)}
                </MenuItem>
              )}
            </Select>
          </Box>
          <Box className={classes.selectWrapper}>
            <InputLabel className={clsx(classes.label, "semiBold-sm-txt")}>
              {getLabel(LangConstant.TXT_OUTPUT_DEVICE)}
            </InputLabel>
            <Select
              onChange={onChangeAudioOutput}
              classes={{ select: classes.select, icon: classes.dropIcon }}
              value={currentAudioOutputDevice}
              MenuProps={menuProps}
              displayEmpty
            >
              {audioOutputDevices.length > 0 ? (
                audioOutputDevices.map(device => (
                  <MenuItem
                    classes={{ root: classes.menuItemRoot, selected: classes.menuItemSelected }}
                    key={device.deviceId}
                    value={device.deviceId}
                    selected={device.deviceId === currentAudioOutputDevice}
                  >
                    {device.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem
                  value={""}
                  classes={{ root: classes.menuItemRoot, selected: classes.menuItemSelected }}
                  selected={true}
                >
                  {getLabel(LangConstant.TXT_DEVICE_NOT_FOUND)}
                </MenuItem>
              )}
            </Select>
          </Box>
        </Box>
        <Button
          fullWidth
          className={classes.button}
          onClick={onSaveAudioSetting}
          color="primary"
          variant="contained"
          size="large"
        >
          {getLabel(LangConstant.TXT_DONE)}
        </Button>
      </Box>
    </Dialog>
  );
};

SettingDialog.propTypes = {
  open: PropTypes.bool,

  onClose: PropTypes.func,
};

SettingDialog.defaultProps = {
  open: false,

  onClose: () => {},
};

export default SettingDialog;

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: 20,
    backgroundColor: "#1F1F1F",
    padding: "32px 28px",
    boxShadow: "none",
    position: "relative",
  },

  wrapper: {
    width: 470,
  },

  wrapperItem: {
    width: "50%",
  },

  closeButtonWrapper: {
    position: "absolute",
    top: 24,
    right: 24,
  },

  closeButton: {
    padding: 6,
    color: theme.palette.white,
    backgroundColor: "#3A3A3A",
    "&:hover": {
      backgroundColor: "#585858",
    },
  },

  title: {
    lineHeight: "20px",
    color: theme.palette.white,
    marginBottom: 16,
  },

  selectWrapper: {
    marginBottom: 16,
    width: "100%",
    "& > .MuiInputBase-root": {
      borderRadius: "10px !important",
      width: "100%",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  label: {
    lineHeight: "20px",
    color: "#8E8E8E",
    marginBottom: 4,
  },

  inputRoot: {
    width: "100%",
  },

  select: {
    backgroundColor: "#3A3A3A",
    width: "100%",
    color: theme.palette.white,
    fontSize: 15,
    fontWeight: 700,
    lineHeight: "20px",
    borderRadius: "10px !important",
    outline: "none",
    padding: "8px 14px",
  },

  button: {
    marginTop: 12,
  },

  dropIcon: {
    color: theme.palette.white,
  },

  menuItemPaper: {
    backgroundColor: "#3a3a3a",
    boxShadow: "none",
    marginTop: 4,
    width: 324,
    borderRadius: 10,
  },

  menuList: {
    padding: 4,
  },

  menuItemRoot: {
    color: theme.palette.white,
    whiteSpace: "pre-wrap",
  },

  menuItemSelected: {
    backgroundColor: "#7F7F80 !important",
    borderRadius: 10,
    border: "1px solid #1877F2",
  },
}));
