export const REDUX_STATE = {
  isFetching: false,
  error: null,
};

export const requestReducerFunc = state => {
  return {
    ...state,
    isFetching: true,
    error: null,
  };
};

export const successReducerFunc = (state, action) => {
  const data = action.data || {};
  return {
    ...state,
    isFetching: false,
    error: null,
    ...data,
  };
};

export const failureReducerFunc = (state, action) => {
  const error = action.error ? action.error : {};
  const data = action.data ? action.data : {};

  return { ...state, isFetching: false, error: error, ...data };
};

export const resetReducerFunc = initState => initState;

export const setReducerFunc = (state, action) => {
  const data = action.data || {};
  return {
    ...state,
    ...data,
  };
};
