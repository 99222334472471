import { Fragment, useEffect, useState } from "react";
import { Box, CardMedia, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ImageConstant, KeyConstant, LangConstant, AppConstant } from "const";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ThirdPartyService } from "services";
import QueryString from "qs";
import { AuthSelectors } from "redux-store";

const HandleThirdToken = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const isLogin = useSelector(AuthSelectors.isLogin);

  const [isShow, setIsShow] = useState(false);
  const [deeplink, setDeeplink] = useState();
  const [titleLoading, setTitleLoading] = useState("");

  const handleCreateServiceToken = async () => {
    setIsShow(() => true);

    const queryUrl = deeplink.substring(deeplink.indexOf("?") + 1);
    const eventParams = QueryString.parse(queryUrl);

    if (eventParams.service_id && eventParams.callbackUrl) {
      setTitleLoading(
        getLabel(LangConstant.TXT_CREATE_THIRD_TOKEN) + " " + AppConstant.SERVICE_NAME[eventParams.service_id],
      );

      const response = await ThirdPartyService.createThirdPartyToken(eventParams.service_id);

      // Return token to service
      window.handleExternalLink.openExternalLink(`${eventParams.callbackUrl}&token=${response.data?.token || ""}`);
    }

    setDeeplink();
    setIsShow(false);
  };

  useEffect(() => {
    if (!window.electronEvent) return;
    window.electronEvent.addListener(KeyConstant.EVENT_KEY.createThirdToken, (_, payload) => {
      setDeeplink(payload);
    });
  }, []);

  useEffect(() => {
    if (isLogin && deeplink) {
      handleCreateServiceToken();
    }
  }, [isLogin, deeplink]);

  return (
    <>
      {isShow ? (
        <Box className={classes.container}>
          <Box className={classes.loading}>
            <Typography variant="h5" className={classes.titleLoading}>
              {titleLoading}
            </Typography>
            <Box>
              <CircularProgress color="inherit" />
            </Box>
            <Typography className={classes.contentLoading}>{getLabel(LangConstant.TXT_WAIT_MINUTE)}</Typography>
          </Box>
          <Box className={classes.imageContainer}>
            <CardMedia component="img" src={ImageConstant.LoginWelcomeImage} className={classes.loginWelcomeImage} />
          </Box>
        </Box>
      ) : (
        <Fragment />
      )}
    </>
  );
};

export default HandleThirdToken;

const useStyles = makeStyles({
  container: {
    zIndex: 9999,
    backgroundColor: "#FAFAFA",
    position: "fixed",
    height: "100%",
    display: "flex",
  },

  loading: {
    width: "30%",
    textAlign: "center",
    margin: "auto 50px",
  },

  imageContainer: {
    width: "70%",
    backgroundColor: "#FAFAFA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  loginWelcomeImage: {
    width: "80%",
  },

  titleLoading: {
    fontWeight: 600,
    padding: 32,
    wordBreak: "break-word",
  },

  contentLoading: {
    padding: 32,
    wordBreak: "break-word",
  },
});
