import { Avatar, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { useContext, useEffect, useState } from "react";
import { getContactName, getImageSrcAvatar, removeDuplicateInArray } from "utils";
import { ConversationContext } from ".";
import { isGroupOrChannelType } from "pages/Call";
import { useTranslation } from "react-i18next";
import { StorageUtil } from "utils";

const SeenMessage = ({ data, isShow }) => {
  const classes = useStyles();
  let accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const { groupDetail } = useContext(ConversationContext);
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);

  const [seenMembersName, setSeenMembersName] = useState("");
  const [avatarSeenMemberList, setAvatarSeenMemberList] = useState([]);

  useEffect(() => {
    let tmpSeenList = removeDuplicateInArray(data, "name");

    if (tmpSeenList.length > 0 && tmpSeenList.find(item => item.name !== accountId)) {
      if (isGroupOrChannelType(groupDetail.groupType)) {
        let tmpSeenMembersName = "";
        let result = [];

        tmpSeenList.forEach((item, index) => {
          if (item.name !== accountId) {
            result.push({
              id: item.name,
              name: getContactName(item.name),
              avatarId: item.avatarId,
            });

            tmpSeenMembersName = tmpSeenMembersName.concat(
              getContactName(item.name),
              index !== tmpSeenList.length - 1 ? ", " : "",
            );
          }
        });

        setAvatarSeenMemberList(result);

        setSeenMembersName(
          getLabel(LangConstant.FM_SEEN_MEMBER, {
            memberNames: tmpSeenMembersName,
          }),
        );
      } else {
        let otherPerson = tmpSeenList.find(item => item.name !== accountId);

        setAvatarSeenMemberList([
          {
            id: otherPerson.name,
            name: getContactName(otherPerson.name),
            avatarId: otherPerson.avatarId,
          },
        ]);
        setSeenMembersName(getLabel(LangConstant.TXT_SEEN));
      }
    }
  }, [data]);

  return (
    <>
      {seenMembersName !== "" && (
        <Typography className={isShow ? classes.memberSeenName : "hidden"}>{seenMembersName}</Typography>
      )}

      {avatarSeenMemberList.length > 0 && (
        <Box className={classes.memberSeen}>
          {avatarSeenMemberList.map((item, index) => (
            <SeenMemberItem key={index} data={item} />
          ))}
        </Box>
      )}
    </>
  );
};

const SeenMemberItem = ({ data }) => {
  const classes = useStyles();

  const [memberAvatar, setMemberAvatar] = useState(null);

  useEffect(() => {
    let isMounted = true;

    // if (data.avatarId && !memberAvatar) {
    //   setInterval(() => {
    //     getImageSrcAvatar(data.avatarId, SystemConstant.GROUP_CHAT_TYPE.personal).then(src => {
    //       if (isMounted && (src !== memberAvatar || !memberAvatar)) {
    //         setMemberAvatar(src);
    //       }
    //     });
    //   }, 2000);
    // }

    getImageSrcAvatar(data.avatarId, SystemConstant.GROUP_CHAT_TYPE.personal).then(src => {
      if (isMounted && (src !== memberAvatar || !memberAvatar)) {
        setMemberAvatar(src);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [data]);

  return (
    <Avatar key={data.id} src={memberAvatar} className={classes.avatarSeenMember}>
      {data.name?.charAt(0)}
    </Avatar>
  );
};

export default SeenMessage;

const useStyles = makeStyles(theme => ({
  memberSeen: {
    display: "flex",
    justifyContent: "end",
    paddingRight: "25px",
  },

  avatarSeenMember: {
    width: 15,
    height: 15,
    margin: 1,
    fontSize: 12,
  },

  memberSeenName: {
    display: "flex",
    justifyContent: "end",
    paddingRight: 25,
    fontSize: 12,
    height: 20,
  },
}));
