import React, { memo, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import StringFormat from "string-format";
import { makeStyles } from "@mui/styles";
import { FormatConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import {
  compareObject,
  convertString2JSON,
  copyImageToClipboard,
  downloadFile,
  formatEmoij,
  getImageSrc,
  getImageSrcAvatar,
  isArrayNotEquals,
  isJSONString,
  removeUndefinedValueInArray,
  replaceId2NameDisplay,
  toCamel,
  uuid,
} from "utils";
import { ContentCopy, Delete, Edit, MoreHoriz, Reply, TagFaces, Download, Replay, Error } from "@mui/icons-material";
import { formatSentTime } from "utils/date.utils";
import { getCommonKey, getNSKey } from "utils/lang.utils";
import { ActionMenu, FullEmojiMenu } from "components";
import MessengerPeople from "./MessengerPeople";
import FileDownLoad from "./FileDownLoad";
import FilePreview from "./FilePreview";
import ImagePreview from "./ImagePreview";
import ReactionDetailDialog from "./ReactionDetailDialog";
import { Box, IconButton, Typography } from "@mui/material";
import { ConversationContext } from ".";
import AudioChat from "./AudioChat";
import EmojiMenu from "./EmojiMenu";
import ConfirmDeleteMessagePopup from "./ConfirmDeteleMessagePopup";
import SeenMessage from "./SeenMessage";
import { notificationClear } from "../../../../utils/common.utils";
import LineChat from "./LineChat";
import { LocalAccountService, LocalMessageService } from "services/local.service";
import { ConversationActions } from "redux-store";
import { StorageUtil } from "utils";

// TODO: Need to refactor props
const ChatItem = ({ data, onSendMessage, isAvatar, isThreading, isChannel = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const isMine = data.senderId === accountId;
  const { setDeletedMessage, onMediaClick } = useContext(ConversationContext);

  const isUpdateViewMode = useSelector(state => state.conversationRedux.isUpdateViewMode);
  const isFetchMessageSuccess = useSelector(state => state.conversationRedux.isFetchMessageSuccess);
  const isSearchMode = useSelector(state => state.conversationRedux.isSearchMode);
  const isSendMessageSuccess = useSelector(state => state.conversationRedux.isSendMessageSuccess);
  const isSent = useSelector(state => state.conversationRedux.isSent);
  const sendingMsg = useSelector(state => state.conversationRedux.sendingMsg);

  const [message, setMessage] = useState({});
  const [menuAnchor, setMenuAnchorEl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [reactionList, setReactionList] = useState([]);
  const [displayReaction, setDisplayReaction] = useState([]);
  const [isOpenReactionDetail, setIsOpenReactionDetail] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [threadMessageArr, setThreadMessageArr] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [senderAvatar, setSenderAvatar] = useState({
    src: "",
    name: "",
  });
  const [isOpenFullEmojiMenu, setIsOpenFullEmojiMenu] = useState(false);
  const [emojiAnchorEl, setEmojiAnchorEl] = useState(null);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [isShowSeenMembers, setIsShowSeenMembers] = useState(false);
  const [isSendFailed, setIsSendFailed] = useState(false);
  const [unreadThreadMsgs, setUnreadThreadMsgs] = useState([]);
  const [leaveGroupObj, setLeaveGroupObj] = useState({
    senderName: "",
    accountRemovedName: "",
  });
  const [isDeletedLocal, setIsDeletedLocal] = useState(false);

  const onOpenChatItemMenu = event => {
    setMenuAnchorEl(event.currentTarget);
  };

  const onCloseChatItemMenu = () => {
    setMenuAnchorEl(null);
    setIsHovered(false);
  };

  const onEnableEdit = () => {
    setIsEditing(data.id, true);
    onCloseChatItemMenu();
  };

  const onReplyMessage = () => {
    dispatch(
      ConversationActions.conversationSet({
        threadingId: data.sourceId,
      }),
    );
    onCloseChatItemMenu();
  };

  const onDownLoadFile = () => {
    if (MEDIA_TYPES.includes(data.sendType) && isJSONString(data.content)) {
      let content = toCamel(JSON.parse(data.content));
      let metaData = toCamel(JSON.parse(content.metaData));
      getImageSrc(
        content.attachmentId,
        SystemConstant.GROUP_CHAT_TYPE.group,
        metaData.mimeType,
        metaData.fileName,
      ).then(src => {
        if (metaData.fileName && src && metaData.mimeType) {
          downloadFile(metaData.fileName, src, metaData.mimeType);
        } else {
          alert(getLabel(getNSKey(LangConstant.NS_HOME_CONVERSATION, LangConstant.TXT_FILE_NOT_FOUND)));
        }
      });
    } else {
      alert(getLabel(getNSKey(LangConstant.NS_HOME_CONVERSATION, LangConstant.TXT_FILE_NOT_FOUND)));
    }
  };

  const onSelectEmoji = value => {
    setEmojiAnchorEl(null);
    setIsOpenFullEmojiMenu(false);

    let newReactionList;
    let existedReaction = reactionList.filter(react => react.content === value && react.senderId === accountId);
    let currentAccountReactionIndex = reactionList.findIndex(reaction => reaction.senderId === accountId);

    if (existedReaction.length > 0) {
      newReactionList = reactionList.filter((react, index) => index !== currentAccountReactionIndex);
      setReactionList(newReactionList);
      onSendMessage(SystemConstant.SEND_TYPE.deleteReaction, value, data.sourceId);
    } else {
      let newReaction = {
        id: uuid(),
        sourceId: uuid(),
        parentId: data.sourceId,
        threadId: null,
        sendType: SystemConstant.SEND_TYPE.reaction,
        accountId: accountId,
        content: value,
        mentions: null,
        senderId: accountId,
        groupId: data.groupId,
        created: Date.now(),
        accountName: currentAccount?.name || "No name",
        accountAvatarId: currentAccount?.avatarId || null,
      };

      if (currentAccountReactionIndex >= 0) {
        newReactionList = reactionList.map((react, index) =>
          index === currentAccountReactionIndex ? newReaction : react,
        );
      } else {
        newReactionList = [...reactionList, newReaction];
      }

      setReactionList(newReactionList);
      onSendMessage(SystemConstant.SEND_TYPE.reaction, value, data.sourceId);
    }
  };

  const onMouseEnter = () => {
    setIsHovered(true);
  };

  const onMouseLeave = () => {
    if (!Boolean(emojiAnchorEl) && !Boolean(menuAnchor)) {
      setIsHovered(false);
    }
  };

  const onOpenReactionDetailDialog = () => {
    setIsOpenReactionDetail(true);
  };

  const onCloseReactionDetailDialog = () => {
    setIsOpenReactionDetail(false);
  };

  const onDeleteItem = (item, isDeletedLocal = false) => {
    setIsDeletedLocal(isDeletedLocal);
    setIsOpenConfirmDialog(true);
    setDeleteItem(item);
  };

  const onCopyContent = item => {
    onCloseChatItemMenu();
    navigator?.clipboard.writeText(item.content);
  };

  const onCopyImage = async item => {
    let content = toCamel(JSON.parse(item.content));
    let metaData = toCamel(JSON.parse(content.metaData));
    let src = await getImageSrc(
      content.attachmentId,
      SystemConstant.GROUP_CHAT_TYPE.group,
      metaData.mimeType,
      metaData.fileName,
    );

    onCloseChatItemMenu();
    await copyImageToClipboard(src, metaData.mimeType);
  };

  const onMediaClicked = useCallback(() => {
    onMediaClick(message);
  }, [message]);

  const onOpenEmojiMenu = event => {
    setEmojiAnchorEl(event.target);
  };

  const onCloseEmojiMenu = () => {
    setEmojiAnchorEl(null);
    setIsOpenFullEmojiMenu(false);
  };

  const onOpenFullEmojiMenu = () => {
    setIsOpenFullEmojiMenu(true);
  };

  const onCloseConfirmDialog = () => {
    setIsOpenConfirmDialog(false);
    onCloseChatItemMenu();
  };

  const onConfirmDelete = async () => {
    if (isDeletedLocal) {
      await LocalMessageService.updateDeleteStateForMsg(deleteItem.id);
      setIsDeletedLocal(false);
    } else {
      onSendMessage(SystemConstant.SEND_TYPE.deleteMessage, deleteItem.id, deleteItem.sourceId);
    }
    onCloseConfirmDialog();
    onCloseChatItemMenu();
    setDeletedMessage(deleteItem);
  };

  const onResendMessage = event => {
    event.stopPropagation();

    // Add new field into message table for recognize failed status

    // repository.message
    //   .save([
    //     toSnake({
    //       ...message,
    //       sendType: SystemConstant.SEND_TYPE.keyError,
    //       content: "?",
    //     }),
    //   ])
    //   .then(() => {
    //     onSendMessage(message.sendType, message.content);
    //   });
  };

  const setSenderInfo = async isMounted => {
    try {
      const senderAccount = data.members.find(item => item.id === data.senderId);
      if (senderAccount) {
        const avatarSrc = await getImageSrcAvatar(senderAccount.avatarId);
        const accountData = {
          name: senderAccount.name,
          src: avatarSrc,
        };

        if (isMounted) setSenderAvatar(accountData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (data.members) {
      let res = LocalMessageService.getChildMessages(data.sourceId, data.sendType);
      if (res.length > 0) {
        const editedMessage = res[res.length - 1];
        const mentionContent = replaceId2NameDisplay(editedMessage.content, data.members);
        const editedSentTime = formatSentTime(editedMessage.created);
        setMessage({ ...data, content: mentionContent, sentTime: editedSentTime, isEdited: true });
      } else {
        setMessage({
          ...data,
          content: replaceId2NameDisplay(data.content, data.members),
          sentTime: formatSentTime(data.created),
        });
      }

      if (data.senderId !== accountId) {
        setSenderInfo(isMounted);
      }

      if (!currentAccount) {
        setCurrentAccount(toCamel(LocalAccountService.getAccount(accountId)));
      }

      let records = toCamel(LocalMessageService.getChildMessages(data.sourceId, SystemConstant.SEND_TYPE.reaction));
      let deletedRecords = toCamel(
        LocalMessageService.getChildMessages(data.sourceId, SystemConstant.SEND_TYPE.deleteReaction),
      );

      let handleReactionArr = records.concat(deletedRecords).sort((current, prev) => current.created - prev.created);

      let handleReactionObj = handleReactionArr.reduce((newReactList, react) => {
        let account = react.senderId;
        newReactList[account] = (newReactList[account] || []).concat(react);

        return newReactList;
      }, {});

      let reactionArr = Object.entries(handleReactionObj).map(([account, data]) => {
        return data.length > 0 ? data[data.length - 1] : data[0];
      });

      let finalReactionArr = reactionArr.filter(react => react.sendType === SystemConstant.SEND_TYPE.reaction);
      if (isArrayNotEquals(finalReactionArr, reactionList)) {
        setReactionList(finalReactionArr);
      }

      let threadMessageArray = LocalMessageService.getThreadMessage(data.sourceId);
      threadMessageArray = toCamel(threadMessageArray).filter(
        item =>
          !(
            Boolean(item.parentId) &&
            (item.sendType === SystemConstant.SEND_TYPE.message ||
              item.sendType === SystemConstant.SEND_TYPE.groupMessage)
          ) &&
          item.sendType !== SystemConstant.SEND_TYPE.reaction &&
          item.sendType !== SystemConstant.SEND_TYPE.deleteMessage &&
          LocalMessageService.getChildMessages(item.sourceId, SystemConstant.SEND_TYPE.deleteMessage).length === 0,
      );

      if (threadMessageArray.length > 0) {
        setThreadMessageArr(threadMessageArray);
        let unreadThreadMsg = LocalMessageService.getUnreadInThread(data.sourceId);
        let allNotSeen = notificationClear(unreadThreadMsg).allNotSeen;
        setUnreadThreadMsgs(Object.values(allNotSeen));
      }
    }
    return () => {
      isMounted = false;
    };
  }, [data, isFetchMessageSuccess, isUpdateViewMode]);

  useEffect(() => {
    let handleReacionArr = reactionList.map(react => react.content);
    let finalReactionArr = handleReacionArr
      .filter((react, index) => handleReacionArr.indexOf(react) === index)
      .slice(0, 2);

    setDisplayReaction(finalReactionArr);
  }, [reactionList]);

  useEffect(() => {
    if (isSent) {
      dispatch(
        ConversationActions.conversationSet({
          isSent: false,
        }),
      );
    }

    setIsSending(!Boolean(data.modified));
    setIsSendFailed(data.state === SystemConstant.STATE.inactive);
  }, [data, isFetchMessageSuccess, isSendMessageSuccess, isSent, sendingMsg]);

  useEffect(() => {
    if (message.sendType === SystemConstant.SEND_TYPE.leaveGroup) {
      const senderId = message.senderId;
      const removeAccountId = message.content;

      if (senderId && removeAccountId) {
        const sender = LocalAccountService.getAccountByIds([senderId])[0];
        let removedAccount = {};
        if (senderId !== removeAccountId) {
          removedAccount = LocalAccountService.getAccountByIds([removeAccountId])[0];
        }

        setLeaveGroupObj({
          senderName: sender.name,
          accountRemovedName: removedAccount.name,
        });
      }
    }
  }, [message]);

  let menuArray =
    isMine && data.sendType !== SystemConstant.SEND_TYPE.file
      ? [
          {
            onClick: onDeleteItem,
            icon: <Delete />,
            content: getLabel(getCommonKey(LangConstant.TXT_DELETE)),
          },
          {
            onClick: MEDIA_TYPES.includes(data.sendType) ? onDownLoadFile : onEnableEdit,
            icon: MEDIA_TYPES.includes(data.sendType) ? <Download /> : <Edit />,
            content: getLabel(
              MEDIA_TYPES.includes(data.sendType)
                ? getCommonKey(LangConstant.TXT_DOWNLOAD)
                : getCommonKey(LangConstant.TXT_EDIT),
            ),
          },
        ]
      : [
          {
            onClick: item => onDeleteItem(item, true),
            icon: <Delete />,
            content: getLabel(LangConstant.TXT_DELETE_FOR_YOU),
          },
          [SystemConstant.SEND_TYPE.message, SystemConstant.SEND_TYPE.groupMessage].includes(data.sendType)
            ? {
                onClick: data.sendType === SystemConstant.SEND_TYPE.image ? onCopyImage : onCopyContent,
                icon: <ContentCopy />,
                content: getLabel(getCommonKey(LangConstant.TXT_COPY)),
              }
            : null,
          MEDIA_TYPES.includes(data.sendType)
            ? {
                onClick: onDownLoadFile,
                icon: <Download />,
                content: getLabel(getCommonKey(LangConstant.TXT_DOWNLOAD)),
              }
            : null,
        ];

  let isHavingExtraInfo = (threadMessageArr && threadMessageArr.length > 0) || reactionList.length > 0;

  return message && message.content ? (
    <>
      {message?.seenMembers?.length > 0 && (
        <SeenMessage data={message.seenMembers} isShow={isShowSeenMembers} isGroupOrChannel={false} />
      )}
      {!(message.sendType === SystemConstant.SEND_TYPE.leaveGroup) &&
        isSending &&
        isMine &&
        message.senderDeviceId === deviceId && (
          <>
            <Typography className={classes.sendingStatus}>{getLabel(LangConstant.TXT_SENDING)}</Typography>
          </>
        )}

      {!(message.sendType === SystemConstant.SEND_TYPE.leaveGroup) &&
        !isSending &&
        isMine &&
        message.senderDeviceId === deviceId && (
          <>
            <Typography className={clsx(classes.sentStatus, !isHovered && classes.sentStatusHidden)}>
              {getLabel(
                message.options && convertString2JSON(message.options).seen_members?.length > 0
                  ? LangConstant.TXT_SEEN
                  : message.options && convertString2JSON(message.options).received_members?.length > 0
                  ? LangConstant.TXT_RECEIVED
                  : LangConstant.TXT_SENT,
              )}
            </Typography>
          </>
        )}

      {!(message.sendType === SystemConstant.SEND_TYPE.leaveGroup) &&
        isMine &&
        message.senderDeviceId === deviceId &&
        isSendFailed && (
          <Typography className={classes.failedStatus}>
            {getLabel(LangConstant.TXT_SEND_FAILED)} <Error />
          </Typography>
        )}

      <Box
        className={clsx(
          classes.chatItemRoot,
          isMine ? classes.chatItemRootFromMe : classes.chatItemRootReceived,
          isHavingExtraInfo && classes.messageWithExtra,
        )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        id={StringFormat(FormatConstant.FM_CHAT_ITEM_ID, data.id)}
      >
        {!isSearchMode && (
          <ActionMenu
            contentArray={
              !isThreading
                ? [
                    {
                      onClick: () => onReplyMessage(data),
                      icon: <Reply />,
                      content: getLabel(getCommonKey(LangConstant.TXT_REPLY)),
                    },
                    ...getOnclickWithData(removeUndefinedValueInArray(menuArray), data),
                  ]
                : getOnclickWithData(removeUndefinedValueInArray(menuArray), data)
            }
            onClose={onCloseChatItemMenu}
            anchorEl={menuAnchor}
            placement="top"
          />
        )}

        {/* emoji & send time */}
        {message.sendType !== SystemConstant.SEND_TYPE.leaveGroup && (
          <Box
            className={clsx(classes.menuAndTimer, {
              [classes.menuAndTimerReceived]: !isMine,
              [classes.hiddenReceived]: isEditing,
              [classes.biggerMargin]: isJSONString(message.content),
            })}
          >
            {!isSearchMode && (
              <Box
                className={clsx(classes.emojiAndMenu, {
                  [classes.emojiAndMenuReceived]: !isMine,
                })}
              >
                <IconButton
                  className={clsx(classes.chatMenuButton, {
                    hidden: !isHovered,
                  })}
                  onClick={onOpenEmojiMenu}
                  id={StringFormat(FormatConstant.FM_OPEN_EMOJI_BUTTON_ID, data.id)}
                >
                  <TagFaces className={classes.chatMenuIcon} />
                </IconButton>
                <IconButton
                  className={clsx(classes.chatMenuButton, {
                    hidden: !isHovered,
                  })}
                  onClick={onOpenChatItemMenu}
                >
                  <MoreHoriz className={classes.chatMenuIcon} />
                </IconButton>
              </Box>
            )}
            <Typography component={"span"} className={classes.messageSentTimeWrapper}>
              {message.isEdited && !isMine && <Edit className={classes.editedMessage} />}
              <Typography
                component={"span"}
                className={clsx("medium-sm-txt", classes.messageSentTime, {
                  [classes.messageSentTimeVisible]: isHovered,
                })}
              >
                {message.sentTime}
              </Typography>
              {message.isEdited && isMine && <Edit className={classes.editedMessage} />}
            </Typography>
          </Box>
        )}

        <Box
          className={
            isEditing ? classes.messageContentEditing : clsx(classes.messageContent, classes.messageMainContent)
          }
          onClick={() => {
            console.log({ message });
            setIsShowSeenMembers(!isShowSeenMembers);
          }}
        >
          {isMine && isSendFailed && (
            <IconButton className={clsx(classes.chatMenuButton, classes.resendButton)} onClick={onResendMessage}>
              <Replay />
            </IconButton>
          )}

          {(message.sendType === SystemConstant.SEND_TYPE.video ||
            message.sendType === SystemConstant.SEND_TYPE.image) &&
          isJSONString(message.content) ? (
            <ImagePreview
              data={{
                ...message,
                avatar: senderAvatar.src,
                senderName: senderAvatar.name,
              }}
              isAvatar={isAvatar}
              isMine={isMine}
              onClick={() => {
                if (!(isSending && isMine)) {
                  onMediaClicked();
                }
              }}
            />
          ) : message.isPreview ? (
            <FileDownLoad
              data={{
                ...message,
                avatar: senderAvatar.src,
                senderName: senderAvatar.name,
                isAvatar,
              }}
            />
          ) : message.sendType === SystemConstant.SEND_TYPE.file ? (
            <FilePreview
              data={{
                ...message,
                avatar: senderAvatar.src,
                senderName: senderAvatar.name,
                isAvatar,
              }}
            />
          ) : message.sendType === SystemConstant.SEND_TYPE.audio ? (
            <AudioChat
              data={{
                ...message,
                avatar: senderAvatar.src,
                senderName: senderAvatar.name,
                isAvatar,
              }}
            />
          ) : (
            message.sendType !== SystemConstant.SEND_TYPE.leaveGroup && (
              <MessengerPeople
                data={{
                  ...message,
                  avatar: senderAvatar.src,
                  senderName: senderAvatar.name,
                }}
                isAvatar={isAvatar}
                isMine={isMine}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            )
          )}
        </Box>
        <ReactionDetailDialog open={isOpenReactionDetail} onClose={onCloseReactionDetailDialog} data={reactionList} />
        {!isEditing && (
          <Box className={isMine ? classes.extraInfoMine : classes.extraInfo}>
            {reactionList.length > 0 && (
              <Box className={classes.reactCounterWrapper} onClick={onOpenReactionDetailDialog}>
                {displayReaction.map(react => (
                  <Typography component={"span"} key={react} dangerouslySetInnerHTML={{ __html: formatEmoij(react) }} />
                ))}
                {reactionList.length > 1 && (
                  <Typography component={"span"} className={classes.reactCounterNumber}>
                    {reactionList.length}
                  </Typography>
                )}
              </Box>
            )}
            {threadMessageArr && threadMessageArr.length > 0 && !isThreading && (
              <Box
                className={clsx(
                  isMine ? classes.threadCountBoxMine : classes.threadCountBox,
                  unreadThreadMsgs.length > 0 && classes.unreadBox,
                )}
                onClick={onReplyMessage}
              >
                {unreadThreadMsgs.length > 0 ? (
                  <>
                    <Box className={classes.newReplyCount}>+{unreadThreadMsgs.length}</Box>
                    <Typography className={classes.threadCount}>{getLabel(LangConstant.FM_NEW_REPLY_COUNT)}</Typography>
                  </>
                ) : (
                  <Typography className={classes.threadCount}>
                    {getLabel(LangConstant.FM_REPLY_COUNT, {
                      count: threadMessageArr.length,
                    })}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        )}

        {message.sendType === SystemConstant.SEND_TYPE.leaveGroup && (
          <LineChat
            data={getLabel(
              getNSKey(
                LangConstant.NS_HOME,
                message.senderId !== message.content
                  ? LangConstant.FM_GROUP_REMOVE_MEMBER
                  : isChannel
                  ? LangConstant.FM_CHANNEL_NOTIFICATION
                  : LangConstant.FM_GROUP_NOTIFICATION,
              ),
              {
                accountRemove: leaveGroupObj.accountRemovedName,
                senderName: leaveGroupObj.senderName,
                accountName: leaveGroupObj.senderName,
              },
            )}
          />
        )}

        <EmojiMenu
          open={Boolean(emojiAnchorEl) && !isOpenFullEmojiMenu}
          anchorEl={emojiAnchorEl}
          onClose={onCloseEmojiMenu}
          onOpenFullEmojiMenu={onOpenFullEmojiMenu}
          onSelectEmoji={onSelectEmoji}
        />

        <FullEmojiMenu
          open={Boolean(emojiAnchorEl) && isOpenFullEmojiMenu}
          onClose={onCloseEmojiMenu}
          anchorEl={emojiAnchorEl}
          onSelectEmoji={onSelectEmoji}
        />
        <ConfirmDeleteMessagePopup
          open={isOpenConfirmDialog}
          onCloseConfirmDialog={onCloseConfirmDialog}
          onConfirmDelete={onConfirmDelete}
        />
      </Box>
    </>
  ) : (
    <></>
  );
};

const getOnclickWithData = (array, data) => {
  let result = array.map(item => ({
    ...item,
    onClick: () => item.onClick(data),
  }));
  return result;
};

const MEDIA_TYPES = [SystemConstant.SEND_TYPE.audio, SystemConstant.SEND_TYPE.image, SystemConstant.SEND_TYPE.video];

export default memo(ChatItem, (prev, next) => {
  return (
    next.data &&
    compareObject(prev.data, next.data) &&
    prev.data?.modified === next.data?.modified &&
    prev.data?.options === next.data?.options &&
    prev.data?.content === next.data?.content &&
    prev.emojiValue === next.emojiValue
  );
});

const useStyles = makeStyles(theme => ({
  chatItemRoot: {
    position: "relative",
    paddingTop: 8,
    paddingBottom: 0,
  },

  chatItemRootFromMe: {
    display: "flex",
    justifyContent: "flex-end",
  },

  chatItemRootReceived: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
  },

  messageContent: {
    maxWidth: "50%",
    position: "relative",
    flexDirection: "inherit",
    cursor: "pointer",
  },

  messageSentTimeWrapper: {
    position: "absolute",
    top: -4,
    display: "flex",
    alignItems: "center",
  },

  messageSentTime: {
    display: "none",
    color: "#666666",
    flexShrink: 0,
    marginLeft: 4,
    lineHeight: "16px",
  },

  messageSentTimeVisible: {
    display: "block",
  },

  editedMessage: {
    color: theme.palette.grey[200],
    width: 12,
    height: 16,
    marginLeft: 4,
  },

  menuAndTimer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    minWidth: 12,
    marginLeft: 10,
    marginRight: 10,
    position: "relative",
  },

  biggerMargin: {
    marginLeft: 30,
  },

  menuAndTimerReceived: {
    alignItems: "flex-start",
    bottom: 2,
  },

  chatMenuIcon: {
    width: 20,
    height: 20,
  },

  chatMenuButton: {
    width: 24,
    height: 24,
    minHeight: 10,
    minWidth: 10,
    padding: 4,
  },

  emojiAndMenu: {
    display: "flex",
  },

  emojiAndMenuReceived: {
    flexDirection: "row-reverse",
  },

  extraInfo: {
    position: "absolute",
    bottom: -10,
    left: 80,
    display: "flex",
    flexDirection: "row-reverse",
  },

  extraInfoMine: {
    position: "absolute",
    bottom: -10,
    right: 30,
    display: "flex",
  },

  reactCounterWrapper: {
    display: "flex",
    backgroundColor: theme.palette.white,
    borderRadius: 13,
    padding: "0 4px",
    cursor: "pointer",
  },

  reactCounterNumber: {
    marginLeft: 3,
    fontWeight: 400,
    color: theme.palette.black,
  },

  hiddenReceived: {
    display: "none",
  },

  threadCountBox: {
    borderRadius: 12,
    padding: "0 4px",
    border: "1px solid white",
    backgroundColor: "#7F7F80",
    cursor: "pointer",
  },

  threadCountBoxMine: {
    borderRadius: 12,
    padding: "0 4px",
    border: "1px solid white",
    backgroundColor: theme.palette.primary.main,
    cursor: "pointer",
  },

  threadCount: {
    width: "max-content",
    fontSize: 15,
    fontWeight: 400,
    lineHeight: "20px",
    color: "#fff",
  },

  messageWithExtra: {
    marginBottom: 10,
  },

  sendingStatus: {
    textAlign: "end",
    padding: "0 28px",
    fontSize: 10,
    fontWeight: 450,
  },

  sentStatus: {
    textAlign: "end",
    padding: "0 28px",
    fontSize: 9,
    fontWeight: 450,
    opacity: 1,
    transition: "opacity 0.2s ease-in-out",
  },

  sentStatusHidden: {
    opacity: 0,
  },

  resendButton: {
    color: "red",
  },

  messageMainContent: {
    display: "flex",
    alignItems: "center",
  },

  messageMainContentReceived: {
    display: "flex",
  },

  failedStatus: {
    textAlign: "end",
    padding: "0 28px",
    fontSize: 12,
    fontWeight: 400,
    color: "red",
    marginTop: 5,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "&>svg": {
      fontSize: 18,
      marginLeft: 2,
    },
  },

  newReplyCount: {
    background: "red",
    borderRadius: "50%",
    width: 20,
    display: "flex",
    justifyContent: " center",
    marginRight: 5,
    color: "#fff",
  },

  unreadBox: {
    paddingLeft: 0,
    display: "flex",
    alignItems: "center",
  },
}));
