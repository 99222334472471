import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Avatar, Box, Typography } from "@mui/material";
import { Downloading, DownloadDone } from "@mui/icons-material";
import clsx from "clsx";
import {
  convertFileSizeUnit,
  convertJSONObject,
  convertString2JSON,
  downloadFile,
  getFileColor,
  getImageSrc,
  isJSONString,
  toCamel,
} from "utils";
import { getNSKey } from "utils/lang.utils";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { FilePreviewIcon } from "icons";
import { AttachmentService } from "services";
import { LocalAttachmentService } from "services/local.service";
import { StorageUtil } from "utils";

const FilePreview = ({ data }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  let accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);

  const [fileMetaData, setFileMetaData] = useState({});
  const [saved, setSaved] = useState(false);
  const onDownloadFile = () => {
    if (data.content && isJSONString(data.content)) {
      let content = toCamel(JSON.parse(data.content));
      let metaData = toCamel(JSON.parse(content.metaData));
      getImageSrc(
        content.attachmentId,
        SystemConstant.GROUP_CHAT_TYPE.group,
        metaData.mimeType,
        metaData.fileName,
      ).then(src => {
        if (metaData.fileName && src && metaData.mimeType) {
          downloadFile(metaData.fileName, src, metaData.mimeType);
        } else {
          alert(getLabel(getNSKey(LangConstant.NS_HOME_CONVERSATION, LangConstant.TXT_FILE_NOT_FOUND)));
        }
      });
    } else {
      alert(getLabel(getNSKey(LangConstant.NS_HOME_CONVERSATION, LangConstant.TXT_FILE_NOT_FOUND)));
    }
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) setFileMetaData(toCamel(convertJSONObject(data.content)));
  }, [data]);

  useEffect(() => {
    if (data.content && isJSONString(data.content)) {
      let content = toCamel(convertJSONObject(convertString2JSON(data.content)));
      if (
        !LocalAttachmentService.exitsLocalFile(
          content.attachmentId,
          SystemConstant.ATTACHMENT_TYPE.group,
          content?.metaData?.fileName,
        )
      ) {
        let response = AttachmentService.getAttachment({ attachment_id: content.attachmentId });
        response.then(responseData => {
          var encryptedBytes = new Uint8Array(responseData.data);
          LocalAttachmentService.decryptAESGroupFile(
            encryptedBytes,
            content?.aesKeyInfo?.key,
            content?.aesKeyInfo?.iv,
            content?.aesKeyInfo?.authtag,
            content?.attachmentId,
            content?.metaData?.fileName,
          ).then(() => {
            setSaved(true);
          });
        });
      } else {
        setSaved(true);
      }
    }
  }, []);

  let isMine = data.senderId === accountId;
  let isHaveData = Object.keys(fileMetaData).length > 0;

  return (
    isHaveData && (
      <>
        <Box className={clsx(classes.root, isMine ? classes.wrapPreviewSubFlex : classes.wrapItemFlex)}>
          <Box>
            {!isMine && (
              <Box className={classes.avatarBox}>
                <Typography className={classes.senderName}>
                  {data?.senderName + ","} {data.sentTime}
                </Typography>
              </Box>
            )}
            <Box
              className={clsx(
                classes.contentContainer,
                isMine ? classes.backgroundPreviewFlex : classes.receivedFileContainer,
              )}
            >
              <Box className={classes.flexPreview}>
                <Box className={classes.filePreviewIcon}>
                  <FilePreviewIcon color={getFileColor(fileMetaData?.metaData?.fileName.split(".")[1])} />
                </Box>
                <Box className={classes.wrapText}>
                  <Typography
                    variant="subtitle2"
                    className={clsx("ellipsis", isMine ? classes.wapTextSubFlex : classes.receivedFileContent)}
                  >
                    {fileMetaData?.metaData?.fileName}
                  </Typography>
                  <Typography
                    variant="caption"
                    className={isMine ? classes.wapTextSubPFlex : classes.receivedFileContent}
                  >
                    {convertFileSizeUnit(fileMetaData?.metaData?.size)}
                  </Typography>
                </Box>
                {saved ? (
                  <DownloadDone
                    onClick={onDownloadFile}
                    className={isMine ? classes.downloadIcon : classes.receivedFileDownload}
                  />
                ) : (
                  <Downloading
                    onClick={onDownloadFile}
                    className={isMine ? classes.downloadIcon : classes.receivedFileDownload}
                  />
                )}
              </Box>
            </Box>
          </Box>
          {!isMine && <Avatar src={data.avatar}>{data?.senderName?.charAt(0)}</Avatar>}
        </Box>
      </>
    )
  );
};

export default memo(FilePreview);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },

  contentContainer: {},

  backgroundPreview: {
    backgroundColor: "#F1F3F6",
    height: 65,
    padding: "0 24px",
    marginLeft: 14,
    borderRadius: 18,
  },

  backgroundPreviewFlex: {
    backgroundColor: "#008FE8",
    width: "fit-content",
    height: 62,
    padding: "0 24px",
    borderRadius: 18,
    display: "flex",
    alignItems: "center",
  },

  receivedFileContainer: {
    backgroundColor: "#F1F3F6",
    width: "fit-content",
    height: 62,
    padding: "0 24px",
    borderRadius: 18,
    display: "flex",
    alignItems: "center",
    marginLeft: 16,
  },

  flexPreview: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },

  wrapItemFlex: {
    width: "fit-content",
    backgroundColor: theme.palette.white,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    flexDirection: "row-reverse",
    marginRight: 14,
  },

  filePreviewIcon: {
    width: 48,
    height: 48,
    minWidth: 48,
    backgroundColor: theme.palette.white,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  wrapText: {
    flex: 1,
    marginLeft: 8,
    display: "flex",
    flexDirection: "column",
  },

  wapTextSubFlex: {
    color: theme.palette.white,
  },

  wapTextSubPFlex: {
    color: theme.palette.white,
    fontWeight: 600,
  },

  receivedFileContent: {
    color: "#1E272E",
    marginLeft: 4,
    fontWeight: 600,
  },

  messengerTimer: {
    color: "#666666",
    marginTop: 10,
    marginRight: 8,
  },

  receivedFileTime: {
    color: "#666666",
    marginTop: 10,
    marginLeft: 8,
  },

  wrapPreviewSubFlex: {
    display: "flex",
    alignItems: "end",
    justifyContent: "flex-end",
    marginLeft: 14,
  },

  downloadIcon: {
    color: theme.palette.white,
    cursor: "pointer",
    marginLeft: 20,
  },

  receivedFileDownload: {
    color: theme.palette.primary.main,
    marginLeft: 20,
    cursor: "pointer",
  },

  avatarBox: {
    paddingLeft: 16,
  },

  senderName: {
    paddingLeft: 5,
    fontSize: 12,
    fontWeight: 550,
    marginBottom: 5,
  },
}));
