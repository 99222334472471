import { Box, Dialog, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import { getCommonKey } from "utils/lang.utils";

const AlertDialog = ({ open, content, onClose, ...otherProps }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const classes = useStyles();

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} {...otherProps}>
      <Typography className={classes.root}>{getLabel(LangConstant.TXT_NOTIFICATION)}</Typography>
      <Typography className={clsx(classes.content, classes.root)}>{content}</Typography>
      <Box className={classes.actionBox}>
        <button className={classes.btn} autoFocus onClick={onClose}>
          {getLabel(getCommonKey(LangConstant.TXT_OK))}
        </button>
      </Box>
    </Dialog>
  );
};

export default AlertDialog;

const useStyles = makeStyles({
  root: {
    padding: "5px 20px",
    fontSize: 14,
  },

  paper: {
    width: 400,
    borderRadius: 0,
  },

  content: {
    marginTop: 20,
    marginBottom: 10,
  },

  actionBox: {
    backgroundColor: "#F0F0F0",
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
  },

  btn: {
    width: 64,
    marginRight: 20,
    cursor: "pointer",
    "&:focus": {
      borderColor: "#0078D7",
    },
  },
});
