import { all, call, put, select } from "redux-saga/effects";
import CallingAction from "redux-store/calling.redux";
import { ApiConstant, AppConstant, KeyConstant, SystemConstant } from "const";
import { BranchService, CallingService } from "services";
import ConversationAction from "redux-store/conversation.redux";
import { isObjectNotEqual, toCamel, toSnake, uuid } from "utils";
import { AttachmentService } from "services";
import { getSavedServer } from "utils/common.utils";
import { LocalCallHistoryService } from "services/local.service";
import { StorageUtil } from "utils";

const repository = window.repository;
const attachmentApi = window.attachment;

export function* checkCallingStatus(action) {
  try {
    const { data } = action;
    const { accountId: calleeAccountId } = data;
    const callerAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    const currentBranch = getSavedServer();
    const {
      callingRedux: { callingGroupDetail, isReceiver, isOpenCallingDialog },
    } = yield select();
    const lastCall = toCamel(LocalCallHistoryService.getByGroupId(callingGroupDetail?.id)[0]);

    const dataToToken = {
      branch_id: currentBranch.id,
      room_id: Boolean(isReceiver) ? lastCall?.roomId : uuid(),
      is_moderator: !Boolean(isReceiver),
    };

    const [callerResp, calleeResp] = yield all([
      call(CallingService.getCallingStatus, {
        account_id: callerAccountId,
      }),
      call(CallingService.getCallingStatus, {
        account_id: calleeAccountId,
      }),
    ]);

    let isInAnotherCall = false;
    let isCalleeInAnotherCall = false;

    if (callerResp.status === ApiConstant.STT_OK) {
      const data = toCamel(callerResp.data);
      isInAnotherCall =
        data.callingF === SystemConstant.CALL_CHECK.inCall || data.blockF === SystemConstant.ACCOUNT_CHECK.blocked;
      yield put(CallingAction.callingSet({ isInAnotherCall }));
    }

    if (calleeResp.status === ApiConstant.STT_OK) {
      const data = toCamel(calleeResp.data);
      isCalleeInAnotherCall =
        data.callingF === SystemConstant.CALL_CHECK.inCall || data.blockF === SystemConstant.ACCOUNT_CHECK.blocking;
      yield put(CallingAction.callingSet({ isCalleeInAnotherCall, isPingToCallee: true }));
    }

    if (false === isCalleeInAnotherCall && false === isInAnotherCall) {
      yield getBranches();
      const callingTokenRes = yield call(CallingService.postMeetToken, dataToToken);
      if (callingTokenRes.status === ApiConstant.STT_OK) {
        const callInfo = toCamel(callingTokenRes.data);
        StorageUtil.setItem(KeyConstant.KEY_MEET_OBJ, callInfo);
        yield put(
          CallingAction.callingSet({
            isSendMeetInfoSuccess: true,
          }),
        );
      } else {
        yield put(
          CallingAction.callingSet({
            isSendMeetInfoSuccess: false,
          }),
        );
      }
    }

    if (isOpenCallingDialog === AppConstant.CALLING_STATUS.checking) {
      yield put(
        CallingAction.callingSet({
          isOpenCallingDialog: AppConstant.CALLING_STATUS.finishChecking,
        }),
      );
    }

    yield put(CallingAction.callingSet({ isFetching: false }));
  } catch (error) {
    yield put(CallingAction.callingSet({ error: error, isFetching: false }));
  }
}

export function* checkReceiversStatus() {
  try {
    yield getBranches();
    const calleeAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    const currentBranch = getSavedServer();
    const {
      callingRedux: { callingGroupDetail, isOpenCallingDialog },
    } = yield select();
    const lastCall = toCamel(LocalCallHistoryService.getByGroupId(callingGroupDetail?.id)[0]);

    const dataToToken = {
      branch_id: currentBranch.id,
      room_id: lastCall?.roomId,
      is_moderator: false,
    };

    const [calleeResp] = yield all([
      call(CallingService.getCallingStatus, {
        account_id: calleeAccountId,
      }),
    ]);

    if (calleeResp.status === ApiConstant.STT_OK) {
      let data = toCamel(calleeResp.data);
      let isCalleeInAnotherCall = data.callingF === SystemConstant.CALL_CHECK.inCall;
      yield put(
        CallingAction.callingSet({
          isCalleeInAnotherCall,
          isPingToCallee: true,
          isReceiving: true,
        }),
      );

      if (false === Boolean(isCalleeInAnotherCall)) {
        const callingTokenRes = yield call(CallingService.postMeetToken, dataToToken);
        if (callingTokenRes.status === ApiConstant.STT_OK) {
          const callInfo = toCamel(callingTokenRes.data);
          StorageUtil.setItem(KeyConstant.KEY_MEET_OBJ, callInfo);
          yield put(
            CallingAction.callingSet({
              isSendMeetInfoSuccess: true,
            }),
          );
        } else {
          yield put(
            CallingAction.callingSet({
              isSendMeetInfoSuccess: false,
            }),
          );
        }
      }
    }

    if (isOpenCallingDialog === AppConstant.CALLING_STATUS.checking) {
      yield put(
        CallingAction.callingSet({
          isOpenCallingDialog: AppConstant.CALLING_STATUS.finishChecking,
        }),
      );
    }

    yield put(CallingAction.callingSet({ isFetching: false }));
  } catch (error) {
    yield put(CallingAction.callingSet({ error: error, isFetching: false }));
  }
}

export function* getCallHistory() {
  try {
    let sinceTime = 0;
    const {
      callingRedux: { callingGroupDetail },
    } = yield select();

    try {
      let loginTime = StorageUtil.getItem(KeyConstant.KEY_LOGIN_TIME) || 0;
      let lastRecord = getSavedServer() && repository.callHistory.getLastNotByMe()[0];

      sinceTime = lastRecord?.created ? lastRecord?.created + 1 : Number(loginTime);
    } catch (error) {
      console.log({ error });
    }
    const data = {
      sinceTime: sinceTime,
    };
    let response = yield call(CallingService.getCallHistory, toSnake(data));
    if (response.status === ApiConstant.STT_OK && response.data.data.length > 0) {
      let responseData = response.data.data;
      const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
      yield repository.callHistory.save(responseData);
      if (responseData.length > 0) {
        yield put(
          CallingAction.callingSet({
            isFetchHistorySuccess: true,
          }),
        );
      }
      if (responseData.find(item => item.sender_id === accountId)) {
        yield put(
          ConversationAction.conversationSet({
            isUpdateViewMode: Date.now(),
          }),
        );
      }

      let callHistory = responseData.find(item => item.room_id === callingGroupDetail?.roomId);
      if (callHistory) {
        let groupCallOptions = yield select(state => state.callingRedux.groupCallOptions);
        let newOptions = JSON.parse(callHistory.options);
        if (isObjectNotEqual(groupCallOptions, newOptions)) {
          yield put(CallingAction.callingSet({ groupCallOptions: newOptions }));
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export function* updateCallHistory(action) {
  try {
    const { data } = action;
    const {
      callingRedux: { callingGroupDetail },
    } = yield select();
    let response = yield call(CallingService.updateCallHistory, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let optionsUpdate = toCamel(JSON.parse(response.data.options));

      yield put(
        CallingAction.callingSet({
          callingGroupDetail: {
            ...callingGroupDetail,
            avatarId: optionsUpdate.avatarUrl,
            callName: optionsUpdate.callName,
          },
        }),
      );
    } else {
      yield put(
        CallingAction.callingSet({
          isUpdateSuccess: false,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getBranches() {
  try {
    let response = yield call(BranchService.getBranchList);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data;

      if (responseData.length > 0) {
        yield repository.branch.save(responseData);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getImageIncomingCall(action) {
  try {
    const { avatarId } = action.data;
    let fileName = avatarId + ".png";
    if (!attachmentApi.exitsLocalFile(avatarId, SystemConstant.ATTACHMENT_TYPE.group, fileName)) {
      let attachmentResponse = yield call(
        AttachmentService.getGlobalAttachment,
        toSnake({
          attachmentId: avatarId,
        }),
      );
      if (attachmentResponse.status === ApiConstant.STT_OK) {
        let unit8String = new Uint8Array(attachmentResponse.data);
        try {
          attachmentApi.saveFileUnEncryptedGroup(unit8String, avatarId, fileName);
        } catch (error) {
          console.log({ error });
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
