import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Badge, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { ContactIcon } from "icons";
import ContactListTab from "./ContactListTab";
import { PrimaryTab, TabPanel } from "components";
import ChatTab from "./ChatTab";
import AddingChat from "../../AddingChat";
import { useSelector } from "react-redux";
import { useCreateConversation, useServerMode, useUnreadMessage } from "hooks";
import { useTranslation } from "react-i18next";
import { ChatBubbleOutline, VideocamOutlined } from "@mui/icons-material";
import AddingConferenceDrawer from "../../AddingChat/AddingConferenceDrawer";
import { LocalGroupService } from "services/local.service";
import { ConversationActions } from "redux-store";
import { StorageUtil } from "utils";

const ViewModeTabs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { createWithPersonal } = useCreateConversation();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const { t: getConversationLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const { isBranchServer, selectedBranch } = useServerMode();
  const { allUnreadNumber } = useUnreadMessage();

  const isCreateGroupSuccess = useSelector(state => state.conversationRedux.isCreateGroupSuccess);
  const isFetchMessageSuccess = useSelector(state => state.conversationRedux.isFetchMessageSuccess);
  const isFetchNotificationSuccess = useSelector(state => state.conversationRedux.isFetchNotificationSuccess);

  const [selectedViewMode, setSelectedViewMode] = useState(VIEW_MODE_TAB_INDEX.message);
  const [isShowConference, setIsShowConference] = useState(false);

  // TODO: Need to refactor
  const onClickContact = useCallback(
    contact => {
      if (contact.groupType) {
        dispatch(
          ConversationActions.setSelectGroupId({
            selectedGroupId: contact.id,
          }),
        );
        setSelectedViewMode(VIEW_MODE_TAB_INDEX.message);
      } else {
        // When branch mode use account and when global mode use contact
        const otherMemberId = contact.contactAccountId;

        LocalGroupService.findInbox(
          [accountId, otherMemberId],
          SystemConstant.GROUP_CHAT_TYPE.personal,
          selectedBranch.id,
        ).then(result => {
          if (result && result.id) {
            dispatch(
              ConversationActions.setSelectGroupId({
                selectedGroupId: result.id,
              }),
            );
          } else {
            // Create a conversation if the user never chat before
            createWithPersonal([contact], contact.name);
          }
          setSelectedViewMode(VIEW_MODE_TAB_INDEX.message);
        });
      }
    },
    [isBranchServer],
  );

  const onChangeTabIndex = tabIndex => {
    if (tabIndex === VIEW_MODE_TAB_INDEX.conference) {
      setIsShowConference(true);
    } else {
      setSelectedViewMode(tabIndex);
    }
  };

  useEffect(() => {
    if (isCreateGroupSuccess) {
      setSelectedViewMode(VIEW_MODE_TAB_INDEX.message);
    }
  }, [isCreateGroupSuccess]);

  useEffect(() => {
    if (isFetchNotificationSuccess) {
      dispatch(
        ConversationActions.conversationSet({
          isFetchNotificationSuccess: false,
        }),
      );
    }
  }, [isFetchMessageSuccess]);

  // TODO: Need refactor
  const tabArray = [
    {
      label: getLabel(LangConstant.TXT_CHATS),
      icon: (
        <Badge badgeContent={allUnreadNumber} color="primary" classes={{ root: classes.numNotify, badge: "badge" }}>
          <ChatBubbleOutline />
        </Badge>
      ),
    },
    {
      label: isBranchServer ? getConversationLabel(LangConstant.TXT_MEMBERS) : getLabel(LangConstant.TXT_CONTACT),
      icon: <ContactIcon />,
    },
  ];

  if (isBranchServer) {
    tabArray.push({
      label: getLabel(LangConstant.TXT_CONFERENCE),
      icon: <VideocamOutlined />,
    });
  }

  return (
    <Box className={classes.viewModeRoot}>
      <TabPanel value={selectedViewMode} index={VIEW_MODE_TAB_INDEX.message} className={classes.tabPanel}>
        <ChatTab />
        <AddingChat />
      </TabPanel>

      <TabPanel value={selectedViewMode} index={VIEW_MODE_TAB_INDEX.contact} className={classes.tabPanel}>
        <ContactListTab onClick={onClickContact} />
      </TabPanel>

      <Box className={classes.tabContainer}>
        <PrimaryTab
          tabArray={tabArray}
          onChange={onChangeTabIndex}
          TabIndicatorProps={{
            className: "hidden",
          }}
          classes={{
            tabLabel: "regular-sm-txt",
          }}
          selectedTabIndex={selectedViewMode}
        />
      </Box>
      {isShowConference && <AddingConferenceDrawer open onClose={() => setIsShowConference(false)} />}
    </Box>
  );
};

const VIEW_MODE_TAB_INDEX = {
  message: 0,
  contact: 1,
  conference: 2,
};

ViewModeTabs.propTypes = {};

export default ViewModeTabs;

const useStyles = makeStyles(theme => ({
  viewModeRoot: {
    height: "-webkit-fill-available",
    maxHeight: "calc(100% - 142px)",
  },

  tabContainer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    padding: 4,
    borderTop: "0.5px solid " + theme.palette.divider,
    background: "white",
  },

  tabButton: {
    padding: 0,
    textTransform: "none",
    width: "auto",
    minWidth: "unset",
    flex: 1,
  },

  tabLabelBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    height: "100%",
  },

  tabLabel: {
    marginTop: 10,
  },

  tabPanel: {
    height: "100%",
    overflowY: "auto",
    paddingBottom: 66,
  },

  numNotify: {
    "& .badge": {
      top: 4,
      right: -5,
      backgroundColor: "#EE4F49",
      border: "2px solid white",
    },
  },
}));
