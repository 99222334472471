import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ProfileActions } from "redux-store";

// Listener changing and update online status
const useHandleOnlineStatus = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const updateOnlineStatus = hasInternet => _ =>
      dispatch(
        ProfileActions.profileSet({
          hasInternet: hasInternet,
        }),
      );

    window.addEventListener("offline", updateOnlineStatus(false));
    window.addEventListener("online", updateOnlineStatus(true));

    return () => {
      window.removeEventListener("offline", updateOnlineStatus(false));
      window.removeEventListener("online", updateOnlineStatus(true));
    };
  }, []);
};

export default useHandleOnlineStatus;
