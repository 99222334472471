import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Box, Button, CardMedia, Typography, Container } from "@mui/material";
import { Processing } from "components";
import clsx from "clsx";
import { getCommonKey } from "utils/lang.utils";
import { LangConstant, PathConstant, ImageConstant } from "const";
import { RestoreActions } from "redux-store";
import RestoreDrawer from "pages/HomePage/components/Account/BackupRestore/RestoreDrawer";

const RestoreData = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_LOGIN);
  
  const isSynchronizing = useSelector(state => state.conversationRedux.isSynchronizing);

  const [isShowRestore, setIsShowRestore] = useState(false);

  const onGetData = () => {
    dispatch(RestoreActions.restore({}));
    setIsShowRestore(true);
  };

  const onGotoHome = () => {
    history.replace(PathConstant.ROOT);
  };

  const handleCloseRestore = () => {
    onGotoHome();
    setIsShowRestore(false);
  };

  return (
    <>
      <Processing isShow={isSynchronizing} />
      <Container
        maxWidth="xl"
        classes={{
          root: classes.container,
          maxWidthXl: classes.container,
        }}
      >
        <Box className={classes.root}>
          <Box className={classes.nameMainForm}>
            <Typography variant="subtitle1" className={classes.nameTitle}>
              {getLabel(LangConstant.TXT_RESTORE_DATA)}
            </Typography>
            <CardMedia component="img" src={ImageConstant.LogoImage} className={classes.logoImg} />
            <Typography className={classes.restoreWarning}>
              {getLabel(LangConstant.TXT_RESTORE_DATA_WARNING)}
            </Typography>

            <Box className={classes.buttonWrap}>
              <Button
                variant="contained"
                className={clsx("semiBold-lg-txt", classes.continueButton)}
                onClick={onGetData}
              >
                {getLabel(getCommonKey(LangConstant.TXT_YES))}
              </Button>
              <Button
                type="submit"
                variant="contained"
                className={clsx("semiBold-lg-txt", classes.cancelButton)}
                onClick={onGotoHome}
              >
                {getLabel(getCommonKey(LangConstant.TXT_NO))}
              </Button>
            {isShowRestore && <RestoreDrawer open onClose={handleCloseRestore} hideBackdrop={false} />}
            </Box>

          </Box>

          <Box className={classes.imageContainer}>
            <CardMedia component="img" src={ImageConstant.LoginWelcomeImage} className={classes.loginWelcomeImage} />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default RestoreData;

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%",
    padding: 0,
  },

  root: {
    display: "flex",
    height: "100%",
  },
  buttonWrap: {
    width: "100%",
  },
  imageContainer: {
    width: "70%",
    backgroundColor: "#FAFAFA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  loginWelcomeImage: {
    width: "80%",
  },

  nameMainForm: {
    backgroundColor: theme.palette.white,
    width: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0 80px",
    [theme.breakpoints.down(1350)]: {
      padding: "0 50px",
    },
  },

  nameTitle: {
    fontWeight: 700,
    textAlign: "center",
    lineHeight: "20px",
    width: "100%",
    marginBottom: 15,
  },

  logoImg: {
    marginBottom: 15,
    maxWidth: 75,
  },

  continueButton: {
    marginTop: 15,
    width: "100%",
    padding: "14px 0",
  },

  cancelButton: {
    backgroundColor: "#72B9E6 !important",
    color: theme.palette.white + "!important",
    marginTop: 15,
    width: "100%",
    padding: "14px 0",
  },

  restoreWarning: {
    textAlign: "center",
  },
}));
