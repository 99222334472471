import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { CallingContext, isGroupOrChannelType } from "..";
import { AppConstant, FormatConstant, KeyConstant, SystemConstant } from "const";
import { useDispatch, useSelector } from "react-redux";
import { convertHex2rgba, convertString2JSON, toCamel, toSnake, uuid } from "utils";
import CallingAction from "redux-store/calling.redux";
import ConversationAction from "redux-store/conversation.redux";
import { Box, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { HorizontalRule } from "@mui/icons-material";
import useCallInfo from "../../../hooks/useCallInfo";
import WaitingScreen from "./WaitingScreen";
import CallLayout from "./CallLayout";
import { LocalAccountService, LocalAttachmentService, LocalCallHistoryService } from "services/local.service";
import { getSavedServer } from "utils/common.utils";
import StringFormat from "string-format";
import { StorageUtil } from "utils";

const repository = window.repository;
const { JitsiMeeting } = require("@jitsi/react-sdk");

const Conference = (props, jitsiApiRef) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    onSendMessage,
    localStream,
    deviceList,
    setCallingTime,
    setIsMute,
    setIsVideoing,
    isMute,
    isVideoing,
    setIsAudioAvailable,
    setIsVideoAvailable,
  } = useContext(CallingContext);
  const { callInfo } = useCallInfo();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const meetObj = StorageUtil.getItem(KeyConstant.KEY_MEET_OBJ);
  const currentServerOptions = toCamel(JSON.parse(getSavedServer().options));

  const createdMessage = useSelector(state => state.callingRedux.createdMessage);
  const isReceiver = useSelector(state => state.callingRedux.isReceiver);
  const isInAnotherCall = useSelector(state => state.callingRedux.isInAnotherCall);
  const isCalleeInAnotherCall = useSelector(state => state.callingRedux.isCalleeInAnotherCall);
  const hasInternet = useSelector(state => state.profileRedux.hasInternet);
  const selectedServer = toCamel(StorageUtil.getItem(KeyConstant.KEY_SELECTED_SERVER));
  const isHideCallingDialog = useSelector(state => state.callingRedux.isHideCallingDialog);
  const isVideoCall = useSelector(state => state.callingRedux.isVideoCall);
  const isCallAccepted = useSelector(state => state.callingRedux.isCallAccepted);
  const isInCall = useSelector(state => state.callingRedux.isInCall);
  const isOpenCallingDialog = useSelector(state => state.callingRedux.isOpenCallingDialog);
  const isSendMeetInfoSuccess = useSelector(state => state.callingRedux.isSendMeetInfoSuccess);

  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
  const [username, setUsername] = useState("");
  const [timeStart, setTimeStart] = useState(null);
  const [endCallStatus, setEndCallStatus] = useState(ENDING_STATUS.nothing);

  const isHideCallingDialogRef = useRef();
  const callInfoRef = useRef();

  const jitsiEmail = accountId + "@" + currentServerOptions.meetUrl;

  const handleJitsiEvent = () => {
    if (!jitsiApiRef.current) return;
    jitsiApiRef.current.executeCommand("subject", callInfo.callName || callInfo.name);
    let user = toCamel(LocalAccountService.getAccount(accountId));
    if (user) {
      setUsername(user.name);
      const userAvatarUrl = LocalAttachmentService.getAvatarRemoteUrl(
        user.avatarId,
        SystemConstant.ATTACHMENT_TYPE.account,
      );
      jitsiApiRef.current.executeCommand("avatarUrl", userAvatarUrl);
    }

    jitsiApiRef.current.isAudioAvailable().then(available => {
      setIsAudioAvailable(available);
    });

    jitsiApiRef.current.isVideoAvailable().then(available => {
      setIsVideoAvailable(available);
    });

    // if in conference screen, add event listener to update isMute & isVideoing state
    jitsiApiRef.current.addListener("audioMuteStatusChanged", listener => {
      if (!isHideCallingDialogRef.current) {
        setIsMute(listener.muted);
      }
    });

    jitsiApiRef.current.addListener("videoMuteStatusChanged", listener => {
      if (!isHideCallingDialogRef.current) {
        setIsVideoing(!listener.muted);
      }
    });

    jitsiApiRef.current.addListener("conferenceTerminated", listener => {
      console.log(listener);
    });

    jitsiApiRef.current.addListener("audioAvailabilityChanged", listener => {
      setIsAudioAvailable(listener.available);
    });

    jitsiApiRef.current.addListener("videoAvailabilityChanged", listener => {
      setIsVideoAvailable(listener.available);
    });

    jitsiApiRef.current.addListener("participantLeft", listener => {
      if (
        callInfo.groupType === SystemConstant.GROUP_CHAT_TYPE.personal &&
        jitsiApiRef.current.getNumberOfParticipants() < 2
      ) {
        setEndCallStatus(ENDING_STATUS.endByOtherUser);
      }
    });

    if (!isReceiver) setTimeStart(Date.now());
  };

  // send end message with call_status === MESSAGE_CALL_STATUS.waiting if you in conference call
  // send end message with call_status === MESSAGE_CALL_STATUS.end if you in personal call
  const onEndCall = () => {
    let calls = repository.callHistory.findByRoomId(meetObj.roomId);
    let isEnd = calls.filter(c => c.status !== SystemConstant.CALL_HISTORY_STATUS.calling).length > 0;
    let lastCall = toCamel(calls[0]);

    let callMessage = toCamel(repository.message.getMessageWithSourceId(lastCall.sourceId));
    let currentCallingMessage = createdMessage[callMessage.groupId] || callMessage;

    if (
      !isEnd &&
      (currentCallingMessage ||
        (lastCall &&
          lastCall.status === SystemConstant.CALL_HISTORY_STATUS.calling &&
          JSON.parse(lastCall.callingMembers).includes(deviceId)))
    ) {
      let endContent = {
        room_id: convertString2JSON(currentCallingMessage?.content)?.room_id,
      };

      onSendMessage({
        content: JSON.stringify(endContent),
        parentId: currentCallingMessage?.parentId ? currentCallingMessage?.parentId : currentCallingMessage?.sourceId,
        threadId: null,
        callStatus: isGroupOrChannelType(callInfo.groupType)
          ? SystemConstant.MESSAGE_CALL_STATUS.waiting
          : SystemConstant.MESSAGE_CALL_STATUS.end,
        roomId: endContent?.room_id,
      });
      StorageUtil.setItem(KeyConstant.KEY_MEET_OBJ, null);
      dispatch(
        CallingAction.callingSet({
          createdMessage: {
            ...createdMessage,
            [callInfo.id]: null,
          },
          isOpenCallingDialog: AppConstant.CALLING_STATUS.notStart,
          isReceiver: false,
          isInCall: false,
        }),
      );
      dispatch(
        ConversationAction.conversationSet({
          isUpdateViewMode: Date.now(),
        }),
      );
    }
    dispatch(CallingAction.callingReset());
    setTimeStart(0);
    localStream?.getVideoTracks()?.forEach(track => track.stop());
    localStream?.getAudioTracks()?.forEach(track => track.stop());
  };

  // join current call => send message with call_status === accept
  const handleAcceptCalling = () => {
    if (hasInternet) {
      const lastCall = toCamel(LocalCallHistoryService.getByGroupId(callInfo.id)[0]);
      if (lastCall?.roomId === callInfo?.roomId) {
        const messageContent = {
          room_id: lastCall?.roomId,
          type: "answer",
          aes_key: "",
        };

        const parentId = createdMessage[callInfo?.id]?.parentId
          ? createdMessage[callInfo?.id]?.parentId
          : createdMessage[callInfo?.id]?.sourceId;

        onSendMessage({
          parentId: parentId,
          threadId: null,
          callStatus: SystemConstant.MESSAGE_CALL_STATUS.accept,
          content: JSON.stringify(messageContent),
          roomId: lastCall?.roomId,
          isReceiver: isReceiver,
        });

        dispatch(
          CallingAction.callingSet({
            isConnectSuccess: true,
            isInCall: true,
            isCallAccepted: true,
          }),
        );

        setTimeStart(createdMessage[callInfo.id].created);
        saveCurrentCall(parentId);
        callInfoRef.current = callInfo;
      }
    } else {
      console.warn("NOT_NETWORK");
    }
  };

  const onHideCallDialog = () => {
    dispatch(CallingAction.callingSet({ isHideCallingDialog: true }));
  };

  const saveCurrentCall = parentId => {
    const savedServer = getSavedServer();
    const activeDevice = deviceList.filter(item => item.state === SystemConstant.DEVICE_STATE.active);
    const msgContent = JSON.stringify({
      room_id: meetObj.roomId,
    });
    const callStatus = isGroupOrChannelType(callInfo.groupType)
      ? SystemConstant.MESSAGE_CALL_STATUS.waiting
      : SystemConstant.MESSAGE_CALL_STATUS.end;
    const sendType = isGroupOrChannelType(callInfo.groupType)
      ? SystemConstant.SEND_TYPE.conference
      : isVideoCall
      ? SystemConstant.SEND_TYPE.personalVideoCall
      : SystemConstant.SEND_TYPE.personalCall;
    let deviceMsgId = "";

    const msgArr = activeDevice.map(device => {
      const msgId = uuid();
      if (device.id === deviceId) deviceMsgId = msgId;
      return {
        messageId: msgId,
        sendToDeviceId: device.id,
        sendToAccountId: device.account_id,
        content: msgContent,
        options: "",
        status: 1 /* SystemConstant.MESSAGE_STATUS.send */,
        sendType: sendType,
        mentions: JSON.stringify([]),
        callStatus: callStatus,
        roomId: meetObj.roomId,
      };
    });

    const dataCurrentCall = {
      groupType: callInfo.groupType,
      isSendingKey: false,
      messages: msgArr,
      parentId: parentId,
      sendType: sendType,
      sourceId: uuid(),
      roomId: meetObj.roomId,
      branchId: savedServer.id,
      groupId: callInfo.id,
      created: Date.now(),
      baseUrl: StringFormat(FormatConstant.FM_BASE_URL, savedServer.domain),
      accountId: accountId,
      currentDeviceId: deviceId,
      messageId: deviceMsgId,
      callStatus: callStatus,
    };

    const currentCall = {
      appToken: StorageUtil.getItem(KeyConstant.KEY_TOKEN),
      isAuthenticating: false,
      isRequestTriosAccess: false,
      data: JSON.parse(JSON.stringify(toSnake(dataCurrentCall))),
      triosAccess: StorageUtil.getItem(KeyConstant.KEY_TRIOS_ACCESS),
    };

    window.handleExternalLink.saveCurrentCall(currentCall);
  };

  useEffect(() => {
    let user = toCamel(window.repository.account.getAccount(accountId));
    if (user) {
      setUsername(user.name);
    }

    // starting conference call
    if (
      isOpenCallingDialog === AppConstant.CALLING_STATUS.finishChecking &&
      deviceList.length > 0 &&
      !isReceiver &&
      Object.keys(callInfo).length > 0 &&
      !isInAnotherCall &&
      !isCalleeInAnotherCall &&
      hasInternet &&
      isSendMeetInfoSuccess
    ) {
      let content = {
        room_id: meetObj.roomId,
        type: "offer",
        aes_key: "",
      };
      let msgId = uuid();
      let sourceId = uuid();

      dispatch(
        CallingAction.callingSet({
          createdMessage: {
            ...createdMessage,
            [callInfo.id]: {
              parentId: null,
              threadId: null,
              callStatus: SystemConstant.MESSAGE_CALL_STATUS.waiting,
              content: JSON.stringify(content),
              roomId: meetObj.roomId,
              sourceId: sourceId,
              id: msgId,
            },
          },
        }),
      );

      console.trace("Starting the call");

      let options = {
        avatarUrl: callInfo.avatarId,
        adminId: callInfo.adminId,
        callName: callInfo.callName,
        listMemberChoose: callInfo.listMemberChoose,
      };

      onSendMessage({
        parentId: null,
        threadId: null,
        callStatus: SystemConstant.MESSAGE_CALL_STATUS.waiting,
        content: JSON.stringify(content),
        roomId: meetObj.roomId,
        sourceId: sourceId,
        msgId: msgId,
        isReceiver: isReceiver,
        options: toSnake(options),
      });

      const isPersonalCall = callInfo.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;

      dispatch(
        CallingAction.callingSet({
          isConnectSuccess: true,
          isInCall: true,
          isRinging: isPersonalCall, // update isRinging = false ???
        }),
      );

      saveCurrentCall(sourceId);

      callInfoRef.current = callInfo;
    }
  }, [callInfo, isReceiver, isOpenCallingDialog]);

  useEffect(() => {
    const isAvailable = !isInCall && !isInAnotherCall && !isCalleeInAnotherCall && isSendMeetInfoSuccess;
    if (
      isReceiver &&
      isOpenCallingDialog === AppConstant.CALLING_STATUS.finishChecking &&
      callInfo?.roomId &&
      isAvailable
    ) {
      handleAcceptCalling();
    }
  }, [isReceiver, callInfo, isOpenCallingDialog]);

  useEffect(() => {
    let callTimeInterval = null;
    if (timeStart) {
      callTimeInterval = setInterval(() => {
        let startToCal = Date.now();

        if (isReceiver) startToCal -= 1000;
        let res = startToCal - timeStart;

        setCallingTime(res > 0 ? res : 0);
      }, 1000);
    }
    return () => {
      clearInterval(callTimeInterval);
    };
  }, [timeStart]);

  useEffect(() => {
    if (jitsiApiRef.current && isHideCallingDialog) {
      jitsiApiRef.current.executeCommand("toggleAudio");
    }
  }, [isMute, jitsiApiRef.current]);

  useEffect(() => {
    if (jitsiApiRef.current && isHideCallingDialog) {
      jitsiApiRef.current.executeCommand("toggleVideo");
    }
  }, [isVideoing, jitsiApiRef.current]);

  // TODO: Need to update logic for sync status video/ audio in conference - full screen (this component) and conference - small screen
  useEffect(() => {
    isHideCallingDialogRef.current = isHideCallingDialog;
  }, [isHideCallingDialog]);

  useEffect(() => {
    if (endCallStatus) onEndCall();
  }, [endCallStatus]);

  useEffect(() => {
    if (jitsiApiRef.current) {
      handleJitsiEvent();
    }
  }, [jitsiApiRef.current, callInfo]);

  const isCallingGroup =
    isGroupOrChannelType(callInfo.groupType) &&
    isOpenCallingDialog === AppConstant.CALLING_STATUS.finishChecking &&
    !isInAnotherCall;
  const isCallingPersonal =
    isOpenCallingDialog === AppConstant.CALLING_STATUS.finishChecking &&
    callInfo.groupType === AppConstant.GROUP_CHAT_TYPE.personal &&
    isCallAccepted;

  const hasJitsiData =
    callInfo.id &&
    meetObj &&
    meetObj.roomId &&
    toCamel(JSON.parse(selectedServer.options)).meetUrl &&
    meetObj.meetToken;
  const isShowJitsi = callInfo.id && isSendMeetInfoSuccess && (isCallingGroup || isCallingPersonal);
  return (
    <Box className={classes.container}>
      {!isShowJitsi && (
        <CallLayout>
          <WaitingScreen />
        </CallLayout>
      )}

      <Box sx={{ visibility: isShowJitsi ? "unset" : "hidden", background: "black" }}>
        <Box className={classes.topBar}>
          <IconButton className={classes.iconButton} onClick={onHideCallDialog}>
            <HorizontalRule className={classes.iconInButton} />
          </IconButton>
        </Box>
        {hasJitsiData && (
          <JitsiMeeting
            domain={toCamel(JSON.parse(selectedServer.options)).meetUrl}
            roomName={meetObj.roomId}
            jwt={meetObj.meetToken}
            configOverwrite={{
              apiLogLevels: ["error"],
              startWithVideoMuted: !isVideoCall && !isGroupOrChannelType(callInfo.groupType),
              disableModeratorIndicator: isGroupOrChannelType(callInfo.groupType) ? false : true,
              participantsPane: {
                hideModeratorSettingsTab: true,
                hideMoreActionsButton: true,
                hideMuteAllButton: true,
              },
              hideAddRoomButton: true,
              startScreenSharing: false,
              enableEmailInStats: false,
              prejoinConfig: {
                enabled: false,
              },
              hideConferenceSubject: !isGroupOrChannelType(callInfo.groupType),
              hideConferenceTimer: true,
              iceConnectionReceivingTimeout: 15000,
              toolbarButtons: isGroupOrChannelType(callInfo.groupType)
                ? ["camera", "microphone", "tileview", "raisehand", "participants-pane", "hangup"]
                : ["camera", "microphone", "tileview", "hangup"],
            }}
            interfaceConfigOverwrite={{
              DISABLE_JOIN_LEAVE_NOTIFICATIONS: !isGroupOrChannelType(callInfo.groupType),
            }}
            userInfo={{
              email: jitsiEmail,
              displayName: username,
            }}
            onApiReady={externalApi => (jitsiApiRef.current = externalApi)}
            onReadyToClose={() => setEndCallStatus(ENDING_STATUS.endByMyself)}
            getIFrameRef={iframeRef => {
              iframeRef.style.height = "100vh";
              iframeRef.style.width = "100vw";
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default forwardRef(Conference);

const ENDING_STATUS = {
  nothing: 0,
  endByMyself: 1,
  endByOtherUser: 2,
};

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#000000",
  },

  topBar: {
    position: "absolute",
    right: 12,

    backgroundColor: "#000000",
    paddingTop: 10,
  },

  iconButton: {
    width: 36,
    height: 36,
    padding: 9,
    backgroundColor: "#383737",
    "&:hover": {
      backgroundColor: convertHex2rgba("#000000", 0.8),
    },
  },

  iconInButton: {
    height: 18,
    width: 18,
    color: "white",
  },
}));
