import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BranchActions, BranchSelectors } from "redux-store";
import useServerMode from "./useServerMode";

// Listener changing and update selectServer
const useHandleServerMode = () => {
  const dispatch = useDispatch();
  const { saveServerMode } = useServerMode();

  const defaultBranch = useSelector(BranchSelectors.getDefaultBranch);

  useEffect(() => {
    if (defaultBranch) {
      dispatch(
        BranchActions.branchSet({
          defaultBranch: null,
        }),
      );
      saveServerMode(defaultBranch);
    }
  }, [defaultBranch]);
};

export default useHandleServerMode;
