import React, { memo } from "react";
import PropTypes from "prop-types";
import { Divider, ListItem, ListItemButton, Typography, ListItemText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const AccountItemProfile = ({ text, classes, onClick }) => {
  const defaultClasses = useStyles();

  return (
    <>
      <Divider sx={{ color: "#F5F5F5" }} />
      <ListItem disablePadding>
        <ListItemButton onClick={onClick}>
          <ListItemText
            primary={<Typography className={clsx(defaultClasses.text, classes?.text)}>{text}</Typography>}
          />
        </ListItemButton>
      </ListItem>
    </>
  );
};

AccountItemProfile.propTypes = { text: PropTypes.string, onClick: PropTypes.func };

export default memo(AccountItemProfile);

const useStyles = makeStyles({
  text: {
    fontSize: 15,
    fontWeight: 400,
    color: "#1E272E",
  },
});
