import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { ArrowBack } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LangConstant, SystemConstant } from "const";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import ThreadViewMode from "./ThreadViewMode";
import { ConversationActions } from "redux-store";
import PeopleIcon from "@mui/icons-material/People";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import LockIcon from "@mui/icons-material/Lock";
import { useThreadInfo } from "hooks";
import { checkOnlineStatus } from "utils/common.utils";
import { LocalAccountService, LocalMessageService } from "services/local.service";

const Thread = ({ onSendMessage, groupDetail }) => {
  const { groupMembers: members } = groupDetail;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { totalThreads } = useThreadInfo();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);

  const threadingId = useSelector(state => state.conversationRedux.threadingId);

  const [senderParentMsg, setSenderParentMsg] = useState({});
  const [isOnline, setIsOnline] = useState(false);

  const isChannel = groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.channel;
  const isGroup = groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.group;
  const isPersonal = groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;
  const isChannelPrivate = isChannel && Boolean(groupDetail.privateF);
  let checkOnlineInterval = null;

  const onCloseThreading = () => {
    dispatch(
      ConversationActions.setSelectGroupId({
        threadingId: null,
        selectedGroupId: groupDetail.id,
      }),
    );
  };

  const getParentSender = async () => {
    const parentMsg = await LocalMessageService.getMessageWithSourceId(threadingId);
    const senderId = parentMsg.sender_id;
    const senderAccount = LocalAccountService.getAccountByIds([senderId])[0];
    
    setSenderParentMsg(senderAccount);
  };

  useEffect(() => {
    const threadInfo = totalThreads.filter(item => item.threadId === threadingId);
    if (threadInfo && threadInfo.length > 0) {
      setSenderParentMsg(threadInfo[0].senderParentMsg);
    } else {
      getParentSender();
    }
  }, [totalThreads, threadingId]);

  useEffect(() => {
    if (threadingId && isPersonal && senderParentMsg) {
      checkOnlineInterval = setInterval(() => {
        setIsOnline(Boolean(checkOnlineStatus(senderParentMsg.id)));
      }, 1000);
    }

    return () => {
      clearInterval(checkOnlineInterval);
    };
  }, [threadingId, senderParentMsg]);

  return (
    <Box className={classes.container}>
      <Box className={classes.threadTitle}>
        <IconButton onClick={onCloseThreading}>
          <ArrowBack />
        </IconButton>
        <Box className={classes.titleText}>
          <Typography className={classes.title}>{getLabel(LangConstant.TXT_THREAD)}</Typography>
          <Box className={classes.subtitleBox}>
            {isPersonal ? (
              <>
                <Box className={isOnline ? classes.activeState : classes.inactiveStatus} />
                <Typography className={clsx(classes.subtitle, "regular-sm-txt")}>{senderParentMsg?.name}</Typography>
              </>
            ) : (
              <>
                {isGroup ? (
                  <PeopleIcon className={classes.icon} />
                ) : isChannelPrivate ? (
                  <LockIcon className={classes.icon} />
                ) : (
                  <Grid3x3Icon className={classes.icon} />
                )}
                <Typography className={clsx(classes.subtitle, "regular-sm-txt")}>{groupDetail.groupName}</Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box className={classes.viewContainer}>
        <ThreadViewMode onSendMessage={onSendMessage} isChannel={isChannel} members={members} />
      </Box>
    </Box>
  );
};

export default Thread;

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    backgroundColor: theme.palette.white,
    border: 20,
    width: "100%",
    height: "calc(100% - 177px)",
    flex: 1,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },

  threadTitle: {
    padding: "35px 28px",
    display: "flex",
    alignItems: "center",
  },

  title: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 4,
  },

  subtitle: {
    color: theme.palette.grey[500],
  },

  titleText: {
    marginLeft: 20,
  },

  viewContainer: {
    overflow: "auto",
  },

  activeState: {
    width: 10,
    height: 10,
    backgroundColor: "#0bd50b",
    borderRadius: "50%",
    marginRight: 4,
  },

  inactiveStatus: {
    width: 10,
    height: 10,
    backgroundColor: "gray",
    borderRadius: "50%",
    marginRight: 4,
  },

  icon: {
    color: "gray",
    fontSize: 16,
    marginRight: 4,
  },

  subtitleBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
