import { ApiConstant } from "const";
import qs from "qs";
import GlobalApi from "./config/api.config";

export const getAccountByIds = data =>
  GlobalApi.post(ApiConstant.POST_GET_ACCOUNT, qs.stringify(data, { skipNulls: true }));

export const getLoginAccount = () => GlobalApi.post(ApiConstant.POST_GET_ACCOUNT);

export const getAccountList = data => GlobalApi.get(ApiConstant.GET_ACCOUNT_LIST, data);

export const updateAccount = data =>
  GlobalApi.post(
    ApiConstant.POST_UPDATE_ACCOUNT,
    qs.stringify(data, {
      skipNulls: true,
    }),
  );

export const uploadFileAccount = data => GlobalApi.post(ApiConstant.POST_UPLOAD_FILE_ACCOUNT, data);

export const blockAccount = data =>
  GlobalApi.post(ApiConstant.POST_BlOCK_ACCOUNT, qs.stringify(data, { skipNulls: true }));

export const unblockAccount = data =>
  GlobalApi.post(ApiConstant.POST_UNBLOCK_USER, qs.stringify(data, { skipNulls: true }));
