import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSavedServer } from "utils/common.utils";
import { KeyConstant, SystemConstant } from "const";
import { getImageSrcAvatar, isJSONString, toCamel } from "utils";
import { BranchActions, BranchSelectors } from "redux-store";
import { BGlobalServerImage } from "theme/images";
import useSynchronize from "./useSynchronize";
import { StorageUtil } from "utils";

const useServerMode = () => {
  const dispatch = useDispatch();
  const { synchronize } = useSynchronize();

  const selectedServerRedux = useSelector(BranchSelectors.getSelectedBranch);

  const [selectedBranch, setSelectedBranch] = useState({});
  const [serverOption, setServerOption] = useState(new ServerOption());

  const saveServerMode = async selectedServer => {
    if (selectedServer.attachmentId && false === Boolean(selectedServer.branchIcon)) {
      const branchIconUrl = await getImageSrcAvatar(
        selectedServer.attachmentId,
        SystemConstant.GROUP_CHAT_TYPE.personal,
      );

      selectedServer.branchIcon = branchIconUrl || BGlobalServerImage;
    }

    StorageUtil.setItem(KeyConstant.KEY_SELECTED_SERVER, selectedServer);
    dispatch(
      BranchActions.branchSet({
        selectedBranch: selectedServer,
      }),
    );

    setSelectedBranch(selectedServer);

    const newServerOptions = getServerOptions(selectedServer.options);
    setServerOption(new ServerOption(newServerOptions));

    // Synch data after select server
    synchronize();
  };

  useEffect(() => {
    const storedServer = getSavedServer();
    const newServerMode = selectedServerRedux || storedServer || {};

    if (newServerMode.id !== selectedBranch.id) {
      setSelectedBranch(newServerMode);

      const newServerOptions = getServerOptions(newServerMode.options);
      setServerOption(new ServerOption(newServerOptions));
    }
  }, [selectedServerRedux]);

  return {
    selectedBranch,
    serverOption,
    isBranchServer: selectedBranch.type === SystemConstant.SERVER_TYPE.branch,
    saveServerMode,
  };
};

export default useServerMode;

const MAX_CALL_PERSON = 20;
const DEFAULT_SEVER_OPTIONS = {
  meetMaxCallPerson: MAX_CALL_PERSON,
};

class ServerOption {
  constructor(data = DEFAULT_SEVER_OPTIONS) {
    this.meetMaxCallPerson = data?.meetMaxCallPerson || MAX_CALL_PERSON;
  }
}

const getServerOptions = options => {
  return options && isJSONString(options) ? toCamel(JSON.parse(options)) : DEFAULT_SEVER_OPTIONS;
};
