import { ApiConstant, AppConstant, KeyConstant } from "const";
import { call, put, select } from "redux-saga/effects";
import { AuthService, AccountKeyService } from "services";
import { handlingLogin, toCamel, toSnake } from "utils";
import { LocalKeyActionService } from "services/local.service";
import { setApiHeader } from "services/config/api.config";
import { getBranch } from "./branch.saga";
import { AuthActions } from "redux-store";
import { StorageUtil } from "utils";

export function* requestLogin(action) {
  try {
    const { data } = action;
    const params = toSnake(data);

    const response = yield call(AuthService.login, params);
    const dataLogin = toCamel(response.data);
    if (response.status === ApiConstant.STT_OK && dataLogin) {
      yield put(
        AuthActions.authSuccess({
          isSuccess: true,
          phoneNumber: data.phone,
          accountUuid: data.accountUuid,
          deviceUuid: data.deviceUuid,
          token: data.token,
          isNew: response.data.isNew,
          masterExisted: dataLogin.masterExisted,
          masterName: response.data.master_name,
        }),
      );
    } else {
      yield handleErrorResponse(response);
    }
  } catch (error) {
    yield put(AuthActions.authFailure(error));
  }
}

export function* requestVerify(action) {
  try {
    const {
      authRedux: { token, accountUuid, deviceUuid },
    } = yield select();
    const { data } = action;
    const params = {
      ...data,
      token: token,
      account_uuid: accountUuid,
      device_uuid: deviceUuid,
    };

    const response = yield call(AuthService.verify, params);
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        AuthActions.authSuccess({
          isLogin: true,
        }),
      );

      const camelResponse = toCamel(response.data);
      handlingLogin(camelResponse);
      setApiHeader(camelResponse);

      yield getBranch();

      // TODO: Review upload key part here if it makes sense.
      const keys = LocalKeyActionService.getAllKeys();
      const uploadResponse = yield call(AccountKeyService.uploadKeys, keys);
      if (uploadResponse.status === ApiConstant.STT_OK) {
        StorageUtil.setItem(KeyConstant.KEY_FIRST_UPLOAD_F, true);
      }
    } else {
      yield handleErrorResponse(response);
    }
  } catch (error) {
    yield put(AuthActions.authFailure(error));
  }
}

function* handleErrorResponse(response) {
  const {
    authRedux: { retries },
  } = yield select();

  switch (response.status) {
    case ApiConstant.STT_FORBIDDEN:
      yield put(
        AuthActions.authSuccess({
          isLimitedResend: true,
        }),
      );
      break;

    case ApiConstant.STT_BAD_REQUEST:
      yield put(
        AuthActions.authSuccess({
          isWrongOtp: true,
          retries: retries < AppConstant.MAX_RETRY_TIMES ? retries + 1 : AppConstant.MAX_RETRY_TIMES,
        }),
      );
      break;

    case ApiConstant.STT_NOT_FOUND:
      yield put(
        AuthActions.authSuccess({
          isNotFoundPhone: true,
        }),
      );
      break;

    case ApiConstant.STT_OTP_EXPIRED:
      yield put(
        AuthActions.authSuccess({
          isOtpExpired: true,
        }),
      );
      break;

    case ApiConstant.STT_LIMIT_DEVICE:
      yield put(
        AuthActions.authSuccess({
          isLimitDevice: true,
        }),
      );
      break;

    default:
      yield put(AuthActions.authFailure(response.data));
      break;
  }
}
