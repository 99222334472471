import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { ArrowForward } from "@mui/icons-material";
import { Box, Divider, Grid, IconButton, List, Radio, Typography } from "@mui/material";
import PropTypes from "prop-types";
import clsx from "clsx";
import { convertHex2rgba, objectToJSON } from "utils";
import { LangConstant } from "const";
import { AvatarBadge, AvatarInfo, DrawerLayout, SearchBar } from "components";

const AddMemberDrawer = ({ open, onMovingOn, onClose, dataAddNew }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);

  const [selectedContact, setSelectedContact] = useState([]);

  const onCreateSelected = useCallback(
    item => () => {
      setSelectedContact(preSelectedContact => {
        const newContactIndex = preSelectedContact.findIndex(contact => objectToJSON(contact) === objectToJSON(item));
        return newContactIndex >= 0
          ? preSelectedContact.filter(contact => objectToJSON(contact) !== objectToJSON(item))
          : [...preSelectedContact, item];
      });
    },
    [],
  );

  const onDetectItem = useCallback(
    item => selectedContact.findIndex(contact => objectToJSON(contact) === objectToJSON(item)) >= 0,
    [selectedContact],
  );

  const onNext = () => {
    onMovingOn(selectedContact);
  };

  return (
    <DrawerLayout onClose={onClose} open={open} title={getLabel(LangConstant.TXT_ADD_NEW_MEMBER)}>
      <IconButton className={classes.nextButton} onClick={onNext}>
        <ArrowForward />
      </IconButton>
      <Box className={classes.root}>
        <SearchBar
          classes={{
            root: classes.searchBar,
          }}
        />
        <Grid container className={classes.selectedList}>
          {selectedContact.map(item => (
            <Grid item xs={3} key={item.id}>
              <AvatarBadge avatar={{ ...item, src: item.image }} onClick={onCreateSelected(item)}>
                {item.name}
              </AvatarBadge>
            </Grid>
          ))}
        </Grid>
        <Box className={classes.memberList}>
          <Typography className={clsx(classes.listTitle, "semiBold-sm-txt")}>
            {getLabel(LangConstant.TXT_PEOPLE)}
          </Typography>
          <List>
            {dataAddNew?.map((item, index) => (
              <>
                {index > 0 && <Divider variant="inset" component="li" />}
                <AvatarInfo
                  key={item.id}
                  avatar={{ src: item.image, name: item.name }}
                  title={item.name}
                  onClick={onCreateSelected(item)}
                  button={true}
                  endComponent={<Radio checked={onDetectItem(item)} />}
                />
              </>
            ))}
          </List>
        </Box>
      </Box>
    </DrawerLayout>
  );
};

AddMemberDrawer.propTypes = {
  onMovingOn: PropTypes.func,

  open: PropTypes.bool,
};

AddMemberDrawer.defaultProps = {
  onMovingOn: () => {},

  open: false,
};

export default AddMemberDrawer;

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    padding: "12px 15px",
    paddingTop: 0,
  },

  memberList: {
    marginTop: 12,
  },

  listTitle: {
    color: theme.palette.text.secondary,
    lineHeight: "20px",
  },

  selectedList: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 16,
  },

  searchBar: {
    position: "sticky",
    top: 0,
    zIndex: theme.zIndex.drawer,
  },

  nextButton: {
    position: "absolute",
    bottom: 20,
    right: 20,
    zIndex: theme.zIndex.drawer,
    width: 54,
    height: 54,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: convertHex2rgba(theme.palette.primary.main, 0.8),
    },
  },
}));
