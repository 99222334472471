import { createReducer, createActions } from "reduxsauce";
import { hasLogin } from "utils";
import {
  REDUX_STATE,
  requestReducerFunc,
  failureReducerFunc,
  successReducerFunc,
  setReducerFunc,
} from "./redux-structure";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  requestLogin: ["data"],
  requestVerify: ["data"],

  authSuccess: ["data"],
  authFailure: ["error", "data"],
  authSet: ["data"],

  changeBackgroundSuccess: ["data"],
});

export const AuthTypes = Types;
export const AuthActions = Creators;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  ...REDUX_STATE,
  isLogin: hasLogin(),

  phoneNumber: null,
  isNotFoundPhone: false,
  isWrongOtp: false,
  retries: 0,
  isLimitedResend: false,
  isLimitDevice: false,
  isOtpExpired: false,
  fullLoginForm: {},
  isNew: false,
  masterExisted: false,

  downloadProcess: null,
};

/* ------------- Selector ------------- */
export const AuthSelectors = {
  isLogin: state => state.authRedux.isLogin,
};

/* ------------- Reducers ------------- */
const request = (state = INITIAL_STATE) => requestReducerFunc(state);

const requestLogin = (state = INITIAL_STATE) =>
  requestReducerFunc({
    ...state,
    isWrongOtp: false,
    isLogin: null,
  });

const set = (state = INITIAL_STATE, action) => setReducerFunc(state, action);

const success = (state = INITIAL_STATE, action) => successReducerFunc(state, action);

const failure = (state = INITIAL_STATE, action) => failureReducerFunc(state, action);

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.REQUEST_LOGIN]: requestLogin,
  [Types.REQUEST_VERIFY]: request,

  [Types.AUTH_SET]: set,
  [Types.AUTH_SUCCESS]: success,
  [Types.AUTH_FAILURE]: failure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const AuthReducer = createReducer(INITIAL_STATE, HANDLERS);
