import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Error, Phone, VideoCall } from "@mui/icons-material";
import { Avatar, Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CallingAction from "redux-store/calling.redux";
import ConversationAction from "redux-store/conversation.redux";
import { AvatarConversation } from "components";
import { AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import clsx from "clsx";
import GroupInfo from "../GroupInfo";
import SearchPopup from "./SearchPopup";
import { getImageSrcAvatar, isArrayNotEquals, toCamel } from "utils";
import { getNSKey } from "utils/lang.utils";
import { checkOnlineStatus, getSavedServer } from "utils/common.utils";
import PublicChanelAva from "../../../../public.png";
import PrivateChanelAva from "../../../../private.png";
import InitGroupCallPopup from "./InitGroupCallPopup";
import { isGroupOrChannelType } from "pages/Call";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { useBlockedAccountStatus, useServerMode } from "hooks";
import { LocalCallHistoryService } from "services/local.service";
import { StorageUtil } from "utils";

const repository = window.repository;
let checkOnlineInterval = null;

const TitleChat = ({ data, isOpenSearchPopup, setIsOpenSearchPopup, isAdminGroup, isInactive }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchAnchorRef = useRef(null);
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const { serverOption } = useServerMode();
  const { isBlockedAccount, isBlockedByAccount } = useBlockedAccountStatus();

  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);

  const isFetchHistorySuccess = useSelector(state => state.callingRedux.isUpdateViewMode);
  const isFetchMessageSuccess = useSelector(state => state.conversationRedux.isUpdateNotificationSuccess);
  const selectedId = useSelector(state => state.conversationRedux.selectedGroupId);
  const hasInternet = useSelector(state => state.profileRedux.hasInternet);
  const demandingVoiceCall = useSelector(state => state.conversationRedux.demandingVoiceCall);
  const demandingVideoCall = useSelector(state => state.conversationRedux.demandingVideoCall);
  const createdMessage = useSelector(state => state.callingRedux.createdMessage);
  const isInCall = useSelector(state => state.callingRedux.isInCall);
  const isInAnotherCall = useSelector(state => state.callingRedux.isInAnotherCall);

  const [isShowGroupDetail, setIsShowGroupDetail] = useState(false);
  const [members, setMembers] = useState([]);
  const [callingDetail, setCallingDetail] = useState(null);
  const [userAvatar, setUserAvatar] = useState("");
  const [isOnline, setIsOnline] = useState(false);
  const [openInitGroupCall, setOpenInitGroupCall] = useState(false);
  const [groupDetail, setGroupDetail] = useState({});

  const onOpenGroupInfoDrawer = () => {
    setIsShowGroupDetail(true);
  };

  // start conference call
  const onStartConference = () => {
    setOpenInitGroupCall(true);
  };

  // start audio call
  // call api to check receiver is in another call or not
  const onOpenAudioCallWindow = () => {
    onSendEndLastCall();
    dispatch(
      CallingAction.callingSet({
        isOpenCallingDialog: AppConstant.CALLING_STATUS.checking,
        callingGroupDetail: groupDetail,
        isReceiver: false,
      }),
    );
    dispatch(CallingAction.onCallCheck({ accountId: groupDetail.members[0].id }));
  };

  // start video call
  // call api to check receiver is in another call or not
  const onOpenVideoCallWindow = () => {
    onSendEndLastCall();
    dispatch(
      CallingAction.callingSet({
        isOpenCallingDialog: AppConstant.CALLING_STATUS.checking,
        callingGroupDetail: groupDetail,
        isVideoCall: true,
        isReceiver: false,
      }),
    );

    dispatch(CallingAction.onCallCheck({ accountId: groupDetail.members[0].id }));
  };

  // TODO: check condition to end last call
  const onJoinCurrentCall = () => {
    let lastCall = toCamel(LocalCallHistoryService.getByGroupId(groupDetail.id)[0]);
    if (
      lastCall &&
      lastCall.status === SystemConstant.CALL_HISTORY_STATUS.calling &&
      JSON.parse(lastCall.callingMembers).includes(deviceId)
    ) {
      onSendEndLastCall();
    } else {
      if (lastCall && lastCall.status === SystemConstant.CALL_HISTORY_STATUS.calling) {
        dispatch(CallingAction.getCallHistory());
        let roomId = lastCall.roomId;
        // join current conference call
        dispatch(
          CallingAction.callingSet({
            isOpenCallingDialog: AppConstant.CALLING_STATUS.checking,
            callingGroupDetail: { ...groupDetail, roomId: roomId },
            isReceiver: true,
            createdMessage: {
              ...createdMessage,
              [groupDetail.id]: callingDetail,
            },
            isVideoCall: callingDetail.sendType === SystemConstant.SEND_TYPE.groupVideoCall,
          }),
        );

        dispatch(CallingAction.onCallCheck({ accountId: accountId }));
      } else {
        dispatch(
          CallingAction.callingSet({
            isCallEnded: groupDetail.id,
          }),
        );
      }
    }
  };

  const onSendEndLastCall = () => {
    // If current user with current device is in another call, send end message to that group
    let lastCall = toCamel(repository.callHistory.getByGroupId(groupDetail.id)[0]);

    let savedServer = getSavedServer();

    let callMessage = toCamel(repository.message.getMessageWithSourceId(lastCall.sourceId));

    if (
      lastCall &&
      lastCall.status === SystemConstant.CALL_HISTORY_STATUS.calling &&
      JSON.parse(lastCall.callingMembers).includes(deviceId)
    ) {
      let endContent = JSON.stringify({
        room_id: lastCall.roomId,
      });

      let groupMembers = groupDetail.members;
      let memberIdArray = groupMembers.map(member => member.id) || [];

      let deviceList = repository.device.findByList("account_id", memberIdArray);
      console.log("Sending end message from chat title", { callMessage });

      dispatch(
        ConversationAction.sendMessage({
          groupDetail: groupDetail,
          sendType: callMessage?.sendType,
          content: endContent,
          parentId: callMessage.parentId,
          deviceList: [...deviceList],
          branchId: savedServer?.id,
          mentionIdsArr: [],
          threadId: null,
          callStatus:
            groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal
              ? SystemConstant.MESSAGE_CALL_STATUS.end
              : SystemConstant.MESSAGE_CALL_STATUS.waiting,
          roomId: lastCall.roomId,
          isReceiver: true,
        }),
      );
    }
  };

  const onClosePopupInit = () => {
    setOpenInitGroupCall(false);
  };

  useEffect(() => {
    if (groupDetail) {
      let lastCall = toCamel(repository.callHistory.getByGroupId(groupDetail.id)[0]);
      if (lastCall && lastCall.status === SystemConstant.CALL_HISTORY_STATUS.calling && !isInAnotherCall) {
        // let lastCallOption = lastCall.options ? toCamel(JSON.parse(lastCall.options)) : {};

        let parentMessage = toCamel(repository.message.getMessageWithSourceId(lastCall.sourceId));
        if (
          parentMessage &&
          Object.keys(parentMessage).length > 0 &&
          (!Boolean(callingDetail) || parentMessage.sourceId !== callingDetail.sourceId)
        ) {
          setCallingDetail(parentMessage);
        }
      } else {
        setCallingDetail(null);
      }
    }
  }, [groupDetail, isFetchHistorySuccess, isFetchMessageSuccess, isInAnotherCall]);

  useEffect(() => {
    if (groupDetail && groupDetail.id && groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
      checkOnlineInterval = setInterval(() => {
        setIsOnline(Boolean(checkOnlineStatus(groupDetail.members[0].id)));
      }, STATUS_CHECKING_INTERVAL);
    }

    return () => {
      clearInterval(checkOnlineInterval);
    };
  }, [groupDetail]);

  useEffect(() => {
    if (demandingVideoCall && demandingVideoCall === groupDetail.id) {
      dispatch(
        ConversationAction.conversationSet({
          demandingVideoCall: null,
        }),
      );
      onOpenVideoCallWindow();
    }
  }, [demandingVideoCall, groupDetail]);

  useEffect(() => {
    if (demandingVoiceCall && demandingVoiceCall === groupDetail.id) {
      dispatch(
        ConversationAction.conversationSet({
          demandingVoiceCall: null,
        }),
      );
      onOpenAudioCallWindow();
    }
  }, [demandingVoiceCall, groupDetail]);

  useEffect(() => {
    let isMounted = true;
    let avatarId = isGroupOrChannelType(groupDetail?.groupType)
      ? groupDetail.avatarId
      : members.find(acc => acc.id !== accountId)?.avatarId;

    if (avatarId) {
      getImageSrcAvatar(avatarId, SystemConstant.ATTACHMENT_TYPE.account).then(src => {
        if (src !== userAvatar && isMounted) {
          setUserAvatar(src);
        }
      });
    } else {
      setUserAvatar(null);
    }

    return () => {
      isMounted = false;
    };
  }, [groupDetail, selectedId]);

  useEffect(() => {
    const savedServer = getSavedServer();
    const groupMemberList = data?.members || data?.groupMembers || [];
    const isCurrentGroup = data?.id && data.id === groupDetail.id;
    const isChangeMember =
      isCurrentGroup && isArrayNotEquals(groupMemberList, groupDetail.members || groupDetail.groupMembers || []);
    if (!isCurrentGroup || isChangeMember) {
      setGroupDetail(data);

      if (groupMemberList) {
        if (
          data.groupType === SystemConstant.GROUP_CHAT_TYPE.personal &&
          savedServer.type === SystemConstant.SERVER_TYPE.server
        ) {
          const filterMemberList = data.members.filter(
            item => item.id !== StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID),
          );

          setMembers(filterMemberList);
        } else {
          setMembers(groupMemberList);
        }
      }
    }
  }, [data]);

  const isShowGroupDrawer = useMemo(
    () => Boolean(isShowGroupDetail && groupDetail && groupDetail.id),
    [isShowGroupDetail],
  ); // TODO: Fix rendering many time

  return (
    <>
      <Box className={classes.wapFlex}>
        <Box className={classes.wrapHeader} ref={searchAnchorRef}>
          <Box className={classes.flexStart}>
            <Box className={classes.nameAndAvatar}>
              <Box className={classes.avatarAndStatus}>
                {groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.channel ? (
                  <Avatar
                    alt={groupDetail?.name}
                    className={classes.avatar}
                    style={{
                      backgroundColor: "#BEBEBE",
                    }}
                    imgProps={{
                      sx: {
                        objectFit: "scale-down",
                      },
                    }}
                    src={groupDetail?.privateF === 0 ? PublicChanelAva : PrivateChanelAva}
                  >
                    Channel
                  </Avatar>
                ) : groupDetail.avatarId && userAvatar ? (
                  <Avatar alt={groupDetail?.name} src={userAvatar}></Avatar>
                ) : (
                  <AvatarConversation memberArray={members} />
                )}
                {!isGroupOrChannelType(groupDetail.groupType) && (
                  <Box className={isOnline ? classes.onlineStatus : classes.offline} />
                )}
              </Box>
              <Box className={classes.groupChatName}>
                <Typography variant="subtitle2" className={clsx(classes.titleChat, "ellipsis")}>
                  {groupDetail.name}
                </Typography>
                {groupDetail.groupType === AppConstant.GROUP_CHAT_TYPE.group ? (
                  <Box className={clsx(classes.groupChat, "regular-sm-txt")}>{getLabel(LangConstant.TXT_GROUP)}</Box>
                ) : (
                  groupDetail.groupType === AppConstant.GROUP_CHAT_TYPE.channel && (
                    <Box className={clsx(classes.channelText, "regular-sm-txt")}>
                      {getLabel(LangConstant.TXT_CHANNEL)}
                    </Box>
                  )
                )}
              </Box>
            </Box>
            <Box className={classes.titleActionContainer}>
              {!(isBlockedAccount || isBlockedByAccount || isInactive) &&
                (callingDetail && isGroupOrChannelType(groupDetail?.groupType) ? (
                  <Button
                    className={clsx(classes.joinCallBtn, "semiBold-sm-txt")}
                    variant="contained"
                    onClick={onJoinCurrentCall}
                    disabled={isInCall}
                  >
                    <Phone className={classes.phoneIcon} />
                    {getLabel(getNSKey(LangConstant.NS_CALLING, LangConstant.TXT_JOIN_CALL))}
                  </Button>
                ) : hasInternet ? (
                  <>
                    {isGroupOrChannelType(groupDetail?.groupType) ? (
                      <IconButton
                        onClick={onStartConference}
                        disabled={groupDetail.groupMembers.length > serverOption.meetMaxCallPerson || isInCall || isInactive}
                        className={classes.infoButton}
                      >
                        <VideocamOutlinedIcon color="inherit" className={classes.iconBtn} />
                      </IconButton>
                    ) : (
                      <>
                        <IconButton onClick={onOpenAudioCallWindow} className={classes.infoButton} disabled={isInCall || isInactive}>
                          <Phone color="inherit" />
                        </IconButton>

                        <IconButton onClick={onOpenVideoCallWindow} className={classes.infoButton} disabled={isInCall || isInactive}>
                          <VideoCall color="inherit" className={classes.videoCallIcon} />
                        </IconButton>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Tooltip title={getLabel(LangConstant.TXT_NO_INTERNET)}>
                      <span>
                        {isGroupOrChannelType(groupDetail?.groupType) ? (
                          <>
                            <IconButton onClick={onStartConference} className={classes.infoButton} disabled={true}>
                              <VideocamOutlinedIcon color="inherit" className={classes.iconBtn} />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <IconButton onClick={onOpenAudioCallWindow} className={classes.infoButton} disabled={true}>
                              <Phone color="inherit" />
                            </IconButton>
                            <IconButton onClick={onOpenVideoCallWindow} className={classes.infoButton} disabled={true}>
                              <VideoCall color="inherit" className={classes.videoCallIcon} />
                            </IconButton>
                          </>
                        )}
                      </span>
                    </Tooltip>
                  </>
                ))}

              <IconButton onClick={onOpenGroupInfoDrawer} className={classes.infoButton}>
                <Error color="action" />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <SearchPopup
          open={isOpenSearchPopup}
          anchor={searchAnchorRef.current}
          onClose={() => setIsOpenSearchPopup(false)}
        />
        <GroupInfo
          open={isShowGroupDrawer}
          data={groupDetail}
          onClose={() => setIsShowGroupDetail(false)}
          onOpenSearchPopup={() => setIsOpenSearchPopup(true)}
          isAdminGroup={isAdminGroup}
          isInactive={isInactive}
        />
      </Box>
      {openInitGroupCall && (
        <InitGroupCallPopup open={openInitGroupCall} onClose={onClosePopupInit} data={groupDetail} />
      )}
    </>
  );
};

const STATUS_CHECKING_INTERVAL = 1000;

export default memo(TitleChat);

const useStyles = makeStyles(theme => ({
  wapFlex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    backgroundColor: theme.palette.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },

  flexStart: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },

  avatarAndStatus: {
    display: "flex",
    position: "relative",
    width: 50,
    height: 50,
    minWidth: 50,
  },

  titleChat: {
    color: theme.palette.black,
    position: "relative",
  },

  onlineStatus: {
    position: "absolute",
    width: 10,
    height: 10,
    backgroundColor: "#2CC84A",
    borderRadius: "50%",
    bottom: 7,
    right: 0,
    border: "1px solid white",
  },

  offline: {
    position: "absolute",
    width: 10,
    height: 10,
    backgroundColor: theme.palette.divider,
    borderRadius: "50%",
    bottom: 7,
    right: 0,
    border: `1px solid ${theme.palette.grey[300]}`,
  },

  wrapHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: 36,
    paddingBottom: 15,
  },

  groupChatName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginLeft: 10,
  },

  iconError: {
    width: "38px",
    height: "38px",
  },

  nameAndAvatar: {
    display: "flex",
  },

  infoButton: {
    width: "fit-content",
    height: "fit-content",
  },

  groupChat: {
    bottom: 0,
    color: theme.palette.grey[500],
    width: "100%",
  },

  channelText: {
    position: "absolute",
    bottom: 0,
    right: "-80%",
    color: theme.palette.grey[500],
  },

  videoCallIcon: {
    fontSize: 24,
  },

  joinCallBtn: {
    backgroundColor: "#2CC84A",
    textTransform: "none",
    color: "white",
    borderRadius: 26,
    padding: "6px 8px",
    height: 28,
    minHeight: "unset",
    marginLeft: 10,
    width: 95,
  },

  phoneIcon: {
    fontSize: 20,
    marginRight: 5,
  },

  avatar: {
    width: 50,
    height: 50,
  },

  titleActionContainer: {
    display: "flex",
    alignItems: "center",
  },

  iconBtn: {
    fontSize: 26,
  },
}));
