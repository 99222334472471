import React, { memo, useCallback, useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { AvatarInfo, SearchBar } from "components";
import { objectToJSON } from "utils";
import EmptyContact from "./EmptyContact";
import { AppConstant, SystemConstant } from "const";
import { Box, Divider, List } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddingChannelButton from "./AddingChannel/AddingChannelButton";
import { TabPanel } from "@mui/base";
import { useConversationList } from "hooks";
import { ConversationActions } from "redux-store";

const AddingChannelTab = ({ onCloseDrawer }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { channelList, groupList } = useConversationList();
  const channelGroupList = [...channelList, ...groupList];

  const [isShowEmptyImage, setIsShowEmptyImage] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [showList, setShowList] = useState([]);

  const onCreateSelect = useCallback(
    item => () => {
      dispatch(
        ConversationActions.setSelectGroupId({
          selectedGroupId: item.id,
        }),
      );
      onCloseDrawer();
    },
    [],
  );

  const onChangeSearch = value => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (channelGroupList.length > 0) {
      const list = sortByFirstCharacter(
        channelGroupList.filter(item => item.groupType === SystemConstant.GROUP_CHAT_TYPE.channel),
      );

      if (list && objectToJSON(list) !== objectToJSON(showList)) {
        setShowList(list);
        setIsShowEmptyImage(false);
      }
    }
  }, [channelList, groupList]);

  useEffect(() => {
    const list = sortByFirstCharacter(
      channelGroupList.filter(item => item.groupType === SystemConstant.GROUP_CHAT_TYPE.channel),
    );

    if (searchValue) {
      let filteredList = [];
      filteredList = [...list].filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()));
      setShowList(filteredList);
    } else {
      setShowList(list);
    }
  }, [searchValue]);

  return (
    <TabPanel value={AppConstant.CHATTING_TAB_MODE.channel}>
      <Box className={classes.rootSpace}>
        <SearchBar onChange={onChangeSearch} />
      </Box>
      <AddingChannelButton onComplete={onCloseDrawer} />
      <List>
        {showList.map((item, index) => (
          <Fragment key={index}>
            <AvatarInfo
              avatar={{ src: item.avatarId, name: item.name, type: item.groupType }}
              title={item.name}
              privateF={item.privateF}
              onClick={onCreateSelect(item)}
              button={true}
              titleProps={{ className: classes.channelName }}
            />
            <Divider variant="inset" component="li" />
          </Fragment>
        ))}
      </List>
      {isShowEmptyImage && <EmptyContact mode={AppConstant.CHATTING_TAB_MODE.channel} />}
    </TabPanel>
  );
};

AddingChannelTab.propTypes = {
  onCloseDrawer: PropTypes.func,
};

export default memo(AddingChannelTab);

const sortByFirstCharacter = array => {
  if (!array) return [];

  // Sort
  let sortArray = array
    .filter(item => Boolean(item?.name))
    .sort((firstObj, secondObj) => {
      let firstChar = (firstObj.name.charAt(0) + "").toUpperCase();
      let secondChar = (secondObj.name.charAt(0) + "").toUpperCase();

      if (firstChar > secondChar) {
        return 1;
      }
      if (secondChar > firstChar) {
        return -1;
      }
      return 0;
    });

  return sortArray;
};

const useStyles = makeStyles(theme => ({
  rootSpace: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  subTitle: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    padding: theme.spacing(0.5, 2),
  },
  channelName: {
    fontWeight: 400,
    color: "black",
  },
}));
