import { AppConstant, KeyConstant } from "const";
import { convertJSONObject } from "utils";
import { TriosRtcPeerConnection } from "../model/TriosRtcPeerConnection";
import { LocalBranchService } from "services/local.service";
import { StorageUtil } from "utils";

var pc;
var remoteStream;
var candidatesArr = [];
var localStream = null;
var peerConnection = null;
var isRunning = false;

export const onGetMediaDevices = async (currentInputDeviceId = "default", reject = () => {}) => {
  if (!currentInputDeviceId) {
    localStream = null;
    return null;
  }

  let requestObj = {
    audio:
      currentInputDeviceId === "default"
        ? { echoCancellation: true }
        : {
            deviceId: { exact: currentInputDeviceId },
            echoCancellation: true,
          },
  };

  let requestVideoObj = {
    video: true,
  };

  let audioStream = null;
  let videoStream = null;

  try {
    audioStream = await navigator.mediaDevices.getUserMedia(requestObj);
  } catch (error) {
    reject(error);
    console.log({ error });
  }

  if (audioStream?.getVideoTracks().length === 0) {
    try {
      videoStream = await navigator.mediaDevices.getUserMedia(requestVideoObj);
      if (videoStream) {
        audioStream.addTrack(videoStream.getVideoTracks()[0]);
      }
    } catch (error) {
      let defaultScreenId = "screen:0:0";

      try {
        videoStream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            mandatory: {
              chromeMediaSource: "desktop",
              chromeMediaSourceId: defaultScreenId,
              maxHeight: 1080,
              maxWidth: 1920,
            },
          },
        });
        if (videoStream) {
          let track = videoStream.getVideoTracks()[0];
          // track.enabled = false;
          audioStream.addTrack(track);
          track.stop();
        }
      } catch (error) {
        console.log({ error });
      }
      console.log({ error });
    }
  }

  localStream = audioStream;

  return audioStream;
};

const createPeerConnection = () => {
  try {
    let iceServers = createRTCConfig();

    pc = new TriosRtcPeerConnection({
      iceServers: iceServers,
      bundlePolicy: "max-bundle",
      iceTransportPolicy: "relay",
    });

    // pc.onicecandidate = gatherCandidate;
    pc.onaddstream = getRemoteStream;
    return { pc };
  } catch (e) {
    console.log("Failed to create PeerConnection, exception: " + e.message);
    console.log("Cannot create RTCPeerConnection object.");
    return;
  }
};

const getRemoteStream = event => {
  remoteStream = event.stream;
};

export const onSetCustomTag = id => {
  let video = document.getElementById(id);
  if (video) video.srcObject = remoteStream;
};

const gatherCandidate = event => {
  let candidate = event.candidate;
  candidatesArr.push(candidate);
};

const stop = () => {
  if (peerConnection) {
    peerConnection.close();
    peerConnection = null;
  }
};

export const onHandleCandidateArrayString = JsonString => {
  let candidateArr = Array.from(JsonString);
  console.log(candidateArr);
  return candidateArr
    .map(item => {
      console.log(item);
      try {
        if (item?.candidate != null && item?.sdpMLineIndex != null && item?.sdpMid != null) {
          return new RTCIceCandidate({
            candidate: item.candidate,
            sdpMLineIndex: item.sdpMLineIndex,
            sdpMid: item.sdpMid,
          });
        } else {
          console.log("WRONG ICE");
          return null;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    })
    .filter(s => s !== null);
};

export const onGetPeerConnection = () => {
  return peerConnection;
};

export const onCreateSessionDescriptionError = error => {};

export const handleCreateOfferError = event => {
  return null;
};

export const onStartCall = (callback, customStream = localStream, isForceCreating = false) => {
  if (!isRunning || isForceCreating) {
    try {
      isRunning = true;
      let peerObject = createPeerConnection();
      let newPc = peerObject.pc;
      let newDataChannel = newPc.createDataChannel("dataChannel");
      peerConnection = newPc;
      let stream = customStream ? customStream : localStream;
      if (stream) {
        newPc.addStream(stream);
        newPc.createOffer(
          res => {
            newPc.setLocalDescription(res);
            Boolean(callback) && callback(res, newPc, newDataChannel);
            isRunning = false;
          },
          handleCreateOfferError,
          {
            offerToReceiveAudio: true,
            DtlsSrtpKeyAgreement: true,
          },
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
};

export const onReceiveOffer = (
  offer,
  callback,
  customStream = localStream,
  isForceCreating = false,
  callBackDataChannel,
) => {
  if (!isRunning || isForceCreating) {
    try {
      isRunning = true;
      let peerObject = createPeerConnection();
      let receiverPc = peerObject.pc;
      receiverPc.ondatachannel = e => callBackDataChannel(e, receiverPc);
      peerConnection = receiverPc;
      let stream = customStream ? customStream : localStream;
      if (stream) {
        receiverPc.addStream(stream);
        receiverPc.setRemoteDescription(new RTCSessionDescription(offer));
        receiverPc.createAnswer(res => {
          receiverPc.setLocalDescription(res);
          Boolean(callback) && callback(res, receiverPc);
          isRunning = false;
        }, onCreateSessionDescriptionError);
      }
    } catch (error) {
      console.error(error);
    }
  }
};

export const onAddCandidates = (candidatesJSONString, customPc) => {
  let pc = customPc ? customPc : peerConnection;
  console.log(candidatesJSONString);
  let cArr = onHandleCandidateArrayString(candidatesJSONString);
  cArr.forEach(element => {
    pc.addIceCandidate(element);
  });
};

export const onAddRemoteDescription = (answer, currentCon) => {
  let connection = currentCon ? currentCon : peerConnection;
  if (connection) {
    connection
      .setRemoteDescription(answer)
      .then(() => {
        return {
          isSetRemoteDescriptionSuccess: true,
        };
      })
      .catch(e => {
        console.trace(e);
      });
  }
};

export const onEndPhone = () => {
  stop();
};

export const onGetCandidates = () => {
  return JSON.stringify(candidatesArr);
};

export const onMute = () => {
  if (localStream && localStream.getAudioTracks()[0]) {
    localStream.getAudioTracks()[0].enabled = false;
  }
};

export const onUnmute = () => {
  if (localStream && localStream.getAudioTracks()[0]) {
    localStream.getAudioTracks()[0].enabled = true;
  }
};

export const onEnableVideoStream = () => {
  if (localStream) {
    let camTrack = localStream.getVideoTracks().find(item => item.label !== "Screen" && item.label !== "screen");
    if (camTrack) {
      camTrack.enabled = true;
    }
  }
};

export const onDisableVideoStream = () => {
  if (localStream) {
    let camTrack = localStream.getVideoTracks().find(item => item.label !== "Screen" && item.label !== "screen");
    if (camTrack) {
      camTrack.enabled = false;
    }
  }
};

export const onClosePeer = peer => {
  if (peer) peer.close();
};

export const onSetSinkIdByElement = async (mediaElement, currentAudioOutputDevice) => {
  if (currentAudioOutputDevice && typeof mediaElement.sinkId !== "undefined") {
    try {
      let localCallingSetting = StorageUtil.getItem(KeyConstant.KEY_CALLING_MEDIA_SETTING);
      let savedOutputSetting = localCallingSetting.output;
      mediaElement.setSinkId(currentAudioOutputDevice ? currentAudioOutputDevice : savedOutputSetting);
    } catch (error) {
      // TODO: Show error alert
      console.error("Got error while change output setting: ", error);
    }
  } else {
    // TODO: Show error alert
    console.error("Application does not support output device selection");
  }
};

export const onGetScreenSource = async id => {
  let videoStream = null;

  try {
    videoStream = await navigator.mediaDevices.getUserMedia({
      video: {
        mandatory: {
          chromeMediaSource: "desktop",
          chromeMediaSourceId: id,
          maxHeight: 1080,
          maxWidth: 1920,
        },
      },
    });
  } catch (error) {
    console.log(error);
  }
  return videoStream;
};

export const onSetCustomVideoTrack = (con, stream, customTag) => {
  let senders = con.getSenders();
  let screenVideoTrack = stream.getVideoTracks()[0];
  let tmpVideoTrack = localStream.getVideoTracks()[0];
  if (screenVideoTrack) {
    senders.forEach(sender => {
      if (sender.track.id === tmpVideoTrack.id) {
        sender.replaceTrack(screenVideoTrack);
        if (customTag) {
          let tmpNew = new MediaStream();
          tmpNew.addTrack(screenVideoTrack);
          customTag.srcObject = tmpNew;
        }
      }
    });
  }
};

export const createRTCConfig = () => {
  var iceServers = [];
  var turnServes = {};
  //TODO await
  var branches = LocalBranchService.getAllInstantly();
  branches.forEach(branch => {
    if (branch && branch.options) {
      let options = convertJSONObject(branch.options);

      if (options?.turn_server) {
        options?.turn_server?.forEach(turn => {
          turnServes[turn.url] = turn;
        });
      }
    }
  });

  Object.values(turnServes).forEach(tur => {
    if (tur.type_server === AppConstant.TURN_SERVER_TYPE.not) {
      iceServers.push({
        urls: tur.url,
      });
    } else if (tur.type_server === AppConstant.TURN_SERVER_TYPE.yes && tur.user_name != null && tur.pass_word != null) {
      iceServers.push({
        urls: tur.url,
        username: tur.user_name,
        credential: tur.pass_word,
      });
    }
  });
  console.log({ iceServers });
  return iceServers;
};
