import { all, call } from "redux-saga/effects";
import { AccountKeyService } from "services";
import { toSnake } from "utils";
import { ApiConstant, SystemConstant } from "const";
import { LocalAccountKeyService, LocalDeviceService } from "services/local.service";

// Logic function, not be called from react
export function* saveKeysOfDevice(data) {
  const { accountId, deviceId } = data;

  try {
    const limit = 10000;
    const offset = 0;
    const payloadToGetKey = {
      mode: 0,
      accountId,
      deviceId,
      limit: limit,
      offset: offset,
      paging: 0,
      asc: 1,
      order_by: "created",
      filter: "",
    };

    const response = yield call(AccountKeyService.getKeys, toSnake(payloadToGetKey));
    if (response.status === ApiConstant.STT_OK) {
      const responseData = response.data;

      for (let i1 = 0; i1 < Object.values(responseData).length; i1++) {
        const deviceIdWithKey = Object.values(responseData)[i1];
        for (let i = 0; i < Object.values(deviceIdWithKey).length; i++) {
          const accountKey = Object.values(deviceIdWithKey)[i];
          let keys = [];
          if (accountKey.identityKey) {
            keys.push({
              ...accountKey.identityKey,
              id: accountKey.identityKey.uuid,
            });
          }

          if (accountKey.signPreKey) {
            keys.push({
              ...accountKey.signPreKey,
              id: accountKey.signPreKey.uuid,
            });
          }

          if (accountKey.oneTimeKeys) {
            keys = [
              ...keys,
              ...accountKey.oneTimeKeys.map(item => ({
                ...item,
                id: item.uuid,
              })),
            ];
          }

          let isEnuf = keys.length === SystemConstant.MAX_KEY_LENGTH;
          let currentDevice = LocalDeviceService.getRealTime(Object.keys(deviceIdWithKey)[0]);
          currentDevice.key_f = Number(isEnuf);

          LocalDeviceService.save([currentDevice]);
          yield LocalAccountKeyService.save(keys);
        }
      }
      return true;
    }
  } catch (error) {
    return true;
  }
}

export function* saveKeysByAccountIds(accountIds) {
  try {
    let deviceList = LocalDeviceService.getAccountsDevice(accountIds);
    deviceList = deviceList.filter(item => !Boolean(item.key_f));

    const stepArr = [];

    for (let index = 0; index < Math.ceil(deviceList.length / 5); index++) {
      let toPush = [];
      if (index === 0) {
        toPush = deviceList.slice(index * 5, (index + 1) * 5);
      } else {
        toPush = deviceList.slice(index * 5 + 1, (index + 1) * 5);
      }
      stepArr.push(toPush);
    }

    function* promiseGetDeviceKey() {
      if (stepArr.length > 0) {
        const curr = stepArr.shift();

        yield all(
          curr.map(item =>
            saveKeysOfDevice({
              accountId: item.account_id,
              deviceId: item.id,
            }),
          ),
        );

        yield promiseGetDeviceKey();
      } else return;
    }
    yield promiseGetDeviceKey();
  } catch (error) {
    console.log(error);
  }
}
