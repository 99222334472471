import { all, call, put } from "redux-saga/effects";
import { AccountService, AttachmentService, ContactService, ConversationService } from "services";
import { ApiConstant, AppConstant, KeyConstant, SystemConstant } from "const";
import { isJSONString, toCamel, toSnake, uuid } from "utils";
import {
  LocalAccountGroupService,
  LocalAccountService,
  LocalAttachmentService,
  LocalContactService,
  LocalGroupService,
  LocalGroupSettingService,
  LocalMessageService,
} from "services/local.service";
import { formatArray2Key, formatPagingParams } from "./saga.helper";
import { AccountActions, ConversationActions, GroupInfoActions } from "redux-store";
import { saveKeysByAccountIds } from "./account-key.saga";
import { StorageUtil } from "utils";
import { synchGroupMemberKey } from "./synch-key.saga";
import { markReadMessageInGroup } from "./conversation-message.saga";

export function* changeGroupPhoto(action) {
  try {
    const { data } = action;
    const apiPayload = new FormData();
    apiPayload.append("file", data.upload, data.upload.path);
    let response = yield call(AccountService.uploadFileAccount, apiPayload);
    if (response.status === ApiConstant.STT_OK) {
      const imageId = response.data.id;
      yield call(
        ConversationService.updateConversation,
        toSnake({
          groupId: data.groupId,
          avatarId: imageId,
        }),
      );
      let fileName = imageId + AppConstant.IMAGE_FORMAT_SAVED;
      if (!LocalAttachmentService.exitsLocalFile(imageId, SystemConstant.ATTACHMENT_TYPE.group, fileName)) {
        let attachmentResponse = yield call(AttachmentService.getGlobalAttachment, toSnake({ attachmentId: imageId }));
        if (attachmentResponse.status === ApiConstant.STT_OK) {
          let unit8String = new Uint8Array(attachmentResponse.data);
          yield LocalAttachmentService.saveFileUnEncryptedGroup(unit8String, imageId, fileName);
          yield LocalGroupService.changPhotoGroup(data.groupId, imageId);
          yield put(
            GroupInfoActions.groupInfoSet({
              isFetching: false,
              groupAvatar: imageId,
              changeGroupPhoto: imageId,
              groupId: data.groupId,
              isChangeGroupInfoSuccess: true,
              updatedGroup: data.groupId,
            }),
          );
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getGroupMember(groupObject) {
  try {
    let paramsObject = {};
    paramsObject = {
      ...formatPagingParams(formatArray2Key({ groupIds: Array.isArray(groupObject) ? groupObject : [groupObject.id] })),
    };

    let response = yield call(ConversationService.getConversationMember, paramsObject);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;

      let arrayAccountGroup = [];
      let accountArr = [];

      Object.entries(responseData).forEach(pair =>
        pair[1].forEach(item2 => {
          accountArr.push(item2);
          arrayAccountGroup.push({
            ...item2,
            id: pair[0] + item2.id,
            account_id: item2.id,
            group_id: pair[0],
          });
        }),
      );

      // Change this to get key by group members
      let memberIds = arrayAccountGroup.map(item => item.id);
      yield saveKeysByAccountIds(memberIds);

      yield LocalAccountService.save(accountArr);
      yield LocalAccountGroupService.save(
        arrayAccountGroup.map(account => ({
          id: account.id,
          account_id: account.account_id,
          group_id: account.group_id,
          state: account.group_state,
          options: account.options,
          created: account.created,
          modified: account.modified,
          invite_by: account.invite_by,
          type: account.type,
        })),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* removeMemberGroup(action) {
  try {
    const { data } = action;
    let response = yield call(
      ConversationService.deleteConversation,
      toSnake({
        groupId: data.groupId,
        memberId: data.memberId,
      }),
    );
    if (response.status === ApiConstant.STT_OK) {
      yield LocalAccountGroupService.removeMemberGroup(data.groupId, data.memberId);
      let { groupName, groupMembers } = yield getNewGroup(data.groupId);
      yield put(
        GroupInfoActions.groupInfoSet({
          isFetching: false,
          groupId: data.groupId,
          groupName: groupName,
          groupMembers: groupMembers,
          isChangeGroupInfoSuccess: true,
          updatedGroup: data.groupId,
        }),
      );
      yield put(
        ConversationActions.conversationSet({
          eventGroupUpdate: { time: new Date().getTime(), groupId: data.groupId },
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* addMemberGroup(action) {
  try {
    const { data } = action;
    const response = yield call(ConversationService.addConversationMembers, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      if (Array.isArray(response.data)) {
        const saveData = response.data.map(item => ({
          id: item.id,
          account_id: item.account_id,
          group_id: item.group_id,
          state: item.state,
          options: item.details ? item.details : null,
          created: item.created,
          modified: item.modified,
          invite_by: item.invite_by,
          type: item.type,
        }));

        yield window.repository.accountGroup.save(saveData);
      }

      const { groupName, groupMembers } = yield getNewGroup(data.groupId);
      yield put(
        GroupInfoActions.groupInfoSet({
          isFetching: false,
          groupId: data.groupId,
          groupName: groupName,
          groupMembers: groupMembers,
          isChangeGroupInfoSuccess: true,
          updatedGroup: data.groupId,
        }),
      );
      yield put(
        ConversationActions.conversationSet({
          eventGroupUpdate: { time: new Date().getTime(), groupId: data.groupId },
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getNewGroup(groupId) {
  let groupName = "No name";
  let groupMembers = [];
  let accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);

  let group = yield LocalGroupService.get(groupId);
  let groupData = toCamel(group);
  let tmpMemberArray = [];
  let accountGroupList = yield LocalAccountGroupService.findByGroupId(groupData.id);
  let filteredArray = toCamel(accountGroupList)
    .filter(s => s.state !== SystemConstant.STATE.inactive)
    .map(r => r.accountId);

  let members = yield LocalAccountService.getAccountByIds(filteredArray);
  tmpMemberArray = members;

  if (groupData.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
    tmpMemberArray = tmpMemberArray.filter(member => member.id !== accountId);
  }

  groupMembers = toCamel(tmpMemberArray);

  if (
    groupData.groupType === SystemConstant.GROUP_CHAT_TYPE.group &&
    isJSONString(groupData.name) &&
    JSON.parse(groupData.name).name
  ) {
    groupName = JSON.parse(groupData.name).name;
  } else if (groupData.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
    let other = tmpMemberArray?.find(member => member.id !== accountId);
    groupName = other ? (other.name ? other.name : other.phone) : "No Name";
  } else if (groupData.groupType === SystemConstant.GROUP_CHAT_TYPE.channel) {
    groupName = JSON.parse(groupData.name).name || tmpMemberArray.map(item => item.name).join(", ");
  } else {
    let groupNameArr = tmpMemberArray.map(item => item.name);
    groupName = groupNameArr.join(", ");
  }

  return { groupName, groupMembers };
}

export function* muteGroupNotification(action) {
  const { data } = action;
  try {
    const selectGroup = LocalGroupSettingService.getByGroupId(data.groupId);
    let newSetting;

    if (selectGroup && selectGroup.id) {
      newSetting = {
        ...selectGroup,
        state: data.status,
      };
    } else {
      newSetting = {
        id: uuid(),
        groupId: data.groupId,
        settingId: SystemConstant.SETTING_TYPE.notification,
        status: SystemConstant.STATUS.active,
        state: data.status,
        options: "",
        created: Date.now(),
        modified: 0,
      };
    }

    yield LocalGroupSettingService.save([toSnake(newSetting)]);
    yield put(
      GroupInfoActions.groupInfoSet({
        newActionNotification: data.groupId,
        statusGroup: data.status,
      }),
    );
  } catch (error) {
    console.log(error);
  }
}

export function* addAdmin(action) {
  const { data } = action;
  try {
    let response = yield call(ConversationService.updateConversation, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield LocalAccountGroupService.memberToAdmin(response.data.group_id, response.data.account_id);
      yield put(
        GroupInfoActions.groupInfoSet({
          dataMemberToAdmin: toCamel(response).data,
        }),
      );
    }
  } catch (error) {
    console.log({ error });
  }
}

export function* requestUploadImageCall(action) {
  try {
    const { data } = action;
    const apiPayload = new FormData();
    apiPayload.append("file", data.upload, data.upload.path);
    let response = yield call(AccountService.uploadFileAccount, apiPayload);
    if (response.status === ApiConstant.STT_OK) {
      const avatarID = response.data.id;
      let fileName = data.upload.name;
      if (!LocalAttachmentService.exitsLocalFile(avatarID, SystemConstant.ATTACHMENT_TYPE.group, fileName)) {
        let attachmentResponse = yield call(
          AttachmentService.getGlobalAttachment,
          toSnake({
            attachmentId: avatarID,
          }),
        );
        if (attachmentResponse.status === ApiConstant.STT_OK) {
          let unit8String = new Uint8Array(attachmentResponse.data);
          try {
            LocalAttachmentService.saveFileUnEncryptedGroup(unit8String, avatarID, fileName);
            if (data.initCall) {
              yield put(
                ConversationActions.conversationSet({
                  idGroupCallAvatar: avatarID,
                }),
              );
            } else {
              yield put(
                ConversationActions.conversationSet({
                  idAvatarEdit: avatarID,
                }),
              );
            }
          } catch (error) {
            console.log({ error });
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* createNewGroup(action) {
  try {
    yield put(
      ConversationActions.conversationSet({
        isSynchronizing: true,
      }),
    );
    const { data } = action;
    let response = yield call(ConversationService.addConversation, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data;
      LocalGroupService.save([
        {
          ...responseData,
          name: responseData.name,
        },
      ]);
      yield getGroupMember(responseData);
      yield put(
        ConversationActions.conversationSet({
          isFetching: false,
          isCreateGroupSuccess: true,
          isRefreshing: Date.now(),
          demandingVoiceCall: Boolean(data.isDemandVoiceCall) ? responseData.id : null,
          demandingVideoCall: Boolean(data.isDemandVideoCall) ? responseData.id : null,
        }),
      );
      yield put(
        ConversationActions.setSelectGroupId({
          selectedGroupId: responseData.id,
        }),
      );
    }
    yield put(
      ConversationActions.conversationSet({
        isSynchronizing: false,
      }),
    );
  } catch (error) {
    console.log(error);
  }
}

export function* deleteGroup(action) {
  try {
    yield put(
      ConversationActions.conversationSet({
        isSynchronizing: true,
      }),
    );
    const { data, isLeaveGroup } = action;
    let response = yield call(ConversationService.deleteConversation, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      LocalMessageService.updateDeleteStateForMsg(data.groupId);
      yield put(
        ConversationActions.conversationSet({
          isFetching: false,
          deletedGroupId: data.groupId,
          isDeleteGroupSuccess: true,
          isLeavingGroup: Boolean(isLeaveGroup),
          selectedGroupId: null,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
  yield put(
    ConversationActions.conversationSet({
      isSynchronizing: false,
    }),
  );
}

export function* updateGroup(action) {
  try {
    const { data } = action;
    let response = yield call(ConversationService.updateConversation, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ConversationActions.conversationSet({
          isFetching: false,
          isUpdateGroupSuccess: true,
          dataUpdateGroupSuccess: data.name,
        }),
      );

      yield LocalGroupService.changeNameGroup(data.groupId, data.name);
      yield put(
        ConversationActions.conversationSet({
          eventGroupUpdate: { time: new Date().getTime(), groupId: data.groupId },
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleSelectConversation(action) {
  try {
    const { selectedGroupId } = action.data;
    const selectedGroup = toCamel(LocalGroupService.get(selectedGroupId) || {});
    const memberList = yield LocalAccountGroupService.findByGroupId(selectedGroupId) || [];

    yield all([
      call(synchGroupMemberKey, memberList),
      call(markReadMessageInGroup, { selectedGroupId: selectedGroupId }),
    ]);

    const isChatWithPersonal =
      Array.isArray(selectedGroup.members) &&
      selectedGroup.members.length > 0 &&
      selectedGroup.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;

    if (isChatWithPersonal) {
      const loginAccountId = localStorage.getItem(KeyConstant.KEY_ACCOUNT_ID); // loginAccountId: sender
      const otherPerson = selectedGroup.members.find(memberItem => memberItem.id !== loginAccountId);
      yield checkBlockedContact({
        data: {
          accountId: otherPerson.id,
        },
      });
    }
  } catch (error) {
    console.log("handleSelectConversation", error);
  }
}

// If receiver block login account on server, update local database and display block sending message UI
// Else checking login account block receiver or not
export function* checkBlockedContact(action) {
  try {
    const loginAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID); // loginAccountId: sender
    const { accountId } = action.data; // account_id: receiver

    // Case 1: receiver block login account
    let blockedAccount = yield call(ContactService.getBlockedContactOnServer, accountId);

    // Case 2: login account block receiver
    if (!blockedAccount) {
      const contactInDB = LocalContactService.getContact(loginAccountId, accountId);
      if (contactInDB && contactInDB.status === SystemConstant.CONTACT_STATUS.block) blockedAccount = contactInDB;
    }

    // And then, dispatch block status to view
    yield put(
      ConversationActions.conversationSet({
        blockedAccount: blockedAccount ? toCamel(blockedAccount) : {},
      }),
    );
  } catch (error) {
    console.log(error);
  }
}
