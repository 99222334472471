import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Box, CircularProgress, Dialog, Typography } from "@mui/material";
import clsx from "clsx";
import { AppConstant, LangConstant } from "const";
import { appendBuffer, convertHex2rgba, isAudio, isImage, isVideo, replaceName2Id } from "utils";
import MessengerChatInput from "../MessengerChatInput";
import { ConversationContext } from "..";
import { LocalAttachmentService } from "services/local.service";
import { ConversationActions } from "redux-store";

const ImageUploadDialog = ({ fileUpload, isShow, groupId, onClose, onSendMessage }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const dispatch = useDispatch();
  const { groupDetail } = useContext(ConversationContext);

  const uploadMessageAttachmentData = useSelector(state => state.conversationRedux.uploadMessageAttachmentData);
  const isUploadFileFailed = useSelector(state => state.conversationRedux.isUploadFileFailed);

  const [file, setFile] = useState(null);
  const [caption, setCaption] = useState("");
  const [formData, setFormData] = useState(null);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [messageFileContent, setMessageFileContent] = useState({});
  const [previewUrl, setPreviewUrl] = useState("");
  const [isUploadFailed, setIsUploadFailed] = useState(false);
  const [mediaType, setMediaType] = useState();

  const onCloseConfirmDialog = () => {
    setFormData(null);
    setMessageFileContent({});
    onClose();
  };

  const onConfirmSend = () => {
    setIsLoadingFile(true);
    if (formData) {
      dispatch(
        ConversationActions.uploadMessageFile({
          upload: formData,
          sendType: LocalAttachmentService.getSendType(fileUpload.type),
        }),
      );
    }
  };

  useEffect(() => {
    if (fileUpload && fileUpload.name !== file?.name && fileUpload.type !== file?.type) {
      setFile(fileUpload);
      setPreviewUrl(URL.createObjectURL(fileUpload));

      switch (true) {
        case isImage(fileUpload.type):
          setMediaType(AppConstant.MEDIA_TYPE.image);
          break;

        case isAudio(fileUpload.type):
          setMediaType(AppConstant.MEDIA_TYPE.audio);
          break;

        case isVideo(fileUpload.type):
          setMediaType(AppConstant.MEDIA_TYPE.video);
          break;

        default:
          break;
      }
    }
  }, [fileUpload]);

  useEffect(() => {
    if (file) {
      let messageMetaData = {
        file_name: fileUpload.name,
        size: `${fileUpload.size + 2}`,
        mime_type: fileUpload.type,
      };

      LocalAttachmentService.encryptAESGroupFile(fileUpload.path, fileUpload).then(r => {
        setMessageFileContent({
          aes_key_info: JSON.stringify({ iv: r.iv, key: r.key, authtag: r.authtag }),
          meta_data: JSON.stringify(messageMetaData),
        });
        const buffer = new ArrayBuffer(2);
        let addSize = appendBuffer(r.encryptedData, buffer);
        let tmpForm = new FormData();
        tmpForm.append("file", new Blob([addSize]));
        tmpForm.append("group_id", groupId);
        tmpForm.append("metadata", JSON.stringify(messageMetaData));
        tmpForm.append("type", "1");
        setFormData(tmpForm);
      });
    }
  }, [file]);

  useEffect(() => {
    if (uploadMessageAttachmentData) {
      if (fileUpload) {
        LocalAttachmentService.copyToUpload(
          fileUpload.path,
          uploadMessageAttachmentData.attachment_id,
          fileUpload.name,
          fileUpload,
        );
      }
      let content = {
        ...messageFileContent,
        content_message: replaceName2Id(caption, groupDetail.groupMembers).trim(),
        attachment_id: uploadMessageAttachmentData.attachment_id,
      };

      const mentionIds = groupDetail.groupMembers.reduce((results, item) => {
        if (content.content_message.includes(item.id)) {
          return [...results, item.id];
        } else {
          return results;
        }
      }, []);

      onSendMessage(uploadMessageAttachmentData.sendType, JSON.stringify(content), null, mentionIds);
      onCloseConfirmDialog();
      setIsLoadingFile(false);
    }
  }, [uploadMessageAttachmentData]);

  useEffect(() => {
    if (isUploadFileFailed) {
      dispatch(
        ConversationActions.conversationSet({
          isUploadFileFailed: false,
        }),
      );
      setIsUploadFailed(true);
    }

    return () => {
      setIsUploadFailed(false);
    };
  }, [isUploadFileFailed]);

  return (
    <Dialog
      open={isShow}
      classes={{
        paper: classes.dialogPaper,
      }}
      maxWidth="md"
      onClose={onCloseConfirmDialog}
    >
      <Typography className={classes.confirmTitle}>{getLabel(LangConstant.TXT_CONFIRM_SENDING_MEDIA)}</Typography>

      {previewUrl && mediaType && (
        <Box className={classes.mediaPreview}>
          {mediaType === AppConstant.MEDIA_TYPE.image && <img src={previewUrl} alt={file.name} loading="lazy" />}

          {mediaType === AppConstant.MEDIA_TYPE.audio && (
            <Box className={classes.audioPreview}>
              <Typography>{fileUpload.name}</Typography>
              <audio controls className={classes.audioPlayer}>
                <source src={previewUrl} type={file.type} />
              </audio>
            </Box>
          )}

          {mediaType === AppConstant.MEDIA_TYPE.video && (
            <video controls className={classes.videoPreview} src={previewUrl} />
          )}
        </Box>
      )}

      {isUploadFailed && (
        <Typography className={clsx(classes.uploadFailed, "small-md-txt")}>
          {getLabel(LangConstant.TXT_UPLOAD_FAILED)}
        </Typography>
      )}

      <MessengerChatInput
        customStyle={classes.input}
        tagListStyle={classes.tagListClasses}
        placeholder={getLabel(LangConstant.TXT_INPUT_CAPTION)}
        onSendMessage={onConfirmSend}
        onSetMessageContent={setCaption}
        isAllowAttach={false}
      />

      <Box className={isLoadingFile ? classes.loadingBox : "hidden"}>
        <CircularProgress className={classes.circularIcon} />
      </Box>
    </Dialog>
  );
};

ImageUploadDialog.propTypes = {
  fileUpload: PropTypes.any,
  isShow: PropTypes.bool,
  groupId: PropTypes.string,
  onClose: PropTypes.func,
  onSendMessage: PropTypes.func,
};

ImageUploadDialog.defaultProps = {
  fileUpload: null,
  isShow: false,
  groupId: "",
  onClose: () => {},
  onSendMessage: () => {},
};

export default ImageUploadDialog;

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    padding: 30,
    width: "100%",
  },

  confirmTitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 20,
  },

  mediaPreview: {
    maxHeight: "100%",
    height: 450,
    width: "100%",
    padding: 20,
    backgroundColor: "#F3F3F5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    "&>img": {
      maxHeight: "100%",
    },
  },

  videoPreview: {
    height: "100%",
    width: "100%",
    borderRadius: 8,
  },

  input: {
    padding: 0,
    marginTop: 15,
  },

  tagListClasses: {
    margin: 0,
  },

  loadingBox: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: convertHex2rgba("#808080", 0.9),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: theme.zIndex.tooltip + 1,
  },

  audioPreview: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "&>p": {
      fontSize: 17,
      fontWeight: 500,
    },
  },

  audioPlayer: {
    minWidth: 400,
    minHeight: 80,
  },

  uploadFailed: {
    color: "red",
    marginTop: 10,
    width: "100%",
    textAlign: "end",
  },
}));
