import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { AppConstant, LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";

const OTPAction = ({ otpType, onChangePhone, onResendOtp, onChangeOtpType, ...otherProps }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_LOGIN);

  const [isEnableResend, setIsEnableResend] = useState(false);
  const [currentOtpType, setCurrentOtpType] = useState(otpType || SystemConstant.OTP_TYPE.smartOtp);
  const [countdownResend, setCountdownResend] = useState(AppConstant.RESEND_OTP_COUNTDOWN);

  const onChangeTypeOfOTP = () => {
    const newOtpType =
      currentOtpType === SystemConstant.OTP_TYPE.smartOtp
        ? SystemConstant.OTP_TYPE.smsOtp
        : SystemConstant.OTP_TYPE.smartOtp;

    setCurrentOtpType(newOtpType);
    setCountdownResend(AppConstant.RESEND_OTP_COUNTDOWN);

    if (onChangeOtpType) onChangeOtpType(newOtpType);
  };

  const onResend = () => {
    if (onResendOtp) onResendOtp(currentOtpType);
    setCountdownResend(AppConstant.RESEND_OTP_COUNTDOWN);
  };

  useEffect(() => {
    let resendTimeout = null;
    if (countdownResend) {
      setIsEnableResend(false);
      resendTimeout = setTimeout(() => {
        if (countdownResend > 0) setCountdownResend(countdownResend - 1);
      }, 1000);
    } else {
      setIsEnableResend(true);
    }

    return () => {
      if (resendTimeout) clearTimeout(resendTimeout);
    };
  }, [countdownResend]);

  useEffect(() => {
    if (otpType !== currentOtpType) setCurrentOtpType(otpType);
  }, [otpType]);

  const otpLabelBtn =
    currentOtpType === SystemConstant.OTP_TYPE.smartOtp
      ? getLabel(LangConstant.TXT_CHANGE_SMS_OTP)
      : getLabel(LangConstant.TXT_CHANGE_SMART_OTP);
  const countdownText = getLabel(LangConstant.FM_COUNTDOWN_OTP, { time: getBelowTenNumber(countdownResend) });

  return (
    <Box {...otherProps}>
      <Box className={classes.btnContainer}>
        {isEnableResend ? (
          <Button variant="text" className={clsx(classes.resendButton, "regular-md-txt")} onClick={onResend}>
            {getLabel(LangConstant.TXT_RESEND_OTP)}
          </Button>
        ) : (
          <Typography>{countdownText}</Typography>
        )}

        {onChangePhone && (
          <Button variant="text" className={clsx("regular-md-txt", classes.resendButton)} onClick={onChangePhone}>
            {getLabel(LangConstant.TXT_CHANGE_PHONE_NUMBER)}
          </Button>
        )}
      </Box>

      {onChangeOtpType && (
        <Button variant="text" className={clsx("regular-md-txt", classes.resendButton)} onClick={onChangeTypeOfOTP}>
          {otpLabelBtn}
        </Button>
      )}
    </Box>
  );
};

OTPAction.propTypes = {
  otpType: PropTypes.number,

  onChangeOtpType: PropTypes.func,
  onChangePhone: PropTypes.func,
  onResendOtp: PropTypes.func,
};

export default OTPAction;

const getBelowTenNumber = number => {
  if (!number) return "00";
  if (number < 10) number = "0" + number;
  return number;
};

const useStyles = makeStyles({
  btnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 16,
  },

  resendButton: {
    textTransform: "none",
    marginRight: 5,
    padding: "6px 0px",
  },
});
