import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConversationActions } from "redux-store";
import { toCamel } from "utils";
import { LocalGroupService } from "services/local.service";
import { getSavedServer } from "utils/common.utils";
import { KeyConstant, SystemConstant } from "const";

// Check current user is blocked by other user or not
// Blocking account feature is active on global server
const useHandleBlockAccount = () => {
  const dispatch = useDispatch();
  const eventGroupUpdate = useSelector(state => state.conversationRedux.eventGroupUpdate);
  const selectedGroupId = useSelector(state => state.conversationRedux.selectedGroupId);

  useEffect(() => {
    const isGlobalServer = getSavedServer().type === SystemConstant.SERVER_TYPE.server;
    if (isGlobalServer && selectedGroupId && selectedGroupId === eventGroupUpdate.groupId) {
      const selectedGroup = toCamel(LocalGroupService.get(selectedGroupId) || {});
      const isChatWithPersonal =
        Array.isArray(selectedGroup.members) &&
        selectedGroup.members.length > 0 &&
        selectedGroup.groupType === SystemConstant.GROUP_CHAT_TYPE.personal;

      if (isChatWithPersonal) {
        const loginAccountId = localStorage.getItem(KeyConstant.KEY_ACCOUNT_ID); // loginAccountId: sender
        const otherPerson = selectedGroup.members.find(memberItem => memberItem.id !== loginAccountId);
        dispatch(
          ConversationActions.checkBlockedContact({
            accountId: otherPerson.id, // account_id: receiver
          }),
        );

        return true;
      }
    }

    dispatch(
      ConversationActions.conversationSet({
        blockedAccount: {}, // Reset
      }),
    );
  }, [eventGroupUpdate, selectedGroupId]);
};

export default useHandleBlockAccount;
