import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { getImageSrcAvatar } from "../utils";
import { AppConstant, SystemConstant } from "const";
import PublicChanelAva from "../public.png";
import PrivateChanelAva from "../private.png";
import { isGroupOrChannelType } from "pages/Call";

// TODO: Need to refactor
const AvatarInfo = props => {
  const {
    avatar,
    title,
    secondTitle,
    endComponent,
    size,
    avatarProps,
    titleProps,
    privateF,
    itemProps,
    avatarRootProps,
    ...otherProps
  } = props;
  const classes = useStyleBySize(size, Boolean(avatarProps?.onClick));

  const isDeletingAvatar = useSelector(state => state.profileRedux.isDeletingAvatar);
  const changingAvatarId = useSelector(state => state.profileRedux.changingAvatarId);

  const [src, setSrc] = useState("");

  useEffect(() => {
    let isMounted = true;
    if (avatar.src && !(changingAvatarId && changingAvatarId !== avatar.src)) {
      getImageSrcAvatar(avatar.src, SystemConstant.ATTACHMENT_TYPE.account).then(res => {
        if (isMounted) setSrc(res);
      });
    } else {
      setSrc("");
    }

    return () => {
      isMounted = false;
    };
  }, [avatar]);

  useEffect(() => {
    if (changingAvatarId) {
      let isGroupOrChannel = isGroupOrChannelType(avatar?.type);

      let getAvatar = function () {
        getImageSrcAvatar(
          changingAvatarId,
          isGroupOrChannel ? SystemConstant.GROUP_CHAT_TYPE.group : SystemConstant.ATTACHMENT_TYPE.account,
        ).then(res => {
          if (!isDeletingAvatar) {
            if (res) {
              setSrc(res);
              return;
            } else {
              getAvatar();
            }
          }
        });
      };
      getAvatar();
    }
  }, [changingAvatarId]);

  useEffect(() => {
    if (isDeletingAvatar) {
      setSrc("");
    }
  }, [isDeletingAvatar]);

  return (
    <ListItem secondaryAction={endComponent} {...otherProps} classes={{ root: itemProps }}>
      <ListItemAvatar {...avatarProps}>
        <Avatar alt={avatar?.name} src={src} sx={classes.avatar} classes={{ root: avatarRootProps }}>
          {privateF !== undefined && avatar.type === SystemConstant.GROUP_CHAT_TYPE.channel ? (
            privateF !== 0 ? (
              <img src={PrivateChanelAva} alt="..." loading="lazy" />
            ) : (
              <img src={PublicChanelAva} alt="..." loading="lazy" />
            )
          ) : (
            avatar?.name?.charAt(0)
          )}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography sx={classes.title} {...titleProps}>
            {title || ""}
          </Typography>
        }
        secondary={<Typography sx={classes.secondTitle} dangerouslySetInnerHTML={{ __html: secondTitle }}></Typography>}
      />
    </ListItem>
  );
};

AvatarInfo.propTypes = {
  avatar: PropTypes.shape({
    name: PropTypes.string,
    src: PropTypes.string,
    type: PropTypes.number,
  }),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  secondTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  endComponent: PropTypes.node,
  size: PropTypes.string,
  avatarProps: PropTypes.object,
  titleProps: PropTypes.object,
  onClick: PropTypes.func,
};

export default memo(AvatarInfo);

const useStyleBySize = (size, hasClickEvent) => {
  const commonStyle = { minWidth: "unset", marginRight: 2, cursor: hasClickEvent ? "pointer" : "default" };
  switch (size) {
    case AppConstant.SIZE_OBJ.small:
      return {
        avatar: { width: 36, height: 36, ...commonStyle },
        title: { fontWeight: 600, fontSize: 12 },
        secondTitle: { fontWeight: 500, fontSize: 10, color: "#6C7078" },
      };
    case AppConstant.SIZE_OBJ.large:
      return {
        avatar: { width: 54, height: 54, ...commonStyle },
        title: { fontWeight: 700, fontSize: 15 },
        secondTitle: { fontWeight: 700, fontSize: 12, color: "#6C7078" },
      };

    default:
      return {
        avatar: { width: 48, height: 48, ...commonStyle },
        title: { fontWeight: 600, fontSize: 14 },
        secondTitle: { fontWeight: 600, fontSize: 12, color: "#6C7078" },
      };
  }
};
