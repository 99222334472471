import React, { useEffect, useState } from "react";
import { Avatar, CardActionArea, CardMedia, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getImageSrc, isVideo } from "utils";
import { AppConstant, SystemConstant } from "const";
import { PlayArrow } from "@mui/icons-material";
import clsx from "clsx";

const DataMedia = ({ data, onClick }) => {
  const classes = useStyles();

  const [src, setSrc] = useState({ src: "", videoType: null, videoUrl: "" });
  const [isV, setIsV] = useState(false);
  const [pro, setPro] = useState();

  useEffect(() => {
    if (data) {
      getImageSrc(
        data.content?.attachmentId,
        SystemConstant.GROUP_CHAT_TYPE.group,
        data.content?.metaData?.mimeType,
        data.content?.metaData?.fileName,
      ).then(s => {
        let videoType = data.content?.metaData?.mimeType;
        let video = videoType && isVideo(videoType);
        let videoUrl = "";
        if (video) {
          setPro({
            preload: "metadata",
          });
          videoUrl = s + "#t=1";
          setIsV(true);
        }
        setSrc({ src: s, videoType: videoType, videoUrl: videoUrl });
      });
    }
  }, []);

  return (
    <Grid key={data.id} className={classes.dataMediaGrid} item xs={4} onClick={onClick}>
      {isV ? (
        <CardActionArea className={clsx(classes.thumbnailItem)} onClick={onClick}>
          <video className={classes.cover} {...pro}>
            <source
              src={src.videoUrl}
              type={
                AppConstant.SUPPORT_VIDEO_TYPES.includes(src.videoType) ? src.videoType : AppConstant.DEFAULT_VIDEO_TYPE
              }
            />
          </video>
          <Avatar className={classes.thumbnailVideoPlay}>
            <PlayArrow />
          </Avatar>
        </CardActionArea>
      ) : (
        <CardMedia
          component="img"
          alt="cover"
          className={classes.cover}
          src={src.src}
          preload="metadata"
          controls="controls"
        />
      )}
    </Grid>
  );
};

export default DataMedia;

const useStyles = makeStyles(theme => ({
  cover: {
    paddingLeft: 2,
    borderRadius: 4,
    paddingBottom: 2,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  dataMediaGrid: {
    padding: "0 !important",
    height: 109,
    width: 108,
    cursor: "pointer",
  },

  thumbnailVideoPlay: {
    position: "absolute",
    top: 28,
    left: 28,
    width: 50,
    height: 50,
    background: "rgba(0, 0, 0, 0.4)",
    backdropFilter: "blur(10px)",
  },

  thumbnailItem: {
    display: "inline-block",
    width: "100%",
    height: "100%",
    overflow: "hidden",

    "& img, & video": {
      objectFit: "cover",
    },
  },
}));
