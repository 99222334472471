import React, { memo, useEffect, useState } from "react";
import { Avatar, Box, Card, CardMedia, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PopupUpdateImage from "./UpdateImagePopup";
import EditIcon from "@mui/icons-material/Edit";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { getImageSrcAvatar } from "utils";
import { SystemConstant } from "const";
import { useSelector } from "react-redux";

const AccountCoverEdit = ({ data }) => {
  const classes = useStyles();

  const changingAvatarId = useSelector(state => state.profileRedux.changingAvatarId);
  const changingBackgroundId = useSelector(state => state.profileRedux.changingBackgroundId);
  const isDeletingAvatar = useSelector(state => state.profileRedux.isDeletingAvatar);

  const [accountDetail, setAccountDetail] = useState({});
  const [isHandlingAvatar, setIsHandlingAvatar] = useState(false);
  const [isShowActionPopup, setIsShowActionPopup] = useState(false);
  const [popupAnchorEl, setPopupAnchorEl] = useState(null);

  const handleClosePopup = () => {
    setIsShowActionPopup(false);
    setPopupAnchorEl();
  };

  const handleShowActionFunc = isClickedAvatar => event => {
    setIsHandlingAvatar(isClickedAvatar);
    setPopupAnchorEl(event.currentTarget);
    setIsShowActionPopup(true);
  };

  const handleChangingImage = async (attachmentId, isGettingAvatarPath) => {
    let imageUrl = null;
    try {
      if (attachmentId) imageUrl = await getImageSrcAvatar(attachmentId, SystemConstant.ATTACHMENT_TYPE.account);
    } catch (error) {
      console.log(error);
    }

    if (isGettingAvatarPath) {
      setAccountDetail(preState => ({ ...preState, avatarId: attachmentId, avatarUrl: imageUrl }));
    } else {
      setAccountDetail(preState => ({ ...preState, backgroundId: attachmentId, backgroundUrl: imageUrl }));
    }
  };

  useEffect(() => {
    if (data) {
      handleChangingImage(data.avatarId, true);
      handleChangingImage(data.backgroundId);
      setAccountDetail(data);
    }
  }, []);

  // TODO: Need to refactor value of changingAvatarId/ changingBackgroundId
  useEffect(() => {
    if (changingAvatarId) {
      handleChangingImage(changingAvatarId, true);
    }
  }, [changingAvatarId]);

  useEffect(() => {
    if (changingBackgroundId) {
      handleChangingImage(changingBackgroundId);
    }
  }, [changingBackgroundId]);

  useEffect(() => {
    const isDeleteAvatar = isDeletingAvatar && isHandlingAvatar;
    const isDeleteBackground = isDeletingAvatar && !isHandlingAvatar;

    // Update state after deleting
    setAccountDetail(preState => ({
      ...preState,
      avatarUrl: isDeleteAvatar ? null : preState.avatarUrl,
      backgroundUrl: isDeleteBackground ? null : preState.backgroundUrl,
    }));
  }, [isDeletingAvatar]);

  return (
    <Box className={classes.root}>
      <Card className={classes.coverBox} elevation={0}>
        <Box className={classes.cover}>
          {accountDetail.backgroundUrl && (
            <CardMedia sx={{ height: 180 }} image={accountDetail.backgroundUrl} title={accountDetail.name} />
          )}
          <IconButton className={classes.addPhotoIcon} onClick={handleShowActionFunc(false)}>
            <AddPhotoAlternateIcon />
          </IconButton>
        </Box>

        <Box className={classes.avatarBox}>
          <Avatar className={classes.avatar} src={accountDetail.avatarUrl}>
            {!accountDetail.avatarId && accountDetail.name?.charAt(0)}
          </Avatar>

          <IconButton className={classes.editIcon} onClick={handleShowActionFunc(true)}>
            <EditIcon />
          </IconButton>
        </Box>

        {isShowActionPopup && (
          <PopupUpdateImage
            open
            onClose={handleClosePopup}
            anchorEl={popupAnchorEl}
            isAvatar={isHandlingAvatar}
            data={accountDetail}
          />
        )}
      </Card>
      <Typography className={classes.name}>{data.name}</Typography>
    </Box>
  );
};

export default memo(AccountCoverEdit);

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": { textAlign: "center" },
  },

  coverBox: {
    position: "relative",
    marginBottom: 12,
  },

  cover: {
    width: "100%",
    height: 180,
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, darkgray 71.67%, darkgray 100%)",
    marginBottom: 36,

    "& img": {
      width: "100%",
      height: 180,
    },
  },

  avatarBox: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },

  editIcon: {
    fontSize: 16,
    color: theme.palette.white,
    position: "absolute",
    bottom: "0%",
    left: "50%",
    cursor: "pointer",
    transform: "translate(-50%, 0)",
  },

  addPhotoIcon: {
    fontSize: 19,
    color: theme.palette.white,
    position: "absolute",
    bottom: 50,
    right: 15,
    zIndex: 1,
  },

  paper: {
    marginTop: 15,
    borderRadius: 20,
    overflowX: "unset",
    overflowY: "unset",
    "&::before": {
      content: '""',
      position: "absolute",
      top: -15,
      left: "calc(50% - 0px)",
      width: 15,
      height: 15,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
      transform: "translate(-50%, 50%) rotate(315deg)",
      clipPath: "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
    },
  },

  center: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  avatar: {
    width: 120,
    height: 120,
    margin: "auto",
    border: "2px solid white",
    fontSize: 48,
  },

  name: {
    fontWeight: 700,
    fontSize: 15,
  },
}));
