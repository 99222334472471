import { useState, useEffect } from "react";
import { Box, Divider, IconButton, List, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { DrawerLayout, SearchBar } from "components";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { AddingConferenceIcon } from "icons";
import AddingGroupLayout from "./AddingGroup/AddingGroupLayout";
import ConferenceItem from "./ConferenceItem";
import { useConversationList } from "hooks";
import { ConversationActions } from "redux-store";

const AddingConferenceDrawer = ({ onClose, ...otherProps }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { channelList, groupList } = useConversationList();

  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const chatObjContent = getLabel(LangConstant.OBJ_ADDING_CHAT, { returnObjects: true });

  const [searchValue, setSearchValue] = useState("");
  const [filterGroupList, setFilterGroupList] = useState([]);

  const onChangeSearchValue = debounce(value => {
    setSearchValue(value);
  }, 500);

  const onStartConference = () => {
    dispatch(
      ConversationActions.conversationSet({
        isAddConference: true,
      }),
    );

    if (onClose) onClose();
  };

  useEffect(() => {
    const filteredList = [...channelList, ...groupList].filter(item =>
      item.name.toLowerCase().includes(searchValue.toLowerCase()),
    );

    setFilterGroupList(filteredList);
  }, [channelList, groupList, searchValue]);

  return (
    <DrawerLayout title={chatObjContent.titleConference} onClose={onClose} {...otherProps}>
      <Box className={classes.rootSpace}>
        <SearchBar onChange={onChangeSearchValue} />
      </Box>

      <Box className={clsx(classes.selectedList, classes.rootSpace)}>
        <Box className={clsx(classes.infoBox)}>
          <Typography variant="subtitle2" className={classes.subTitle}>
            {getLabel(LangConstant.FM_GROUP_TAG, { number: filterGroupList.length })}
          </Typography>
          <AddingGroupLayout onComplete={onStartConference} DrawerProps={{ isConferenceFlow: true }}>
            <IconButton>
              <AddingConferenceIcon />
            </IconButton>
          </AddingGroupLayout>
        </Box>

        <List>
          {filterGroupList.map((item, index) => (
            <Box key={index}>
              {index > 0 && <Divider key={index} variant="inset" component="li" />}
              <ConferenceItem key={item.id} data={item} />
            </Box>
          ))}
        </List>
      </Box>
    </DrawerLayout>
  );
};

AddingConferenceDrawer.propTypes = DrawerLayout.propTypes;

AddingConferenceDrawer.defaultProps = {};

export default AddingConferenceDrawer;

const useStyles = makeStyles(theme => ({
  rootSpace: {
    paddingLeft: 16,
    paddingRight: 16,
  },

  subTitle: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    textAlign: "left",
    marginTop: 10,
  },

  selectedList: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },

  infoBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
  },
}));
