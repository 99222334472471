import { useEffect, useState } from "react";
import { KeyConstant } from "const";
import { isArray, toCamel } from "utils";
import { getSavedServer } from "utils/common.utils";
import { sortBy } from "lodash";
import { useSelector } from "react-redux";
import { LocalBranchAccountService, LocalContactService } from "services/local.service";
import { BranchSelectors } from "redux-store";
import { StorageUtil } from "utils";
import useConversationList from "./useConversationList";

const useContactList = () => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const isBranchServer = useSelector(BranchSelectors.isBranchServer);

  const unfriendContactId = useSelector(state => state.contactRedux.unfriendContactId);
  const addNewContact = useSelector(state => state.contactRedux.addNewContactResponse);

  const { personalMsgList } = useConversationList();

  const [isFetching, setIsFetching] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [searchingName, setSearchingName] = useState("");

  const handleDataFromResponse = response => {
    if (response && isArray(response)) {
      response = toCamel(response).map(contact => ({
        ...contact,
        name: contact.contactName || contact.accountName || "",
      }));

      if (isBranchServer) {
        // Remove login account
        response = response.filter(accountItem => accountItem.id !== accountId);
      }

      const sortContactList = sortBy(response, [contact => contact.name.toLowerCase()]);
      setContactList(sortContactList);
    } else {
      console.error("useContactList - Response is not array");
    }

    setIsFetching(false);
  };

  /**
   * In Branch Mode
   * contactList = distinct(contact + personalChat)
   *
   * In Global Mode
   * contactList = contact
   *
   */
  const getAllContactByName = async (name = "") => {
    setIsFetching(true);
    name = name ? name.trim() : "";
    setSearchingName(name);

    let response = await LocalContactService.searchContactsByCondition(name);
    // If branch server => add account inbox
    if (isBranchServer && personalMsgList.length > 0) {
      const inboxAccountList = personalMsgList.map(item => item.members[0]).filter(item => item && item.id);
      const inboxAccountIdList = inboxAccountList.map(item => item.id);
      const accountsActiveInBranch = await LocalBranchAccountService.findByList("account_id", inboxAccountIdList);
      const activeInBranchDetails = accountsActiveInBranch.reduce((results, item) => {
        const accountDetails = inboxAccountList.find(account => account.id === item.account_id) || {};
        return [
          ...results,
          {
            ...item,
            account_name: accountDetails.name,
            phone: accountDetails.phone,
            avatar_id: accountDetails.avatarId,
            contact_account_id: item.account_id,
          },
        ];
      }, []);

      const contactList = activeInBranchDetails.reduce((results, item) => {
        if (false === Boolean(response.find(account => account.contact_id === item.account_id))) {
          results = [...results, item];
        }

        return results;
      }, response || []);

      response = contactList;
    }
    const inactiveAccountIds = LocalBranchAccountService.getAllInactiveAccount().map(item => item.account_id);
    handleDataFromResponse(
      response.filter(
        item => inactiveAccountIds.length === 0 || false === inactiveAccountIds.includes(item.contact_id),
      ),
    );
  };

  useEffect(() => {
    getAllContactByName(searchingName);
    setIsFetching(false);
  }, [isBranchServer, unfriendContactId, addNewContact, personalMsgList]);

  return { isFetching, contactList, getAllContactByName };
};

export default useContactList;
