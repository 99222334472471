import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { AppConstant, LangConstant, SystemConstant } from "const";
import { convertJSONObject, convertString2JSON, toCamel } from "utils";
import { DrawerLayout, PrimaryTab } from "components";
import Media from "./Media";
import Link from "./Link";
import File from "./File";
import { LocalMessageService } from "services/local.service";

const Gallery = ({ data, onClose, memberArray, open }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);

  const [value, setValue] = useState(GALLERY_TYPE.media);
  const [dataLink, setDataLink] = useState(undefined);
  const [dataAudio, setDataAudio] = useState(undefined);
  const [dataImage, setDataImage] = useState(undefined);

  const onChangeTab = newValue => {
    setValue(newValue);
  };

  const galleryTabArray = [
    {
      label: getLabel(LangConstant.TXT_MEDIA),
    },
    {
      label: getLabel(LangConstant.TXT_LINK),
    },
    {
      label: getLabel(LangConstant.TXT_FILES),
    },
  ];

  useEffect(() => {
    if (data) {
      let tmpMediaArr = [];
      LocalMessageService.getMediaMessenger(data).then(response => {
        response = toCamel(response)
          .filter(
            item =>
              item.sendType !== AppConstant.MESSAGE_SEND_TYPE.deleteMessage &&
              item.state !== SystemConstant.STATE.inactive,
          )
          .map(msg => {
            let childMessages = LocalMessageService.getChildMessages(
              msg.sourceId,
              SystemConstant.SEND_TYPE.deleteMessage,
            );
            if (childMessages.length > 0) {
              return null;
            }
            return msg;
          });

        const dataLink = response.filter(item => item?.sendType === SystemConstant.SEND_TYPE.link);
        // const dataAudio = response.filter(item => item.sendType === SystemConstant.SEND_TYPE.audio);
        const dataFile = response.filter(item => item?.sendType === SystemConstant.SEND_TYPE.file);
        const dataImage = response.filter(item => MEDIA_TYPE.includes(item?.sendType));

        dataImage.forEach(message => {
          let fileObj = toCamel(convertJSONObject(convertString2JSON(message.content)));

          tmpMediaArr.push({
            src: "",
            name: fileObj.metaData.fileName,
            id: fileObj.attachmentId,
            created: message.created,
            type: fileObj.metaData?.mimeType,
            content: fileObj,
            senderName: memberArray.find(member => member.id === message.senderId)?.name,
            senderAvatar: memberArray.find(member => member.id === message.senderId)?.avatarId,
          });
        });

        setDataImage(tmpMediaArr);
        setDataLink(dataLink);
        setDataAudio(dataFile);
      });
    }
  }, [data]);

  return (
    <DrawerLayout
      open={open}
      onClose={onClose}
      anchor="right"
      title={getLabel(LangConstant.TXT_GALLERY)}
      hideBackdrop={false}
      classes={{ root: classes.drawerRoot }}
    >
      <PrimaryTab
        tabArray={galleryTabArray}
        classes={{
          flexContainer: classes.flexContainer,
          tabButton: classes.tabButton,
          tabLabel: classes.tabLabel,
          tabLabelSelected: classes.tabLabelSelected,
          tabButtonSelected: classes.tabButtonSelected,
          indicator: classes.tabs,
        }}
        onChange={onChangeTab}
        selectedTabIndex={value}
      />
      {/* TODO tabpanel */}
      {value === GALLERY_TYPE.media && <Media data={dataImage} />}
      {value === GALLERY_TYPE.link && <Link data={dataLink} />}
      {value === GALLERY_TYPE.file && <File data={dataAudio} />}
    </DrawerLayout>
  );
};

Gallery.propTypes = { onClose: PropTypes.func };

Gallery.defaultProps = { onClose: () => {} };

export default Gallery;

const MEDIA_TYPE = [SystemConstant.SEND_TYPE.image, SystemConstant.SEND_TYPE.video];

const GALLERY_TYPE = { media: 0, link: 1, file: 2, dataImage: 3, dataAudio: 5, dataVideo: 6, dataLinks: 9 };

const useStyles = makeStyles(theme => ({
  gridGroupBtn: {
    padding: "0 10px",
  },
  gridBtn: {
    textAlign: "center",
  },
  tabs: {
    display: "none",
  },
  tab: {
    background: theme.palette.grey[100],
    borderRadius: 15.5,
    color: "#1E272E !important",
  },
  flexContainer: {
    padding: "12px 14px",
  },
  tabButton: {
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    height: 30,
    minHeight: "unset",
    borderRadius: 15.5,
  },
  tabLabel: {
    marginTop: 0,
    color: theme.palette.grey[500],
  },
  tabLabelSelected: {
    color: theme.palette.black,
  },
  tabButtonSelected: {
    backgroundColor: theme.palette.grey[100],
  },
  drawerRoot: {
    "&>.MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
  },
}));
