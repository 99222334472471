import React, { memo, useEffect, useRef, useState, Suspense, useCallback, createContext, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { getNSKey } from "utils/lang.utils";
import {
  isJSONString,
  replaceName2Id,
  textNormalize,
  toCamel,
  isArrayNotEquals,
  isExternalLink,
  uuid,
  getMediaMessagesByGroupId,
} from "utils";
import { AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { CarouselMedia } from "components";
import TitleChat from "./TitleChat";
import SearchMode from "./SearchMode";
import Thread from "./ViewMode/components/Thread";
import { getSavedServer } from "utils/common.utils";
import { ImageUploadDialog, UploadConfirmDialog } from "./components";
import { isGroupOrChannelType } from "pages/Call";
import InitGroupCallPopup from "./InitGroupCallPopup";
import {
  LocalAccountGroupService,
  LocalAccountService,
  LocalBranchAccountService,
  LocalGroupService,
} from "services/local.service";
import MessengerChatInput from "./MessengerChatInput";
import { AccountActions, ConversationActions, GroupInfoActions } from "redux-store";
import { useBlockedAccountStatus, useThreadInfo } from "hooks";
import { StorageUtil } from "utils";

const ViewMode = React.lazy(() => import("./ViewMode"));

const MessengerChat = ({ isOpenSearchPopup, setIsOpenSearchPopup }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const autoFocusRef = useRef();
  const groupDetailRef = useRef({}); // TODO: hotfix render many time
  const { totalThreads } = useThreadInfo();
  const { isBlockedAccount, isBlockedByAccount } = useBlockedAccountStatus();

  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const selectId = useSelector(state => state.conversationRedux.selectedGroupId);
  const isRefreshing = useSelector(state => state.conversationRedux.isRefreshing);

  const isSearchMode = useSelector(state => state.conversationRedux.isSearchMode);
  const threadingId = useSelector(state => state.conversationRedux.threadingId);
  const isUpdateGroupSuccess = useSelector(state => state.conversationRedux.isUpdateGroupSuccess);
  const dataUpdateGroupSuccess = useSelector(state => state.conversationRedux.dataUpdateGroupSuccess);
  const changeGroupPhoto = useSelector(state => state.groupInfoRedux.changeGroupPhoto);
  const isUpdateViewMode = useSelector(state => state.conversationRedux.isUpdateViewMode);
  const getGroupName = useSelector(state => state.groupInfoRedux.groupName);
  const getGroupMembers = useSelector(state => state.groupInfoRedux.groupMembers);
  const eventGroupUpdate = useSelector(state => state.conversationRedux.eventGroupUpdate);
  const isSendMessageSuccess = useSelector(state => state.conversationRedux.isSendMessageSuccess);
  const isFetchNotificationSuccess = useSelector(state => state.conversationRedux.isFetchNotificationSuccess);
  const isAddConference = useSelector(state => state.conversationRedux.isAddConference);

  const [fileUpload, setFileUpload] = useState(null);
  const [groupDetail, setGroupDetail] = useState({
    name: null,
    groupName: "",
    groupMembers: [],
  });
  const [messageC, setMessageC] = useState("");
  const [mentionIdsArr, setMentionIdsArr] = useState([]);
  const [isShowCarousel, setIsShowCarousel] = useState(false);
  const [mediaArr, setMediaArr] = useState([]);
  const [mediaInitialIndex, setMediaInitialIndex] = useState(0);
  const [deletedMessage, setDeletedMessage] = useState(null);
  const [isAdminGroup, setIsAdminGroup] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(null);
  const [isOpenInit, setIsOpenInit] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [isInactive, setIsInactive] = useState(false);

  const [, forceRerender] = useReducer(x => x + 1, 0);

  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);

  const onResetInput = () => {
    setMessageC("");
    setMentionIdsArr([]);
    setMediaArr([]);
    setFileUpload(null);
    if (autoFocusRef?.current) {
      autoFocusRef.current.value = "";
    }
  };

  // TODO: hotfix performance render MessengerChat: make useCallback
  const onSendMessage = useCallback(
    (sendType = SystemConstant.SEND_TYPE.message, content, parentId = null, mentionList) => {
      setFileUpload(null);

      if (!content || content === "") return;
      let savedServer = getSavedServer();
      sendType = isExternalLink(content) ? SystemConstant.SEND_TYPE.link : sendType;

      let accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
      let deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
      let newSendType =
        groupDetailRef.current.groupType === SystemConstant.GROUP_CHAT_TYPE.personal
          ? SystemConstant.SEND_TYPE.message
          : SystemConstant.SEND_TYPE.groupMessage;

      if (SystemConstant.MEDIA_SEND_TYPE.includes(sendType)) {
        newSendType = sendType;
      }

      let msgId = uuid();
      let sourceId = uuid();
      let saveMessage = {
        account_id: accountId,
        branch_id: savedServer?.id,
        content: content,
        created: Date.now(),
        device_id: deviceId,
        group_id: groupDetailRef.current.id,
        id: msgId,
        mentions: mentionList ? JSON.stringify(mentionList) : "",
        modified: null,
        options: null,
        parent_id: parentId,
        send_type: newSendType,
        sender_device_id: deviceId,
        sender_id: accountId,
        source_id: sourceId,
        state: 1,
        status: 1,
        thread_id: threadingId ? threadingId : null,
        call_status: null,
      };

      setSendingMessage(toCamel(saveMessage));

      dispatch(
        ConversationActions.sendMessage({
          groupDetail: groupDetailRef.current,
          sendType: sendType,
          content: content,
          parentId: parentId,
          // deviceList: devicesList,
          branchId: savedServer?.id,
          mentionIdsArr: mentionList,
          threadId: threadingId ? threadingId : null,
          currentMessage: saveMessage,
        }),
      );
      dispatch(
        ConversationActions.conversationSet({
          isSearchMode: false,
          uploadMessageAttachmentData: null,
        }),
      );
      onResetInput();
    },
    [threadingId, mentionIdsArr],
  );

  const onUnblockUser = () => {
    dispatch(AccountActions.unblockUser(groupDetail.groupMembers[0].id));
  };

  const onMediaClick = msg => {
    const mediaTypes = [SystemConstant.SEND_TYPE.image, SystemConstant.SEND_TYPE.video];

    if (mediaTypes.includes(msg.sendType)) {
      const index = mediaArr.findIndex(media => media.id === msg.id);
      setMediaInitialIndex(index);
      setIsShowCarousel(true);
    }
  };

  useEffect(() => {
    let idsArr = [];
    groupDetail.groupMembers.forEach(member => {
      let name2Test = "@" + member.name;
      if (messageC.includes(name2Test)) {
        idsArr.push(member.id);
      }
    });

    if (messageC.includes(accountId)) {
      idsArr.push(accountId);
    }

    // setMessageWithId(replaceName2Id(messageC, groupMembers));
    if (isArrayNotEquals(idsArr, mentionIdsArr)) setMentionIdsArr(idsArr);
  }, [messageC]);

  useEffect(() => {
    setIsOpenSearchPopup(isSearchMode);
  }, [isSearchMode]);

  useEffect(() => {
    if (groupDetail && groupDetail.id) {
      groupDetailRef.current = groupDetail;
    }
  }, [groupDetail]);

  useEffect(() => {
    if (selectId) {
      dispatch(
        ConversationActions.conversationSet({
          threadingId: null,
        }),
      );
    }

    if (selectedGroupId) {
      setGroupDetail({
        ...{
          name: null,
          groupName: "",
          groupMembers: [],
        },
      });
      onResetInput();
      render();
    }
  }, [selectedGroupId]);

  useEffect(() => {
    if (selectedGroupId && (isRefreshing || isUpdateViewMode || isFetchNotificationSuccess)) {
      forceRerender();
      render();
    }
  }, [selectedGroupId, isRefreshing, isUpdateViewMode, isFetchNotificationSuccess]);

  useEffect(() => {
    if (getGroupName) {
      setGroupDetail({ ...groupDetail, groupName: getGroupName });
    }
  }, [getGroupName]);

  useEffect(() => {
    if (getGroupMembers) {
      setGroupDetail({ ...groupDetail, groupMembers: getGroupMembers });
    }
  }, [getGroupMembers]);

  useEffect(() => {
    if (eventGroupUpdate.groupId === groupDetail.id) {
      render();
    }
  }, [eventGroupUpdate]);

  useEffect(() => {
    if (dataUpdateGroupSuccess) {
      setGroupDetail({
        ...groupDetail,
        name: JSON.parse(dataUpdateGroupSuccess).name,
      });
    }
  }, [isUpdateGroupSuccess, dataUpdateGroupSuccess]);

  useEffect(() => {
    setGroupDetail({
      ...groupDetail,
      avatarId: changeGroupPhoto,
    });
  }, [changeGroupPhoto]);

  useEffect(() => {
    LocalAccountGroupService.findByGroupId(groupDetail.id).then(result => {
      result = toCamel(result);
      if (result) {
        let tmp = result.find(item => item.accountId === accountId);
        if (tmp?.type === SystemConstant.ROLE_OBJ.admin && tmp) {
          setIsAdminGroup(true);
        } else {
          setIsAdminGroup(false);
        }
      }
    });
  }, [groupDetail]);

  useEffect(() => {
    if (isSendMessageSuccess) {
      dispatch(
        ConversationActions.conversationSet({
          isSendMessageSuccess: false,
        }),
      );
    }
  }, [isSendMessageSuccess]);

  useEffect(() => {
    window.addEventListener("paste", event => {
      var items = event.clipboardData || event.originalEvent.clipboardData;
      let files = items?.files;

      Array.from(files).forEach(item => {
        if (item.type.split("/")[0] === "image") {
          setFileUpload(item);
        }
      });
    });

    return () => {
      window.removeEventListener("paste", event => {
        var items = event.clipboardData || event.originalEvent.clipboardData;
        let files = items?.files;

        Array.from(files).forEach(item => {
          if (item.type.split("/")[0] === "image") {
            setFileUpload(item);
          }
        });
      });
    };
  }, []);

  useEffect(() => {
    if (isAddConference) {
      setIsOpenInit(true);
      dispatch(
        ConversationActions.conversationSet({
          isAddConference: false,
        }),
      );
    }
  }, [isAddConference]);

  useEffect(() => {
    const selectedGroupId = selectId || totalThreads.filter(item => item.threadId === threadingId)[0]?.groupId;
    setSelectedGroupId(selectedGroupId);
  }, [totalThreads, selectId]);

  function render() {
    let waitTimeout = null;
    let groupData = {};
    let groupMembers = [];
    let groupName = "";
    let isContact = false;
    let r = LocalGroupService.getByGroupId(selectedGroupId);
    groupData = toCamel(r);
    let tmpMemberArray = [];
    LocalAccountGroupService.findByGroupId(groupData.id).then(accountGroupList => {
      let filteredArray = toCamel(accountGroupList).map(r => r.accountId);

      const members = LocalAccountService.getAccountByIds(filteredArray);
      tmpMemberArray = members;
      if (groupData.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
        tmpMemberArray = tmpMemberArray.filter(member => member.id !== accountId);
      } else {
        tmpMemberArray = tmpMemberArray.filter(member => member.state === SystemConstant.STATE.active);
      }
      groupMembers = toCamel(tmpMemberArray);
      dispatch(
        GroupInfoActions.groupInfoSet({
          groupMembers: groupMembers,
        }),
      );

      let memberIdArray = tmpMemberArray.map(member => member.id) || [];
      if (groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
        memberIdArray.push(accountId);
      }
      const otherMember = members?.find(member => member.id !== accountId);
      const inactiveAccountIds = LocalBranchAccountService.getAllInactiveAccount().map(item => item.account_id);

      if (
        groupData.groupType === SystemConstant.GROUP_CHAT_TYPE.personal &&
        otherMember &&
        (otherMember.state === SystemConstant.STATE.inactive || inactiveAccountIds.includes(otherMember.id))
      ) {
        setIsInactive(true);
      } else {
        setIsInactive(false);
      }

      if (
        isGroupOrChannelType(groupData?.groupType) &&
        isJSONString(groupData.name) &&
        JSON.parse(groupData.name).name
      ) {
        groupName = JSON.parse(groupData.name).name;
      } else if (groupData.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
        if (otherMember) {
          groupName = otherMember.name || getLabel(LangConstant.TXT_NO_NAME);
        } else {
          groupName = getLabel(LangConstant.TXT_NO_NAME);
        }
      } else {
        const groupNameArr = tmpMemberArray.map(item => item.name);
        groupName = groupNameArr.join(", ");
      }
    });

    waitTimeout = setTimeout(() => {
      setGroupDetail({
        ...groupData,
        name: groupName,
        groupName: groupName,
        groupMembers: groupMembers,
        members: groupMembers,
        isContact: isContact,
      });
    }, 1);

    const handleMediaList = getMediaMessagesByGroupId(selectedGroupId);

    setMediaInitialIndex(0);
    if (isArrayNotEquals(handleMediaList, mediaArr)) {
      setMediaArr(handleMediaList);
    }

    return () => {
      clearTimeout(waitTimeout);
    };
  }

  const isCompleteFetchData = groupDetail.groupMembers.length > 0;

  const contextData = {
    deletedMessage,
    setDeletedMessage,
    groupDetail,
    onSendMessage,
    sendingMessage,
    setSendingMessage,
    onMediaClick,
  };

  const isUploadFile =
    fileUpload && (AppConstant.FILE_EXTENSIONS.includes(fileUpload?.type) || !Boolean(fileUpload?.type));
  const isUploadMedia = fileUpload && !isUploadFile;

  return (
    <ConversationContext.Provider value={contextData}>
      {isUploadFile && (
        <UploadConfirmDialog
          fileUpload={fileUpload}
          isShow
          groupId={selectedGroupId}
          onSendMessage={onSendMessage}
          onClose={() => {
            setFileUpload(null);
          }}
        />
      )}

      {isUploadMedia && (
        <ImageUploadDialog
          isShow={Boolean(fileUpload)}
          fileUpload={fileUpload}
          groupId={selectedGroupId}
          onSendMessage={onSendMessage}
          onClose={() => {
            setFileUpload(null);
          }}
        />
      )}
      <Box className={selectedGroupId || threadingId ? classes.container : "hidden"}>
        {threadingId ? (
          <Thread onSendMessage={onSendMessage} groupDetail={groupDetail} />
        ) : (
          <>
            {isCompleteFetchData && (
              <TitleChat
                data={groupDetail}
                isOpenSearchPopup={isOpenSearchPopup}
                setIsOpenSearchPopup={setIsOpenSearchPopup}
                isAdminGroup={isAdminGroup}
                isInactive={isInactive}
              />
            )}
            <Box className={clsx(classes.wrapFlex, isBlockedAccount && classes.blockedContainer)}>
              {isSearchMode ? (
                <Suspense fallback={<Box>Loading...</Box>}>
                  <SearchMode
                    onSendMessage={onSendMessage}
                    onMediaClick={onMediaClick}
                    setIsOpenSearchPopup={setIsOpenSearchPopup}
                    groupDetail={groupDetail}
                  />
                </Suspense>
              ) : (
                <Suspense fallback={<Box></Box>}>
                  <ViewMode
                    isChannel={groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.channel}
                    groupMembers={groupDetail.groupMembers}
                    onMediaClick={onMediaClick}
                    groupDetail={groupDetail}
                  />
                </Suspense>
              )}
            </Box>
          </>
        )}
        <Box>
          {isInactive ? (
            <Box className={classes.blockedBox}>
              <Typography className={classes.blockedMessage}>{getLabel(LangConstant.TXT_INACTIVE_USER)}</Typography>
              <Button className={clsx(classes.unblockButton, "semiBold-lg-txt")}>
                {getLabel(LangConstant.TXT_INACTIVE_BUTTON_LABEL)}
              </Button>
            </Box>
          ) : isBlockedAccount || isBlockedByAccount ? (
            <Box className={classes.blockedBox}>
              <Typography className={classes.blockedMessage}>
                {getLabel(
                  isBlockedByAccount ? LangConstant.TXT_BEING_BLOCKED_MESSAGE : LangConstant.TXT_BLOCKED_MESSAGE,
                )}
              </Typography>
              {!isBlockedByAccount && (
                <Button className={clsx(classes.unblockButton, "semiBold-lg-txt")} onClick={onUnblockUser}>
                  {getLabel(LangConstant.TXT_UN_BLOCK)}
                </Button>
              )}
            </Box>
          ) : (
            <Box>
              <MessengerChatInput
                placeholder={getLabel(getNSKey(LangConstant.NS_COMMON, LangConstant.TXT_PLACEHOLDER))}
                onSendMessage={() =>
                  onSendMessage(
                    SystemConstant.SEND_TYPE.message,
                    replaceName2Id(messageC, groupDetailRef.current.groupMembers).trim(),
                    null,
                    mentionIdsArr,
                  )
                }
                onSetMessageContent={setMessageC}
                onFileChange={setFileUpload}
              />
              <CarouselMedia
                open={isShowCarousel}
                onClose={() => setIsShowCarousel(false)}
                data={mediaArr}
                initialIndex={mediaInitialIndex}
              />
            </Box>
          )}
        </Box>
      </Box>

      {isOpenInit && groupDetail.id && (
        <InitGroupCallPopup open={isOpenInit} onClose={() => setIsOpenInit(false)} data={groupDetail} />
      )}
    </ConversationContext.Provider>
  );
};

export const getFilterArray = (memberArray, search) => {
  let filterItems = [];
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  if (memberArray && memberArray.length > 0) {
    filterItems = memberArray.filter(
      member => member.id !== accountId && textNormalize(member.name).includes(textNormalize(search)),
    );
  }
  return filterItems;
};

export const ConversationContext = createContext();

export default memo(MessengerChat);

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    borderRadius: 20,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  wrapFlex: {
    paddingTop: 20,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.white,
  },

  blockedContainer: {
    height: "calc(100% - 220px)",
  },

  blockedBox: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 20,
    height: 120,
  },

  blockedMessage: {
    fontWeight: 600,
    lineHeight: "16px",
  },

  unblockButton: {
    backgroundColor: theme.palette.grey[500],
    textTransform: "none",
    color: theme.palette.white,
    padding: "14px 132px",
    marginBottom: 23,
    marginTop: 16,
    "&:hover": {
      backgroundColor: theme.palette.grey[800],
      boxShadow: "none",
    },
  },
}));
