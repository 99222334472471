import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DrawerLayout } from "components";
import { getNSKey } from "utils/lang.utils";
import { LangConstant, SystemConstant } from "const";
import PropTypes from "prop-types";
import SelectingListContact from "../AddingChat/SelectingListContact";
import { useContactList } from "hooks";
import { GroupInfoActions } from "redux-store";

const AddingGroupMembers = ({ open, onClose, data }) => {
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const { contactList } = useContactList();
  const groupMemberList = useSelector(state => state.groupInfoRedux.groupMembers);

  const [listAddGroupMember, setListAddGroupMember] = useState([]);

  const handleAddingMember = selectedContact => {
    const memberIds = selectedContact.map(item => item.contactAccountId);
    dispatch(
      GroupInfoActions.addMemberGroup({
        groupId: data.id,
        memberIds: memberIds,
        memberInviteType: SystemConstant.ROLE_OBJ.member,
      }),
    );
    onClose();
  };

  useEffect(() => {
    const memberList = groupMemberList || data.members;
    const filterMemberGroup = Array.isArray(memberList) ? memberList.map(item => item.id) : [];
    setListAddGroupMember(contactList.filter(item => !filterMemberGroup.includes(item.contactAccountId)));
  }, [contactList]);

  return (
    <DrawerLayout
      open={open}
      onClose={onClose}
      anchor={"right"}
      hideBackdrop={false}
      title={getLabel(getNSKey(LangConstant.NS_HOME_CONVERSATION, LangConstant.TXT_ADD_NEW_MEMBER))}
    >
      <SelectingListContact data={listAddGroupMember} onComplete={handleAddingMember} />
    </DrawerLayout>
  );
};

AddingGroupMembers.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,

  onClose: PropTypes.func,
};

AddingGroupMembers.defaultProps = {
  open: false,
  data: {},

  onClose: () => {},
};

export default AddingGroupMembers;
