import { useState, memo, useRef } from "react";
import PropTypes from "prop-types";
import { AppConstant, LangConstant } from "const";
import { isVideo } from "utils";
import NoticeDialog from "./dialog/NoticeDialog";
import { useTranslation } from "react-i18next";

const HandleUploadingInput = ({ onChange, ...otherProps }) => {
  const { t: getLabel } = useTranslation();

  const inputRef = useRef(null);

  const [isError, setIsError] = useState(false);

  const handleChangingFile = event => {
    const file = event.target.files[0];
    const fileType = file?.type;
    const isNotSupportVideo = isVideo(fileType) && false === AppConstant.SUPPORT_VIDEO_TYPES.includes(fileType);

    if (isNotSupportVideo) {
      setIsError(true);
    } else if (onChange) {
      onChange(event);
    }

    inputRef.current.value = null;
  };

  return (
    <>
      <input ref={inputRef} onChange={handleChangingFile} {...otherProps} />
      <NoticeDialog
        open={isError}
        title={getLabel(LangConstant.ERR_TITLE)}
        content={getLabel(LangConstant.ERR_UNSUPPORTED_EXTENSION)}
        submitProps={{ onClick: () => setIsError(false) }}
      />
    </>
  );
};

HandleUploadingInput.propTypes = {
  id: PropTypes.string,
  accept: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,

  onChange: PropTypes.func,
};

HandleUploadingInput.defaultProps = {};

export default memo(HandleUploadingInput);
