import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { KeyConstant, SystemConstant } from "const";
import { getImageSrcAvatar, isArrayNotEquals, toCamel } from "utils";
import { BranchSelectors } from "redux-store";
import { LocalBranchService } from "services/local.service";
import { BGlobalServerImage } from "theme/images";
import { StorageUtil } from "utils";

const useServerList = () => {
  const isGetBranchSuccess = useSelector(BranchSelectors.isGetBranchSuccess);
  const kickedBranchArray = useSelector(BranchSelectors.getKickedBranchArray);
  const isUpdateBranchInviteStatus = useSelector(BranchSelectors.isUpdateBranchInviteStatus);

  const [serverList, setServerList] = useState([]); // Global server
  const [branchList, setBranchList] = useState([]); // Private server

  const getServerListFromDB = async () => {
    const defaultBranchId = StorageUtil.getItem(KeyConstant.KEY_IS_HAVING_DEFAULT_FLAG);
    let accountServerList = await LocalBranchService.getServerList({
      state: SystemConstant.BRANCH_STATE.active,
    });
    accountServerList = toCamel(accountServerList);

    // Only display default branch if it exist
    const hasDefaultBranch = defaultBranchId && Boolean(accountServerList.find(item => item.id === defaultBranchId));
    if (hasDefaultBranch) {
      accountServerList = accountServerList.filter(server => server.id === defaultBranchId);
    }

    // Add icon
    accountServerList = await Promise.all(
      accountServerList.map(async serverItem => {
        serverItem.branchIcon = BGlobalServerImage;

        if (serverItem.attachmentId) {
          const branchIconUrl = await getImageSrcAvatar(
            serverItem.attachmentId,
            SystemConstant.GROUP_CHAT_TYPE.personal,
          );

          serverItem.branchIcon = branchIconUrl || BGlobalServerImage;
        }
        return serverItem;
      }),
    );

    const tmpServerArr = accountServerList.filter(server => server.type === SystemConstant.SERVER_TYPE.server);
    const tmpBranchArr = accountServerList.filter(server => server.type === SystemConstant.SERVER_TYPE.branch);

    if (isArrayNotEquals(tmpServerArr, serverList)) {
      setServerList(tmpServerArr);
    }

    if (isArrayNotEquals(tmpBranchArr, branchList)) {
      setBranchList(tmpBranchArr);
    }
  };

  useEffect(() => {
    if (isUpdateBranchInviteStatus || isGetBranchSuccess) getServerListFromDB();
  }, [isUpdateBranchInviteStatus, isGetBranchSuccess]);

  useEffect(() => {
    getServerListFromDB();
  }, [kickedBranchArray]);

  return { serverList, branchList };
};

export default useServerList;
