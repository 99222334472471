import { createReducer, createActions } from "reduxsauce";
import { REDUX_STATE, requestReducerFunc } from "./redux-structure";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  synchronizeData: [],
  periodicSynchronize: [],
});

export const SystemTypes = Types;
export const SystemActions = Creators;
export default Creators;

/* ------------- Initial State ------------- */
const INITIAL_STATE = {
  ...REDUX_STATE,
};

/* ------------- Selector ------------- */
export const SystemSelectors = {};

/* ------------- Reducers ------------- */
const request = (state = INITIAL_STATE) => requestReducerFunc(state);

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.SYNCHRONIZE_DATA]: request,
  [Types.PERIODIC_SYNCHRONIZE]: request,
};

/* ------------- Hookup Reducers To Types ------------- */
export const SystemReducer = createReducer(INITIAL_STATE, HANDLERS);
