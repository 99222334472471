import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogTitle, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { useDispatch } from "react-redux";
import { getSavedServer } from "utils/common.utils";
import { ConversationActions } from "redux-store";
import { StorageUtil } from "utils";

const DialogConfirmLeave = ({ open, onClose, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  let accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  let savedServer = getSavedServer();

  const clickLeave = () => {
    dispatch(
      ConversationActions.sendMessage({
        groupDetail: data,
        sendType: SystemConstant.SEND_TYPE.leaveGroup,
        content: accountId,
        parentId: null,
        deviceList: [],
        branchId: savedServer?.id,
        mentionIdsArr: [],
        threadId: null,
        removingId: accountId,
      }),
    );

    onClose();
  };

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.wrapDialogGroup }}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.title}>{getLabel(LangConstant.TXT_CONFIRM_LEAVE)}</DialogTitle>
      <Typography className={classes.descGroupMember}>{getLabel(LangConstant.TXT_CONTENT_BUTTON_GROUP)}</Typography>
      <Button onClick={clickLeave} className={classes.buttonChannel} variant="contained">
        {getLabel(LangConstant.TXT_BUTTON_LEAVE_GROUP)}
      </Button>
      <Button onClick={onClose} className={classes.buttonGroup} color="inherit" variant="contained">
        {getLabel(LangConstant.TXT_CANCEL_CHANNEL_INFO)}
      </Button>
    </Dialog>
  );
};

export default DialogConfirmLeave;

const useStyles = makeStyles(theme => ({
  wrapDialogGroup: {
    textAlign: "center",
    borderRadius: 20,
    padding: "30px 20px",
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
  },
  descGroupMember: {
    margin: "0 22px 16px 22px",
  },
  wrapButton: {
    display: "flex",
    marginBottom: 20,
  },
  buttonLeave: {
    marginLeft: 24,
    marginRight: 24,
    width: "100%",
    backgroundColor: theme.palette.white,
    color: theme.palette.secondary.main,
    "&:hover": {
      background: "none",
      boxShadow: "none",
    },
  },
  buttonGroup: {
    backgroundColor: theme.palette.white,
    color: "#CBCACA",
    margin: "auto",
    "&:hover": {
      color: theme.palette.grey[500],
      boxShadow: "none",
      background: "none",
    },
  },
  buttonChannel: {
    marginBottom: 10,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));
