import React, { useEffect, useState } from "react";
import { Box, Drawer } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Processing } from "components";
import { Welcome, Sidebar } from "./components";
import { useSelector } from "react-redux";
import MessengerChat from "./components/MessengerChat";
import { AppConstant } from "const";
import CallingNotification from "pages/CallingNotification";
import Call from "pages/Call";
import store, { AuthActions, ConversationActions } from "redux-store";
import {
  useAccount,
  useHandleServerMode,
  useSynchronize,
  useHandleOnlineStatus,
  useHandleBlockAccount,
  useHandleUnreadMessage,
} from "hooks";

const Home = () => {
  const classes = useStyles();
  const { currentAccount } = useAccount();
  const { synchronizeWhenOpenApp } = useSynchronize();

  // Logic only be created 1 time
  useHandleServerMode();
  useHandleOnlineStatus();
  useHandleBlockAccount();
  useHandleUnreadMessage();

  const isSynchronizing = useSelector(state => state.conversationRedux.isSynchronizing);
  const isOpenCallingDialog = useSelector(state => state.callingRedux.isOpenCallingDialog);

  const [isOpenSearchPopup, setIsOpenSearchPopup] = useState(false);

  useEffect(() => {
    window.onmessage = handleApplicationEvents;
    const synchPeriodInterval = synchronizeWhenOpenApp();

    return () => {
      if (synchPeriodInterval) clearInterval(synchPeriodInterval);
    };
  }, []);

  return (
    <>
      <Processing isShow={isSynchronizing} />
      <Box className={classes.root}>
        <Drawer
          variant="permanent"
          anchor="left"
          classes={{
            root: classes.sidebar,
            paper: classes.sidebar,
          }}
        >
          <Sidebar />
        </Drawer>
        <Box component="main" className={classes.main}>
          <MessengerChat isOpenSearchPopup={isOpenSearchPopup} setIsOpenSearchPopup={setIsOpenSearchPopup} />
          <Welcome name={currentAccount.name || ""} />
        </Box>
      </Box>
      <CallingNotification isOpenSearchPopup={isOpenSearchPopup} />
      {isOpenCallingDialog !== AppConstant.CALLING_STATUS.notStart && <Call isOpenSearchPopup={isOpenSearchPopup} />}
    </>
  );
};

export default Home;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  sidebar: {
    width: AppConstant.SIDEBAR_WIDTH.medium,
    flexShrink: 0,
    boxSizing: "border-box",
    maxHeight: "calc(100vh)",
    overflow: "hidden",
    [theme.breakpoints.up(1500)]: {
      width: AppConstant.SIDEBAR_WIDTH.large,
    },
  },
  main: {
    position: "relative",
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    backgroundColor: "#F7F7F8",
    padding: 20,
    maxWidth: `calc(100% - ${AppConstant.SIDEBAR_WIDTH.medium})`,
    maxHeight: "100vh",
    [theme.breakpoints.up(1500)]: {
      maxWidth: `calc(100% - ${AppConstant.SIDEBAR_WIDTH.large}px)`,
    },
  },
}));

const handleApplicationEvents = async (event, msgEvent) => {
  if (event.source === window) {
    if (event.data === AppConstant.EVENT_PUSH_NOTIFICATION_CLICKED) {
      const [port] = event.ports;
      port.onmessage = event => {
        console.log({
          data: event.data,
        });
        let currentGroupId = event.data.groupId;

        if (currentGroupId) {
          store.dispatch(
            ConversationActions.setSelectGroupId({
              selectedGroupId: currentGroupId,
            }),
          );
        }
      };
    } else if (event.data === AppConstant.EVENT_RECEIVED_URL_METADATA) {
      const [port] = event.ports;
      port.onmessage = event => {
        let metaData = event.data.metaData;
        store.dispatch(
          ConversationActions.conversationSetLinkData({
            key: event.data.messageId,
            value: {
              messageId: event.data.messageId,
              metaData: metaData,
            },
          }),
        );
      };
    } else if (event.source === window && event.data === "show-download-update-process") {
      const [port] = event.ports;
      port.onmessage = event => {
        if (event.data) {
          let { downloadSpeed, downloadedPercentage, downloadedContent, totalContent } = event.data;
          store.dispatch(
            AuthActions.authSet({
              downloadProcess: {
                downloadSpeed,
                downloadedPercentage,
                downloadedContent,
                totalContent,
              },
            }),
          );
        }
      };
    }
  }
};
