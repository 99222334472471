import { ApiConstant } from "const";
import qs from "qs";
import { createApiWithBranch } from "./config/api.config";

export const addConversation = data =>
  createApiWithBranch().post(ApiConstant.POST_CREATE_CONVERSATION, qs.stringify(data, { skipNulls: true }));

export const getConversationList = data => createApiWithBranch().get(ApiConstant.GET_CONVERSATION_LIST, data);

export const getConversationMember = data =>
  createApiWithBranch().post(ApiConstant.POST_GET_CONVERSATION_MEMBER, qs.stringify(data));

export const updateConversation = data =>
  createApiWithBranch().post(ApiConstant.POST_UPDATE_CONVERSATION, qs.stringify(data, { skipNulls: true }));

export const deleteConversation = data =>
  createApiWithBranch().post(ApiConstant.POST_DELETE_CONVERSATION, qs.stringify(data, { skipNulls: true }));

export const addConversationMembers = data =>
  createApiWithBranch().post(ApiConstant.POST_ADD_MEMBERS, qs.stringify(data, { skipNulls: true }));
