import { useEffect, useState } from "react";
import { KeyConstant } from "const";
import { toCamel } from "utils";
import { LocalAccountService } from "services/local.service";
import { StorageUtil } from "utils";

const useAccount = () => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);

  const [currentAccount, setCurrentAccount] = useState({});

  const getAccount = async selectAccountId => {
    if (!selectAccountId) return;

    const account = await LocalAccountService.get(selectAccountId);

    if (account) {
      setCurrentAccount(toCamel(account));
    } else {
      // TODO: Redirect to login or do something
    }
  };

  useEffect(() => {
    getAccount(accountId);
  }, [accountId]);

  return { currentAccount };
};

export default useAccount;
