import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { LangConstant } from "const";
import { PrimaryTab, SearchBar, TabPanel, InfiniteScroll } from "components";
import GroupItem from "../../AddingContact/GroupItem";
import ContactListByLetter from "../../AddingContact/ContactListByLetter";
import AddingContact from "../../AddingContact";
import { useAccountList, useContactList, useConversationList, useServerMode } from "hooks";

const ContactListTab = ({ onClick }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const { contactList } = useContactList();
  const { accountList } = useAccountList();
  const { isBranchServer } = useServerMode();
  const { channelList, groupList } = useConversationList();
  const channelGroupList = [...channelList, ...groupList];

  const [selectedTab, setSelectedTab] = useState(CHAT_TAB_INDEX.personal);
  const [orderContactByName, setOrderContactByName] = useState({}); // Group contact
  const [filterGroupList, setFilterGroupList] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const contactTabArray = [
    {
      label: getLabel(isBranchServer ? LangConstant.TXT_MEMBERS : LangConstant.TXT_MY_CONTACT),
    },

    {
      label: getLabel(LangConstant.TXT_GROUP),
    },
  ];

  const onChangeSearchInput = value => {
    setSearchValue(value);
  };

  // TODO: pagination when user scroll
  const onScrollToBottom = () => {
    if (selectedTab === CHAT_TAB_INDEX.personal) {
      // Contact list pagination
    }

    if (selectedTab === CHAT_TAB_INDEX.group) {
      // Group list pagination
    }
  };

  useEffect(() => {
    let filteredList;
    if (isBranchServer) {
      if (searchValue === "" || false === Boolean(searchValue)) {
        filteredList = contactList
      } else {
        filteredList = accountList.filter(
          item =>
            (item.name || "").toLowerCase().includes(searchValue.toLowerCase()) || item.phone.includes(searchValue),
        );
      }
    } else {
      filteredList = contactList.filter(
        item => (item.name || "").toLowerCase().includes(searchValue.toLowerCase()) || item.phone.includes(searchValue),
      );
    }

    setOrderContactByName(groupContactByName(filteredList));
  }, [contactList, accountList, searchValue]);

  useEffect(() => {
    const filteredList = channelGroupList.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()));

    setFilterGroupList(filteredList);
  }, [channelList, groupList, searchValue]);

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.fixedHeader}>
          <PrimaryTab
            tabArray={contactTabArray}
            onChange={setSelectedTab}
            TabIndicatorProps={{
              className: "hidden",
            }}
            selectedTabIndex={selectedTab}
            classes={{
              flexContainer: classes.flexContainer,
              tabButton: classes.tabButton,
              tabLabel: clsx(classes.tabLabel, "bold-sm-txt"),
              tabLabelSelected: classes.tabLabelSelected,
              tabButtonSelected: classes.tabButtonSelected,
            }}
          />

          <SearchBar
            value={searchValue}
            onChange={onChangeSearchInput}
            classes={{
              root: classes.searchBarRoot,
            }}
          />
        </Box>

        <TabPanel value={selectedTab} index={CHAT_TAB_INDEX.personal}>
          <InfiniteScroll onScroll={onScrollToBottom} className={classes.contactListBox}>
            {Object.keys(orderContactByName).map((firstLetter, index) => (
              <ContactListByLetter
                key={index}
                contactList={orderContactByName[firstLetter]}
                letter={firstLetter}
                onItemClick={onClick}
              />
            ))}
          </InfiniteScroll>
        </TabPanel>

        <TabPanel value={selectedTab} index={CHAT_TAB_INDEX.group}>
          <InfiniteScroll onScroll={onScrollToBottom} className={classes.contactListBox}>
            {filterGroupList.map((groupItem, index) => (
              <GroupItem key={index} data={groupItem} onClick={() => onClick(groupItem)} />
            ))}
          </InfiniteScroll>
        </TabPanel>
      </Box>

      <AddingContact isGroupTab={selectedTab === CHAT_TAB_INDEX.group} />
    </>
  );
};

ContactListTab.propTypes = {
  onClick: PropTypes.func,
};

ContactListTab.defaultProps = {
  onClick: () => {},
};

const CHAT_TAB_INDEX = {
  personal: 0,
  group: 1,
};

export default memo(ContactListTab);

const groupContactByName = contactList => {
  if (!contactList || contactList.length === 0) return [];

  const handleContactsObj = contactList.reduce((newContactList, contact) => {
    const firstLetter = contact.name ? contact.name?.charAt(0).toUpperCase() : "#";
    newContactList[firstLetter] = (newContactList[firstLetter] || []).concat(contact);

    return newContactList;
  }, {});

  return handleContactsObj;
};

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
  },

  flexContainer: {
    padding: "12px 14px",
  },

  tabButton: {
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    height: 30,
    minHeight: "unset",
    borderRadius: 15.5,
  },

  tabLabel: {
    marginTop: 0,
    color: "#7F7F7F",
  },

  tabLabelSelected: {
    color: "#1E272E",
  },

  tabButtonSelected: {
    backgroundColor: theme.palette.grey[100],
  },

  fixedHeader: {
    position: "sticky",
  },

  searchBarRoot: {
    margin: "0 14px",
    maxWidth: "calc(100% - 28px)",
  },

  contactListBox: {
    maxHeight: "calc(100vh - 330px)",
    overflow: "auto",
    padding: "16px 14px",
  },
}));
