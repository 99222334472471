import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, OutlinedInput, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { SystemConstant } from "const";

const OTPInput = ({ otpLength, errorContent, onChange, ...otherProps }) => {
  const classes = useStyles();

  const inputRefsArray = useRef(new Array(otpLength).fill(null));
  const isWrongOtp = useSelector(state => state.authRedux.isWrongOtp);

  const [otpValues, setOtpValues] = useState(new Array(otpLength).fill(""));
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleDigitChange = (event, index) => {
    const targetValue = event.target.value;

    // Ignore non-numeric values
    if (!event.target.validity.valid) return;

    // This is a "mobile paste" event, the whole value has been provided
    if (targetValue.length === otpLength) {
      handlePastedOtp(targetValue);
      return;
    }

    setOtpValues(updateArrayAtIndex(otpValues, targetValue, index));

    // Auto advance to next input field in the set
    const nextIndex = index + 1;
    if (nextIndex < otpLength) {
      setCurrentIndex(nextIndex);
      inputRefsArray.current[nextIndex]?.focus();
    } else {
      setCurrentIndex(-1);
    }
  };

  /**
   * Handle Backspace key only, ignore other keydown events
   *
   * @param event
   * @param index
   */
  const handleBackspace = (event, index) => {
    // Ignore if not Backspace key
    if (event.key !== "Backspace") return;

    // Go back to previous input field in the set and clear the value
    const prevIndex = Math.max(index - 1, 0);
    inputRefsArray.current[prevIndex]?.focus();
    setOtpValues(updateArrayAtIndex(otpValues, "", prevIndex));
    setCurrentIndex(prevIndex);
  };

  const handleFocus = (_, index) => {
    setCurrentIndex(index);
  };

  // This only fires on desktop pastes
  const handlePasteEvent = event => {
    event.preventDefault();
    const pastedText = event.clipboardData?.getData("text").trim();
    handlePastedOtp(pastedText);
  };

  const handlePastedOtp = pastedText => {
    if (pastedText.match(new RegExp(`^[0-9]{${otpLength}}$`))) {
      setOtpValues(pastedText.split(""));
      setCurrentIndex(-1);
    }
  };

  useEffect(() => {
    if (inputRefsArray.current[0]) {
      inputRefsArray.current[0].focus();
    }
  }, [inputRefsArray]);

  useEffect(() => {
    if (currentIndex === -1) {
      inputRefsArray.current.forEach(inputRef => {
        inputRef?.blur();
      });
    } else {
      setOtpValues(otpValues.map((value, index) => (index < currentIndex ? value : "")));
    }
  }, [currentIndex]);

  useEffect(() => {
    if (onChange) {
      onChange(otpValues.join(""));
    }
  }, [otpValues]);

  return (
    <>
      <Box className={classes.otpRoot}>
        {inputRefsArray.current.map((_, index) => (
          <OutlinedInput
            inputRef={element => (inputRefsArray.current[index] = element)}
            key={index}
            value={otpValues[index]}
            onChange={event => handleDigitChange(event, index)}
            onKeyDown={event => handleBackspace(event, index)}
            onFocus={event => handleFocus(event, index)}
            onPaste={handlePasteEvent}
            classes={{
              root: classes.otpInputRoot,
              input: clsx(classes.otpInput, isWrongOtp && classes.otpInputError),
              notchedOutline: classes.otpInputBorder,
            }}
            inputProps={{
              "aria-label": `Enter OTP code, part ${index + 1} of ${otpLength}`,
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            {...otherProps}
          />
        ))}
      </Box>

      {Boolean(errorContent) && <Typography className={classes.errorText}>{errorContent}</Typography>}
    </>
  );
};

OTPInput.propTypes = {
  otpLength: PropTypes.number,
  errorContent: PropTypes.string,
};

OTPInput.defaultProps = {
  otpLength: SystemConstant.DEFAULT_OTP_LENGTH,
  errorContent: null,

  onChange: () => {},
};

export default OTPInput;

const updateArrayAtIndex = (array = [], newValue, index) => [
  ...array.slice(0, index),
  newValue,
  ...array.slice(index + 1),
];

const useStyles = makeStyles(theme => ({
  otpRoot: {
    width: "100%",
    display: "flex",
    gap: 8,
    flexWrap: "wrap",
    justifyContent: "center",
  },

  otpInputRoot: {
    width: 42,
    height: 42,
    borderRadius: 8,

    "&:hover $otpInputBorder": {
      border: "2px solid #008FE8",
    },
  },
  otpInputBorder: {},

  otpInput: {
    padding: 9,
    backgroundColor: "white",
    textAlign: "center",
  },

  otpInputError: {
    border: "1px solid #EF5845",
    caretColor: theme.palette.secondary.main,
    borderRadius: 8,
  },

  errorText: {
    marginTop: 8,
    lineHeight: "16px",
    color: "#EF5845",
    textAlign: "center",
    fontSize: "12px",
  },

  unregisteredMessage: {
    marginTop: 12,
    color: theme.palette.secondary.main,
    lineHeight: "16px",
    textAlign: "center",
  },
}));
