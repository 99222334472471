import { ApiConstant } from "const";
import qs from "qs";
import GlobalApi from "./config/api.config";

export const getBranchList = () => GlobalApi.post(ApiConstant.POST_GET_BRANCH_LIST);

export const getBranchAccount = branch_ids => GlobalApi.post(ApiConstant.POST_GET_BRANCH_ACCOUNT, { branch_ids });

export const updateBranch = data =>
  GlobalApi.post(ApiConstant.POST_UPDATE_BRANCH_ACCOUNT, qs.stringify(data, { skipNulls: true }));
