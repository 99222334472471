import React, { memo, useEffect, useState } from "react";
import clsx from "clsx";
import StringFormat from "string-format";
import PropTypes from "prop-types";
import { Box, CardMedia, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormatConstant, ImageConstant, KeyConstant } from "const";
import { getExternalLinkFromString } from "utils";
import { useSelector } from "react-redux";
import { StorageUtil } from "utils";

const LinkPreview = ({ message, classes }) => {
  const defaultClasses = useStyles();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const isMine = message.senderId === accountId;

  const linkData = useSelector(state => state.conversationRedux.linkData);

  const [handleContent, setHandleContent] = useState(message?.content || "");
  const [data, setData] = useState({
    title: "",
    description: "",
    image: "",
    url: "",
  });

  const onOpenExternalLink = e => {
    e.preventDefault();
    let el = e.currentTarget;
    let url = el.href;
    window.handleExternalLink.openExternalLink(url);
  };

  useEffect(() => {
    setHandleContent(message?.content);
  }, [message]);

  useEffect(async () => {
    let url = getExternalLinkFromString(message?.content);
    if (url) {
      let newContent = message?.content?.replace(url, `<a href="${url}" style="word-break: break-word">${url}</a>`);
      setHandleContent(newContent);
      if (!linkData[message.id]) {
        window.getLinkMetaData.fetch({
          url: url,
          messageId: message.id,
        });
      }
    }
  }, [message]);

  useEffect(() => {
    let id = StringFormat(FormatConstant.FM_CHAT_ITEM_ID, message.id);
    let elements = document.querySelectorAll(`#${id} a`);
    if (elements) {
      for (const element of elements) {
        element.addEventListener("click", onOpenExternalLink);
      }
    }

    return () => {
      if (elements) {
        for (const element of elements) {
          element.removeEventListener("click", onOpenExternalLink);
        }
      }
    };
  });

  useEffect(() => {
    if (linkData[message.id]) {
      const dataMessageLink = linkData[message.id]?.metaData;
      const og = dataMessageLink?.og;
      const firstImageInPage =
        Array.isArray(dataMessageLink?.images) && dataMessageLink.images.length > 0
          ? dataMessageLink.images[0].src
          : null;

      setData({
        ...dataMessageLink,
        ...dataMessageLink?.og,
        ...dataMessageLink?.meta,
        image: og?.image || firstImageInPage || ImageConstant.NoFileImage,
      });
    }
  }, [linkData[message.id]]);

  return (
    <Box className={clsx(defaultClasses.root, classes.root)}>
      <Typography
        className={clsx(defaultClasses.message, classes.message, {
          [defaultClasses.myMessage]: isMine,
          [classes.myMessage]: isMine,
        })}
        dangerouslySetInnerHTML={{ __html: handleContent }}
      />
      <Link
        href={data.url}
        className={clsx(
          defaultClasses.preview,
          {
            [defaultClasses.previewWithDesc]: data.desc,
          },
          classes.preview,
        )}
      >
        <CardMedia
          component="img"
          className={clsx(defaultClasses.image, classes.image, {
            [defaultClasses.skeletonLoading]: !Boolean(data.image),
            [classes.skeletonLoading]: !Boolean(data.image),
          })}
          image={data.image}
        />
        {data.title && (
          <Typography className={clsx(defaultClasses.title, classes.title, "bold-md-txt ellipsis")}>
            {data.title}
          </Typography>
        )}
        {data.description && (
          <Typography className={clsx(defaultClasses.desc, classes.desc, "regular-sm-txt ellipsis")}>
            {data.description}
          </Typography>
        )}
      </Link>
    </Box>
  );
};

export default memo(LinkPreview);

LinkPreview.propTypes = {
  message: PropTypes.object,

  classes: PropTypes.shape({
    root: PropTypes.string,
    message: PropTypes.string,
    preview: PropTypes.string,
    image: PropTypes.string,
    skeletonLoading: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.string,
  }),
};

LinkPreview.defaultProps = {
  message: {},

  classes: {
    root: "",
    message: "",
    preview: "",
    image: "",
    skeletonLoading: "",
    title: "",
    desc: "",
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    width: 300,
  },

  preview: {
    display: "block",
    backgroundColor: theme.palette.grey[100],
    textDecoration: "none",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    "&:hover": {
      textDecoration: "none",
    },
  },

  previewWithDesc: {
    paddingBottom: 12,
  },

  message: {
    padding: "8px 16px 12px",
    color: theme.palette.black,
    backgroundColor: theme.palette.primary.main,
    fontSize: 15,
    fontWeight: 400,
    lineHeight: "20px",
    lineBreak: "normal",
    wordBreak: "break-word",
    "& > a": {
      textDecoration: "underline",
      color: theme.palette.black,
    },
  },

  image: {
    width: "100%",
    height: "100%",
    maxHeight: "200px",
    minHeight: "120px",
    backgroundSize: "cover",
    objectFit: "cover",
  },

  skeletonLoading: {
    background: `linear-gradient(to right, 
      rgba(255,255,255,0),
      rgba(255,255,255,0.2),
      rgba(255,255,255,0.4),
      rgba(255,255,255,0.55),
      rgba(255,255,255,0.6),
      rgba(255,255,255,0.4),
      rgba(255,255,255,0.2),
      rgba(255,255,255,0))`,
    backgroundSize: "200% 100%",
    animation: `$loading 3000ms linear infinite`,
  },

  title: {
    padding: "8px 16px 2px",
    lineHeight: "20px",
    color: theme.palette.black,
    WebkitLineClamp: 2,
    lineBreak: "normal",
    wordBreak: "break-word",
  },

  desc: {
    padding: "2px 16px 0",
    lineHeight: "20px",
    color: theme.palette.grey[600],
    WebkitLineClamp: 3,
    lineBreak: "normal",
  },

  myMessage: {
    color: theme.palette.white,
    "& > a": {
      textDecoration: "underline",
      color: theme.palette.white,
    },
  },

  "@keyframes loading": {
    to: {
      backgroundPositionX: "-200%",
    },
  },
}));
