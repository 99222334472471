import { useEffect, useState } from "react";
import { LocalAccountService, LocalBranchAccountService } from "services/local.service";
import { sortBy } from "lodash";
import { toCamel } from "utils";
import { useSelector } from "react-redux";
import { BranchSelectors } from "redux-store";

const useAccountList = () => {
  const selectedBranch = useSelector(BranchSelectors.getSelectedBranch);
  const [accountList, setAccountList] = useState([]); // List accounts of current branch

  const getAccountList = async (name = "") => {
    name = name ? name.trim() : "";
    const accountsInBranch = await LocalBranchAccountService.searchAccountsByCondition(selectedBranch.id, name);
    if (accountsInBranch.length > 0) {
      const accountIdsInBranch = accountsInBranch.map(item => item.id);
      const accounts = await LocalAccountService.getAccountByIds(accountIdsInBranch);
      const convertAccountList = accountsInBranch.map(item => {
        const convertAccount = accounts.find(account => account.id === item.id);
        return { ...item, name: convertAccount.name };
      });

      const sortedAccountList = sortBy(convertAccountList, [account => account.name?.toLowerCase()]);

      setAccountList(toCamel(sortedAccountList));
    } else {
      setAccountList([]);
    }
  };

  useEffect(() => {
    if (selectedBranch && selectedBranch.id) {
      getAccountList();
    }
  }, [selectedBranch]);

  return { accountList };
};

export default useAccountList;
