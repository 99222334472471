import { ApiConstant } from "const";
import qs from "qs";
import GlobalApi from "./config/api.config";

export const getDevice = data => GlobalApi.post(ApiConstant.POST_GET_DEVICE, qs.stringify(data, { skipNulls: true }));

export const deleteDevice = data =>
  GlobalApi.post(ApiConstant.POST_DELETE_DEVICE, qs.stringify(data, { skipNulls: true }));

export const updateDevice = data =>
  GlobalApi.post(ApiConstant.POST_UPDATE_DEVICE, qs.stringify(data, { skipNulls: true }));
