import { useCallback, useEffect, useState } from "react";
import { KeyConstant } from "const";
import { toCamel } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { LocalCallHistoryService } from "services/local.service";
import { debounce } from "lodash";
import { CallingActions } from "redux-store";
import { StorageUtil } from "utils";

const useCallInfo = () => {
  const dispatch = useDispatch();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const callingGroupDetail = useSelector(state => state.callingRedux.callingGroupDetail);
  const groupCallOptions = useSelector(state => state.callingRedux.groupCallOptions);
  const isReceiver = useSelector(state => state.callingRedux.isReceiver);

  const [callInfo, setCallInfo] = useState({});

  // Get callingGroupDetails
  // Get avatar group call
  // When accept a group call or start a call
  const getCallInfoByGroupDetail = (callingGroupDetail, groupCallOptions) => {
    const callingOpt = getCallInfoByOptions(groupCallOptions);
    if (callingGroupDetail?.adminId !== accountId) {
      let callHistoryArr = LocalCallHistoryService.getByGroupId(callingGroupDetail?.id);
      let callHistoryOptions = {};
      if (callHistoryArr && callHistoryArr[0] && callHistoryArr[0]?.options) {
        callHistoryOptions = toCamel(JSON.parse(callHistoryArr[0].options)) || {};
      }

      if (callHistoryOptions?.avatarUrl) {
        dispatch(CallingActions.getImageIncomingCall({ avatarId: callHistoryOptions?.avatarUrl }));
      }

      const newCallInfo = {
        ...callingGroupDetail,
        callName: callHistoryOptions?.callName,
        avatarId: callHistoryOptions?.avatarUrl,
        roomId: toCamel(callHistoryArr[0]).roomId,
      };

      setCallInfo({ ...newCallInfo, ...callingOpt });
    } else if (callingGroupDetail?.id) {
      setCallInfo({ ...callingGroupDetail, ...callingOpt });
    } else {
      setCallInfo({});
    }
  };

  // Receive call's info change ????
  const getCallInfoByOptions = groupCallOptions => {
    if (Boolean(groupCallOptions)) {
      let options = {};
      if (groupCallOptions && groupCallOptions.avatar_url) {
        options = { ...options, avatarId: groupCallOptions.avatar_url };
      }
      if (groupCallOptions && groupCallOptions.call_name) {
        options = { ...options, callName: groupCallOptions.call_name };
      }
      return options;
    }
    return {};
  };

  const handleCallingDetail = useCallback(
    debounce(
      (callingGroupDetail, groupCallOptions) => getCallInfoByGroupDetail(callingGroupDetail, groupCallOptions),
      500,
    ),
    [],
  );

  useEffect(() => {
    handleCallingDetail(callingGroupDetail, groupCallOptions);
  }, [callingGroupDetail, groupCallOptions, isReceiver]);

  return { callInfo, getCallInfoByGroupDetail, getCallInfoByOptions };
};

export default useCallInfo;
