import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Avatar, Box, Button, CardMedia, Typography } from "@mui/material";
import clsx from "clsx";
import { getCommonKey } from "utils/lang.utils";
import { formatSentTime } from "utils/date.utils";
import { getImageSrcAvatar, isJSONString, sortArrayObjectReserved, toCamel, toSnake } from "utils";
import { ImageConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { AvatarConversation, DrawerLayout, InfiniteScroll } from "components";
import { getSavedServer } from "utils/common.utils";
import { useConversationList } from "hooks";
import {
  LocalAccountService,
  LocalBranchAccountService,
  LocalBranchService,
  LocalNotificationService,
} from "services/local.service";
import { BranchActions, ConversationActions } from "redux-store";
import { isGroupOrChannelType } from "pages/Call";
import { StorageUtil } from "utils";

const NotificationTab = ({ onBackToMessage, open, onClose }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const dispatch = useDispatch();

  const { channelList, groupList, personalMsgList } = useConversationList();
  const channelGroupList = [...personalMsgList, ...channelList, ...groupList];

  const isRefreshing = useSelector(state => state.conversationRedux.isRefreshing);
  const getGroupMembers = useSelector(state => state.groupInfoRedux.groupMembers);
  const isUpdateViewMode = useSelector(state => state.conversationRedux.isUpdateViewMode);
  const isFetchNotificationSuccess = useSelector(state => state.conversationRedux.isFetchNotificationSuccess);
  const newBranchInvitation = useSelector(state => state.conversationRedux.newBranchInvitation);

  const [inviteList, setInviteList] = useState([]);
  const [normalList, setNormalList] = useState([]);
  const [ownerList, setOwnerList] = useState([]);
  const [otpList, setOTPList] = useState([]);
  const [allList, setAllList] = useState([]);
  const [groupNotificationList, setGroupNotificationList] = useState([]);
  const [limit, setLimit] = useState(SystemConstant.LIMIT_PAGING_DEFAULT);

  const onScrollToBottom = () => {
    if (limit < allList.length) setLimit(limit + SystemConstant.LIMIT_PAGING_DEFAULT);
  };

  useEffect(() => {
    LocalNotificationService.getNormalNotification().then(result => {
      let savedServer = getSavedServer();
      setAllList(
        sortArrayObjectReserved(
          toCamel(result.filter(r => r.branch_id === savedServer?.id || r.type === SystemConstant.NotifyType.SMARTOTP)),
        ),
      );
    });
  }, [getGroupMembers, isFetchNotificationSuccess]);

  useEffect(() => {
    if (newBranchInvitation) {
      dispatch(
        ConversationActions.conversationSet({
          newBranchInvitation: null,
        }),
      );
      setAllList(state => [...state, newBranchInvitation]);
    }
  }, [newBranchInvitation]);

  useEffect(() => {
    if (allList.length > 0) {
      let savedServer = getSavedServer();
      if (savedServer.type === SystemConstant.SERVER_TYPE.server) {
        let tmpRequestList =
          allList
            .slice(0, allList.length > limit ? limit : allList.length)
            .filter(item => item.type === SystemConstant.NOTIFICATION_TYPE.invite) || [];

        let maxedByBranchId = {};
        tmpRequestList.forEach(item => {
          if (!maxedByBranchId[item.branchId] || item.created > maxedByBranchId[item.branchId]?.created) {
            maxedByBranchId[item.branchId] = item;
          }
        });

        setInviteList(Object.values(maxedByBranchId));
      }

      let tmpNormalList =
        allList
          .slice(0, allList.length > limit ? limit : allList.length)
          .filter(item => item.type === SystemConstant.NOTIFICATION_TYPE.normal && item.branchId === savedServer?.id) ||
        [];
      setNormalList(sortArrayObjectReserved(tmpNormalList));

      let tmpOwnerList =
        allList
          .slice(0, allList.length > limit ? limit : allList.length)
          .filter(item => item.type === SystemConstant.NOTIFICATION_TYPE.owner && item.branchId === savedServer?.id) ||
        [];
      setOwnerList(sortArrayObjectReserved(tmpOwnerList));

      let tmpGroupList =
        allList
          .slice(0, allList.length > limit ? limit : allList.length)
          .filter(
            item =>
              item.type === SystemConstant.NotifyType.GROUP_UPDATE &&
              item.fromAccountId !== StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID),
          ) || [];

      setGroupNotificationList(sortArrayObjectReserved(tmpGroupList));

      let tmpOtpList =
        allList
          .slice(0, allList.length > limit ? limit : allList.length)
          .filter(item => item.type === SystemConstant.NotifyType.SMARTOTP) || [];
      setOTPList(sortArrayObjectReserved(tmpOtpList));

      let notSeen = allList.filter(n => n.status !== SystemConstant.NOTIFICATION_STATUS.read);

      if (notSeen.length > 0) {
        LocalNotificationService.save(
          notSeen.map(item =>
            toSnake({
              ...item,
              status: SystemConstant.NOTIFICATION_STATUS.read,
            }),
          ),
        );
        dispatch(
          ConversationActions.updateMessageStatus({
            notifyIds: notSeen.map(n => n.id),
            status: SystemConstant.NOTIFICATION_STATUS.read,
          }),
        );
      }
    }
  }, [isRefreshing, allList, limit, isUpdateViewMode]);

  useEffect(() => {
    if (inviteList.length > 0 && inviteList.findIndex(item => item.state === SystemConstant.BRANCH_STATE.pending) > 0) {
      dispatch(BranchActions.getBranch());
    }
  }, [inviteList]);

  return (
    <DrawerLayout open={open} onClose={onClose} title={getLabel(LangConstant.TXT_NOTIFICATION)}>
      <InfiniteScroll className={classes.chatList} onScroll={onScrollToBottom}>
        {allList.length > 0 &&
        (inviteList.length > 0 ||
          normalList.length > 0 ||
          otpList.length > 0 ||
          groupNotificationList.length > 0 ||
          ownerList.length > 0) &&
        limit ? (
          <>
            {inviteList.map(item => (
              <NotificationInviteItem key={item.id} data={item} />
            ))}

            {otpList.map(item => (
              <NotificationOTPItem key={item.id} data={item} />
            ))}

            {normalList.map(item => (
              <NotificationTextItem
                key={item.id}
                data={item}
                channelGroupList={channelGroupList}
                onBackToMessage={onBackToMessage}
              />
            ))}

            {ownerList.map(item => (
              <NotificationOwnerItem
                key={item.id}
                data={item}
                channelGroupList={channelGroupList}
                onBackToMessage={onBackToMessage}
              />
            ))}

            {groupNotificationList.map(item => (
              <NotificationGroupItem
                key={item.id}
                data={item}
                channelGroupList={channelGroupList}
                onBackToMessage={onBackToMessage}
              />
            ))}
          </>
        ) : (
          <Box className={classes.emptyChatList}>
            <CardMedia component="img" src={ImageConstant.WelcomeImage} className={classes.emptyImage} />
            <Typography className={classes.emptyMessage}>{getLabel(LangConstant.TXT_NO_NOTIFICATION)}</Typography>
          </Box>
        )}
      </InfiniteScroll>
    </DrawerLayout>
  );
};

const NotificationInviteItem = ({ data }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  const dispatch = useDispatch();
  const isUpdateNotificationSuccess = useSelector(state => state.conversationRedux.isUpdateNotificationSuccess);

  const [branchDetail, setBranchDetail] = useState({});
  const [notification, setNotification] = useState(data);

  const onAccept = () => {
    let formData = {
      branchAccounts: [
        {
          id: branchDetail.branchTableId,
          accountId: notification.noticeToAccountId,
          branchId: notification.content,
          state: SystemConstant.BRANCH_STATE.active,
          options: null,
          created: branchDetail.created,
          modified: Date.now(),
          messageId: notification.id,
        },
      ],
    };

    setBranchDetail({ ...branchDetail, accountState: SystemConstant.BRANCH_STATE.active });
    dispatch(BranchActions.updateBranch(formData));
  };

  const onDecline = () => {
    let formData = {
      branchAccounts: [
        {
          id: branchDetail.branchTableId,
          accountId: notification.noticeToAccountId,
          branchId: notification.content,
          state: SystemConstant.BRANCH_STATE.rejected,
          options: null,
          created: branchDetail.created,
          modified: Date.now(),
          messageId: notification.id,
        },
      ],
    };

    dispatch(BranchActions.updateBranch(formData));
    setBranchDetail({ ...branchDetail, accountState: SystemConstant.BRANCH_STATE.rejected });
  };

  useEffect(() => {
    if (isUpdateNotificationSuccess) {
      dispatch(
        ConversationActions.conversationSet({
          isUpdateNotificationSuccess: false,
        }),
      );
    }
  }, [isUpdateNotificationSuccess]);

  useEffect(() => {
    let accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    let branchAccountResult = LocalBranchAccountService.findByAccountId(accountId, data.content);
    let tmpBranchDetail = LocalBranchService.getByBranchId(data.content);

    setBranchDetail({
      ...toCamel(tmpBranchDetail),
      accountState: branchAccountResult?.state,
      accountId: branchAccountResult?.account_id,
      branchTableId: branchAccountResult?.id,
    });

    setNotification(data);
  }, [data]);

  return (
    <Box className={classes.notificationItemRoot}>
      <Box className={classes.notificationRequestItemRoot}>
        <Box className={classes.detailContainer}>
          <Box className={classes.avatarBox}>
            <AvatarConversation
              memberArray={[
                {
                  name: branchDetail.name || "",
                  avatarId: branchDetail.attachmentId,
                },
              ]}
            />
          </Box>
          <Box className={classes.notificationContentBox}>
            <Typography variant="subtitle2" className={classes.inviteName}>
              {branchDetail.name}
            </Typography>
            <Typography className={clsx(classes.inviteContent, "regular-sm-txt")}>
              {getLabel(
                branchDetail?.accountState === SystemConstant.BRANCH_STATE.active
                  ? LangConstant.TXT_INVITE_ACCEPTED
                  : branchDetail?.accountState === SystemConstant.BRANCH_STATE.inactive
                  ? LangConstant.TXT_INVITE_DISMISSED
                  : branchDetail?.accountState === SystemConstant.BRANCH_STATE.rejected
                  ? LangConstant.TXT_INVITE_REJECTED
                  : LangConstant.TXT_INVITE_TO_SERVER,
              )}
            </Typography>
          </Box>
        </Box>
        {branchDetail.accountState === SystemConstant.BRANCH_STATE.pending && (
          <Box className={classes.actionContainer}>
            <Button
              variant="contained"
              color="primary"
              className={clsx(classes.actionButton, "semiBold-sm-txt")}
              onClick={onAccept}
            >
              {getLabel(getCommonKey(LangConstant.TXT_ACCEPT))}
            </Button>
            <Button className={clsx(classes.actionButton, classes.deniedButton, "semiBold-sm-txt")} onClick={onDecline}>
              {getLabel(getCommonKey(LangConstant.TXT_DENIED))}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const NotificationTextItem = ({ data, channelGroupList = [], onBackToMessage }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);

  const [senderDetail, setSenderDetail] = useState({
    accountAvatar: null,
    accountName: "",
    groupName: "",
    groupId: "",
    groupType: "",
    message: "",
  });
  const [userAvatar, setUserAvatar] = useState("");

  const onClickNotification = () => {
    onBackToMessage();
    let group = channelGroupList.find(item => item.id === senderDetail.groupId);
    if (group) {
      dispatch(
        ConversationActions.setSelectGroupId({
          selectedGroupId: senderDetail.groupId,
        }),
      );
    }

    const scrollToMsg = () => {
      if (senderDetail.message && senderDetail.message.state === SystemConstant.STATE.active) {
        dispatch(
          ConversationActions.conversationSet({
            scrollToChildId: senderDetail.message.id,
          }),
        );
      } else {
        dispatch(
          ConversationActions.conversationSet({
            scrollToChildId: null,
          }),
        );
      }
    };

    setTimeout(() => {
      if (senderDetail.message.thread_id) {
        dispatch(
          ConversationActions.conversationSet({
            threadingId: senderDetail.message.thread_id,
          }),
        );

        setTimeout(() => {
          scrollToMsg();
        }, 1000);
      } else {
        dispatch(
          ConversationActions.conversationSet({
            threadingId: null,
          }),
        );
        scrollToMsg();
      }
    }, 1000);
  };

  useEffect(() => {
    let handleData = {
      ...data,
      options: isJSONString(data.options) ? toCamel(JSON.parse(data.options)) : {},
    };

    LocalNotificationService.getNormalNotificationDetail(handleData).then(result => {
      setSenderDetail(result);
    });

    const sender = toCamel(LocalAccountService.getAccount(data.fromAccountId));
    if (sender.avatarId) {
      getImageSrcAvatar(sender.avatarId, SystemConstant.ATTACHMENT_TYPE.account).then(src => {
        if (src !== userAvatar) setUserAvatar(src);
      });
    }
  }, [data]);

  return (
    <Box className={classes.mentionNotificationItemRoot} onClick={onClickNotification}>
      <Box className={classes.detailContainer}>
        <Box className={classes.avatarBox}>
          <Avatar alt={data?.name} src={userAvatar} className={classes.avatar}>
            {senderDetail?.accountName.charAt(0)}
          </Avatar>
        </Box>
        <Box className={classes.notificationContentBox}>
          <Typography
            className={clsx(classes.messageContent, "regular-md-txt ellipsis")}
            dangerouslySetInnerHTML={{
              __html: getLabel(
                isGroupOrChannelType(senderDetail.groupType)
                  ? LangConstant.FM_MENTION_NOTIFICATION
                  : LangConstant.FM_MENTION_INBOX,
                {
                  accountName: senderDetail.accountName,
                  groupName: senderDetail.groupName,
                  message: senderDetail.message.content,
                },
              ),
            }}
          />
          <Typography className={classes.createdTimeNotification}>{formatSentTime(data.created)}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const NotificationOTPItem = ({ data }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);

  return (
    <Box className={classes.mentionNotificationItemRoot}>
      <Box className={classes.detailContainer}>
        <Box className={classes.avatarBox}>
          <AvatarConversation
            memberArray={[
              {
                image: "",
              },
            ]}
          />
        </Box>
        <Box className={classes.notificationContentBox}>
          <Typography
            className={clsx(classes.messageContent, "regular-md-txt ellipsis")}
            dangerouslySetInnerHTML={{
              __html: getLabel(LangConstant.FM_OTP_NOTIFICATION, {
                message: data.content,
              }),
            }}
          />
          <Typography className={classes.createdTimeNotification}>{formatSentTime(data.created)}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const NotificationOwnerItem = ({ data }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const [notification, setNotification] = useState(data);
  useEffect(() => {
    let accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    let tmpBranchDetail = LocalBranchService.getByBranchId(data.branchId);
    setNotification({ ...notification, avatarId: tmpBranchDetail.attachment_id });
  }, [data]);

  return (
    <Box className={classes.mentionNotificationItemRoot}>
      <Box className={classes.detailContainer}>
        <Box className={classes.avatarBox}>
          <AvatarConversation
            memberArray={[
              {
                avatarId: notification.avatarId,
              },
            ]}
          />
        </Box>
        <Box className={classes.notificationContentBox}>
          <Typography
            className={clsx(classes.messageContent, "regular-md-txt ellipsis")}
            dangerouslySetInnerHTML={{
              __html: getLabel(LangConstant.FM_OWNER_NOTIFICATION, {
                message: notification.content,
              }),
            }}
          />
          <Typography className={classes.createdTimeNotification}>{formatSentTime(notification.created)}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const NotificationGroupItem = ({ data, channelGroupList = [], onBackToMessage }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);

  const [senderDetail, setSenderDetail] = useState({
    accountAvatar: null,
    accountName: "",
    groupName: "",
    groupId: "",
  });
  const [userAvatar, setUserAvatar] = useState("");

  const onClickNotification = () => {
    onBackToMessage();
    let group = channelGroupList.find(item => item.id === senderDetail.groupId);
    if (group) {
      dispatch(ConversationActions.setSelectGroupId({ selectedGroupId: senderDetail.groupId }));
    }
  };

  useEffect(() => {
    let handleData = {
      ...data,
      options: isJSONString(data.options) ? toCamel(JSON.parse(data.options)) : {},
    };
    LocalNotificationService.getGroupNotificationDetail(handleData).then(result => {
      setSenderDetail(result);
    });
  }, [data]);

  useEffect(() => {
    let sender = toCamel(LocalAccountService.getAccount(data.fromAccountId));
    if (sender.avatarId) {
      getImageSrcAvatar(sender.avatarId, SystemConstant.ATTACHMENT_TYPE.account).then(src => {
        if (src !== userAvatar) setUserAvatar(src);
      });
    }
  }, [data]);

  return (
    <>
      <Box className={classes.mentionNotificationItemRoot} onClick={onClickNotification}>
        <Box className={classes.detailContainer}>
          <Box className={classes.avatarBox}>
            <Avatar alt={data?.name} src={userAvatar} className={classes.avatar} />
          </Box>
          <Box className={classes.notificationContentBox}>
            <Typography
              className={clsx(classes.messageContent, "regular-md-txt ellipsis")}
              dangerouslySetInnerHTML={{
                __html: getLabel(
                  data.groupType === SystemConstant.GROUP_CHAT_TYPE.channel
                    ? LangConstant.FM_CHANNEL_NOTIFICATION
                    : LangConstant.FM_GROUP_NOTIFICATION,
                  {
                    accountName: senderDetail.accountName,
                    groupName: senderDetail.groupName,
                  },
                ),
              }}
            />
            <Typography className={classes.createdTimeNotification}>{formatSentTime(data.created)}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NotificationTab;

const useStyles = makeStyles(theme => ({
  chatList: {
    height: "100%",
    overflow: "auto",
  },

  emptyChatList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "0 56px",
    borderTop: "0.5px solid " + theme.palette.divider,
  },

  emptyImage: {
    padding: "0 14px",
  },

  emptyMessage: {
    fontSize: 15,
    fontWeight: 600,
    marginTop: 16,
    lineHeight: "20px",
  },

  notificationItemRoot: {
    padding: "12px 14px",
    borderTop: "0.5px solid " + theme.palette.divider,
  },

  notificationRequestItemRoot: {
    display: "flex",
    flexDirection: "column",
  },

  detailContainer: {
    display: "flex",
    alignItems: "center",
  },

  notificationContentBox: {
    maxWidth: "calc(100% - 50px)",
    height: "100%",
    paddingLeft: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  avatarBox: {
    width: 50,
    height: 50,
    flexShrink: 0,
  },

  inviteName: {
    lineHeight: "16px",
    marginBottom: 5,
  },

  inviteContent: {
    lineHeight: "16px",
  },

  actionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 14,
  },

  actionButton: {
    borderRadius: 10,
    height: 35,
    minHeight: "unset",
    marginRight: 6,
    width: "50%",
  },

  deniedButton: {
    backgroundColor: theme.palette.grey[100],
    marginRight: 0,
    marginLeft: 6,
    width: "50%",
    color: theme.palette.black,
  },

  createdTimeNotification: {
    lineHeight: "16px",
    marginTop: 5,
    fontSize: 10,
    fontWeight: 400,
  },

  messageContent: {
    lineHeight: "16px",
    color: theme.palette.black,
    WebkitLineClamp: 3,
    lineBreak: "auto",
  },

  mentionNotificationItemRoot: {
    padding: "12px 14px",
    borderTop: "0.5px solid " + theme.palette.divider,
    cursor: "pointer",
  },
}));
