import { KeyConstant, SystemConstant } from "const";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MessageService } from "services";
import { LocalGroupService, LocalMessageService } from "services/local.service";
import { notificationClear } from "utils/common.utils";
import useServerMode from "./useServerMode";
import { StorageUtil } from "utils";

const useUnreadMessage = groupId => {
  const { selectedBranch } = useServerMode();

  const isFetchMessageSuccess = useSelector(state => state.conversationRedux.isFetchMessageSuccess);
  const isUpdateViewMode = useSelector(state => state.conversationRedux.isUpdateViewMode);

  const [allUnreadNumber, setAllUnreadNumber] = useState(0);
  const [unreadInGroupNumber, setUnreadInGroupNumber] = useState(0);

  const getUnreadMsgList = async () => {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    const unreadMsg = await LocalMessageService.findUnreadOrderByCreatedDescAll(accountId);

    const allUnreadMessage = notificationClear(unreadMsg);
    const allMsgNotSeen = allUnreadMessage.allNotSeen;
    const clearList = allUnreadMessage.clearList;

    if (clearList && clearList.length > 0) {
      const clearListId = clearList.map(item => item.id);

      MessageService.updateMessageStatus({
        message_ids: clearListId,
        status: SystemConstant.MESSAGE_STATUS.read,
      });
    }

    let tmpUnreadMsg = [];

    Object.values(allMsgNotSeen)
      .filter(item => item.branch_id === selectedBranch.id)
      .forEach(msg => {
        const currentGroup = LocalGroupService.getByGroupId(msg.group_id);
        const options = currentGroup.options ? JSON.parse(currentGroup.options) : {};

        if (!(options.hidden && options.hidden.includes(accountId))) {
          tmpUnreadMsg = [...tmpUnreadMsg, msg];
        }
      });

    setAllUnreadNumber(tmpUnreadMsg.length);

    if (groupId) {
      const unreadMsgInGroupLength = tmpUnreadMsg.filter(item => item.group_id === groupId).length;
      setUnreadInGroupNumber(unreadMsgInGroupLength);
    }
  };

  useEffect(() => {
    getUnreadMsgList();
  }, [isFetchMessageSuccess, isUpdateViewMode, selectedBranch]);

  return { allUnreadNumber, unreadInGroupNumber, getUnreadMsgList };
};

export default useUnreadMessage;
