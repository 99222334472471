import { ApiConstant, AppConstant, KeyConstant, SystemConstant } from "const";
import { call, put, select } from "redux-saga/effects";
import { AttachmentService, RestoreService } from "services";
import { RestoreActions } from "redux-store/restore.redux";
import { deviceFetching } from "./account-device.saga";
import { LocalAttachmentService, LocalBranchService, LocalMessageService } from "services/local.service";
import { appendBuffer, toCamel, toSnake, uuid } from "utils";
import { StorageUtil } from "utils";

export function* changeDeviceRole() {
  try {
    const response = yield call(RestoreService.requestChangeDeviceRole);
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        RestoreActions.restoreSet({
          changeRoleStatus: SystemConstant.REDUX_LIFECYCLE.success,
        }),
      );
    } else {
      yield put(
        RestoreActions.restoreSet({
          changeRoleStatus: SystemConstant.REDUX_LIFECYCLE.fail,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    RestoreActions.restoreSet({
      changeRoleStatus: SystemConstant.REDUX_LIFECYCLE.fail,
    });
  }
}

export function* verifyChangeDeviceRole(action) {
  try {
    const { data } = action;
    const response = yield call(RestoreService.requestVerifyChangeDeviceRole, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        RestoreActions.restoreSet({
          verifyStatus: SystemConstant.VERIFY_OTP_ERROR_TYPE.success,
        }),
      );

      yield deviceFetching();

      StorageUtil.setItem(KeyConstant.KEY_VERIFY_RETRIES, 0);
    } else if (response.status === ApiConstant.STT_FORBIDDEN) {
      yield put(
        RestoreActions.restoreSet({
          verifyStatus: SystemConstant.VERIFY_OTP_ERROR_TYPE.limitResend,
        }),
      );
    } else if (response.status === ApiConstant.STT_BAD_REQUEST) {
      yield put(
        RestoreActions.restoreSet({
          verifyStatus: SystemConstant.VERIFY_OTP_ERROR_TYPE.wrongOtp,
        }),
      );
    } else if (response.status === ApiConstant.STT_OTP_EXPIRED) {
      yield put(
        RestoreActions.restoreSet({
          verifyStatus: SystemConstant.VERIFY_OTP_ERROR_TYPE.expiredOtp,
        }),
      );
    } else {
      yield put(
        RestoreActions.restoreSet({
          verifyStatus: SystemConstant.VERIFY_OTP_ERROR_TYPE.systemError,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      RestoreActions.restoreSet({
        verifyStatus: SystemConstant.VERIFY_OTP_ERROR_TYPE.systemError,
      }),
    );
  }
}

// Get file info
export function* restore(action) {
  try {
    const { data } = action;
    const response = yield call(RestoreService.requestGetBackup, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      const backupInfo = toCamel(response.data);
      if (backupInfo && Object.keys(backupInfo).length > 0) {
        yield put(
          RestoreActions.restoreSet({
            backupInfo: backupInfo,
            restoreStatus: SystemConstant.RESTORE_STATUS.getFileInfo,
          }),
        );
      } else {
        yield put(
          RestoreActions.restoreSet({
            backupInfo: backupInfo,
            restoreStatus: SystemConstant.RESTORE_STATUS.noBackupFile,
          }),
        );
      }
    } else {
      throw new Error("An error occurred");
    }
  } catch (error) {
    console.log(error);
    yield put(
      RestoreActions.restoreSet({
        backupInfo: null,
        restoreStatus: SystemConstant.RESTORE_STATUS.error,
      }),
    );
  }
}

/**
 *
 * Check passCode
 * Get file
 * Decrypt data and migrate data
 */
export function* restoreToLocal(action) {
  try {
    const { passCode } = action.data;
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
    const {
      restoreRedux: { backupInfo },
    } = yield select();

    if (Boolean(backupInfo)) {
      yield put(
        RestoreActions.restoreSet({
          restoreStatus: SystemConstant.RESTORE_STATUS.inProgress,
        }),
      );

      let fileResponse = yield call(AttachmentService.forceGetAttachment, {
        attachment_id: backupInfo.attachmentId,
      });

      if (fileResponse.status === ApiConstant.STT_OK) {
        let decryptFilePath = "";
        try {
          const key0 = "" + passCode + accountId + backupInfo.createdTime;
          const key0Encrypt = yield LocalAttachmentService.encryptWithHmac256(key0);
          const keyEncryptFileInfo = yield LocalAttachmentService.decryptWithCBC(backupInfo.ckx, key0Encrypt);
          const keyInfoConvert = JSON.parse(keyEncryptFileInfo);

          const contentBuffer = new Uint8Array(fileResponse.data);
          decryptFilePath = yield LocalAttachmentService.decryptAESGroupFile(
            contentBuffer,
            keyInfoConvert.key,
            keyInfoConvert.iv,
            keyInfoConvert.authtag,
            backupInfo.attachmentId,
            backupInfo.attachmentId + ".txt",
          );
        } catch {
          throw new Error(AppConstant.PASSCODE_ERROR);
        }

        // read file restore and insert data to local db
        yield LocalAttachmentService.readFile(decryptFilePath, deviceId);

        yield put(
          RestoreActions.restoreSet({
            restoreStatus: SystemConstant.RESTORE_STATUS.success,
          }),
        );
      } else {
        throw new Error();
      }
    }
  } catch (error) {
    console.log(error);
    if (error.message === AppConstant.PASSCODE_ERROR) {
      yield put(
        RestoreActions.restoreSet({
          restoreStatus: SystemConstant.RESTORE_STATUS.wrongPasscode,
        }),
      );
    } else {
      yield put(
        RestoreActions.restoreSet({
          restoreStatus: SystemConstant.RESTORE_STATUS.error,
        }),
      );
    }
  }
}

// Upload file backup to server
export function* backup(action) {
  try {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);
    const { passCode, createdTime } = action.data;
    yield put(
      RestoreActions.restoreSet({
        backupStatus: SystemConstant.RESTORE_STATUS.inProgress,
      }),
    );
    const file = yield LocalMessageService.getMessagesAndCallHistories(deviceId);

    if (file) {
      const branchList = yield LocalBranchService.getActiveBranch();
      const fileMetadata = {
        file_name: file.name,
        size: file.size ? file.size + 2 : null,
        mime_type: file.type,
      };

      if (file.path) {
        const key = "" + passCode + accountId + createdTime;
        const keyEncrypt = yield LocalAttachmentService.encryptWithHmac256(key);

        const resultF = yield LocalAttachmentService.encryptAESGroupFile(file.path);

        const aes_key_info = JSON.stringify({
          iv: resultF.iv,
          key: resultF.key,
          authtag: resultF.authtag,
        });

        const CKx = yield LocalAttachmentService.encryptWithCBC(aes_key_info, keyEncrypt);

        const attachmentId = uuid();

        const buffer = new ArrayBuffer(2);
        const fileContentBuffer = appendBuffer(resultF.encryptedData, buffer);

        let formData = new FormData();
        formData.append("file", new Blob([fileContentBuffer]));
        formData.append("metadata", JSON.stringify(fileMetadata));
        formData.append("attachment_id", attachmentId);

        const backUpFileInfo = {
          attachment_id: attachmentId,
          ckx: CKx,
          created_time: createdTime,
        };

        for (let index = 0; index < branchList.length; index++) {
          const branch = branchList[index];
          const responseUpload = yield call(RestoreService.requestBackupUpload, formData, branch.id);
          if (responseUpload.status !== ApiConstant.STT_OK) {
            throw new Error();
          }

          const responseBackup = yield call(RestoreService.requestBackup, backUpFileInfo, branch.id);
          if (responseBackup.status === ApiConstant.STT_FORBIDDEN) {
            throw new Error("FORBIDDEN");
          } else if (responseBackup.status !== ApiConstant.STT_OK) {
            throw new Error(responseBackup);
          }
        }

        yield put(
          RestoreActions.restoreSet({
            fileSize: fileMetadata.size,
            backupStatus: SystemConstant.RESTORE_STATUS.success,
          }),
        );
      }
    }
  } catch (error) {
    console.log(error);
    if (error.message === "FORBIDDEN") {
      yield put(
        RestoreActions.restoreSet({
          backupStatus: SystemConstant.RESTORE_STATUS.losePermission,
        }),
      );
    } else {
      yield put(
        RestoreActions.restoreSet({
          backupStatus: SystemConstant.RESTORE_STATUS.error,
        }),
      );
    }
  }
}
