import { call, put } from "redux-saga/effects";
import { AccountService } from "services";
import { toCamel, toSnake } from "utils";
import { LocalAccountService, LocalContactService } from "services/local.service";
import { ApiConstant } from "const";
import { formatArray2Key, formatPagingParams } from "./saga.helper";
import { AccountActions, ConversationActions } from "redux-store";

export function* getAccountList(action) {
  let errorReason;
  try {
    const { data } = action;
    const apiPayload = formatPagingParams({ limit: 10, filter: JSON.stringify(data) });
    const response = yield call(AccountService.getAccountList, apiPayload);
    const responseData = response?.data?.data;
    if (response.status === ApiConstant.STT_OK && Array.isArray(responseData)) {
      yield put(
        AccountActions.accountSuccess({
          accountList: toCamel(responseData) || [],
        }),
      );
    } else {
      errorReason = response;
    }
  } catch (error) {
    console.log(error);
    errorReason = error;
  }

  if (errorReason) yield put(AccountActions.accountFailure(errorReason));
}

export function* saveAccountListByIds(data) {
  let errorReason;
  try {
    const param = toSnake(data);

    const response = yield call(AccountService.getAccountByIds, formatArray2Key(param));
    if (response.status === ApiConstant.STT_OK && Array.isArray(response.data)) {
      const responseData = response.data;
      LocalAccountService.save(responseData);
    } else {
      errorReason = response;
    }
  } catch (error) {
    console.log(error);
    errorReason = error;
  }

  if (errorReason) yield put(AccountActions.accountFailure(errorReason));
}

export function* blockUser(action) {
  let errorReason;
  try {
    const { blockedAccountId } = action;
    const response = yield call(AccountService.blockAccount, {
      blocked_account_id: blockedAccountId,
    });
    if (response.status === ApiConstant.STT_OK) {
      yield LocalContactService.blockAccount(blockedAccountId);

      yield put(
        AccountActions.accountSuccess({
          isBlockSuccess: true,
          blockAccountId: blockedAccountId,
        }),
      );

      yield put(
        ConversationActions.checkBlockedContact({
          accountId: blockedAccountId,
        }),
      );
    } else {
      errorReason = response;
    }
  } catch (error) {
    console.log(error);
    errorReason = error;
  }

  if (errorReason) yield put(AccountActions.accountFailure(errorReason));
}

export function* unblockUser(action) {
  let errorReason;
  try {
    const { blockedAccountId } = action;
    const response = yield call(AccountService.unblockAccount, {
      blocked_account_id: blockedAccountId,
    });
    if (response.status === ApiConstant.STT_OK) {
      yield LocalContactService.unblockAccount(blockedAccountId);

      yield put(
        AccountActions.accountSuccess({
          isUnblockSuccess: true,
          blockAccountId: blockedAccountId,
        }),
      );

      yield put(
        ConversationActions.checkBlockedContact({
          accountId: blockedAccountId,
        }),
      );
    } else {
      errorReason = response;
    }
  } catch (error) {
    console.log(error);
    errorReason = error;
  }

  if (errorReason) yield put(AccountActions.accountFailure(errorReason));
}
