import React, { memo, useEffect, useReducer, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Avatar, Box, Typography } from "@mui/material";
import clsx from "clsx";
import { compareObject, convertJSONObject, getImageSrc, isJSONString, toCamel } from "utils";
import { ApiConstant, AppConstant, KeyConstant, SystemConstant } from "const";
import { LocalAttachmentService } from "services/local.service";
import { AttachmentService } from "services";
import { StorageUtil } from "utils";

const AudioChat = ({ data }) => {
  const classes = useStyles();
  let accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  let currentAudioId = `audio-${toCamel(convertJSONObject(data.content)).attachmentId}`;

  const [, forceRerender] = useReducer(x => x + 1, 0);
  const [fileMetaData, setFileMetaData] = useState({});
  const [caption, setCaption] = useState(null);

  useEffect(() => {
    let currentTag = document.getElementById(currentAudioId);

    if (currentTag && fileMetaData && fileMetaData.attachmentId) {
      if (
        !LocalAttachmentService.exitsLocalFile(
          fileMetaData.attachmentId,
          SystemConstant.GROUP_CHAT_TYPE.group,
          fileMetaData?.metaData?.fileName,
        ) &&
        fileMetaData.attachmentId &&
        fileMetaData.aesKeyInfo
      ) {
        let response = AttachmentService.getAttachment({ attachment_id: fileMetaData.attachmentId });

        response.then(responseData => {
          if (responseData.status === ApiConstant.STT_OK) {
            var encryptedBytes = new Uint8Array(responseData.data);
            LocalAttachmentService.decryptAESGroupFile(
              encryptedBytes,
              fileMetaData.aesKeyInfo?.key,
              fileMetaData.aesKeyInfo?.iv,
              fileMetaData.aesKeyInfo?.authtag,
              fileMetaData.attachmentId,
              fileMetaData.metaData?.fileName,
            ).then(res => {
              LocalAttachmentService.getLocalImageSrc(
                fileMetaData.attachmentId,
                AppConstant.GROUP_CHAT_TYPE.group,
                fileMetaData?.metaData?.mimeType,
                fileMetaData.metaData?.fileName,
                res,
              ).then(src => {
                setCaption(fileMetaData.contentMessage);
                currentTag && currentTag.setAttribute("src", src);
                forceRerender();
              });
            });
          }
        });
      } else {
        let content = isJSONString(data.content) ? toCamel(JSON.parse(data.content)) : toCamel(data.content);
        let metaData = isJSONString(content.metaData)
          ? toCamel(JSON.parse(content.metaData))
          : toCamel(content.metaData);

        getImageSrc(
          content.attachmentId,
          SystemConstant.GROUP_CHAT_TYPE.group,
          metaData.mimeType,
          metaData.fileName,
        ).then(src => {
          setCaption(fileMetaData.contentMessage);
          currentTag && currentTag.setAttribute("src", src);
          forceRerender();
        });
      }
    }
  }, [fileMetaData]);

  useEffect(() => {
    let fileObj = toCamel(convertJSONObject(data.content));

    if (
      data.content &&
      isJSONString(data.content) &&
      Object.keys(data).length > 0 &&
      !compareObject(fileMetaData, fileObj)
    ) {
      setFileMetaData(toCamel(convertJSONObject(data.content)));
    }
  }, [data]);

  let isMine = data.senderId === accountId;
  let isHaveData = Object.keys(fileMetaData).length > 0;

  return isHaveData ? (
    <>
      <Box className={clsx(classes.root, isMine ? classes.wrapPreviewSubFlex : classes.wrapItemFlex)}>
        <Box>
          <Box className={classes.avatarBox}>
            <Typography className={classes.senderName}>
              {data?.senderName}, {data.sentTime}
            </Typography>
          </Box>
          <Box
            className={clsx(
              classes.contentContainer,
              isMine ? classes.backgroundPreviewFlex : classes.receivedFileContainer,
            )}
          >
            {caption && <Typography className={classes.caption} dangerouslySetInnerHTML={{ __html: caption }} />}
            <Box className={classes.flexPreview}>
              <audio controls preload="metadata" id={currentAudioId} />
            </Box>
          </Box>
        </Box>

        {!isMine && <Avatar src={data.avatar}>{data?.senderName?.charAt(0)}</Avatar>}
      </Box>
    </>
  ) : (
    <></>
  );
};

export default memo(AudioChat, (prev, next) => JSON.stringify(prev.data) === JSON.stringify(next.data));

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "end",
    alignItems: "end",
    cursor: "pointer",
    width: "100%",
    height: "auto",
    flexDirection: "column",
  },

  contentContainer: {
    marginLeft: 16,
    backgroundColor: "#F1F3F6",
    borderRadius: 20,
    padding: "10px 8px",
    flexDirection: "column",
  },

  backgroundPreview: {
    backgroundColor: "#F1F3F6",
    height: 65,
    marginLeft: 14,
    borderRadius: 18,
  },

  backgroundPreviewFlex: {
    backgroundColor: "#008FE8",
    width: "fit-content",
    borderRadius: 18,
    display: "flex",
    alignItems: "center",
  },

  receivedFileContainer: {
    backgroundColor: "#F1F3F6",
    width: "fit-content",
    padding: 0,
    borderRadius: 18,
    display: "flex",
    alignItems: "center",
    marginLeft: 16,
  },

  flexPreview: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    "&>audio": {
      display: "block",
      minWidth: 300,
      width: "100%",
    },
  },

  wrapItemFlex: {
    width: "fit-content",
    backgroundColor: theme.palette.white,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    flexDirection: "row-reverse",
    marginRight: 14,
  },

  filePreviewIcon: {
    width: 48,
    height: 48,
    minWidth: 48,
    backgroundColor: theme.palette.white,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  wrapText: {
    flex: 1,
    marginLeft: 8,
    display: "flex",
    flexDirection: "column",
  },

  wapTextSubFlex: {
    color: theme.palette.white,
  },

  wapTextSubPFlex: {
    color: theme.palette.white,
    fontWeight: 600,
  },

  receivedFileContent: {
    color: "#1E272E",
    marginLeft: 4,
    fontWeight: 600,
  },

  messengerTimer: {
    color: "#666666",
    marginTop: 10,
    marginRight: 8,
  },

  receivedFileTime: {
    color: "#666666",
    marginTop: 10,
    marginLeft: 8,
  },

  wrapPreviewSubFlex: {
    display: "flex",
    alignItems: "end",
    justifyContent: "flex-end",
    marginLeft: 14,
  },

  downloadIcon: {
    color: theme.palette.white,
    cursor: "pointer",
    marginLeft: 20,
  },

  receivedFileDownload: {
    color: theme.palette.primary.main,
    marginLeft: 20,
    cursor: "pointer",
  },

  caption: {
    fontSize: 14,
    fontWeight: 600,
    borderBottom: "1px dotted #a7a7a7",
    marginBottom: 5,
    width: "100%",
    padding: "5px 0 10px",
  },

  avatarBox: {
    paddingLeft: 16,
  },

  senderName: {
    paddingLeft: 5,
    fontSize: 12,
    fontWeight: 550,
    marginBottom: 5,
  },
}));
