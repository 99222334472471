import {
  Avatar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AvatarConversation, NoticeDialog } from "components";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getImageSrcAvatar } from "utils";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import PopupUpdateImage from "../Account/UpdateImagePopup";
import { useDispatch, useSelector } from "react-redux";
import { CallingActions, ConversationActions } from "redux-store";
import { StorageUtil } from "utils";

const EditGroupCallInfo = ({ open, data, onClose }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_CALLING);
  const dispatch = useDispatch();
  const idAvatarEdit = useSelector(state => state.conversationRedux.idAvatarEdit);
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const callingDetails = useSelector(state => state.callingRedux.createdMessage);
  const [userAvatar, setUserAvatar] = useState(data?.avatarId);
  const [callName, setCallName] = useState(data?.callName ? data?.callName : "");
  const [openPopup, setOpenPopup] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [error, setError] = useState("");

  let members = data?.members.filter(item => item.id === accountId);
  members = members.concat(data?.members.filter(item => data?.listMemberChoose.includes(item.id)));

  const onEditGroupCallAvatar = event => {
    setAnchorEl(event.currentTarget);
    setOpenPopup(true);
  };

  const onDeleteGroupCallName = () => {
    setCallName("");
  };

  const onEditGroupCallName = event => {
    setCallName(event.target.value);
  };

  const onUpdateInfo = () => {
    // update call history
    let tmpError = "";
    // if (!Boolean(userAvatar)) {
    //   tmpError = tmpError.concat("Avatar cuộc gọi nhóm không được để trống.\n");
    // }

    // if (callName === null || callName === "") {
    //   tmpError = tmpError.concat("Tên cuộc gọi nhóm không được để trống.\n");
    // }

    if (tmpError !== "") {
      setIsOpenError(true);
      setError(tmpError);
    } else {
      //send request to update group call info
      if (data.avatarId !== idAvatarEdit || data.call !== callName) {
        let dataToReq = {
          roomId: callingDetails[data?.id].roomId,
          branchId: data.branchId,
          groupId: data.id,
          senderId: accountId,
          avatarUrl: idAvatarEdit,
          callName: callName,
        };
        dispatch(CallingActions.updateCallHistory(dataToReq));
        dispatch(
          CallingActions.callingSet({
            callingGroupDetail: { ...data, inCall: true, avatarId: idAvatarEdit, callName: callName },
          }),
        );
      }
    }

    onClose();
  };

  const onCancelUpdate = () => {
    dispatch(
      ConversationActions.conversationSet({
        idAvatarEdit: null,
      }),
    );
    setAnchorEl(null);
    onClose();
  };

  const onClosePopup = () => {
    setOpenPopup(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (data?.avatarId) {
      getImageSrcAvatar(data.avatarId, SystemConstant.GROUP_CHAT_TYPE.group).then(src => {
        setUserAvatar(src);
      });
    }
    setCallName(data?.callName);
  }, [data]);

  useEffect(() => {
    if (Boolean(idAvatarEdit)) {
      const currentUserAvatar = userAvatar;
      const getImageInterval = setInterval(() => {
        getImageSrcAvatar(idAvatarEdit, SystemConstant.GROUP_CHAT_TYPE.group).then(src => {
          setUserAvatar(src);
          if (currentUserAvatar !== src) clearInterval(getImageInterval);
        });
      }, 1000);

      return () => {
        clearInterval(getImageInterval);
      };
    }
  }, [idAvatarEdit]);

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.wrapDialogGroup }}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.popupTitle}>{getLabel(LangConstant.TXT_GROUP_CALL_INIT)}</DialogTitle>
      <DialogContent>
        <Box className={classes.avatarAndName}>
          <Box className={classes.avatarGroupCall}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <IconButton onClick={onEditGroupCallAvatar}>
                  <EditOutlinedIcon fontSize="small" className={classes.chooseAvatar} />
                </IconButton>
              }
            >
              {userAvatar ? (
                <Avatar className={classes.avatar} alt={data?.name} src={userAvatar}></Avatar>
              ) : (
                <AvatarConversation memberArray={members} classes={classes} />
              )}
            </Badge>
          </Box>
          <FormControl className={classes.groupCallName} variant="outlined">
            <Input
              type="text"
              value={callName}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={onDeleteGroupCallName} edge="end">
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
              onChange={onEditGroupCallName}
            />
          </FormControl>
        </Box>
        <Button className={classes.buttonEdit} onClick={onUpdateInfo}>
          {getLabel(LangConstant.TXT_EDIT_CALL_INFO)}
        </Button>
        <Button className={classes.buttonCancel} onClick={onCancelUpdate}>
          {getLabel(LangConstant.TXT_CANCEL_CALL)}
        </Button>
      </DialogContent>

      {openPopup && (
        <PopupUpdateImage
          open={openPopup}
          onClose={onClosePopup}
          anchorEl={anchorEl}
          isAvatar={true}
          group={true}
          data={data}
          initCall={false}
        />
      )}
      {isOpenError && (
        <NoticeDialog
          open={isOpenError}
          title={"Lỗi"}
          content={error}
          submitProps={{ onClick: () => setIsOpenError(false) }}
        />
      )}
    </Dialog>
  );
};

export default EditGroupCallInfo;

const useStyles = makeStyles(theme => ({
  wrapDialogGroup: {
    textAlign: "center",
    borderRadius: 20,
    padding: "30px 20px",
    minWidth: "500px",
  },
  popupTitle: {
    fontSize: 24,
    fontWeight: 700,
  },
  avatarAndName: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  groupCallAvatar: {},
  groupCallName: {
    width: "70%",
  },
  buttonEdit: {
    width: "100%",
    padding: "10px 0px",
    marginTop: "10px",
    color: "#ffffff",
    backgroundColor: "#008FE8",
    "&:hover": {
      backgroundColor: "#72B9E6",
    },
  },
  buttonCancel: {
    width: "100%",
    padding: "10px 0px",
    marginTop: "10px",
  },
  chooseAvatar: {
    color: "#dbd0d0",
  },
  avatarGroupCall: {
    display: "grid",
    position: "relative",
    width: 80,
    height: 80,
    maxWidth: 80,
    maxHeight: 80,
  },
  avatar: {
    width: "100%",
    height: "100%",
    maxWidth: 80,
    maxHeight: 80,
  },
  individualAvt: {
    width: 48,
    height: 48,
  },
  doubleAvt: {
    width: 48,
    height: 48,
  },
}));
