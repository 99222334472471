import React, { memo, useState } from "react";
import { AvatarInfo } from "components";
import MemberDetail from "./MemberDetail";

const GroupMemberListItem = ({ data, groupDetail, onCloseParent, dataAdmin, accountBlockArray, isAdminGroup }) => {
  const [selectedMember, setSelectedMember] = useState();

  const onClickDetail = item => {
    setSelectedMember(item);
  };

  const onClose = () => {
    setSelectedMember();
  };

  const onCloseAll = () => {
    onClose();
    onCloseParent();
  };

  const isShowMemberDetail = selectedMember && selectedMember.id;

  return (
    <>
      {data?.map((item, index) => (
        <AvatarInfo
          key={index}
          onClick={() => onClickDetail(item)}
          secondTitle={item.phone}
          title={item.name}
          avatar={{ src: item.avatarId, name: item.name }}
          button={true}
        />
      ))}
      {isShowMemberDetail && (
        <MemberDetail
          data={selectedMember}
          groupDetail={groupDetail}
          onClose={onClose}
          onCloseParent={onCloseAll}
          dataAdmin={dataAdmin}
          accountBlockArray={accountBlockArray}
          isAdminGroup={isAdminGroup}
        />
      )}
    </>
  );
};

export default memo(GroupMemberListItem);
