import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import StringFormat from "string-format";
import { Box, Fade, IconButton, Typography } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { makeStyles, useTheme } from "@mui/styles";
import { FormatConstant, LangConstant } from "const";
import { getNSKey } from "utils/lang.utils";
import { SearchBar } from "components";
import { ConversationActions } from "redux-store";

const SearchPopup = ({ open, anchor, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const searchResults = useSelector(state => state.conversationRedux.conversationSearchResults);
  const searchResultIndex = useSelector(state => state.conversationRedux.conversationSearchIndex);
  const isSearchMode = useSelector(state => state.conversationRedux.isSearchMode);
  const selectId = useSelector(state => state.conversationRedux.selectedGroupId);
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);

  const [form, setForm] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const [resultLength, setResults] = useState(0);

  const onChangeInput = value => {
    setForm(value.trim());
  };

  const onSearch = e => {
    e.preventDefault();

    if (form) {
      setIsSearched(true);
      dispatch(ConversationActions.conversationSet({ conversationSearchContent: form, isSearchMode: true }));
    }
  };

  const onClickUpButton = () => {
    let index = searchResultIndex - 1 > 0 ? searchResultIndex - 1 : 0;
    dispatch(ConversationActions.conversationSet({ conversationSearchIndex: index }));
  };

  const onClickDownButton = () => {
    let maxIndex = resultLength - 1;
    let index = searchResultIndex + 1 < maxIndex ? searchResultIndex + 1 : maxIndex;
    dispatch(ConversationActions.conversationSet({ conversationSearchIndex: index }));
  };

  const onCloseSearchPopup = () => {
    onClose();
    setForm("");
    setResults(0);
    setIsSearched(false);
    dispatch(
      ConversationActions.conversationSet({
        conversationSearchContent: null,
        isSearchMode: false,
        conversationSearchResults: [],
        conversationSearchIndex: null,
      }),
    );
  };

  useEffect(() => {
    if (searchResults) {
      setResults(searchResults.length);
    }
  }, [searchResults]);

  useEffect(() => {
    onCloseSearchPopup();
  }, [selectId]);

  useEffect(() => {
    if (anchor) {
      let popupElement = document.getElementById(SEARCH_POPUP_ID);
      popupElement.style.top = anchor.offsetHeight + "px";
    }
  }, [anchor]);

  useEffect(() => {
    if (!isSearchMode) {
      setForm("");
    }
  }, [isSearchMode]);

  return (
    <Box className={classes.root} id={SEARCH_POPUP_ID}>
      <Fade in={open} timeout={theme.transitions.duration.shortest}>
        <Box component="form" onSubmit={onSearch} className={classes.form}>
          <Box className={classes.inputWrapper}>
            <SearchBar
              value={form}
              disableDebounce={true}
              onChange={onChangeInput}
              classes={{ root: isSearched ? classes.inputRoot : "", input: classes.inputEl }}
            />
            {isSearched && searchResultIndex !== null && (
              <Typography className={clsx(classes.result, "regular-md-txt")}>
                {StringFormat(FormatConstant.FM_SEARCH_RESULT, searchResultIndex + 1, resultLength)}
              </Typography>
            )}
          </Box>
          <Box className={classes.btnWrapper}>
            <IconButton onClick={onClickUpButton} disabled={resultLength <= 1 || searchResultIndex === 0}>
              <KeyboardArrowUp
                className={clsx(classes.upIcon, { [classes.activeIcon]: resultLength > 1 && searchResultIndex > 0 })}
              />
            </IconButton>
            <IconButton
              onClick={onClickDownButton}
              disabled={resultLength <= 1 || searchResultIndex >= resultLength - 1}
            >
              <KeyboardArrowDown
                className={clsx(classes.downIcon, {
                  [classes.activeIcon]: resultLength > 1 && searchResultIndex < resultLength - 1,
                })}
              />
            </IconButton>
          </Box>
          <Typography className={classes.closeBtn} onClick={onCloseSearchPopup}>
            {getLabel(getNSKey(LangConstant.NS_COMMON, LangConstant.TXT_CLOSE))}
          </Typography>
        </Box>
      </Fade>
    </Box>
  );
};

export default SearchPopup;

SearchPopup.propTypes = {
  open: PropTypes.bool,

  onClick: PropTypes.func,
};

SearchPopup.defaultProps = {
  open: false,

  onClick: () => {},
};

const SEARCH_POPUP_ID = "conversation-search-popup-id";

const useStyles = makeStyles(theme => ({
  root: {
    width: "calc(100% - 50px)",
    position: "absolute",
    left: 25,
    zIndex: theme.zIndex.appBar,
  },

  form: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    backgroundColor: theme.palette.white,
    padding: 12,
    borderRadius: 10,
    border: "2px solid #008FE8",
  },

  inputWrapper: {
    width: "100%",
    display: "flex",
    borderRadius: 10,
    paddingRight: 14,
    alignItems: "center",
    backgroundColor: theme.palette.grey[100],
    marginRight: 20,
  },

  inputRoot: {
    height: 40,
    backgroundColor: "transparent",
  },

  result: {
    height: 40,
    display: "flex",
    alignItems: "center",
    color: theme.palette.grey[200],
    flexShrink: 0,
  },

  btnWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
    marginRight: 20,
  },

  upIcon: {
    color: theme.palette.grey[200],
  },

  downIcon: {
    color: theme.palette.grey[200],
  },

  activeIcon: {
    color: theme.palette.primary.main,
  },

  closeBtn: {
    flexShrink: 0,
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: 15,
    fontWeight: 700,
    color: theme.palette.black,
    cursor: "pointer",
    marginRight: 20,
    userSelect: "none",
  },
}));
