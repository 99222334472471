import { ApiConstant } from "const";
import qs from "qs";
import { createApiWithBranch } from "./config/api.config";

export const sendMessage = data =>
  createApiWithBranch(data.branch_id).post(ApiConstant.POST_SEND_MESSAGE, qs.stringify(data, { skipNulls: true }));

export const sendMessageFile = data =>
  createApiWithBranch().post(ApiConstant.POST_SEND_MESSAGE_FILE, data, {
    headers: ApiConstant.HEADER_DEFAULT_UPLOAD_FILE,
  });

export const getMessageList = data => createApiWithBranch().get(ApiConstant.GET_MESSAGE_LIST, data);

export const updateMessageStatus = data =>
  createApiWithBranch().post(ApiConstant.POST_UPDATE_MESSAGE_STT, qs.stringify(data, { skipNulls: true }));

export const getEmoji = data => createApiWithBranch().get(ApiConstant.GET_EMOJI, data);
