import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant, SystemConstant } from "const";

const PopupGroupMember = props => {
  const { onClose, open, changeGroup, onChange, onSubmit, title, placeholder } = props;
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);

  const [isChangeName, setIsChangeName] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.root }}
    >
      <DialogTitle className={classes.titleChanel}>{title}</DialogTitle>
      <DialogContent
        classes={{
          root: classes.dialogContent,
        }}
      >
        <TextField
          value={changeGroup}
          onChange={e => {
            onChange(e.target.value.trim());
            setIsChangeName(e.target.value);
          }}
          InputProps={{ disableUnderline: true, inputProps: { maxLength: SystemConstant.MAX_NAME_LENGTH } }}
          label={getLabel(LangConstant.TXT_CHANGE_GROUP_NAME)}
          variant="filled"
          fullWidth
          focused
          className={classes.textField}
          placeholder={placeholder}
        />
      </DialogContent>
      <Button onClick={onSubmit} className={classes.buttonChannel} variant="contained" disabled={!isChangeName}>
        {getLabel(LangConstant.TXT_SAVE_CHANNEL_INFO)}
      </Button>
      <Button
        onClick={() => {
          onClose();
          setIsChangeName(false);
        }}
        className={classes.cancelChannel}
      >
        {getLabel(LangConstant.TXT_CANCEL_CHANNEL_INFO)}
      </Button>
    </Dialog>
  );
};

export default PopupGroupMember;

const useStyles = makeStyles(theme => ({
  cancelChannel: {
    textAlign: "center",
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    color: theme.palette.grey[200],
    "&:hover": {
      color: theme.palette.grey[500],
      backgroundColor: theme.palette.white,
    },
    margin: "12px auto",
    cursor: "pointer",
  },
  titleChanel: {
    color: theme.palette.black,
    textAlign: "center",
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "24px",
  },
  textField: {
    width: 500,
    overflow: "hidden",
    "& .MuiFilledInput-root": {
      backgroundColor: theme.palette.white,
      borderRadius: "10px",
      border: "1px solid #CBCACA",
    },
  },
  textFieldCss: {
    backgroundColor: theme.palette.white,
  },
  buttonChannel: {
    marginLeft: 24,
    marginRight: 24,
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    backgroundColor: theme.palette.primary.main,
    "&:disabled": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.white,
    },
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.primary.dark,
    },
  },
  dialogContent: {
    padding: "12px 24px",
    backgroundColor: theme.palette.white,
  },
  root: {
    borderRadius: 20,
    padding: theme.spacing(1.5, 1.5),
    textAlign: "center",
    "& .MuiFormLabel-root": {
      color: theme.palette.grey[500],
    }
  },
}));
