import { useEffect, useState } from "react";
import {
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { Device } from "components";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import { jsonToObject, toCamel } from "utils";
import { getFuzzyDeltatime, getTimestamp } from "utils/date.utils";
import PropTypes from "prop-types";
import { StorageUtil } from "utils";

const DeviceItem = ({ data, hasDivider, onClick, control }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);

  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);

  const deviceObjContent = getLabel(LangConstant.OBJ_ACCOUNT_DEVICE, { returnObjects: true });
  const deviceTypeObjContent = getLabel(LangConstant.OBJ_DEVICE_TYPE_TEXT, { returnObjects: true });

  const [device, setDevice] = useState({});

  useEffect(() => {
    let newDevice = {};
    const options = toCamel(jsonToObject(data.options));

    newDevice.type = parseInt(data.type);
    newDevice.name = options.deviceName;

    let deviceTypeName = deviceTypeObjContent.unknown;

    switch (newDevice.type) {
      case SystemConstant.DEVICE_TYPE.mac:
        deviceTypeName = deviceTypeObjContent.mac;
        break;
      case SystemConstant.DEVICE_TYPE.android:
        deviceTypeName = deviceTypeObjContent.android;
        break;
      case SystemConstant.DEVICE_TYPE.ios:
        deviceTypeName = deviceTypeObjContent.ios;
        break;
      case SystemConstant.DEVICE_TYPE.window:
        deviceTypeName = deviceTypeObjContent.window;
        break;
      case SystemConstant.DEVICE_TYPE.linux:
        deviceTypeName = deviceTypeObjContent.linux;
        break;
      default:
        break;
    }

    newDevice.description = deviceTypeName;

    const timeDuration = getTimestamp() - getTimestamp(data.lastSeen);

    if (timeDuration < 60 || data.id === deviceId) {
      newDevice.status = deviceObjContent.statusOnlineFormat;
    } else {
      newDevice.status = getLabel(deviceObjContent.statusOfflineFormat, {
        fuzzyTime: getFuzzyDeltatime(getTimestamp(data.lastSeen), getTimestamp(), getLabel),
      });
    }

    setDevice({ ...data, ...newDevice });
  }, [data]);

  return (
    <>
      <ListItem className={classes.root}>
        <ListItemButton onClick={() => onClick(device)}>
          {control}
          <ListItemAvatar className={classes.logoDeviceInfor}>
            <Device type={device.type} />
          </ListItemAvatar>
          <ListItemText
            disableTypography
            primary={<Typography className={classes.deviceName}>{device.name || ""}</Typography>}
            secondary={
              <>
                <Typography variant="body2" className={classes.deviceDescription}>
                  {device.description || ""}
                </Typography>
                <Typography variant="body2" className={classes.deviceStatus}>
                  {device.status || ""}
                </Typography>
                {data.id === deviceId && (
                  <Typography className={clsx(classes.currentDeviceText, "semiBold-md-txt")}>
                    {getLabel(deviceObjContent.currentDevice)}
                  </Typography>
                )}
              </>
            }
          />
        </ListItemButton>
      </ListItem>
      {hasDivider && <Divider />}
    </>
  );
};

DeviceItem.propTypes = {
  data: PropTypes.object,
  control: PropTypes.object,
  hasDivider: PropTypes.bool,

  onClick: PropTypes.func,
};

export default DeviceItem;

const useStyles = makeStyles(theme => ({
  root: {
    padding: "8px 0px",
  },

  logoDeviceInfor: {
    display: "flex",
    justifyContent: "center",
  },

  deviceName: {
    fontWeight: 600,
    color: "#1E272E",
    paddingLeft: 8,
  },

  deviceDescription: {
    fontWeight: 400,
    color: "#1E272E",
    paddingLeft: 8,
  },

  deviceStatus: {
    fontWeight: 400,
    color: theme.palette.text.secondary,
    paddingLeft: 8,
  },

  currentDeviceText: {
    color: "#2CC84A",
    paddingLeft: 8,
    marginTop: 5,
  },
}));
