import { Fragment, useEffect, useState } from "react";
import { Divider, List, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AvatarInfo, DrawerLayout, SearchBar } from "components";
import { KeyConstant, LangConstant } from "const";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import clsx from "clsx";
import { getSavedServer } from "utils/common.utils";
import { debounce } from "lodash";
import { useContactList, useCreateConversation } from "hooks";
import { StorageUtil } from "utils";

const AddingPersonalDrawer = ({ onClose, ...otherProps }) => {
  const classes = useStyles();
  const { createWithPersonal } = useCreateConversation();
  const { contactList: defaultContactList } = useContactList();

  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const chatObjContent = getLabel(LangConstant.OBJ_ADDING_CHAT, { returnObjects: true });

  const [searchValue, setSearchValue] = useState("");
  const [viewingList, setViewingList] = useState([]);

  const onCreateNewHistoryKeyword = () => {
    const savedServer = getSavedServer();
    if (searchValue) {
      let historyKeywords = StorageUtil.getItem(KeyConstant.KEY_LOCALSTORAGE_SIDEBAR_KEYWORDS) || {};
      let historyKeywordsInServer = historyKeywords[savedServer?.id] || [];
      historyKeywordsInServer.unshift(searchValue);
      StorageUtil.setItem(
        KeyConstant.KEY_LOCALSTORAGE_SIDEBAR_KEYWORDS,
        Object.assign(historyKeywords, { [savedServer?.id]: historyKeywordsInServer }),
      );
    }
  };

  const onStartPersonalChat = item => {
    onCreateNewHistoryKeyword();

    createWithPersonal([item], item.name);
    onClose();
  };

  const onSearchContact = searchingContent => {
    setSearchValue(searchingContent);
    if (searchingContent && searchingContent !== "") {
      const filteredList = defaultContactList.filter(
        item =>
          item.name?.toLowerCase().includes(searchingContent.toLowerCase()) ||
          item.contactName?.toLowerCase().includes(searchingContent.toLowerCase()),
      );
      setViewingList(filteredList);
    } else {
      setViewingList(defaultContactList);
    }
  };

  const onChangeSearchingDebounce = debounce(searchingContent => onSearchContact(searchingContent), 100);

  useEffect(() => {
    onSearchContact(searchValue);
  }, [defaultContactList]);

  return (
    <DrawerLayout title={chatObjContent.title} onClose={onClose} {...otherProps}>
      <Box className={classes.rootSpace}>
        <SearchBar onChange={onChangeSearchingDebounce} />
      </Box>

      <Box className={classes.selectedList}>
        <Typography variant="subtitle2" className={clsx(classes.subTitle, classes.rootSpace)}>
          {getLabel(LangConstant.FM_MEMBER, { number: viewingList.length })}
        </Typography>

        <List>
          {viewingList.map((contact, index) => (
            <Fragment key={index}>
              {index > 0 && <Divider variant="inset" component="li" />}
              <AvatarInfo
                avatar={{ src: contact.avatarId, name: contact.contactName || contact.name || contact.phone }}
                title={contact.contactName || contact.name || contact.phone}
                onClick={() => onStartPersonalChat(contact)}
                button={true}
              />
            </Fragment>
          ))}
        </List>
      </Box>
    </DrawerLayout>
  );
};

AddingPersonalDrawer.propTypes = DrawerLayout.propTypes;

export default AddingPersonalDrawer;

const useStyles = makeStyles(theme => ({
  rootSpace: {
    paddingLeft: 16,
    paddingRight: 16,
  },

  subTitle: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    textAlign: "left",
    marginTop: 10,
  },

  selectedList: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
}));
