import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant, SystemConstant } from "const";
import { RestoreIcon } from "icons";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { convertFileSize } from "utils";
import { getNSKey } from "utils/lang.utils";

const RestoreProgress = ({ hasError, size, onFinish, onRetry, onCancel }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const timerRef = useRef();
  const restoreStatusRef = useRef();

  const restoreStatus = useSelector(state => state.restoreRedux.restoreStatus);

  restoreStatusRef.current = restoreStatus;

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (
      (!Boolean(hasError) && restoreStatusRef.current === SystemConstant.RESTORE_STATUS.inProgress) ||
      restoreStatusRef.current === SystemConstant.RESTORE_STATUS.success
    ) {
      timerRef.current = setInterval(() => {
        setProgress(oldProgress => {
          if (restoreStatusRef.current === SystemConstant.RESTORE_STATUS.success) {
            onFinish(true);
            return 100;
          }

          if (oldProgress > 70 && restoreStatusRef.current !== SystemConstant.RESTORE_STATUS.success) {
            return oldProgress;
          }

          const diff = Math.random() * 10;
          const downloaded = Math.min(oldProgress + diff.toFixed(2), 100);
          return downloaded;
        });
      }, 10);
    }

    return () => {
      if (timerRef.current && progress === 100) {
        clearInterval(timerRef.current);
      }
    };
  }, [restoreStatus]);

  return (
    <Box className={classes.component}>
      <RestoreIcon className={hasError ? classes.errorLogo : classes.logo} />
      {hasError ? (
        <>
          <Typography className={classes.title}>{getLabel(LangConstant.TXT_NOT_CONNECT)}</Typography>
          <Typography className={classes.errorContent}>{getLabel(LangConstant.TXT_NOT_CONNECT_INFO)}</Typography>
          <Box className={classes.actionBtnBox}>
            <Button fullWidth variant="contained" className={classes.actionBtn} onClick={onRetry}>
              {getLabel(LangConstant.TXT_RETRY)}
            </Button>
            <Button fullWidth className={classes.actionBtn} onClick={onCancel}>
              {getLabel(LangConstant.TXT_CANCEL)}
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography className={classes.title}>{getLabel(LangConstant.TXT_KEEP_CONNECT_INTERNET)}</Typography>
          <Box className={classes.progressBox}>
            <LinearProgress variant="determinate" value={progress} />
            <Typography className={classes.downloadProgress}>
              {getLabel(getNSKey(LangConstant.NS_HOME, LangConstant.FM_DOWNLOADED_CONTENT), {
                // change to size of dataRestore
                downloaded: convertFileSize(parseInt(progress * 0.01 * size)),
                total: convertFileSize(size),
                percentage: progress,
              })}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default RestoreProgress;

const useStyles = makeStyles({
  logo: {
    width: 170,
    height: 100,
    color: "#008EF8",
    marginBottom: 30,
  },

  component: {
    textAlign: "center",
  },

  errorLogo: {
    width: 100,
    height: 89,
    color: "#FF2C28",
  },

  title: {
    fontWeight: "bold",
    marginBottom: 10,
  },

  errorContent: {
    marginBottom: 20,
  },

  actionBtn: {
    marginBottom: 10,
    minHeight: 40,
  },

  downloadProgress: {
    marginTop: 10,
    fontSize: 12,
    color: "#7F7F80",
  },
});
