import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocalAccountService, LocalMessageService, LocalThreadService } from "services/local.service";
import { sortArrayObjectReserved, toCamel, uuid } from "utils";
import { getSavedServer } from "utils/common.utils";
import { AppConstant, KeyConstant, SystemConstant } from "const";
import { ConversationActions } from "redux-store";
import { StorageUtil } from "utils";

const useThreadInfo = () => {
  const dispatch = useDispatch();

  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);

  const isFetchMessageSuccess = useSelector(state => state.conversationRedux.isFetchMessageSuccess);
  const isUpdateViewMode = useSelector(state => state.conversationRedux.isUpdateViewMode);
  const restoreStatus = useSelector(state => state.restoreRedux.restoreStatus);

  const [unreadThreads, setUnreadThreads] = useState([]);
  const [readThreads, setReadThreads] = useState([]);
  const [totalThreads, setTotalThreads] = useState([]);

  const getThreadsInfo = async () => {
    const branchId = getSavedServer().id;
    let threadList = LocalThreadService.getAll(branchId);
    if (threadList && threadList.length > 0) {
      threadList = sortArrayObjectReserved(threadList, "modified");
      threadList = Promise.all(
        threadList.map(async item => {
          const threadInfo = toCamel(item);

          const senderParentMsg = LocalAccountService.getAccountByIds([threadInfo.threadAccountId])[0];
          const deleteThreadMsgId = await LocalMessageService.get2NewestDeleteMsg(threadInfo.threadId);
          const deleteIds = deleteThreadMsgId.map(item => item.parent_id);
          const newestMsgInThread = await LocalMessageService.get2NewestThreadMsg(threadInfo.threadId, deleteIds);

          let newestMessage = {};
          let secondMessage = {};

          // Get latest message
          if (newestMsgInThread && newestMsgInThread.length > 0) {
            const firstOriginMessage = newestMsgInThread[0];
            newestMessage = convertMessageContent(firstOriginMessage);
          }

          // Get second message
          if (newestMsgInThread && newestMsgInThread.length > 1) {
            const secondOriginMessage = newestMsgInThread[newestMsgInThread.length - 1];
            secondMessage = convertMessageContent(secondOriginMessage);
          }

          // Convert parent message content
          let parentMessage = await LocalMessageService.find({ source_id: threadInfo.threadId });
          parentMessage = convertMessageContent(parentMessage[0]);

          return {
            ...threadInfo,
            secondMessage: toCamel(secondMessage),
            newestMessage: toCamel(newestMessage),
            parentMessage: toCamel(parentMessage),
            senderParentMsg: toCamel(senderParentMsg),
          };
        }),
      )
        .then(results => {
          const unreadThreads = results.filter(item => item.totalUnread > 0);
          const readThreads = results.filter(item => item.totalUnread === 0);

          setUnreadThreads(unreadThreads);
          setReadThreads(readThreads);
          setTotalThreads([...unreadThreads, ...readThreads]);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const convertMessageContent = message => {
    let convertMessage = { ...message };

    if (MEDIA_SEND_TYPE.includes(message.send_type)) {
      const metaData = JSON.parse(toCamel(JSON.parse(message.content)).metaData);
      const mediaContent = metaData.mime_type.split("/")[0] + ": " + metaData.file_name;
      convertMessage = { ...message, content: mediaContent };
    } else {
      const lastEditMsg = LocalMessageService.getNewestEditMsg(message.source_id);
      if (lastEditMsg && Object.keys(lastEditMsg).length > 0) {
        convertMessage = { ...message, content: lastEditMsg.content };
      }
    }

    return convertMessage;
  };

  const updateThreadAndMessage = threads => {
    let unreadMessageIdArr = [];
    for (let index = 0; index < threads.length; index++) {
      const item = threads[index];
      LocalThreadService.update({ total_unread: 0 }, item.threadId);
      const messagesInThread = LocalMessageService.getByThreadId(item.threadId);
      const unreadMessagesInThread = messagesInThread.filter(
        item => item.status !== SystemConstant.MESSAGE_STATUS.read && item.sender_id !== accountId,
      );
      const unreadId = unreadMessagesInThread.map(item => item.id);
      unreadMessageIdArr = [...unreadMessageIdArr, ...unreadId];
    }

    // Dispatch event update message status = read with chunk = 20;
    const chunkMessage = 20;
    let offset = 0;
    let countMessage = 0;
    while (offset === countMessage) {
      const subUnreadMsgIdArr = unreadMessageIdArr.slice(offset, offset + chunkMessage);
      if (subUnreadMsgIdArr && subUnreadMsgIdArr.length > 0) {
        dispatch(
          ConversationActions.updateMessageStatus({
            messageIds: [...subUnreadMsgIdArr],
            status: SystemConstant.MESSAGE_STATUS.read,
          }),
        );

        LocalMessageService.updateMessageStatusByMsgIdArr([...subUnreadMsgIdArr]);
      }
      offset += subUnreadMsgIdArr.length;
      countMessage += chunkMessage;
    }
  };

  const restoreThread = async () => {
    const threadIds = await LocalMessageService.getThreadInfoFromMsg();

    let restoreThreads = [];
    for (let index = 0; index < threadIds.length; index++) {
      const threadId = threadIds[index].thread_id;

      const parentMessage = await LocalMessageService.getMessageWithSourceId(threadId);
      const messagesInThread = await LocalMessageService.getByThreadId(threadId);
      const changeParentMsg = await LocalMessageService.getMessageWithParentId(threadId);
      const deleteMsgInThread = messagesInThread.filter(
        item => item.send_type === SystemConstant.SEND_TYPE.deleteMessage,
      );
      const normalMessagesInThread = messagesInThread.filter(
        item => item.parent_id === null && !MESSAGE_ERROR_TYPE.includes(item.send_type),
      );
      const lastMessage = messagesInThread[messagesInThread.length - 1];
      const isMeReply =
        parentMessage.sender_id === accountId ||
        messagesInThread.filter(item => item.sender_id === accountId).length > 0;

      const mentionArr = [parentMessage, ...messagesInThread].reduce((resultArr, item) => {
        if (Boolean(item.mentions)) {
          const mention = JSON.parse(item.mentions);
          return [...resultArr, ...mention];
        } else {
          return resultArr;
        }
      }, []);

      const isInvolved = isMeReply || mentionArr.includes(accountId);
      const totalReply = normalMessagesInThread.length - deleteMsgInThread.length;
      const isDeleteParent =
        changeParentMsg.filter(item => item.send_type === SystemConstant.SEND_TYPE.deleteMessage).length > 0;
      const isDelete = isDeleteParent || totalReply <= 0;

      const newThreadInfo = {
        id: uuid(),
        thread_id: threadId,
        total_reply: totalReply,
        total_unread: normalMessagesInThread.filter(item => item.status !== SystemConstant.MESSAGE_STATUS.read).length,
        involved_f: isInvolved ? 1 : 0,
        thread_account_id: parentMessage.sender_id,
        thread_created: parentMessage.created,
        group_id: parentMessage.group_id,
        group_type: null,
        setting_type: null,
        thread_content: parentMessage.content, // TODO: original content of parentMessage
        thread_mentions: JSON.stringify(mentionArr),
        branch_id: parentMessage.branch_id,
        state: isDelete ? AppConstant.THREAD_STATE.inactive : AppConstant.THREAD_STATE.active,
        created: parentMessage.created,
        modified: lastMessage.created,
      };

      restoreThreads.push(newThreadInfo);
    }

    LocalThreadService.save(restoreThreads);
  };

  useEffect(() => {
    getThreadsInfo();
  }, [isFetchMessageSuccess, isUpdateViewMode]);

  useEffect(() => {
    if (restoreStatus === SystemConstant.RESTORE_STATUS.success) {
      restoreThread();
    }
  }, [restoreStatus]);

  return { unreadThreads, readThreads, totalThreads, getThreadsInfo, updateThreadAndMessage };
};

const MEDIA_SEND_TYPE = [
  SystemConstant.SEND_TYPE.image,
  SystemConstant.SEND_TYPE.file,
  SystemConstant.SEND_TYPE.audio,
  SystemConstant.SEND_TYPE.video,
];

const MESSAGE_ERROR_TYPE = [
  SystemConstant.SEND_TYPE.keyError,
  SystemConstant.SEND_TYPE.senderKey,
  SystemConstant.SEND_TYPE.senderKeyDeliveryError,
];

export default useThreadInfo;
