import { ApiConstant, SystemConstant } from "const";
import { all, call, put } from "redux-saga/effects";
import { AccountDeviceService } from "services";
import { removeDuplicateInArray, toSnake } from "utils";
import { LocalAccountService, LocalDeviceService } from "services/local.service";
import { DeviceActions } from "redux-store";

export function* logoutDevice(action) {
  const { data } = action;
  let errorReason;
  try {
    const response = yield call(AccountDeviceService.updateDevice, toSnake(data));
    if (response.status === ApiConstant.STT_OK && Array.isArray(response.data)) {
      const loggedOutDevice = response.data;
      // Remove device on server/ local
      yield all(loggedOutDevice.map(id => deleteDevice({ data: { id } })));

      yield put(
        DeviceActions.deviceSuccess({
          fetchStatus: SystemConstant.REDUX_LIFECYCLE.success,
          loggedOutDevice: loggedOutDevice,
        }),
      );
    } else {
      errorReason = response.error || response;
    }
  } catch (error) {
    console.log(error);
    errorReason = error;
  }

  if (errorReason)
    yield put(DeviceActions.deviceFailure(errorReason, { fetchStatus: SystemConstant.REDUX_LIFECYCLE.fail }));
}

export function* deleteDevice(action) {
  try {
    const { data } = action;
    let response = yield call(AccountDeviceService.deleteDevice, toSnake(data));
    console.log(response);
    if (response.status === ApiConstant.STT_OK) {
      LocalDeviceService.deleteDevice(data.deviceId);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deviceFetching(accountId, deviceId, listAccountId) {
  try {
    yield put(
      DeviceActions.deviceSet({
        fetchStatus: SystemConstant.REDUX_LIFECYCLE.inProgress,
      }),
    );
    let accountIds = [];
    let accounts = [];
    if (accountId != null) {
      accountIds.push(accountId);
    } else if (listAccountId != null) {
      accountIds = listAccountId;
    } else {
      accounts = yield LocalAccountService.getAll();
      accountIds = removeDuplicateInArray(accounts.map(item => item.id));
    }

    let cloneAccountIds = [...accountIds];
    const lastDeviceList = LocalDeviceService.getLastDevices(accountIds);
    // Logic foreach này để làm gì?
    lastDeviceList.forEach(last => {
      cloneAccountIds = cloneAccountIds.filter(accId => accId !== last.account_id);
    });
    const excludeAccount = cloneAccountIds.map(accId => ({ account_id: accId, last_time: 0 }));
    // Get all device base on account_id, last_time
    const deviceListResponse = yield call(AccountDeviceService.getDevice, {
      account_time_ids: JSON.stringify([...lastDeviceList, ...excludeAccount]),
    });

    if (deviceListResponse.status === ApiConstant.STT_OK) {
      const deviceList = deviceListResponse.data.data;
      if (Array.isArray(deviceList)) {
        LocalDeviceService.save(deviceList);
      }
    }

    if (accountId && deviceId) {
      const newDevice = LocalDeviceService.getRealTime(deviceId);

      // If device is not found, calling API to get detail
      if (!newDevice) {
        const deviceItemResponse = yield call(AccountDeviceService.getDevice, {
          account_id: accountId,
          device_id: deviceId,
        });
        if (deviceItemResponse.status === ApiConstant.STT_OK) {
          const deviceList = deviceItemResponse.data.data; // Return array has only 1 item
          if (Array.isArray(deviceList)) {
            LocalDeviceService.save(deviceList);
          }
        } else throw deviceItemResponse;
      }
    }

    yield put(
      DeviceActions.deviceSuccess({
        fetchStatus: SystemConstant.REDUX_LIFECYCLE.success,
      }),
    );
  } catch (error) {
    console.log(error);
    yield put(DeviceActions.deviceFailure(error, { fetchStatus: SystemConstant.REDUX_LIFECYCLE.fail }));
  }
}
