import React from "react";
import DownloadProcess from "./DownloadProcess";
import { useSelector } from "react-redux";

const UpdateProcess = props => {
  const downloadProcess = useSelector(state => state.authRedux.downloadProcess);

  return <DownloadProcess isShow={Boolean(downloadProcess?.downloadSpeed)} content={downloadProcess} />;
};

UpdateProcess.propTypes = {};

export default UpdateProcess;
