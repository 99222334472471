import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { LangConstant, AppConstant } from "const";

const PopupChangeContactName = props => {
  const { onClose, open, onChange, onSubmit } = props;
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const contactObjContent = getLabel(LangConstant.OBJ_ADDING_CONTACT, { returnObjects: true });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.root }}
    >
      <DialogTitle className={classes.titleChanel}>
        {contactObjContent.changeNickname}
      </DialogTitle>
      <form onSubmit={onSubmit} className={classes.form}>
        <DialogContent classes={{
          root: classes.dialogContent
        }}>
          <TextField
            onChange={e => onChange(e.target.value)}
            InputProps={{ disableUnderline: true, inputProps: { maxLength: AppConstant.MAX_LENGTH } }}
            label={contactObjContent.name}
            variant="filled"
            fullWidth
            focused
            className={classes.textField}
            placeholder={contactObjContent.newName}
          />
        </DialogContent>
        <Button type="submit" className={classes.buttonChannel} variant="contained" fullWidth={true}>
          {contactObjContent.change}
        </Button>
      </form>
      <Button onClick={onClose} className={classes.cancelChannel}>
        {contactObjContent.cancel}
      </Button>
    </Dialog>
  );
};

export default PopupChangeContactName;

const useStyles = makeStyles(theme => ({
  cancelChannel: {
    textAlign: "center",
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    color: theme.palette.grey[200],
    "&:hover": {
      color: theme.palette.grey[500],
      backgroundColor: theme.palette.white,
    },
    margin: "4px 24px",
    cursor: "pointer",
  },
  titleChanel: {
    color: theme.palette.black,
    textAlign: "center",
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "24px",
  },
  textField: {
    width: 500,
    overflow: "hidden",
    "& .MuiFilledInput-root": {
      backgroundColor: theme.palette.white,
      borderRadius: "10px",
      border: "1px solid #CBCACA",
    },
  },
  textFieldCss: {
    backgroundColor: theme.palette.white,
  },
  buttonChannel: {
    marginTop: 12,
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    backgroundColor: theme.palette.primary.main,
    "&:disabled": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.white,
    },
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.primary.dark,
    },
  },
  dialogContent: {
    padding: 0,
    backgroundColor: theme.palette.white,
  },
  root: {
    borderRadius: 20,
    padding: theme.spacing(1.5, 1.5),
    textAlign: "center",
    "& .MuiFormLabel-root": {
      color: theme.palette.grey[500],
    }
  },
  form: {
    padding: "0px 24px",
  }
}));
