import { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Box, Drawer, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ConfirmDialog, Device } from "components";
import { AppConstant, LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import { getCommonKey, getNSKey } from "utils/lang.utils";
import PersonIcon from "@mui/icons-material/Person";
import DeleteIcon from "@mui/icons-material/Delete";
import OTPChangeRole from "../OTPChangeRole";
import DeviceActions from "redux-store/device.redux";
import RestoreAction from "redux-store/restore.redux";

const DeviceDetailDrawer = ({ selectedDevice, onClose, isSlaveDevice, isAllowLogout }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const deviceObjContent = getLabel(LangConstant.OBJ_ACCOUNT_DEVICE, { returnObjects: true });

  const changeRoleStatus = useSelector(state => state.restoreRedux.changeRoleStatus);

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isOpenOtp, setIsOpenOtp] = useState(false);
  const [errorContent, setErrorContent] = useState(null);

  const handleLogoutDevice = () => {
    dispatch(
      DeviceActions.deviceLogout({
        slaveDeviceId: [selectedDevice.id],
      }),
    );
  };

  const handleConfirmChangeRole = () => {
    setIsOpenConfirm(true);
  };

  // Request OTP
  const handleChangeRole = () => {
    dispatch(RestoreAction.changeDeviceRole());
  };

  const handleClose = () => {
    dispatch(
      RestoreAction.restoreSet({
        changeRoleStatus: SystemConstant.REDUX_LIFECYCLE.notStart,
      }),
    );
  };

  useEffect(() => {
    if (changeRoleStatus === SystemConstant.REDUX_LIFECYCLE.success) {
      setErrorContent(null);
      setIsOpenConfirm(false);
      setIsOpenOtp(true);
    } else if (changeRoleStatus === SystemConstant.REDUX_LIFECYCLE.fail) {
      setErrorContent(getLabel(getCommonKey(LangConstant.TXT_SERVER_ERROR)));
    } else {
      setErrorContent(null);
    }
  }, [changeRoleStatus]);

  return (
    <>
      <Drawer
        classes={{
          root: clsx(classes.drawer, classes.root),
          paper: clsx(classes.paper, classes.paper),
        }}
        anchor="bottom"
        open={Boolean(selectedDevice)}
        onClose={onClose}
      >
        <Box className={classes.logoDeviceInfor}>
          <Device type={selectedDevice.type} />
        </Box>
        <Box className={classes.contentBox}>
          <Typography className={clsx(classes.deviceName, classes.center)}>{selectedDevice.name || ""}</Typography>
          <Box className={classes.inforDevice}>
            <Typography variant="body2" className={classes.deviceDescriptionInfo}>
              {selectedDevice.description || ""}
            </Typography>
            <Typography variant="body2" className={classes.deviceStatusInfo}>
              {selectedDevice.status || ""}
            </Typography>
          </Box>
        </Box>

        {isSlaveDevice && (
          <Box className={classes.deviceAction}>
            <Typography>{getLabel(LangConstant.TXT_CHANGE_ROLE)}</Typography>
            <IconButton onClick={handleConfirmChangeRole}>
              <PersonIcon />
            </IconButton>
          </Box>
        )}

        {isAllowLogout && (
          <Box className={classes.deviceAction}>
            <Typography>{getLabel(deviceObjContent.logoutFromThisDevice)}</Typography>
            <IconButton onClick={handleLogoutDevice}>
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Drawer>

      <Box>
        {isOpenConfirm && (
          <ConfirmDialog
            open={isOpenConfirm}
            onClose={() => setIsOpenConfirm(false)}
            title={getLabel(LangConstant.TXT_CONFIRM_CHANGE_ROLE_TITLE)}
            content={getLabel(LangConstant.TXT_CONFIRM_CHANGE_ROLE_CONTENT)}
            errorContent={errorContent}
            submitProps={{
              submitText: getLabel(getNSKey(LangConstant.NS_COMMON, LangConstant.TXT_CONFIRM)),
              onClick: handleChangeRole,
            }}
            cancelProps={{
              cancelText: getLabel(getNSKey(LangConstant.NS_COMMON, LangConstant.TXT_CANCEL)),
              onClick: () => setIsOpenConfirm(false),
            }}
            hideBackdrop={true}
          />
        )}

        {isOpenOtp && (
          <OTPChangeRole
            open
            onClose={() => {
              setIsOpenOtp(false);
              handleClose();
            }}
            slaveDeviceId={selectedDevice.id}
            hideBackdrop={true}
            onResendOtp={() => {
              dispatch(RestoreAction.changeDeviceRole());
            }}
          />
        )}
      </Box>
    </>
  );
};

export default DeviceDetailDrawer;

DeviceDetailDrawer.propTypes = {
  selectedDevice: PropTypes.object.isRequired,
  isSlaveDevice: PropTypes.bool,
  isAllowLogout: PropTypes.bool,

  onClose: PropTypes.func.isRequired,
};

DeviceDetailDrawer.defaultProps = {
  isSlaveDevice: true,
  isAllowLogout: false,
};

const useStyles = makeStyles(theme => ({
  drawer: {
    "&, & $paper": {
      width: AppConstant.SIDEBAR_WIDTH.medium,
      flexShrink: 0,
      boxShadow: "unset",

      [theme.breakpoints.up(1500)]: {
        width: AppConstant.SIDEBAR_WIDTH.large,
      },
    },
  },

  center: {
    textAlign: "center",
  },

  logoDeviceInfor: {
    display: "flex",
    justifyContent: "center",
  },

  inforDevice: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  paper: {
    height: 500,
    padding: 25,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },

  contentBox: {
    marginTop: 10,
    marginBottom: 20,
  },

  deviceAction: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  deviceName: {
    fontWeight: 600,
    color: "#1E272E",
    paddingLeft: 8,
  },
}));
