import React, { memo, useState } from "react";
import { AvatarInfo, ConfirmDialog, DrawerLayout } from "components";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getSavedServer } from "utils/common.utils";
import { getCommonKey } from "utils/lang.utils";
import { isGroupOrChannelType } from "pages/Call";
import { ConversationActions, GroupInfoActions } from "redux-store";
import { StorageUtil } from "utils";

const repository = window.repository;

const GroupMemberRemove = ({ open, onClose, title, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);
  let accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);

  const [selectedContact, setSelectedContact] = useState();
  const [isRemove, setIsRemove] = useState(false);

  const handleSelectContact = item => {
    setSelectedContact(item);
    setIsRemove(true);
  };

  const handleCancel = () => {
    setIsRemove(false);
    setSelectedContact();
  };

  const onClickRemove = () => {
    dispatch(
      GroupInfoActions.removeMemberGroup({
        groupId: data.id,
        memberId: selectedContact.id,
      }),
    );
    repository.accountGroup.removeMemberGroup(data.id, selectedContact.id);

    dispatch(
      ConversationActions.sendMessage({
        groupDetail: data,
        sendType: SystemConstant.SEND_TYPE.leaveGroup,
        content: selectedContact.id,
        parentId: null,
        deviceList: [],
        branchId: getSavedServer()?.id,
        mentionIdsArr: [],
        threadId: null,
        removingId: selectedContact.id,
      }),
    );
    setIsRemove(false);
    onClose();
    setSelectedContact();
  };

  return (
    <DrawerLayout onClose={onClose} anchor={"right"} open={open} title={title}>
      {isGroupOrChannelType(data?.groupType) && (
        <Box>
          {data?.members?.map(
            (item, index) =>
              accountId !== item.id && (
                <AvatarInfo
                  key={index}
                  onClick={() => handleSelectContact(item)}
                  secondTitle={item.phone}
                  title={item.name}
                  avatar={{ src: item.avatarId, name: item.name }}
                  button={true}
                />
              ),
          )}
        </Box>
      )}
      <ConfirmDialog
        open={isRemove}
        onClose={handleCancel}
        title={getLabel(LangConstant.TXT_CONFIRM_REMOVE)}
        content={getLabel(
          data.groupType === SystemConstant.GROUP_CHAT_TYPE.group
            ? LangConstant.FM_CONFIRM_REMOVE
            : LangConstant.FM_CONFIRM_REMOVE_CHANNEL_MEMBER,
          { memberName: selectedContact?.name },
        )}
        submitProps={{
          className: classes.submit,
          submitText: getLabel(getCommonKey(LangConstant.TXT_DELETE)),
          onClick: onClickRemove,
        }}
        cancelProps={{
          className: classes.cancel,
          onClick: handleCancel,
        }}
      />
    </DrawerLayout>
  );
};

export default memo(GroupMemberRemove);

const useStyles = makeStyles(theme => ({
  btnDone: {
    "&:disabled": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.white,
    },
    cursor: "pointer",
    marginTop: 20,
    marginBottom: 60,
    "&:hover": {
      boxShadow: "none",
    },
  },
  boxButton: {
    padding: "0px 16px",
  },
  submit: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  cancel: {
    backgroundColor: theme.palette.white,
    color: "#CBCACA",
    margin: "auto",
    "&:hover": {
      color: theme.palette.grey[500],
      boxShadow: "none",
      background: "none",
    },
  },
}));
