import { KeyConstant, SystemConstant } from "const";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LocalDeviceService } from "services/local.service";
import { StorageUtil } from "utils";
import { toCamel } from "utils";

const useDeviceList = () => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);

  const fetchStatus = useSelector(state => state.deviceRedux.fetchStatus);
  const loggedOutDevice = useSelector(state => state.deviceRedux.loggedOutDevice);

  const [masterDeviceList, setMasterDeviceList] = useState([]);
  const [slaveDeviceList, setSlaveDeviceList] = useState([]);
  const [isMaster, setIsMaster] = useState(false);

  const getDeviceList = () => {
    let deviceRecords = LocalDeviceService.getAccountDevice(accountId);

    deviceRecords = toCamel(deviceRecords.filter(s => s.state === 1));
    sortDeviceRecords(deviceRecords);
    setMasterDeviceList(deviceRecords.filter(item => item.masterF === 1));
    setSlaveDeviceList(deviceRecords.filter(item => item.masterF === 0));
  };

  const checkIsMaster = () => {
    LocalDeviceService.get(deviceId).then(deviceInfo => {
      if (Boolean(deviceInfo)) {
        setIsMaster(deviceInfo.master_f === 1);
      }
    });
  };

  const sortDeviceRecords = deviceRecords => {
    deviceRecords.sort((rec1, rec2) => {
      switch (true) {
        case rec1.state === SystemConstant.DEVICE_STATE.active && rec2.state === SystemConstant.DEVICE_STATE.inactive:
          return -1;
        case rec1.state === SystemConstant.DEVICE_STATE.inactive && rec2.state === SystemConstant.DEVICE_STATE.active:
          return 1;
        default:
          return rec2.lastSeen - rec1.lastSeen;
      }
    });
  };

  useEffect(() => {
    getDeviceList();
    checkIsMaster();
  }, []);

  useEffect(() => {
    if (fetchStatus === SystemConstant.REDUX_LIFECYCLE.success) {
      getDeviceList();
      checkIsMaster();

      if (Array.isArray(loggedOutDevice) && loggedOutDevice.length > 0) {
        setSlaveDeviceList(state => state.filter(item => !loggedOutDevice.includes(item.id)));
      }
    }
  }, [fetchStatus, loggedOutDevice]);

  return { masterDeviceList, slaveDeviceList, isMaster, getDeviceList, checkIsMaster };
};

export default useDeviceList;
