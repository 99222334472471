import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { getSavedServer } from "utils/common.utils";
import { AvatarInfo } from "components";
import { ConversationActions } from "redux-store";
import { StorageUtil } from "utils";

const MessageSearchPopup = ({ data, keyword, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_COMMON);
  const [foundResults, setFoundResults] = useState([]);

  const onCreateNewHistoryKeyword = () => {
    const savedServer = getSavedServer();
    if (keyword) {
      let historyKeywords = StorageUtil.getItem(KeyConstant.KEY_LOCALSTORAGE_SIDEBAR_KEYWORDS) || {};
      let historyKeywordsInServer = historyKeywords[savedServer?.id] || [];
      historyKeywordsInServer.unshift(keyword);
      StorageUtil.setItem(
        KeyConstant.KEY_LOCALSTORAGE_SIDEBAR_KEYWORDS,
        Object.assign(historyKeywords, { [savedServer?.id]: historyKeywordsInServer }),
      );
    }
  };

  useEffect(() => {
    data.reduce(function (map, obj) {
      if (!map[obj.groupId]) {
        map[obj.groupId] = 0;
      }
      obj.index = map[obj.groupId];
      map[obj.groupId] = map[obj.groupId] + 1;
      return map;
    }, {});
    setFoundResults(data);
  }, [data]);

  const onClickSearchItem = msg => {
    onCreateNewHistoryKeyword();
    onClose();
    dispatch(ConversationActions.setSelectGroupId({ selectedGroupId: msg.groupId }));
    setTimeout(() => {
      dispatch(
        ConversationActions.conversationSet({
          searchRedirect: msg.index,
          conversationSearchContent: keyword,
          isSearchMode: true,
        }),
      );
    }, 1000);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.heading}>
        <Typography className={clsx(classes.title, "semiBold-sm-txt")}>
          {getLabel(LangConstant.TXT_MESSAGE_FOUND)}
        </Typography>
      </Box>
      <Box className={classes.scrollBox}>
        <Box className={classes.msgWrapper}>
          {foundResults
            .filter(mes => !SystemConstant.ARR_CALLING_TYPES.includes(mes.sendType))
            .map(msg => (
              <Box key={msg.id} className={classes.msgItem} onClick={() => onClickSearchItem(msg)}>
                <AvatarInfo
                  secondTitle={msg.content}
                  title={msg?.accountName || "No name"}
                  avatar={{ src: msg?.accountAvatarId, name: msg?.accountName }}
                  button={true}
                />
                {/*<Avatar className={classes.avatar} src={getImageSrc(msg.accountAvatarId)}>*/}
                {/*  {msg?.accountName?.charAt(0)}*/}
                {/*</Avatar>*/}
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default MessageSearchPopup;

MessageSearchPopup.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      accountAvatarId: PropTypes.string,
      accountName: PropTypes.string,
      content: PropTypes.string,
    }),
  ),

  keyword: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
  heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 12px",
    paddingBottom: 10,
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.white,
    zIndex: 1,
  },

  title: {
    color: "#7F7F80",
    lineHeight: "16px",
  },

  msgWrapper: {
    padding: "0 0px",
  },

  msgItem: {
    display: "flex",
    alignItems: "flex-start",
    cursor: "pointer",
  },

  avatar: {
    width: 50,
    height: 50,
    marginRight: 12,
  },

  msgName: {
    fontSize: 15,
    fontWeight: 600,
    lineHeight: "20px",
    color: theme.palette.black,
    paddingRight: 12,
    width: "100%",
  },

  msgContent: {
    lineHeight: "20px",
    color: theme.palette.grey[500],
    paddingRight: 12,
    width: "100%",
    wordBreak: "break-word",
  },
}));
