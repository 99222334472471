import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Dialog, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { appendBuffer, convertHex2rgba } from "utils";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import { getCommonKey } from "utils/lang.utils";
import { LocalAttachmentService } from "services/local.service";
import { ConversationActions } from "redux-store";

const UploadConfirmDialog = ({ isShow, onSendMessage, fileUpload, groupId, onClose }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_HOME_CONVERSATION);

  const uploadMessageAttachmentData = useSelector(state => state.conversationRedux.uploadMessageAttachmentData);
  const isFinishUpload = useSelector(state => state.conversationRedux.isFinishUpload);
  const isUploadFileFailed = useSelector(state => state.conversationRedux.isUploadFileFailed);

  const [messageFileContent, setMessageFileContent] = useState({});
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [formData, setFormData] = useState(null);
  const [isUploadFailed, setIsUploadFailed] = useState(false);

  const onCloseConfirmDialog = () => {
    setFormData(null);
    setMessageFileContent({});
    onClose();
  };

  const onConfirmUpload = () => {
    setIsLoadingFile(true);
    if (formData) {
      dispatch(
        ConversationActions.uploadMessageFile({
          upload: formData,
          sendType: LocalAttachmentService.getSendType(fileUpload.type),
        }),
      );
    }
  };

  useEffect(() => {
    if (uploadMessageAttachmentData) {
      if (fileUpload) {
        LocalAttachmentService.copyToUpload(
          fileUpload.path,
          uploadMessageAttachmentData.attachment_id,
          fileUpload.name,
          fileUpload,
        );
      }
      let content = { ...messageFileContent, attachment_id: uploadMessageAttachmentData.attachment_id };
      onSendMessage(uploadMessageAttachmentData.sendType, JSON.stringify(content), null, []);
      onCloseConfirmDialog();
      setIsLoadingFile(false);
    }
  }, [uploadMessageAttachmentData]);

  useEffect(() => {
    if (isFinishUpload) {
      dispatch(
        ConversationActions.conversationSet({
          isFinishUpload: false,
        }),
      );
      setIsLoadingFile(false);
    }
  }, [isFinishUpload]);

  useEffect(() => {
    if (fileUpload) {
      let messageMetaData = {
        file_name: fileUpload.name,
        size: `${fileUpload.size + 2}`,
        mime_type: fileUpload.type,
      };

      LocalAttachmentService.encryptAESGroupFile(fileUpload.path, fileUpload).then(r => {
        setMessageFileContent({
          aes_key_info: JSON.stringify({ iv: r.iv, key: r.key, authtag: r.authtag }),
          meta_data: JSON.stringify(messageMetaData),
        });
        const buffer = new ArrayBuffer(2);
        let addSize = appendBuffer(r.encryptedData, buffer);
        let formData = new FormData();
        formData.append("file", new Blob([addSize]));
        formData.append("group_id", groupId);
        formData.append("metadata", JSON.stringify(messageMetaData));
        formData.append("type", "1");
        setFormData(formData);
      });
    }

    return () => {
      setFormData(null);
      setMessageFileContent({});
    };
  }, [fileUpload]);

  useEffect(() => {
    if (isUploadFileFailed) {
      dispatch(
        ConversationActions.conversationSet({
          isUploadFileFailed: false,
        }),
      );
      setIsUploadFailed(true);
    }

    return () => {
      setIsUploadFailed(false);
    };
  }, [isUploadFileFailed]);

  return (
    <Dialog
      open={isShow}
      classes={{
        paper: classes.dialogPaper,
      }}
      onClose={onCloseConfirmDialog}
    >
      <Box className={isLoadingFile ? classes.loadingBox : "hidden"}>
        <CircularProgress className={classes.circularIcon} />
      </Box>
      <Typography className={clsx(classes.uploadConfirmTitle, "semiBold-lg-txt")}>
        {getLabel(LangConstant.TXT_SEND_FILE_CONFIRM_TEXT)}
      </Typography>
      <Typography className={clsx(classes.uploadConfirmText, "medium-md-txt")}>"{fileUpload?.name}"</Typography>

      {isUploadFailed && (
        <Typography className={clsx(classes.uploadFailed, "small-md-txt")}>
          {getLabel(LangConstant.TXT_UPLOAD_FAILED)}
        </Typography>
      )}

      <Box className={classes.actionContainer}>
        <Button className={classes.actionBtn} onClick={onCloseConfirmDialog}>
          {getLabel(getCommonKey(LangConstant.TXT_CANCEL))}
        </Button>
        <Button onClick={onConfirmUpload} className={classes.actionBtn}>
          {getLabel(getCommonKey(LangConstant.TXT_CONFIRM))}
        </Button>
      </Box>
    </Dialog>
  );
};

UploadConfirmDialog.propTypes = {};

export default UploadConfirmDialog;

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    position: "relative",
    padding: "24px 24px 10px 24px",
    width: 350,
  },

  loadingBox: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: convertHex2rgba("#808080", 0.9),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: theme.zIndex.tooltip + 1,
  },

  circularIcon: {},

  actionContainer: {
    display: "flex",
    marginTop: 16,
    width: "100%",
    justifyContent: "flex-end",
  },

  actionBtn: {
    textTransform: "none",
  },

  uploadConfirmTitle: {},

  uploadConfirmText: {
    fontStyle: "italic",
    marginTop: 5,
  },

  uploadFailed: {
    color: "red",
    marginTop: 10,
  },
}));
