import { memo } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import OTPInput from "./OTPInput";
import OTPAction from "./OTPAction";
import OTPTitle from "./OTPTitle";
import clsx from "clsx";

const OTPVerify = ({ className, TitleProps, InputProps, ActionProps }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, className)}>
      <OTPTitle {...TitleProps} />
      <OTPInput {...InputProps} />
      <OTPAction {...ActionProps} />
    </Box>
  );
};

OTPVerify.propTypes = {
  className: PropTypes.string,
  TitleProps: PropTypes.shape(OTPTitle.propTypes),
  InputProps: PropTypes.shape(OTPInput.propTypes),
  ActionProps: PropTypes.shape(OTPAction.propTypes),
};

export default memo(OTPVerify);

const useStyles = makeStyles(() => ({
  container: {
    width: 300,
  },
}));
