import { ApiConstant } from "const";
import GlobalApi, { createApiWithBranch } from "./config/api.config";

const ATTACHMENT_REQ_CONFIG = {
  responseType: "arraybuffer",
};
let AttachmentQueue = {};

export const getAttachment = (data, branch_id) => {
  if (!Object.keys(AttachmentQueue).includes(data.attachment_id)) {
    AttachmentQueue[data.attachment_id] = createApiWithBranch(branch_id).get(
      ApiConstant.GET_ATTACHMENT,
      data,
      ATTACHMENT_REQ_CONFIG,
    );
  }
  return AttachmentQueue[data.attachment_id];
};

export const getGlobalAttachment = data => {
  if (!Object.keys(AttachmentQueue).includes(data.attachment_id)) {
    AttachmentQueue[data.attachment_id] = GlobalApi.get(ApiConstant.GET_ATTACHMENT, data, ATTACHMENT_REQ_CONFIG);
  }
  return AttachmentQueue[data.attachment_id];
};

export const forceGetAttachment = data => {
  return createApiWithBranch().get(ApiConstant.GET_ATTACHMENT, data, ATTACHMENT_REQ_CONFIG);
};
