import React, { useEffect, useState } from "react";
import { CardMedia, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import clsx from "clsx";
import { FormatConstant, ImageConstant } from "const";
import { getExternalLinkFromString } from "utils";
import StringFormat from "string-format";
import { LocalAccountService } from "services/local.service";

const DataLink = ({ data, isDivider }) => {
  const classes = useStyles();

  const [dataLink, setDataLink] = useState({
    title: "",
    sender: "",
    image: "",
    url: "",
  });

  useEffect(async () => {
    let url = getExternalLinkFromString(data?.content);
    if (url) {
      let previewData = {};
      setDataLink(state => ({ ...state, url: url }));
      if (process.env.NODE_ENV !== "development") {
        try {
          let resp = await fetch(url);
          let text = await resp.text();
          let parser = new DOMParser();
          let doc = parser.parseFromString(text, "text/html");
          previewData = {
            title: doc.querySelector("title")?.innerHTML || "",
            image: doc.querySelector("meta[property='og:image']")?.content || "",
            url: url,
          };
        } catch (error) {
          console.error(error);
        }
      }

      if (!previewData.image) {
        previewData = { ...previewData, image: ImageConstant.NoFileImage };
      }
      LocalAccountService.get(data.senderId).then(res => {
        previewData = { ...previewData, sender: res.name };
        setDataLink(state => ({ ...state, ...previewData }));
      });
    }
  }, []);

  const onOpenExternalLink = e => {
    e.preventDefault();
    let el = e.currentTarget;
    let url = el.href;
    window.handleExternalLink.openExternalLink(url);
  };

  useEffect(() => {
    let id = StringFormat(FormatConstant.FM_CHAT_ITEM_ID, data.id);
    let elements = document.querySelectorAll(`#${id} a`);
    if (elements) {
      for (const element of elements) {
        element.addEventListener("click", onOpenExternalLink);
      }
    }

    return () => {
      if (elements) {
        for (const element of elements) {
          element.removeEventListener("click", onOpenExternalLink);
        }
      }
    };
  });

  return (
    <Box className={classes.userAvatarAndName} id={StringFormat(FormatConstant.FM_CHAT_ITEM_ID, data.id)}>
      <CardMedia component="img" className={classes.cover} src={dataLink.image} />
      <Box className={clsx(classes.customerDetailBox, { [classes.borderBottom]: isDivider })}>
        <Box className={classes.boxBodyUserInfo}>
          <Link className={clsx("ellipsis", classes.linkData)} target="_blank" href={dataLink.url}>
            {dataLink.url}
          </Link>
        </Box>
        <Box className={classes.boxRating}>
          <Typography className={clsx("ellipsis", classes.customerDetail)}>{dataLink.title}</Typography>
        </Box>
        <Typography className={clsx("ellipsis", classes.typographyReview)}>{dataLink.sender}</Typography>
      </Box>
    </Box>
  );
};

export default DataLink;

const useStyles = makeStyles(theme => ({
  cover: {
    width: 76,
    height: 76,
    borderRadius: 4,
    objectFit: "cover",
  },
  userAvatarAndName: {
    width: "100%",
    display: "flex",
    paddingTop: 12,
  },
  customerDetailBox: {
    width: "100%",
    marginLeft: 12,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  borderBottom: {
    borderBottom: "0.5px solid #E6E8EB",
  },
  customerDetail: {
    lineHeight: "18px",
    height: "fit-content",
    paddingTop: 3,
    color: theme.palette.black,
    fontWeight: 400,
    fontSize: 12,
  },
  typographyReview: {
    lineHeight: "14px",
    maxWidth: "fit-content",
    width: "100%",
    fontWeight: 600,
    fontSize: 10,
    color: theme.palette.grey[500],
  },
  linkData: {
    textDecoration: "none",
    fontSize: 15,
    fontWeight: 400,
    lineHeight: "20px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
