import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  // changeGroupInfoRequest: ["data"],
  groupInfoSet: ["data"],
  changeGroupPhoto: ["data"],
  removeMemberGroup: ["data"],
  addMemberGroup: ["data"],
  muteGroupNotification: ["data"],
  addAdmin: ["data"],
});

export const GroupInfoTypes = Types;
export const GroupInfoActions = Creators;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isChangeGroupInfoSuccess: false,
  groupAvatar: null,
  groupName: null,
  groupId: null,
  groupMembers: null,
  changeGroupPhoto: "",
  removeMemberGroup: "",
  addMemberGroup: "",
  isFetching: false,
  error: null,
  newActionNotification: null,
  statusGroup: null,
  dataMemberToAdmin: null,
  updatedGroup: null,
};

/* ------------- Selector ------------- */
export const GroupInfoSelectors = {};

/* ------------- Reducers ------------- */
export const request = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    ...data,
    isFetching: true,
  };
};

export const set = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    ...data,
  };
};

export const reset = () => INITIAL_STATE;

/* ------------- Mapping ------------- */
export const HANDLERS = {
  // [Types.GROUP_INFO_REQUEST]: request,
  [Types.CHANGE_GROUP_PHOTO]: request,
  [Types.REMOVE_MEMBER_GROUP]: request,
  [Types.ADD_MEMBER_GROUP]: request,
  [Types.MUTE_GROUP_NOTIFICATION]: request,
  [Types.ADD_ADMIN]: request,
  [Types.GROUP_INFO_SET]: set,
};

/* ------------- Hookup Reducers To Types ------------- */
export const GroupInfoReducer = createReducer(INITIAL_STATE, HANDLERS);
