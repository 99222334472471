import React, { useState, useEffect, Fragment, Suspense, memo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import StringFormat from "string-format";
import PropTypes from "prop-types";
import { Box, IconButton, Slide, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ArrowDownward } from "@mui/icons-material";
import { toCamel, toSnake, deepCloneJsonObject, removeDuplicatesWithKey } from "utils";
import { LangConstant, FormatConstant, SystemConstant, KeyConstant } from "const";
import { convertMillisecondsToDate } from "utils/date.utils";
import { InfiniteScroll } from "components";
import ChatItem from "../ChatItem";
import LineChat from "../LineChat";
import { LocalMessageService } from "services/local.service";
import { ConversationActions } from "redux-store";
import { StorageUtil } from "utils";

const SearchMode = ({ onMessagesChange, onSendMessage, onMediaClick, setIsOpenSearchPopup, groupDetail }) => {
  let isChannel = groupDetail.groupType === SystemConstant.GROUP_CHAT_TYPE.channel;
  let groupMembers = groupDetail.groupMembers;

  const classes = useStyles();
  const dispatch = useDispatch();
  const selectId = useSelector(state => state.conversationRedux.selectedGroupId);
  const searchContent = useSelector(state => state.conversationRedux.conversationSearchContent);
  const searchResultIndex = useSelector(state => state.conversationRedux.conversationSearchIndex);
  const searchRedirect = useSelector(state => state.conversationRedux.searchRedirect);
  const { t: getLabel } = useTranslation(LangConstant.NS_COMMON);
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);

  const [messages, setMessages] = useState([]);
  const [exactMessages, setExactMessages] = useState([]);
  const [searchMessages, setSearchMessages] = useState([]);
  const [topIndex, setTopIndex] = useState(0);
  const [bottomIndex, setBottomIndex] = useState(0);
  const [exactMessageId, setExactMessageId] = useState(null);
  const [isShowScrollButton, setIsShowScrollButton] = useState(false);
  const [showMessage, setShowMessage] = useState([]);

  const onScrollTop = () => {
    setTopIndex(index => index + 1);
  };

  const onScrollBottom = () => {
    setBottomIndex(index => index + 1);
  };

  const onClickScrollButton = () => {
    setIsOpenSearchPopup(false);
    dispatch(
      ConversationActions.conversationSet({
        conversationSearchContent: null,
        isSearchMode: false,
        conversationSearchResults: [],
        conversationSearchIndex: null,
      }),
    );
  };

  useEffect(() => {
    if (topIndex > 0) {
      let snakeMsg = toSnake(messages);
      let result = LocalMessageService.getEarlierMessage(snakeMsg, selectId);
      setMessages(removeDeletedMessages(toCamel(result)));
    }
  }, [topIndex]);

  useEffect(() => {
    if (bottomIndex > 0) {
      let snakeMsg = toSnake(messages);
      let result = LocalMessageService.getLaterMessage(snakeMsg, selectId);
      setMessages(removeDeletedMessages(toCamel(result)));
    }
  }, [bottomIndex]);

  useEffect(() => {
    if (searchContent) {
      let exactSearchMessages = LocalMessageService.searchExactMessageInGroup(selectId, searchContent);
      exactSearchMessages = removeDeletedMessages(toCamel(exactSearchMessages));
      let searchMessages = LocalMessageService.searchMessageInGroup(toSnake(exactSearchMessages), searchContent);
      searchMessages = toCamel(searchMessages);
      searchMessages = searchMessages.map(msgList => removeDeletedMessages(msgList));

      if (exactSearchMessages.length > 0) {
        let index = searchMessages.length - 1;
        let exactMessage = exactSearchMessages[index];
        setSearchMessages(searchMessages);
        setExactMessages(exactSearchMessages);
        setMessages(searchMessages[index]);
        setExactMessageId(StringFormat(FormatConstant.FM_CHAT_ITEM_ID, exactMessage.id));
        dispatch(
          ConversationActions.conversationSet({
            conversationSearchResults: searchMessages,
            conversationSearchIndex: index,
          }),
        );
        if (searchRedirect) {
          dispatch(
            ConversationActions.conversationSet({
              searchRedirect: null,
              conversationSearchIndex: searchRedirect,
            }),
          );
        }
      } else {
        setSearchMessages([]);
        setExactMessages([]);
        setMessages([]);
        setExactMessageId(null);
        dispatch(
          ConversationActions.conversationSet({
            conversationSearchResults: [],
            conversationSearchIndex: null,
          }),
        );
      }
    }
  }, [searchContent]);

  useEffect(() => {
    if (searchResultIndex !== null && searchResultIndex !== undefined && searchResultIndex >= 0) {
      let hanldeMessages = searchMessages[searchResultIndex];
      let exactMessage = exactMessages[searchResultIndex];
      setMessages(hanldeMessages);
      onMessagesChange();
      if (exactMessage) {
        let id = StringFormat(FormatConstant.FM_CHAT_ITEM_ID, exactMessage.id);
        setExactMessageId(id);
      }
    }
  }, [searchResultIndex]);

  useEffect(() => {
    if (searchRedirect && searchResultIndex && searchResultIndex !== searchRedirect) {
      dispatch(
        ConversationActions.conversationSet({
          conversationSearchIndex: searchRedirect,
        }),
      );
    }
  }, [searchRedirect]);

  useEffect(() => {
    let wrapper = document.getElementById(CHAT_WRAPPER_ID);
    let onScroll = e => {
      let element = e.target;
      element.scrollTop + element.clientHeight < element.scrollHeight
        ? setIsShowScrollButton(true)
        : setIsShowScrollButton(false);
    };

    if (wrapper) {
      wrapper.addEventListener("scroll", onScroll);
    }

    return () => {
      if (wrapper) {
        wrapper.removeEventListener("scroll", onScroll);
      }
    };
  });

  useEffect(() => {
    if (messages.length > 0) {
      setShowMessage(rewriteMessages(messages));
    }
  }, [messages]);

  useEffect(() => {
    let wrapper = document.getElementById(CHAT_WRAPPER_ID);
    if (showMessage.length > 0) {
      setTimeout(() => {
        if (wrapper) {
          wrapper.scroll({
            left: 0,
            top: wrapper.scrollHeight,
          });
        }
      }, 500);
    }
  }, [showMessage]);

  return (
    <>
      <InfiniteScroll
        className={showMessage && showMessage.length > 0 ? classes.wrapChatItem : "hidden"}
        id={CHAT_WRAPPER_ID}
        onScrollToTop={() => onScrollTop()}
        onScrollToBottom={() => onScrollBottom()}
        scrollToChildId={exactMessageId}
      >
        <>
          {showMessage.map((msg, index) => (
            <Fragment key={msg.id}>
              <Suspense fallback={<Box></Box>}>
                {msg.isShowTime && (
                  <LineChat data={convertMillisecondsToDate(msg?.created, FormatConstant.FM_HH_MM_DD_MM_YYYY)} />
                )}
                <ChatItem
                  data={{
                    ...msg,
                    members: groupMembers,
                  }}
                  showTime={msg.isAvatar && msg.senderId === accountId}
                  isAvatar={msg.isAvatar}
                  onMediaClick={() => onMediaClick(msg)}
                  onSendMessage={onSendMessage}
                  isChannel={isChannel}
                />
              </Suspense>
            </Fragment>
          ))}
          <Slide direction="up" in={isShowScrollButton} container={() => document.getElementById(CHAT_WRAPPER_ID)}>
            <IconButton className={classes.arrowDown} size="small" disableRipple onClick={onClickScrollButton}>
              <ArrowDownward />
            </IconButton>
          </Slide>
        </>
      </InfiniteScroll>

      <Typography className={showMessage && showMessage.length > 0 ? "hidden" : classes.notFoundText}>
        {getLabel(LangConstant.TXT_NOT_FOUND)}
      </Typography>
    </>
  );
};

export default memo(SearchMode, (prev, next) => prev.groupDetail.id === next.groupDetail.id);

const removeDeletedMessages = messages => {
  let show = deepCloneJsonObject(messages)
    .filter(mg => !CALLING_TYPES.includes(mg.sendType))
    .map(msg => {
      let childMessages = LocalMessageService.getChildMessages(msg.sourceId, SystemConstant.SEND_TYPE.deleteMessage);
      if (childMessages.length > 0) {
        return null;
      }
      return msg;
    });

  show = show.filter(item => item !== null && item !== undefined);

  return removeDuplicatesWithKey(show, "id");
};

const rewriteMessages = messagesArr => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);

  const rewrite = messagesArr.map((msg, index) => {
    if (msg.senderId !== accountId) {
      const maxTime =
        SystemConstant.TIME_FORMAT.minute * SystemConstant.TIME_FORMAT.second * SystemConstant.TIME_FORMAT.ticks;

      const msgCreatedTime = msg.created;

      const prevMsg = messagesArr[index + 1] ? messagesArr[index + 1] : null;

      if (
        (!prevMsg || (prevMsg && (msgCreatedTime - prevMsg.created >= maxTime || prevMsg.senderId !== msg.senderId))) &&
        msg &&
        msg.created &&
        !CALLING_TYPES.includes(msg.sendType)
      ) {
        return {
          ...msg,
          isShowTime: true,
          isAvatar: true,
        };
      }
    }

    return msg;
  });
  return rewrite;
};

const CHAT_WRAPPER_ID = "chat-wrapper-id";

SearchMode.propTypes = {
  onMessagesChange: PropTypes.func,
  onSendMessage: PropTypes.func,
  setIsOpenSearchPopup: PropTypes.func,
};

SearchMode.defaultProps = {
  onMessagesChange: () => {},
  onSendMessage: () => {},
  setIsOpenSearchPopup: () => {},
};

const useStyles = makeStyles(theme => ({
  wrapChatItem: {
    height: "100%",
    overflow: "auto",
    padding: "0 22px",
    paddingBottom: 15,
    paddingTop: 70,
  },

  notFoundText: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.grey[200],
  },

  arrowDown: {
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    padding: 3,
    position: "absolute",
    bottom: 124,
    left: "50%",
    transform: "translateX(-50%)",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    zIndex: 1,
  },
}));

const CALLING_TYPES = [
  SystemConstant.SEND_TYPE.personalCall,
  SystemConstant.SEND_TYPE.groupCall,
  SystemConstant.SEND_TYPE.reconnect,
  SystemConstant.SEND_TYPE.personalVideoCall,
  SystemConstant.SEND_TYPE.groupVideoCall,
  SystemConstant.SEND_TYPE.conference,
];
