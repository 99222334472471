import React, { memo, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  ListItem,
  ListItemButton,
  Typography,
  ListItemText,
  Avatar,
  Badge,
  Paper,
  ClickAwayListener,
} from "@mui/material";
import { ArrowDropDown, Circle } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { KeyConstant, LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import { formatLimitNumber, toCamel } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { LocalAppNotificationService, LocalBranchService, LocalNotificationService } from "services/local.service";
import { AuthActions, BranchSelectors, ConversationActions } from "redux-store";
import { useServerList, useServerMode, useUnreadMessage } from "hooks";
import { StorageUtil } from "utils";

const SelectServer = () => {
  const classes = useStyles();
  const styles = createStyleObj();
  const anchorRef = useRef();
  const dispatch = useDispatch();
  const { allUnreadNumber } = useUnreadMessage();

  const { t: getLabel } = useTranslation(LangConstant.NS_HOME);
  const { selectedBranch, saveServerMode } = useServerMode();
  const { serverList, branchList } = useServerList();

  const hasInternet = useSelector(state => state.profileRedux.hasInternet);
  const kickedBranchArray = useSelector(BranchSelectors.getKickedBranchArray);
  const isFetchMessageSuccess = useSelector(state => state.conversationRedux.isFetchMessageSuccess);
  const isUpdateViewMode = useSelector(state => state.conversationRedux.isUpdateViewMode);
  const newBranchInvitation = useSelector(state => state.conversationRedux.newBranchInvitation);

  const [isShow, setIsShow] = useState(false);
  const [selectedServer, setSelectedServer] = useState({});
  const [messageCount, setMessageCount] = useState(0);

  const handleShowDropdown = () => setIsShow(preIsShow => !preIsShow);

  const handleSelectServer = async serverItem => {
    setIsShow(false);
    if (serverItem && selectedServer.id === serverItem.id) return;

    setSelectedServer(serverItem);
    saveServerMode(serverItem);

    // Reset selecting group
    dispatch(
      ConversationActions.conversationSet({
        threadingId: null,
        selectedGroupId: null,
      }),
    );
  };

  const handleUnreadNotification = async () => {
    const notificationList = await LocalNotificationService.getNormalNotification();
    const unreadNotice = notificationList.filter(
      noticeItem =>
        noticeItem.branch_id === selectedServer.id &&
        (noticeItem.status === SystemConstant.NOTIFICATION_STATUS.unreceived ||
          noticeItem.status === SystemConstant.NOTIFICATION_STATUS.received),
    ).length;

    const unreadAll = unreadNotice + allUnreadNumber;
    setMessageCount(unreadAll);
    LocalAppNotificationService.setBadgeCount(unreadAll);
  };

  useEffect(() => {
    if (selectedServer) {
      handleUnreadNotification();
    }
  }, [selectedServer, isFetchMessageSuccess, isUpdateViewMode, newBranchInvitation, allUnreadNumber]);

  useEffect(() => {
    // Select DefaultBranch || serverList[0] || branchList[0] if user is kicked on current server
    const isRemoveSelectingServer = kickedBranchArray.find(item => item.branchId === selectedServer.id);
    if (isRemoveSelectingServer) {
      const defaultServerId = StorageUtil.getItem(KeyConstant.KEY_SELECTED_SERVER);
      const defaultServer = LocalBranchService.get(defaultServerId);
      handleSelectServer(toCamel(defaultServer) || serverList[0] || branchList[0]);
    }

    if (kickedBranchArray && kickedBranchArray.length > 0) {
      dispatch(
        AuthActions.authSet({
          kickedBranchArray: [],
        }),
      );
    }
  }, [kickedBranchArray]);

  useEffect(() => {
    // Synch selected server
    if (selectedBranch.id && false === Boolean(selectedServer.id)) {
      handleSelectServer(selectedBranch);
    }
  }, [selectedBranch]);

  return (
    <ClickAwayListener onClickAway={() => setIsShow(false)}>
      <Box sx={styles.root}>
        <ListItem disablePadding secondaryAction={<ArrowDropDown />} onClick={handleShowDropdown}>
          <ListItemButton ref={anchorRef} sx={styles.itemButton}>
            <Badge
              badgeContent={formatLimitNumber(messageCount)}
              color="primary"
              classes={{ root: classes.numNotify, badge: "badge" }}
            >
              <Avatar src={selectedServer.branchIcon} sx={styles.avatar} />
            </Badge>
            <ListItemText
              primary={
                <Box display="flex" alignItems="center">
                  <Typography sx={styles.text}>{selectedServer?.name || ""}</Typography>
                  <Circle sx={hasInternet ? styles.circleOnline : styles.circleOffline} />
                </Box>
              }
            />
          </ListItemButton>
        </ListItem>

        <Box className={classes.listServerRoot}>
          <Paper elevation={1} className={clsx(classes.listServer, "server-border", !isShow && "hidden")}>
            {serverList.length > 0 && (
              <>
                <Typography sx={styles.serverHeader}>{getLabel(LangConstant.TXT_SERVERS)}</Typography>
                {serverList.map(item => (
                  <ServerItem onClick={() => handleSelectServer(item)} key={item.id} data={item} />
                ))}
              </>
            )}

            {branchList.length > 0 && (
              <>
                <Typography sx={styles.serverHeader}>{getLabel(LangConstant.TXT_BRAND_SERVERS)}</Typography>
                {branchList.map(item => (
                  <ServerItem onClick={() => handleSelectServer(item)} key={item.id} data={item} />
                ))}
              </>
            )}
          </Paper>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

SelectServer.propTypes = { data: PropTypes.array, onChange: PropTypes.func };

export default memo(SelectServer);
const PADDING_LEFT = 16;

const ServerItem = ({ onClick, data }) => {
  const styles = createStyleObj();

  return (
    <ListItem disablePadding onClick={onClick}>
      <ListItemButton sx={styles.serverItem}>
        <Avatar src={data.branchIcon} sx={styles.avatar} />
        <ListItemText primary={<Typography sx={styles.text}>{data.name}</Typography>} />
      </ListItemButton>
    </ListItem>
  );
};

const useStyles = makeStyles(theme => ({
  numNotify: {
    "& .badge": {
      top: 4,
      right: -5,
      backgroundColor: "#EE4F49",
      border: "2px solid white",
    },
  },

  listServerRoot: {
    display: "flex",
    position: "absolute",
    width: `calc(100% - ${PADDING_LEFT * 2}px)`,
    zIndex: theme.zIndex.drawer + 1,
    "& $listServer.server-border": {
      borderRadius: 10,
    },
  },

  listServer: {
    width: "100%",
    marginTop: 8,
    padding: "10px 8px",
  },
}));

const createStyleObj = () => ({
  root: {
    padding: `8px ${PADDING_LEFT}px`,
  },
  itemButton: {
    border: "2px solid #F2F2F4",
    boxSizing: "border-box",
    borderRadius: "10px",
  },
  avatar: {
    width: 36,
    height: 36,
  },
  text: {
    fontSize: 15,
    fontWeight: 700,
    color: "#1E272E",
    marginLeft: 3,
    marginRight: 1,
  },
  circleOnline: {
    color: "#35CC3F",
    width: 12,
    height: 12,
  },
  circleOffline: {
    color: "yellow",
    width: 12,
    height: 12,
  },
  serverItem: {
    borderRadius: "10px",
  },
  serverHeader: {
    fontSize: 12,
    fontWeight: 600,
    color: "#6C7078",
    marginTop: 1.5,
    marginBottom: 1.5,
    marginLeft: `${PADDING_LEFT}px`,
  },
});
