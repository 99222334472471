import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Avatar, Badge, IconButton, Tooltip, Typography } from "@mui/material";
import {
  CallEnd,
  KeyboardVoice,
  HorizontalRule,
  MicOff,
  OpenInFull,
  Settings,
  Videocam,
  VideocamOff,
  CloseFullscreen,
  PresentToAll,
} from "@mui/icons-material";
import clsx from "clsx";
import { HomeServerIcon } from "icons";
import { AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { convertHex2rgba, convertString2JSON, formatLimitNumber, getImageSrcAvatar, toCamel } from "utils";
import { CallingContext, isGroupOrChannelType } from "../index";
import SettingDialog from "./SettingDialog";
import { useTranslation } from "react-i18next";
import { AvatarConversation, ScreenCaptureOption } from "components";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import BackHandOutlinedIcon from "@mui/icons-material/BackHandOutlined";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import EditGroupCallInfo from "pages/HomePage/components/MessengerChat/EditGroupCallInfo";
import UserInCall from "../GroupCall/components/UserInCall";
import useCallInfo from "../../../hooks/useCallInfo";
import { LocalCallHistoryService, LocalMessageService } from "services/local.service";
import { CallingActions, ConversationActions } from "redux-store";
import { StorageUtil } from "utils";

const CallLayout = ({ children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_CALLING);
  const {
    isMute,
    setIsMute,
    isVideoing,
    setIsVideoing,
    callingGroupDetail,
    onSendMessage,
    localStream,
    connections,
    selectedScreenStream,
    setSelectedScreenStream,
    onStopSharing,
    handUp,
    setHandUp,
    handUpListId,
    remoteStreamArr,
  } = useContext(CallingContext);
  const isReceiver = useSelector(state => state.callingRedux.isReceiver);
  const isCallAccepted = useSelector(state => state.callingRedux.isCallAccepted);
  const createdMessage = useSelector(state => state.callingRedux.createdMessage);
  const participants = useSelector(state => state.callingRedux.callingParticipants);
  const isVideoCall = useSelector(state => state.callingRedux.isVideoCall);
  const isDisconnected = useSelector(state => state.callingRedux.isDisconnected);
  const isInAnotherCall = useSelector(state => state.callingRedux.isInAnotherCall);
  const isOpenCallingDialog = useSelector(state => state.callingRedux.isOpenCallingDialog);

  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);

  const [selectedServer, setSelectedServer] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [groupAvatar, setGroupAvatar] = useState("");
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isShowUserInCall, setIsShowUserInCall] = useState(false);
  const { callInfo } = useCallInfo();

  const handleSendCallingMsg = params => {
    if (isOpenCallingDialog === AppConstant.CALLING_STATUS.checking) return;

    onSendMessage(params);
  };

  const onEnd = () => {
    const callingType = callingGroupDetail?.groupType;
    const callingId = callingGroupDetail?.id;

    // Reset calling
    dispatch(
      CallingActions.callingReset({
        callingGroupDetail: null,
      }),
    );

    let isPersonal = callingType === SystemConstant.GROUP_CHAT_TYPE.personal;
    let callBackEnd = () => {
      console.log("On end call from main call UI");

      let lastCall = toCamel(LocalCallHistoryService.getByGroupId(callingId)[0]);

      let callMessage = toCamel(LocalMessageService.getMessageWithSourceId(lastCall.sourceId));

      let currentCallingMessage = createdMessage[callingId];
      if (
        currentCallingMessage ||
        (lastCall &&
          lastCall.status === SystemConstant.CALL_HISTORY_STATUS.calling &&
          JSON.parse(lastCall.callingMembers).includes(deviceId))
      ) {
        let endMessageParent = currentCallingMessage ? currentCallingMessage : callMessage;
        let endContent = {
          room_id: convertString2JSON(endMessageParent?.content)?.room_id,
        };

        if (isPersonal) {
          if (isReceiver) {
            console.log("Send message end to caller");

            handleSendCallingMsg({
              content: JSON.stringify(endContent),
              parentId: endMessageParent?.parentId ? endMessageParent?.parentId : endMessageParent?.sourceId,
              threadId: null,
              callStatus: SystemConstant.MESSAGE_CALL_STATUS.end,
              roomId: endContent.room_id,
            });

            dispatch(
              CallingActions.callingSet({
                createdMessage: {
                  ...createdMessage,
                  [callingId]: null,
                },
              }),
            );
          } else {
            if (isCallAccepted) {
              console.log("Send message end to receiver");

              handleSendCallingMsg({
                content: JSON.stringify(endContent),
                parentId: endMessageParent?.parentId ? endMessageParent?.parentId : endMessageParent?.sourceId,
                threadId: null,
                callStatus: SystemConstant.MESSAGE_CALL_STATUS.end,
                roomId: endContent.room_id,
              });

              dispatch(
                CallingActions.callingSet({
                  createdMessage: {
                    ...createdMessage,
                    [callingId]: null,
                  },
                }),
              );
            } else {
              console.log("Send message missed to receiver");

              handleSendCallingMsg({
                content: JSON.stringify(endContent),
                parentId: endMessageParent?.parentId ? endMessageParent?.parentId : endMessageParent?.sourceId,
                threadId: null,
                callStatus: isDisconnected
                  ? SystemConstant.MESSAGE_CALL_STATUS.end
                  : SystemConstant.MESSAGE_CALL_STATUS.missed,
                roomId: endContent.room_id,
              });

              dispatch(
                CallingActions.callingSet({
                  createdMessage: {
                    ...createdMessage,
                    [callingId]: null,
                  },
                }),
              );
            }
          }
        } else if (isGroupOrChannelType(callingType)) {
          let endContent = {
            room_id: convertString2JSON(endMessageParent?.content)?.room_id,
          };
          if (participants?.length > 1) {
            console.log("Send message waiting to group");
            handleSendCallingMsg({
              content: JSON.stringify(endContent),
              parentId: endMessageParent?.parentId ? endMessageParent?.parentId : endMessageParent?.sourceId,
              threadId: null,
              callStatus: SystemConstant.MESSAGE_CALL_STATUS.waiting,
              roomId: endContent?.room_id,
            });

            dispatch(
              CallingActions.callingSet({
                createdMessage: {
                  ...createdMessage,
                  [callingId]: null,
                },
              }),
            );
          } else if (Array.isArray(participants) && participants[0]?.id === accountId) {
            // TODO: Send message with call status "end" and send type "groupCall"
            console.log("Send message waiting/end to group", { createdMsg: createdMessage[callingId] });
            handleSendCallingMsg({
              sendType: SystemConstant.SEND_TYPE.groupCall,
              content: JSON.stringify(endContent),
              parentId: endMessageParent?.parentId ? endMessageParent?.parentId : endMessageParent?.sourceId,
              threadId: null,
              callStatus: SystemConstant.MESSAGE_CALL_STATUS.waiting,
              roomId: endContent?.room_id,
            });

            dispatch(
              CallingActions.callingSet({
                createdMessage: {
                  ...createdMessage,
                  [callingId]: null,
                },
              }),
            );
          }
        }
      }

      dispatch(CallingActions.callingReset());
      dispatch(
        ConversationActions.conversationSet({
          idGroupCallAvatar: null,
        }),
      );

      localStream?.getVideoTracks()?.forEach(track => track.stop());
      localStream?.getAudioTracks()?.forEach(track => track.stop());
    };

    // remove comment if don't use jitsi
    if (!isReceiver && isPersonal) {
      // console.log(connections);
      // connections.forEach(obj => {
      //   if (!obj.finishGatheringIce){
      //     obj.connection.forceGathering(callBackEnd)
      //     obj.connection.close();
      //   } else {
      //     callBackEnd();
      //   }
      // });
      callBackEnd();
    } else {
      connections.forEach(obj => {
        obj.connection.close();
      });
      callBackEnd();
    }
  };

  const voiceAction = () => {
    setIsMute(!isMute);
  };

  const camAction = () => {
    if (localStream) {
      if (Boolean(selectedScreenStream)) {
        onStopSharing();
        dispatch(
          CallingActions.callingSet({
            isVideoCall: true,
          }),
        );

        if (callingGroupDetail?.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
          dispatch(
            CallingActions.callingSet({
              createdMessage: {
                ...createdMessage,
                [callingGroupDetail?.id]: {
                  ...createdMessage[callingGroupDetail?.id],
                  sendType: SystemConstant.SEND_TYPE.personalVideoCall,
                },
              },
            }),
          );
        } else {
          dispatch(
            CallingActions.callingSet({
              createdMessage: {
                ...createdMessage,
                [callingGroupDetail?.id]: {
                  ...createdMessage[callingGroupDetail?.id],
                  sendType: SystemConstant.SEND_TYPE.groupVideoCall,
                },
              },
            }),
          );
        }
        setIsVideoing(true);
      } else {
        if (!isVideoCall) {
          dispatch(
            CallingActions.callingSet({
              isVideoCall: true,
            }),
          );

          if (callingGroupDetail?.groupType === SystemConstant.GROUP_CHAT_TYPE.personal) {
            dispatch(
              CallingActions.callingSet({
                createdMessage: {
                  ...createdMessage,
                  [callingGroupDetail?.id]: {
                    ...createdMessage[callingGroupDetail?.id],
                    sendType: SystemConstant.SEND_TYPE.personalVideoCall,
                  },
                },
              }),
            );
          } else {
            dispatch(
              CallingActions.callingSet({
                createdMessage: {
                  ...createdMessage,
                  [callingGroupDetail?.id]: {
                    ...createdMessage[callingGroupDetail?.id],
                    sendType: SystemConstant.SEND_TYPE.groupVideoCall,
                  },
                },
              }),
            );
          }
          setIsVideoing(true);
        } else {
          setIsVideoing(!isVideoing);
        }
      }
    }
  };

  const onScreenSharing = () => {
    setIsShow(true);
  };

  const handAction = () => {
    setHandUp(!handUp);
  };

  useEffect(() => {
    if (selectedScreenStream?.stream && !selectedScreenStream?.stream?.active) {
      onStopSharing();
    }
  }, [selectedScreenStream?.stream?.active]);

  useEffect(() => {
    setInterval(() => {
      window.capture.startCapture();
    }, 1000);
  }, [isShow]);

  useEffect(() => {
    let branch = StorageUtil.getItem(KeyConstant.KEY_SELECTED_SERVER);
    if (branch) {
      setSelectedServer(branch);
    }
  }, []);

  useEffect(() => {
    let endCallTimeout = null;
    if (!navigator.onLine && isDisconnected) {
      endCallTimeout = setTimeout(() => {
        setTimeout(() => {
          onEnd();
          dispatch(CallingActions.callingReset());
        }, 2000);
      }, AppConstant.CALL_WAITING_TIME + 15000);
    }

    return () => {
      clearTimeout(endCallTimeout);
    };
  }, [isDisconnected]);

  useEffect(() => {
    if (callInfo && callInfo.avatarId) {
      const currentUserAvatar = groupAvatar;
      const getGroupImageInterval = setInterval(() => {
        getImageSrcAvatar(
          callInfo.avatarId,
          callInfo.groupType === SystemConstant.GROUP_CHAT_TYPE.personal
            ? SystemConstant.GROUP_CHAT_TYPE.personal
            : SystemConstant.GROUP_CHAT_TYPE.group,
        ).then(src => {
          if (src !== groupAvatar) setGroupAvatar(src);
          if (currentUserAvatar !== src) clearInterval(getGroupImageInterval);
        });
      }, 500);

      return () => {
        clearInterval(getGroupImageInterval);
      };
    }
  }, [callInfo?.avatarId]);

  let isHavingVideoInput =
    localStream &&
    localStream.getVideoTracks() &&
    localStream.getVideoTracks().length > 0 &&
    Boolean(localStream.getVideoTracks().find(item => item.label !== "Screen" && item.label !== "screen"));

  return (
    <>
      <Box className={classes.root} id={CALLING_ROOT}>
        <Box className={classes.container}>
          <Box className={classes.receiveeBox}>{children}</Box>
          <TopBar />
        </Box>
        <Box className={classes.bottomActionBar}>
          {/* {isGroupOrChannelType(callInfo?.groupType) && (
            <Box className={classes.groupCallInfo}>
              <Box className={classes.groupAvatar}>
                {groupAvatar ? (
                  <Avatar className={classes.avatar} alt={callInfo?.callName} src={groupAvatar}></Avatar>
                ) : (
                  <AvatarConversation memberArray={callInfo.members} classes={classes} />
                )}
              </Box>
              <Typography className={clsx(classes.groupCallName, "ellipsis")}>{callInfo.callName}</Typography>
              {callInfo.adminId === StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID) && (
                <IconButton
                  className={classes.editGroupCallInfo}
                  onClick={() => {
                    setIsOpenEdit(true);
                  }}
                >
                  <EditOutlinedIcon />
                </IconButton>
              )}
            </Box>
          )} */}
          {/* <IconButton className={classes.actionButton}>
          <PersonAdd className={classes.iconAction} />
        </IconButton> */}
          {/* {isGroupOrChannelType(callingGroupDetail?.groupType) && (
            <>
              <Box className={!handUp ? classes.actionButton : classes.raiseHandButton}>
                <Badge
                  badgeContent={formatLimitNumber(handUpListId.length)}
                  color="primary"
                  classes={{ root: classes.numNotify, badge: "badge" }}
                >
                  <IconButton className={classes.raiseIconButton} onClick={handAction}>
                    {!handUp ? (
                      <BackHandOutlinedIcon className={classes.iconAction} />
                    ) : (
                      <BackHandOutlinedIcon className={classes.raiseAction} />
                    )}
                  </IconButton>
                </Badge>
              </Box>
              <IconButton
                className={classes.actionButton}
                onClick={onScreenSharing}
                disabled={Boolean(selectedScreenStream)}
              >
                <PresentToAll className={classes.iconAction} />
              </IconButton>
              <Box className={classes.actionButton}>
                <Badge
                  badgeContent={formatLimitNumber(remoteStreamArr?.length)}
                  color="primary"
                  classes={{ root: classes.numNotify, badge: "badge" }}
                >
                  <IconButton
                    className={classes.raiseIconButton}
                    onClick={() => setIsShowUserInCall(!isShowUserInCall)}
                  >
                    <PeopleAltIcon className={classes.iconAction} />
                  </IconButton>
                </Badge>
              </Box>
            </>
          )} */}
          {/* {isGroupOrChannelType(callingGroupDetail?.groupType) && isShow && (
            <ScreenCaptureOption onClose={() => setIsShow(false)} onSelect={setSelectedScreenStream} isShow={isShow} />
          )} */}
          {/* {!isHavingVideoInput ? (
            <Tooltip title={getLabel(LangConstant.TXT_DEVICE_NOT_FOUND)} arrow={true}>
              <IconButton className={classes.actionButton} onClick={() => {}}>
                <VideocamOff className={classes.iconAction} />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton className={classes.actionButton} onClick={camAction} disabled={!isHavingVideoInput}>
              {isVideoing && !Boolean(selectedScreenStream) ? (
                <Videocam className={classes.iconAction} />
              ) : (
                <VideocamOff className={classes.iconAction} />
              )}
            </IconButton>
          )}
          <IconButton className={classes.actionButton} onClick={voiceAction}>
            {isMute ? <MicOff className={classes.iconAction} /> : <KeyboardVoice className={classes.iconAction} />}
          </IconButton> */}
          {!isInAnotherCall && (
            <IconButton className={clsx(classes.actionButton, classes.endButton)} onClick={onEnd}>
              <CallEnd className={classes.iconAction} />
            </IconButton>
          )}
          <Box className={classes.homeServerIcon}>
            <HomeServerIcon />
            <Typography className={classes.serverName}>{selectedServer?.name}</Typography>
          </Box>
        </Box>
      </Box>
      {isOpenEdit && (
        <EditGroupCallInfo
          open={isOpenEdit}
          data={callInfo}
          onClose={() => {
            setIsOpenEdit(false);
          }}
        />
      )}

      {isShowUserInCall && <UserInCall isOpen={isShowUserInCall} onClose={() => setIsShowUserInCall(false)} />}
    </>
  );
};

CallLayout.propTypes = {
  action: PropTypes.shape({
    shareScreenClick: PropTypes.func,
    addUser: PropTypes.func,
    videoAction: PropTypes.func,
    voiceAction: PropTypes.func,
  }),
};

CallLayout.defaultProps = {
  action: {
    shareScreenClick: () => {},
    addUser: () => {},
    videoAction: () => {},
    voiceAction: () => {},
  },
};

const TopBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isOpenSettingDialog, setIsOpenSettingDialog] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const onOpenSettingDialog = () => {
    setIsOpenSettingDialog(true);
  };

  const onCloseSettingDialog = () => {
    setIsOpenSettingDialog(false);
  };

  const onHideCallDialog = () => {
    if (isFullScreen) {
      document.exitFullscreen();
    }
    dispatch(CallingActions.callingSet({ isHideCallingDialog: true }));
  };

  const requestFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    let container = document.getElementById(CALLING_ROOT);
    if (isFullScreen) {
      document.exitFullscreen();
    } else {
      container.requestFullscreen();
    }
  };

  return (
    <Box className={classes.topBar}>
      <IconButton className={classes.iconButton} onClick={onHideCallDialog}>
        <HorizontalRule className={classes.iconInButton} />
      </IconButton>

      {/* refactor UI when using jitsi meet */}
      {/* <IconButton className={classes.iconButton} onClick={requestFullScreen}>
        {isFullScreen ? (
          <CloseFullscreen className={classes.iconInButton} />
        ) : (
          <OpenInFull className={classes.iconInButton} />
        )}
      </IconButton>
      <IconButton className={classes.iconButton} onClick={onOpenSettingDialog}>
        <Settings className={classes.iconInButton} />
      </IconButton>
      <SettingDialog open={isOpenSettingDialog} onClose={onCloseSettingDialog} /> */}
    </Box>
  );
};

const CALLING_ROOT = "CALLING_ROOT";

export default CallLayout;

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },

  bottomActionBar: {
    width: "100%",
    height: 88,
    backgroundColor: "#1F1F1F",
    bottom: 0,
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },

  actionButton: {
    backgroundColor: "#3A3A3A",
    width: 48,
    height: 48,
    borderRadius: "50%",
    margin: "0 27px",
    "&:hover": {
      backgroundColor: convertHex2rgba("#3A3A3A", 0.7),
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  iconAction: {
    color: "white",
    fontSize: 22,
  },

  endButton: {
    backgroundColor: "#EB544B",
  },

  container: {
    height: "100%",
    width: "100%",
    padding: 24,
    position: "relative",
    backgroundColor: "black",
  },

  topBar: {
    display: "flex",
    justifyContent: "end",
  },

  iconButton: {
    width: 36,
    height: 36,
    padding: 9,
    backgroundColor: "black",
    marginLeft: 12,
    "&:hover": {
      backgroundColor: convertHex2rgba("#000000", 0.8),
    },
  },

  iconInButton: {
    height: 18,
    width: 18,
    color: "white",
  },

  receiveeBox: {
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bottom: 88,
  },

  homeServerIcon: {
    position: "absolute",
    right: 30,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  serverName: {
    color: "white",
    fontSize: 10,
    fontWeight: 400,
    marginTop: 6,
  },

  groupCallInfo: {
    position: "absolute",
    left: 30,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    maxWidth: "300px",
  },

  groupCallName: {
    color: "white",
    fontSize: 16,
    fontWeight: 400,
    margin: "0px 8px",
  },

  avatar: {
    width: "100%",
    height: "100%",
  },
  groupAvatar: {
    width: "50px",
    height: "50px",
    minWidth: "50px",
  },
  editGroupCallInfo: {
    color: "white",
  },
  numNotify: {
    "& .badge": {
      top: 4,
      right: -5,
      backgroundColor: "#89898A",
      border: "1px solid white",
    },
  },

  raiseIconButton: {
    alignContent: "center",
  },

  raiseHandButton: {
    backgroundColor: "#FFFFFF",
    width: 48,
    height: 48,
    borderRadius: "50%",
    margin: "0 27px",
    "&:hover": {
      backgroundColor: convertHex2rgba("#FFFFFF", 0.7),
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  raiseAction: {
    color: "black",
  },
}));
