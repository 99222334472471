import {
  Avatar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useEffect, useState } from "react";
import { getImageSrcAvatar, toCamel } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { getSavedServer } from "utils/common.utils";
import CallingAction from "redux-store/calling.redux";
import VideocamIcon from "@mui/icons-material/Videocam";
import PropTypes from "prop-types";
import { HandleUploadingInput } from "components";
import { ConversationActions } from "redux-store";
import { StorageUtil } from "utils";

const repository = window.repository;

const InitGroupCallPopup = ({ open, onClose, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_CALLING);
  const idGroupCallAvatar = useSelector(state => state.conversationRedux.idGroupCallAvatar);
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);

  const [groupCallName, setGroupCallName] = useState("");
  const [userAvatar, setUserAvatar] = useState("");
  const [avatarId, setAvatarId] = useState("");

  const onChangeGroupCallName = event => {
    setGroupCallName(event.target.value);
  };

  const onDeleteGroupCallName = () => {
    setGroupCallName("");
  };

  const onCloseInitPopup = () => {
    dispatch(
      ConversationActions.conversationSet({
        idGroupCallAvatar: null,
      }),
    );
    onClose();
  };

  const onGroupCall = () => {
    const dataCall = {
      ...data,
      avatarId: avatarId,
      callName: groupCallName,
      listMemberChoose: data.members?.filter(item => item.id !== accountId).map(item => item.id),
      adminId: accountId,
    };
    onSendEndLastCall();
    dispatch(
      CallingAction.callingSet({
        isOpenCallingDialog: AppConstant.CALLING_STATUS.checking,
        callingGroupDetail: dataCall,
        isReceiver: false,
      }),
    );

    dispatch(CallingAction.onCallCheck({ accountId: accountId }));
    onClose();
  };

  const onSendEndLastCall = () => {
    // If current user with current device is in another call, send end message to that group
    const lastCall = toCamel(repository.callHistory.getByGroupId(data.id)[0]);

    const savedServer = getSavedServer();

    const callMessage = toCamel(repository.message.getMessageWithSourceId(lastCall.sourceId));

    if (
      lastCall &&
      lastCall.status === SystemConstant.CALL_HISTORY_STATUS.calling &&
      JSON.parse(lastCall.callingMembers).includes(deviceId)
    ) {
      let endContent = JSON.stringify({
        room_id: lastCall.roomId,
      });

      const memberIdArray = data.members.map(member => member.id) || [];

      const deviceList = repository.device.findByList("account_id", memberIdArray);

      dispatch(
        ConversationActions.sendMessage({
          groupDetail: data,
          sendType: callMessage?.sendType,
          content: endContent,
          parentId: callMessage.parentId,
          deviceList: [...deviceList],
          branchId: savedServer?.id,
          mentionIdsArr: [],
          threadId: null,
          callStatus: SystemConstant.MESSAGE_CALL_STATUS.waiting,
          roomId: lastCall.roomId,
          isReceiver: true,
        }),
      );
    }
  };

  const onFileChange = event => {
    const file = event.target.files[0];
    dispatch(ConversationActions.requestUploadImageCall({ upload: file, initCall: true }));
  };

  // get call info
  useEffect(() => {
    setAvatarId(data.avatarId);
    setGroupCallName(data.name);
    if (data.avatarId) {
      getImageSrcAvatar(data.avatarId, SystemConstant.ATTACHMENT_TYPE.account).then(src => {
        setUserAvatar(src);
      });
    }
  }, []);

  useEffect(() => {
    if (Boolean(idGroupCallAvatar)) {
      setAvatarId(idGroupCallAvatar);
      const currentUserAvatar = userAvatar;
      const getImageInterval = setInterval(() => {
        getImageSrcAvatar(idGroupCallAvatar, SystemConstant.ATTACHMENT_TYPE.account).then(src => {
          setUserAvatar(src);
          if (currentUserAvatar !== src) clearInterval(getImageInterval);
        });
      }, 1000);

      return () => {
        clearInterval(getImageInterval);
      };
    }
  }, [idGroupCallAvatar]);

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.wrapDialogGroup }}
      onClose={onCloseInitPopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.popupTitle}>{getLabel(LangConstant.TXT_GROUP_CALL_INIT)}</DialogTitle>
      <DialogContent>
        <Box className={classes.avatarAndName}>
          <Box className={classes.avatarGroupCall}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <IconButton classes={{ root: classes.iconBtn }}>
                  <HandleUploadingInput
                    accept={AppConstant.ACCEPTABLE_IMAGE_TYPES}
                    className="hidden"
                    id="upload_photo_id"
                    type="file"
                    onChange={onFileChange}
                  />
                  <label htmlFor="upload_photo_id" className={classes.styleButton}>
                    <EditOutlinedIcon fontSize="small" className={classes.chooseAvatar} />
                  </label>
                </IconButton>
              }
            >
              {userAvatar ? (
                <Avatar className={classes.avatar} alt={data?.name} src={userAvatar}></Avatar>
              ) : (
                <Box className={classes.defaultAvatar}>
                  <VideocamIcon fontSize="large" color="primary" />
                </Box>
              )}
            </Badge>
          </Box>
          <FormControl className={classes.groupCallName} variant="outlined">
            <Input
              type="text"
              value={groupCallName}
              inputProps={{ maxLength: SystemConstant.MAX_NAME_LENGTH }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={onDeleteGroupCallName} edge="end">
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
              onChange={onChangeGroupCallName}
            />
          </FormControl>
        </Box>
        <Button className={classes.buttonCall} onClick={onGroupCall} disabled={!Boolean(groupCallName)}>
          {getLabel(LangConstant.TXT_CALL)}
        </Button>
        <Button className={classes.buttonCancel} onClick={onCloseInitPopup}>
          {getLabel(LangConstant.TXT_CANCEL_CALL)}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

InitGroupCallPopup.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,

  onClose: PropTypes.func,
};

InitGroupCallPopup.defaultProps = {
  open: false,
  data: {},

  onClose: () => {},
};

export default InitGroupCallPopup;

const useStyles = makeStyles(() => ({
  wrapDialogGroup: {
    textAlign: "center",
    borderRadius: 20,
    padding: "30px 20px",
    minWidth: "500px",
  },
  popupTitle: {
    fontSize: 24,
    fontWeight: 700,
  },
  avatarAndName: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  groupCallName: {
    width: "70%",
  },

  buttonCall: {
    width: "100%",
    padding: "10px 0px",
    marginTop: "10px",
    color: "#ffffff",
    backgroundColor: "#008FE8",
    "&:hover": {
      backgroundColor: "#72B9E6",
    },
  },

  buttonCancel: {
    width: "100%",
    padding: "10px 0px",
    marginTop: "10px",
  },

  chooseAvatar: {
    color: "#dbd0d0",
  },

  avatarGroupCall: {
    display: "grid",
    position: "relative",
    width: 80,
    height: 80,
  },

  avatar: {
    width: "100%",
    height: "100%",
  },

  defaultAvatar: {
    width: 80,
    height: 80,
    borderRadius: "50%",
    border: "solid 1px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#008FE8",
  },

  iconBtn: {
    width: 28,
    height: 28,
  },
}));
