import { getLabel } from "language";
import { FormatConstant, LangConstant } from "const";
import { format as DateFormat, getDay, isThisWeek, isToday } from "date-fns";
import StringFormat from "string-format";
import { getCommonKey } from "./lang.utils";

export const convertTimeStampToDate = (timeStamp, formatConstant) => {
  let resultDate;
  if (formatConstant) {
    resultDate = DateFormat(new Date(timeStamp * 1000), formatConstant);
  } else {
    resultDate = new Date(timeStamp * 1000);
  }
  return resultDate;
};

export const getTimestamp = date => {
  let tmpDate = new Date();
  if (date) tmpDate = new Date(date);
  return Math.floor(tmpDate.getTime() / 1000);
};

export const formatSentTime = (
  miliSec,
  dayArray = getLabel(LangConstant.ARR_DAY_OF_WEEK, {
    returnObjects: true,
  }),
) => {
  if (!miliSec) return "";
  let timeStamp = miliSec / 1000;

  let dateObject = convertTimeStampToDate(timeStamp);
  let result = "";
  if (isToday(dateObject)) result = convertTimeStampToDate(timeStamp, FormatConstant.FM_HH_MM);
  else if (!isToday(dateObject) && isThisWeek(dateObject)) {
    result = dayArray[getDay(convertTimeStampToDate(timeStamp)) - 1];
  } else if (!isThisWeek(dateObject)) result = convertTimeStampToDate(timeStamp, FormatConstant.FM_DD_MM);

  return result;
};

export const getFuzzyDeltatime = (beginTime, endTime, getLabel) => {
  let deltatime = endTime - beginTime;
  let origDeltatime = deltatime;
  let seconds = deltatime % 60;
  deltatime = (deltatime / 60) >> 0;
  let minutes = deltatime % 60;
  deltatime = (deltatime / 60) >> 0;
  let hours = deltatime % 24;
  deltatime = (deltatime / 24) >> 0;
  let days = deltatime;

  switch (true) {
    case origDeltatime <= 0:
      return "";
    case origDeltatime < 60:
      return StringFormat(
        FormatConstant.FM_FUZZY_TIME,
        seconds.toString(),
        getLabel(getCommonKey(LangConstant.TXT_SECOND)),
      );
    case origDeltatime < 3600:
      return StringFormat(
        FormatConstant.FM_FUZZY_TIME,
        minutes.toString(),
        getLabel(getCommonKey(LangConstant.TXT_MINUTE)),
      );
    case origDeltatime < 3600 * 24:
      return StringFormat(
        FormatConstant.FM_FUZZY_TIME,
        hours.toString(),
        getLabel(getCommonKey(LangConstant.TXT_HOUR)),
      );
    default:
      return StringFormat(FormatConstant.FM_FUZZY_TIME, days.toString(), getLabel(getCommonKey(LangConstant.TXT_DAY)));
  }
};

export const convertMillisecondsToDate = (timeStamp, formatConstant) => {
  let resultDate;
  if (formatConstant) {
    resultDate = DateFormat(new Date(timeStamp), formatConstant);
  } else {
    resultDate = new Date(timeStamp);
  }
  return resultDate;
};

export const formatBirthday = date => {
  let dateNew = date.replaceAll("/", "-");
  const [day, month, year] = dateNew.split("-");
  return '"' + year + "-" + day + "-" + month + '"';
};

/**
 * Convert milliseconds to hours, minutes and seconds
 * @param {Number} milliseconds
 * @param {Boolean} isShowPrefix
 * @return {Object} { hours: Number, minutes: Number, seconds: Number }
 */
export const convertMillisecondsToHMS = (milliseconds, isShowPrefix = false) => {
  let result = { hours: 0, minutes: 0, seconds: 0 };

  if (milliseconds && typeof milliseconds === "number") {
    let seconds = Math.ceil(milliseconds / 1000);

    if (seconds < 60) {
      let hours = isShowPrefix ? "00" : 0;
      let minutes = isShowPrefix ? "00" : 0;
      let newSeconds = isShowPrefix && seconds < 10 ? "0" + seconds : seconds;
      result = { hours: hours, minutes: minutes, seconds: newSeconds };
    } else if (seconds < 3600) {
      let hours = isShowPrefix ? "00" : 0;
      let handleMinutes = Math.floor(seconds / 60);
      let handleSeconds = Math.floor(seconds % 60);
      let minutes = isShowPrefix && handleMinutes < 10 ? "0" + handleMinutes : handleMinutes;
      let newSeconds = isShowPrefix && handleSeconds < 10 ? "0" + handleSeconds : handleSeconds;
      result = { hours: hours, minutes: minutes, seconds: newSeconds };
    } else {
      let handleHours = Math.floor(seconds / 3600);
      let handleMinutes = Math.floor((seconds % 3600) / 60);
      let handleSeconds = Math.floor((seconds % 3600) % 60);
      let hours = isShowPrefix && handleHours < 10 ? "0" + handleHours : handleHours;
      let minutes = isShowPrefix && handleMinutes < 10 ? "0" + handleMinutes : handleMinutes;
      let newSeconds = isShowPrefix && handleSeconds < 10 ? "0" + handleSeconds : handleSeconds;
      result = { hours: hours, minutes: minutes, seconds: newSeconds };
    }
  } else {
    console.error("convertMillisecondsToHMS: got error with milliseconds.", milliseconds);
  }

  return result;
};

export const convertMillisecondsToString = milliseconds => {
  if (milliseconds === 0) {
    return "00:00";
  }

  let timeObject = convertMillisecondsToHMS(milliseconds, true);

  return parseInt(timeObject.hours) > 0
    ? StringFormat(FormatConstant.FM_CALL_HMS, timeObject)
    : StringFormat(FormatConstant.FM_CALL_MS, timeObject);
};
