import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { onGetScreenSource } from "utils/call.utils";
import { makeStyles } from "@mui/styles";
import { ArrowBack, Close, Tv } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { compareObject, getMaxObjectWithKey } from "utils";
import clsx from "clsx";
import { ShareWindow } from "icons";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import { getCommonKey } from "utils/lang.utils";
import { CallingContext } from "pages/Call";

const ScreenCaptureOption = ({ onSelect: onSelectSource, onClose, isShow }) => {
  const classes = useStyles();
  const { sharingList } = useContext(CallingContext);

  const callingGroupDetail = useSelector(state => state.callingRedux.callingGroupDetail);
  const isSynchronizing = useSelector(state => state.conversationRedux.isSynchronizing);
  const { t: getLabel } = useTranslation(LangConstant.NS_CALLING);

  let tmpInterval = null;
  let getInterval = null;

  const [currentStep, setCurrentStep] = useState(step.captureType);
  const [captureMode, setCaptureMode] = useState(null);
  const [modeList, setModeList] = useState([]);
  const [currentSources, setCurrentSources] = useState(PUPPET_PREVIEWS);
  const [selectedScreen, setSelectedScreen] = useState(null);
  const [windows, setWindows] = useState([]);
  const [screens, setScreens] = useState([]);

  const onSelectMode = mode => {
    setCaptureMode(mode);
    setCurrentStep(step.stream);
  };

  const onSelect = item => {
    if (item.id !== selectedScreen?.id) {
      setSelectedScreen(item);
    } else {
      setSelectedScreen(null);
    }
  };

  const onGoBack = () => {
    setModeList([]);
    setCurrentStep(step.captureType);
    setCaptureMode(null);
  };

  const onSelectStream = () => {
    onSelectSource(selectedScreen);
    onClose();
  };

  useEffect(() => {
    let timeout = null;
    getInterval = setInterval(() => {
      window.capture.retrieve(async sources => {
        if (!compareObject(sources, currentSources)) {
          let arr = [];
          let arr2 = [];

          sources.windows.forEach(async item => {
            let currentStream = await onGetScreenSource(item.id);

            arr.push({
              id: item.id,
              stream: currentStream,
            });
          });

          sources.screens.forEach(async item => {
            let currentStream = await onGetScreenSource(item.id);

            arr2.push({
              id: item.id,
              stream: currentStream,
            });
          });

          setCurrentSources(sources);

          timeout = setTimeout(() => {
            if (arr.length > 0 && arr.length !== windows.length) {
              setWindows(arr);
            } else {
              for (let index = 0; index < arr.length; index++) {
                const item = arr[index];
                if (!Boolean(windows.find(item2 => item2.id === item.id))) {
                  setWindows(arr);
                  break;
                }
              }
            }

            if (arr2.length > 0 && arr2.length !== screens.length) {
              setScreens(arr2);
            } else {
              for (let index = 0; index < arr2.length; index++) {
                const item = arr2[index];
                if (!Boolean(screens.find(item2 => item2.id === item.id))) {
                  setScreens(arr2);
                  break;
                }
              }
            }
          }, 5000);
        }
      });
    }, 4000);
    return () => {
      clearInterval(getInterval);
      clearTimeout(timeout);
    };
  }, [isShow]);

  useEffect(() => {
    let arr = captureMode === mode.window ? windows : screens;

    if (arr.length > 0 && arr.length !== modeList.length) {
      setModeList(arr);
    } else {
      for (let index = 0; index < arr.length; index++) {
        const item = arr[index];
        if (!Boolean(modeList.find(item2 => item2.id === item.id))) {
          setModeList(arr);
          break;
        }
      }
    }
  }, [captureMode, windows, screens]);

  useEffect(() => {
    if (!tmpInterval) {
      tmpInterval = setInterval(() => {
        let item = modeList.find(item2 => !Boolean(item2.stream.active));

        if (item) {
          let gridItem = document.getElementById(`${item.id}-grid-item`);
          if (gridItem) {
            gridItem.setAttribute("display", "none");
          }
        }
      }, 3000);
    }

    if (modeList.length > 0) {
      modeList.forEach(item => {
        let video = document.getElementById(`${item.id}-video-capture`);
        if (video) {
          video.srcObject = item.stream;
        }
      });
    }

    return () => {
      clearInterval(tmpInterval);
    };
  }, [modeList, currentStep, captureMode]);

  useEffect(() => {
    if (currentStep === step.confirm && selectedScreen) {
      let videoPreview = document.getElementById("video-capture-confirm-preview");
      if (videoPreview) {
        videoPreview.srcObject = selectedScreen.stream;
      }
    }
  }, [currentStep, selectedScreen]);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      classes={{
        root: isSynchronizing ? "hidden" : "",
        paper: classes.capturePaper,
      }}
      maxWidth="md"
    >
      <DialogTitle className={classes.dialogTitle}>
        {currentStep !== step.captureType ? (
          <IconButton className={classes.backArrow} onClick={onGoBack}>
            <ArrowBack />
          </IconButton>
        ) : (
          <Box />
        )}
        <Typography className={clsx(classes.sharingTitle, "bold-xl-txt")}>
          {getLabel(currentStep === step.confirm ? LangConstant.TXT_CONFIRM_TITLE : LangConstant.TXT_SHARING_TITLE)}
        </Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={currentStep === step.captureType ? classes.dialogContent : ""}>
        {currentStep === step.confirm && (
          <Typography className={clsx(classes.confirmMessage, "regular-md-txt")}>
            {getLabel(LangConstant.FM_CONFIRM_SHARE_SCREEN, {
              name: callingGroupDetail.members.find(item => item.id === getMaxObjectWithKey(sharingList).accountId)
                ?.name,
            })}
          </Typography>
        )}
        {currentStep === step.captureType ? (
          <ShareMode onSelectMode={onSelectMode} />
        ) : (
          <Grid container className={classes.gridContainer}>
            {currentStep === step.confirm ? (
              <Grid item xs={12} className={classes.gridCaptureItem}>
                <Box className={clsx(classes.thumbnailContainer, classes.screenSelected)}>
                  <video id={"video-capture-confirm-preview"} autoPlay className={classes.screenRecordThumbnail} />
                </Box>
              </Grid>
            ) : modeList.length > 0 ? (
              modeList.map(item => (
                <Grid
                  item
                  xs={modeList.length > 1 ? 6 : 12}
                  key={item.id}
                  id={`${item.id}-grid-item`}
                  className={classes.gridCaptureItem}
                  onClick={() => {
                    onSelect(item);
                  }}
                >
                  <Box
                    className={clsx(
                      classes.thumbnailContainer,
                      item.id === selectedScreen?.id && classes.screenSelected,
                    )}
                  >
                    <video id={`${item.id}-video-capture`} autoPlay className={classes.screenRecordThumbnail} />
                  </Box>
                </Grid>
              ))
            ) : (
              onGetCurrentArr(captureMode, currentSources).map(item => (
                <Grid
                  item
                  xs={onGetCurrentArr(captureMode, currentSources).length > 1 ? 6 : 12}
                  key={item.id}
                  className={classes.gridCaptureItem}
                >
                  <Box className={clsx(classes.tmpPreview, classes.thumbnailContainer)}>
                    <CircularProgress color="inherit" />
                  </Box>
                </Grid>
              ))
            )}
          </Grid>
        )}
      </DialogContent>
      {currentStep !== step.captureType && (
        <DialogActions>
          <Box className={classes.actionGroup}>
            <Button
              variant="contained"
              color="primary"
              className={classes.actionButton}
              onClick={() => {
                sharingList?.length > 0
                  ? currentStep === step.confirm
                    ? onSelectStream()
                    : setCurrentStep(step.confirm)
                  : onSelectStream();
              }}
              disabled={!Boolean(selectedScreen)}
            >
              {getLabel(LangConstant.TXT_START_CAPTURING)}
            </Button>
            <Button variant="contained" className={clsx(classes.actionButton, classes.cancelButton)} onClick={onClose}>
              {getLabel(getCommonKey(LangConstant.TXT_CANCEL))}
            </Button>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};

const ShareMode = ({ onSelectMode }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_CALLING);

  return (
    <>
      <Typography className={clsx(classes.shareAwareMessage, "regular-md-txt")}>
        {getLabel(LangConstant.TXT_SHARE_SCREEN_MESSAGE)}
      </Typography>
      <Box className={classes.sharingOptionContainer}>
        <Box
          className={classes.captureModeItem}
          onClick={() => {
            onSelectMode(mode.screen);
          }}
        >
          <Box className={classes.shareIconContainer}>
            <Tv className={classes.captureModeIcon} />
          </Box>
          <Typography className={classes.optionTitle}>{getLabel(LangConstant.TXT_SHARE_SCREEN)}</Typography>
        </Box>
        <Box
          className={classes.captureModeItem}
          onClick={() => {
            onSelectMode(mode.window);
          }}
        >
          <Box className={classes.shareIconContainer}>
            <ShareWindow className={classes.captureModeIcon} width={120} height={80} />
          </Box>
          <Typography className={classes.optionTitle}>{getLabel(LangConstant.TXT_SHARE_WINDOW)}</Typography>
        </Box>
      </Box>
    </>
  );
};

ScreenCaptureOption.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  onInactiveThisStream: PropTypes.func,
};

ScreenCaptureOption.defaultProps = {
  onInactiveThisStream: () => {},
};

const step = {
  captureType: 0,
  stream: 1,
  confirm: 2,
};

const mode = {
  screen: 1,
  window: 2,
};

const PUPPET_PREVIEWS = {
  screens: [
    {
      id: "puppet_id_1",
      stream: null,
    },
  ],
  windows: [
    {
      id: "puppet_id_2",
      stream: null,
    },
    {
      id: "puppet_id_3",
      stream: null,
    },
  ],
};

const onGetCurrentArr = (currentMode, obj) => {
  return currentMode === mode.screen ? obj.screens : obj.windows;
};

export default ScreenCaptureOption;

const useStyles = makeStyles(theme => ({
  capturePaper: {
    padding: 10,
    paddingBottom: 20,
    maxHeight: 600,
    overflow: "hidden",
    zIndex: theme.zIndex.modal,
    borderRadius: 20,
    backgroundColor: "#242526",
    minWidth: 500,
  },

  gridContainer: {
    maxWidth: "100%",
    maxHeight: "100%",
    overflow: "auto",
  },

  sharingOptionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&>:first-child": {
      marginRight: 10,
    },
  },

  gridCaptureItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: 16,
  },

  captureModeItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },

  shareIconContainer: {
    padding: "32px 85px",
    marginBottom: 12,
    backgroundColor: theme.palette.grey[500],
    borderRadius: 10,
  },

  captureModeIcon: {
    width: 120,
    height: 80,
    color: "white",
  },

  dialogTitle: {
    display: "flex",
    padding: "0 26px",
    justifyContent: "space-between",
  },

  sharingTitle: {
    color: "white",
  },

  backArrow: {
    backgroundColor: theme.palette.grey[800],
    "&>svg": {
      color: "white",
      fontSize: 20,
    },
  },

  closeButton: {
    backgroundColor: theme.palette.grey[800],
    "&>svg": {
      color: "white",
      fontSize: 20,
    },
  },

  thumbnailContainer: {
    width: 350,
    aspectRatio: "16/9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "gray",
    borderRadius: 8,
  },

  screenRecordThumbnail: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "100%",
    objectFit: "contain",
    maxHeight: 200,
    minWidth: "50%",
    height: 163,
    width: 291,
  },

  screenSelected: {
    border: "5px solid #008FE8",
    borderRadius: 12,
  },

  actionGroup: {
    width: "100%",
  },

  actionButton: {
    color: "white !important",
    textTransform: "none",
    width: "calc(50% - 10px)",
  },

  cancelButton: {
    color: "white",
    backgroundColor: theme.palette.grey[600],
    marginLeft: 20,
    "&:hover": {
      opacity: 0.8,
      backgroundColor: theme.palette.grey[600],
    },
  },

  shareAwareMessage: {
    color: "white",
    lineHeight: "19px",
    margin: "20px 0",
    textAlign: "center",
  },

  dialogContent: {
    width: "min-content",
  },

  optionTitle: {
    color: "white",
  },

  confirmMessage: {
    textAlign: "center",
    marginTop: 20,
    marginBottom: 20,
    color: "white",
  },

  tmpPreview: {
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
