import { useCallback, useEffect, useState } from "react";
import { AppConstant, LangConstant, SystemConstant } from "const";
import { isJSONString, toCamel } from "utils";
import { orderBy, debounce } from "lodash";
import useServerMode from "./useServerMode";
import { getLabel } from "language";
import { getNSKey } from "utils/lang.utils";
import { LocalGroupService, LocalMessageService } from "services/local.service";
import { useSelector } from "react-redux";

const useConversationList = () => {
  const { selectedBranch } = useServerMode();
  const isBranchServer = selectedBranch.type === SystemConstant.SERVER_TYPE.branch;
  const isSynchronizing = useSelector(state => state.conversationRedux.isSynchronizing);

  const [groupList, setGroupList] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [personalMsgList, setPersonalMsgList] = useState([]);

  const getAllConversationByName = async (name = "", groupType) => {
    name = name ? name.trim() : "";

    const conversationList = await getConversationByName(name, groupType);

    switch (groupType) {
      case SystemConstant.GROUP_CHAT_TYPE.channel:
        setChannelList(conversationList);
        break;
      case SystemConstant.GROUP_CHAT_TYPE.group:
        setGroupList(conversationList);
        break;
      case SystemConstant.GROUP_CHAT_TYPE.personal:
        setPersonalMsgList(conversationList);
        break;

      default:
        break;
    }
  };

  const getChannelConversation = (searchingName, option = {}) =>
    getAllConversationByName(searchingName, SystemConstant.GROUP_CHAT_TYPE.channel, option);

  const getGroupConversation = (searchingName, option = {}) =>
    getAllConversationByName(searchingName, SystemConstant.GROUP_CHAT_TYPE.group, option);

  const getPersonalConversation = (searchingName, option = {}) =>
    getAllConversationByName(searchingName, SystemConstant.GROUP_CHAT_TYPE.personal, option);

  useEffect(() => {
    if (isBranchServer) {
      getChannelConversation();
    } else {
      setChannelList([]);
    }

    getGroupConversation();
    getPersonalConversation();
  }, [selectedBranch, isSynchronizing]);

  return {
    channelList,
    groupList,
    personalMsgList,

    getChannelConversation,
    getGroupConversation,
    getPersonalConversation,
  };
};

export default useConversationList;

const getConversationByName = async (name, group_type) => {
  const NO_NAME = getLabel(getNSKey(LangConstant.NS_HOME_CONVERSATION, LangConstant.TXT_NO_NAME));
  const conversationList = await LocalGroupService.searchGroupByCondition(name, { group_type });

  const newGroupList = toCamel(conversationList).map(groupItem => {
    let groupName;
    if (isJSONString(groupItem.name)) {
      const nameJson = JSON.parse(groupItem.name);
      groupName = nameJson.name;
    }

    groupItem.lastActionDate =
      LocalMessageService.getLastByGroupId(groupItem.id)?.created || groupItem?.modified || groupItem?.created || 0;

    groupItem.name = groupItem.handleName || groupName || NO_NAME;

    return groupItem;
  });

  const sortGroupList = orderBy(newGroupList, [item => item.lastActionDate], ["desc"]);

  return sortGroupList;
};
