/**
 * Export an instance of api client
 */

import { ApiConstant, AppConstant, FormatConstant, KeyConstant, LangConstant } from "const";
import apisauce from "apisauce";
import QueryString from "qs";
import { handlingLogin, handlingLogout, toCamel } from "utils";
import { LocalBranchService } from "services/local.service";
import { getSavedServer } from "utils/common.utils";
import StringFormat from "string-format";
import { getLabel } from "language";
import { StorageUtil } from "utils";

export const API_CONFIG = {
  baseURL: ApiConstant.BASE_URL,
  headers: {
    ...ApiConstant.HEADER_DEFAULT,
    Authorization: "Bearer " + StorageUtil.getItem(KeyConstant.KEY_TOKEN),
    "trios-access": StorageUtil.getItem(KeyConstant.KEY_TRIOS_ACCESS),
  },
  timeout: ApiConstant.TIMEOUT,
};

const GlobalApi = apisauce.create(API_CONFIG);
const BranchApi = apisauce.create(API_CONFIG);

const handleResponse = async response => {
  if (response.status === ApiConstant.STT_UNAUTHORIZED && response.config.url !== ApiConstant.POST_REFRESH_TOKEN) {
    const newResponse = await handleRefreshToken(response);
    response = { ...newResponse };
  }
};
GlobalApi.addAsyncResponseTransform(handleResponse);
BranchApi.addAsyncResponseTransform(handleResponse);

const handleRefreshToken = async (response, retry = 1) => {
  console.log("handleRefreshToken", retry);
  // Logout if more than number retry refresh token
  if (retry > 3) {
    window.dispatchEvent(
      new CustomEvent(AppConstant.NOTICE_EVENT_NAME, {
        detail: {
          content: getLabel(LangConstant.TXT_SESSION_EXPIRED),
          callback: handlingLogout,
        },
      }),
    );
    return response;
  }

  const access_token = StorageUtil.getItem(KeyConstant.KEY_TOKEN);
  const refresh_token = StorageUtil.getItem(KeyConstant.KEY_REFRESH_TOKEN);

  const refreshResponse = await GlobalApi.post(
    ApiConstant.POST_REFRESH_TOKEN,
    QueryString.stringify({ access_token, refresh_token }),
  );

  let retryResponse = { ...response };
  if (refreshResponse.status === ApiConstant.STT_OK) {
    const responseData = toCamel(refreshResponse);
    handlingLogin(responseData.data);
    setApiHeader({ [AppConstant.KEY_TOKEN]: responseData.accessToken });

    const newApiConfig = { ...response.config };
    retryResponse = await GlobalApi.any(newApiConfig); // Retry calling API after refresh token
  } else if (
    retryResponse.status !== ApiConstant.STT_MAINTAIN_1 ||
    retryResponse.status !== ApiConstant.STT_MAINTAIN_2 ||
    retryResponse.status !== ApiConstant.STT_MAINTAIN_3
  ) {
    retryResponse = await handleRefreshToken(response, retry + 1);
  } else {
    handlingLogout();
  }

  return retryResponse;
};

const handleRequest = async request => {
  // Do something before calling API
};
GlobalApi.addAsyncRequestTransform(handleRequest);
BranchApi.addAsyncRequestTransform(handleRequest);

export const setApiHeader = (headers = {}) => {
  const keyTriosAccess = headers[KeyConstant.KEY_TRIOS_ACCESS] || StorageUtil.getItem(KeyConstant.KEY_TRIOS_ACCESS);
  const newToken = headers[KeyConstant.KEY_TOKEN] || StorageUtil.getItem(KeyConstant.KEY_TOKEN);

  if (newToken) {
    const headerToken = `Bearer ${newToken}`;
    GlobalApi.setHeader("Authorization", headerToken);
    BranchApi.setHeader("Authorization", headerToken);
  }

  if (keyTriosAccess) {
    GlobalApi.setHeader(KeyConstant.KEY_TRIOS_ACCESS, keyTriosAccess);
    BranchApi.setHeader(KeyConstant.KEY_TRIOS_ACCESS, keyTriosAccess);
  }
};

export const createApiWithBranch = branchId => {
  let domain;
  if (branchId) {
    const brand = LocalBranchService.get(branchId);
    if (brand && brand.domain) domain = brand.domain;
    else console.warn("NOT FOUND BRANCH _ " + branchId);
  } else {
    const savedServer = getSavedServer();
    domain = savedServer?.domain;
  }

  const baseUrl = domain ? StringFormat(FormatConstant.FM_BASE_URL, domain) : ApiConstant.BASE_URL;

  BranchApi.setBaseURL(baseUrl);
  return BranchApi;
};

export default GlobalApi;
