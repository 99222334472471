import { AppConstant } from "const";
import { useDispatch } from "react-redux";
import { ConversationActions, SystemActions } from "redux-store";

const useSynchronize = () => {
  const dispatch = useDispatch();

  const synchronize = () => {
    dispatch(SystemActions.synchronizeData());
    dispatch(ConversationActions.fetchLastMessage({ isFetchFromSynchronizing: true }));
  };

  // Only be used in Home page
  const synchronizeWhenOpenApp = () => {
    synchronize();
    dispatch(SystemActions.periodicSynchronize());

    const synchPeriodInterval = setInterval(() => {
      if (navigator.onLine) {
        dispatch(SystemActions.periodicSynchronize());
      }
    }, AppConstant.SYNCHRONIZE_PERIOD_TIME);

    return synchPeriodInterval;
  };

  return { synchronize, synchronizeWhenOpenApp };
};

export default useSynchronize;
