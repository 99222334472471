import React, { memo, useEffect, useState } from "react";
import { Avatar, Box, Card, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AccountStatus } from "components";
import { SystemConstant } from "const";
import { getImageSrcAvatar } from "utils";
import { useSelector } from "react-redux";

const AccountCoverView = ({ status, accountDetail }) => {
  const classes = useStyles();

  const changingAvatarId = useSelector(state => state.profileRedux.changingAvatarId);
  const changingBackgroundId = useSelector(state => state.profileRedux.changingBackgroundId);
  const isDeletingAvatar = useSelector(state => state.profileRedux.isDeletingAvatar);

  const [avatar, setAvatar] = useState(null);
  const [background, setBackGround] = useState(null);

  useEffect(() => {
    if (isDeletingAvatar) {
      setAvatar("");
    }
  }, [isDeletingAvatar]);

  useEffect(() => {
    if (changingAvatarId) {
      let getAvatar = function () {
        getImageSrcAvatar(changingAvatarId, SystemConstant.ATTACHMENT_TYPE.account).then(res => {
          if (!isDeletingAvatar) {
            if (res) {
              setAvatar(res);
              return;
            } else {
              getAvatar();
            }
          }
        });
      };
      getAvatar();
    }
  }, [changingAvatarId]);

  useEffect(() => {
    if (accountDetail) {
      if (accountDetail.avatarId && !(changingAvatarId && changingAvatarId !== accountDetail.avatarId)) {
        getImageSrcAvatar(accountDetail.avatarId, SystemConstant.ATTACHMENT_TYPE.account).then(src => {
          if (src !== avatar && !isDeletingAvatar) {
            setAvatar(src);
          }
        });
      } else {
        setAvatar("");
      }
    }
  }, [accountDetail]);

  useEffect(() => {
    if (accountDetail) {
      if (accountDetail.backgroundId) {
        getImageSrcAvatar(
          changingBackgroundId ? changingBackgroundId : accountDetail.backgroundId,
          SystemConstant.ATTACHMENT_TYPE.account,
        ).then(src => {
          if (src !== background) {
            setBackGround(src);
          }
        });
      } else {
        setBackGround("");
      }
    }
  }, [accountDetail, changingBackgroundId]);

  return (
    <Box className={classes.root}>
      <Card className={classes.coverBox} elevation={0}>
        {background ? (
          <CardMedia
            component="img"
            alt=""
            className={accountDetail?.backgroundId ? classes.cover : classes.coverDefault}
            src={background}
          />
        ) : (
          <Box className={classes.coverDefault} />
        )}
        <Box className={classes.avatarBox}>
          <Avatar className={accountDetail?.avatarId !== "" ? classes.avatar : classes.avatarDefault} src={avatar}>
            {!accountDetail?.avatarId && accountDetail?.name?.charAt(0)}
          </Avatar>
        </Box>
      </Card>
      <Typography className={classes.name}>{accountDetail?.name}</Typography>
      <Box>
        <AccountStatus status={status} />
      </Box>
    </Box>
  );
};

export default memo(AccountCoverView);

const useStyles = makeStyles({
  root: {
    "& > *": { textAlign: "center" },
  },

  coverBox: {
    position: "relative",
    marginBottom: 12,
  },

  cover: {
    width: "100%",
    height: 180,
    marginBottom: 36,
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, darkgray 71.67%, darkgray 100%)",
  },

  coverDefault: {
    width: "100%",
    height: 180,
    marginBottom: 36,
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, darkgray 71.67%, darkgray 100%)",
  },

  avatarBox: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },

  avatar: {
    width: 120,
    height: 120,
    margin: "auto",
    border: "2px solid white",
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 71.67%, rgba(0, 0, 0, 0.5) 100%)",
  },

  avatarDefault: {
    width: 120,
    height: 120,
    margin: "auto",
    border: "2px solid white",
    fontSize: 48,
  },

  name: {
    fontWeight: 700,
    fontSize: 15,
  },
});
